import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LoginserviceService } from '../../service';
import { ProcessIdGeneraterService } from '../../service';
import { AddsenderdetailsService } from '../../frontservice';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AccessLogService } from '../../service';
import { CookieService } from 'ngx-cookie-service';
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { filter } from 'rxjs/operators';
declare const gtag: Function;
@Component({
  selector: 'app-frontlogin',
  templateUrl: './frontloginnew.component.html',
  styleUrls: ['./frontlogin.component.css'],
})
export class FrontloginComponent implements OnInit {
  public AccessLogForm: FormGroup;
  ipAddress: any;
  public userForm: FormGroup;
  public showForm: boolean;
  userType: any;
  pId: any;
  clearTime: any;
  fieldTextType: boolean;
  isloader: boolean;
  submitted = false;
  userId: any;
  invalidResponse: any;
  invalidResponseFlag: boolean;
  sendReceiveName: any;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private http: HttpClient,
    public bnIdle: BnNgIdleService,
    private _loginService: LoginserviceService,
    private _accessLogService: AccessLogService,
    private cookieService: CookieService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private addSenderdetails: AddsenderdetailsService,
    private processIdGeneraterService: ProcessIdGeneraterService
  ) {
    //     this._router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   /** START : Code to Track Page View  */
    //    gtag('event', 'page_view', {
    //       page_path: event.urlAfterRedirects
    //    })
    //   /** END */
    // })
  }

  ngOnInit(): void {
    this.getIPAddress();
    const o = {
      userName: this.cookieService.get('username'),
      password: this.cookieService.get('password'),
      rememberme: this.cookieService.get('remember'),
    };
    this.pId = this.processIdGeneraterService.getPid();
    this.isloader = true;
    this.userForm = new FormGroup({
      userName: new FormControl('', Validators.required),
      rememberme: new FormControl(''),
      password: new FormControl('', Validators.required),
    });
    if (this.cookieService.get('remember')) {
      this.userForm.patchValue(o);
    }
    this.AccessLogForm = new FormGroup({
      ipAddress: new FormControl(''),
      userType: new FormControl(''),
      userName: new FormControl(''),
      acessResult: new FormControl(''),
      userid: new FormControl(''),
    });
  }
  onforget() {
    this._router.navigate(['/forget']);
  }
  getIPAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((res: any) => {
        this.ipAddress = res.ip;
        //alert(this.ipAddress)
      });
  }
  opensignup() {
    this._router.navigate(['/signup']);
  }
  public get f() {
    return this.userForm.controls;
  }
  onHome() {
    this._router.navigate(['/']);
  }
  // autologout(exp:number){
  //   //alert(exp)
  //   this.clearTime=setTimeout(() => {
  //     alert('session expired please login to continue..')
  //    localStorage.removeItem('userId')
  //    localStorage.removeItem('datas')
  //    localStorage.removeItem('uname')
  //    localStorage.removeItem('userTypeName')
  //    localStorage.removeItem('accessId')
  //    this._router.navigate(['/']);
  //
  //  }, 60000*exp);
  // }

  public onSubmit() {
    clearTimeout(this.clearTime);
    this.submitted = true;
    if (this.userForm.invalid) {
      alert('Please enter the details');
      return;
    } else {
      this.isloader = false;
      this._loginService.postUser(this.userForm.value, this.pId).subscribe(
        (res) => {
          if (res) {
            console.log('login new user..' + JSON.stringify(res.response));
            let JSONDatas = res;
            localStorage.setItem('datas', JSONDatas.response.access_token);
            localStorage.setItem('uname', JSONDatas.response.user_name);
            localStorage.setItem(
              'refresh_token',
              JSONDatas.response.refresh_token
            );
            this.cookieService.set(
              'refresh_token',
              JSONDatas.response.refresh_token
            );
            // localStorage.setItem("registerAs", res.response.registerName);

            this.userType = res.response.user_scope;
            this.invalidResponse = res.response.server_message;
            if (this.invalidResponse != null) {
              this.invalidResponseFlag = true;
              setTimeout(
                function () {
                  this.invalidResponseFlag = false;
                }.bind(this),
                3000
              );
            } else {
              if (this.userForm.value.rememberme) {
                this.cookieService.set(
                  'username',
                  this.userForm.value.userName
                );
                this.cookieService.set(
                  'password',
                  this.userForm.value.password
                );
                this.cookieService.set(
                  'remember',
                  this.userForm.value.rememberme
                );
              }
            }

            this.userId = res.response.userId;
            //  this.bnIdle.resetTimer();
            localStorage.setItem('userTypeName', res.response.user_scope);
            if (this.userType == 'remitz_role_customer') {
              localStorage.setItem('userId', res.response.userId);
            } else {
              localStorage.setItem('adminId', res.response.userId);
            }
            console.log('userType==', this.userType);
            this.isloader = true;
            if (this.userType == undefined) {
            } else {
              // this.autologout(60000);
            }
            if (this.userType == 'remitz_role_customer') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Customer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              localStorage.setItem('customerId', res.response.userId);
              /////////////////////////////////get customer view by user id/////////////////////////////
              this.addSenderdetails
                .getCustomerByUserId(
                  localStorage.getItem('userId'),
                  this.processIdGeneraterService.getPid()
                )
                .subscribe(
                  (res) => {
                    //alert('full')
                    if (res.response.blocked == 'yes') {
                      alert('Your account is being verified');
                      localStorage.clear();
                      return;
                    }
                    if (res.response.status == 'inActive') {
                      alert('Your account is Deleted');
                      localStorage.clear();
                      return;
                    } else {
                      localStorage.setItem('flag', 'full');
                      localStorage.setItem(
                        'customer',
                        JSON.stringify(res.response)
                      );
                      localStorage.setItem(
                        'registerAs',
                        res.response.registerName
                      );
                      let retrievedObject = localStorage.getItem('newObj');
                      console.log(
                        'retrievedObject in login: ',
                        JSON.parse(retrievedObject)
                      );
                      if (res.response.countryName == 'NIGERIA') {
                        this._router.navigate(['/marketrate']);
                      } else {
                        this._router.navigate(['/index']);
                      }
                    }
                    //                   .then(() => {
                    // // window.location.reload();
                    // });
                  },
                  (err) => {
                    //alert('partial')
                    localStorage.setItem('flag', 'partial');

                    this.addSenderdetails
                      .getBusinessDetails(
                        this.processIdGeneraterService.getPid(),
                        res.response.userId
                      )
                      .subscribe((res) => {
                        if (res) {
                          console.log('busness......', res.response);
                          localStorage.setItem(
                            'businessform',
                            JSON.stringify(res.response)
                          );
                          localStorage.setItem(
                            'existingprofile',
                            res.response.existingProfile
                          );
                        }
                      });

                    this._loginService
                      .getUserDetailsById(
                        res.response.userId,
                        this.processIdGeneraterService.getPid()
                      )
                      .subscribe((res) => {
                        console.log('customer in error ...', res.response);
                        localStorage.setItem(
                          'signupform',
                          JSON.stringify(res.response)
                        );

                        if (res.response.registerName == 'Business') {
                          if (localStorage.getItem('existingprofile') == 'no') {
                            this._router.navigate(['/addpersonaldetailsl']);
                          } else if (
                            localStorage.getItem('existingprofile') == 'yes'
                          ) {
                            this._router.navigate(['/index']);
                          } else {
                            this._router.navigate(['/addBusinessDocuments']);
                          }
                        } else {
                          this._router.navigate(['/addpersonaldetailsl']);
                        }
                      }); //getUserDetailsById close

                    console.log(err);
                  }
                ); //getCustomerByUserId closed
            }

            if (this.userType == 'remitz_role_admin') {
              this._router.navigate(['branch']);
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Admin Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });
            }
            if (this.userType == 'remitz_role_complaince') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'compilance officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['complaincelist']);
            }

            if (this.userType == 'remitz_role_ticket_issue') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'ticketIssue officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['partialcustomerlist']);
            }

            if (this.userType == 'remitz_role_payout') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'payout officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }

            if (this.userType == 'remitz_role_payment_clearance') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Payment Clearance login success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }

            if (this.userType == 'remitz_role_customer_service') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'customer service officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }

            if (this.userType == 'remitz_role_bdm') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'business development officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }
            if (this.userType == 'remitz_role_accountant') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'accountant officer Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }

            if (this.userType == 'remitz_role_send_agent') {
              this._router.navigate(['srcagentexchangerate']);
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Sending Agent Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });
            }
            if (this.userType == 'remitz_role_rec_agent') {
              this._router.navigate(['transactionlisst']);
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Receiving Agent Login Success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });
            }

            if (this.userType == 'remitz_role_call_center') {
              const trObj = {
                ipAddress: this.ipAddress,
                userType: this.userType,
                userName: JSONDatas.response.user_name,
                acessResult: 'Payment Clearance login success',
                userid: this.userId,
              };
              this.AccessLogForm.patchValue(trObj);
              console.log(
                'this.AccessLogForm.value====',
                this.AccessLogForm.value
              );

              this._accessLogService
                .saveAccessLog(
                  this.AccessLogForm.value,
                  this.processIdGeneraterService.getPid()
                )
                .subscribe((res) => {
                  if (res) {
                    console.log('accesslog.....', res.response);
                    localStorage.setItem('accessId', res.response.acessId);
                  }
                });

              this._router.navigate(['transactionlist']);
            }

            if(this.userType=='remitz_role_marketing'){

              this._router.navigate(['partialcustomerlist']);
              const trObj={
    ipAddress:this.ipAddress,
    userType:this.userType,
    userName:JSONDatas.response.user_name,
    acessResult:'Sending Agent Login Success',
      userid:this.userId
  }
  this.AccessLogForm.patchValue(trObj);
  console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

  this._accessLogService.saveAccessLog(
  this.AccessLogForm.value,
   this.processIdGeneraterService.getPid()).subscribe(res => {
  if (res) {
    console.log('accesslog.....',res.response);
    localStorage.setItem("accessId", res.response.acessId);

  }
  });


            }

        }
      }, err => {
        this._router.navigate(['/login'])
        console.log(err);
      }

      );
    }
  }
  onclick() {
    this._router.navigate(['index']);
  }
  onclick1() {
    this._router.navigate(['/signup']);
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  openHome() {
    this._router.navigate(['/']);
  }
}
