import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AgentMappingService {
  apiUrl=environment.baseUrl

  public srcAgentsUrl: string = `${this.apiUrl}/user/getAgentsListByFunctionality`;

  public destAgentsUrl: string = `${this.apiUrl}/user/getDestAgentsListByFunctionalityAndAgentId`;

  public saveMappingUrl: string = `${this.apiUrl}/agent/saveAgentMapping`;

  public getAllMappingCountriesUrl:string=`${this.apiUrl}/agent/getAllMappingCountries`
public updateApiNameByIdUrl:string=`${this.apiUrl}/transaction/updateApiInfoById`;
public getApiNamesUrl:string=`${this.apiUrl}/utility/getAllApiNamesList`;
public updateExchRateApiNameByIdUrl:string=`${this.apiUrl}/exchRate/updateExchRateApiNameById`;

public addvelocityUrl:string=`${this.apiUrl}/utility/saveVelocityRules`;
public velocityListUrl:string=`${this.apiUrl}/utility/getVelocityList`;
public getVelocityListBySrcCountryIdUrl:string=`${this.apiUrl}/utility/getVelocityListBySrcCountry`;
public getVelocityListByDestCountryIdUrl:string=`${this.apiUrl}/utility/getVelocityListByDestCountry`;
public getVelocityListByStatusUrl:string=`${this.apiUrl}/utility/getVelocityListByStatus`;
public getVelocityListByPeriodTypeUrl:string=`${this.apiUrl}/utility/getVelocityListByPeriodType`;
public updateMappingStatusUrl:string=`${this.apiUrl}/agent/updateMappingStatus`;

  public retriveAgentBenifPaymentTypeListUrl: string = `${this.apiUrl}/agent/retriveAgentBenifPaymentTypeList`;
public updateBenifPaymentTypeStatusUrl:string=`${this.apiUrl}/agent/updateBenifPaymentTypeStatus`;




  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
    //.set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }

  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }
  public getSrcAgentsList(functionality: string,pId: string): Observable<any> {
    console.log("srcAgentsUrl  list.." + pId);
    return this._http.get(`${this.srcAgentsUrl}` + '?pId=' + pId+'&functionality='+functionality, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getDestAgentsList(functionality: string,agentId:any,pId: string): Observable<any> {
    console.log("destAgentsUrl  list.." + pId);
    return this._http.get(`${this.destAgentsUrl}` + '?pId=' + pId+'&functionality='+functionality+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public createAgentMapping(mappingRecord: any,pId:string): Observable<any> {
    console.log('mappingRecord....',pId);
    return this._http.post(this.saveMappingUrl + '?pId=' + pId, mappingRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  //getVelocityListBySrcCountryIdUrl
  public getVelocityListBySrcCountryId(srcCounryId: string,pId: string): Observable<any> {
    console.log("getVelocityListBySrcCountryIdUrl  list.." + pId);
    return this._http.get(`${this.getVelocityListBySrcCountryIdUrl}` + '?pId=' + pId+'&srcCounry='+srcCounryId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  //getVelocityListByStatusUrl
  public getVelocityListByStatus(status: string,pId: string): Observable<any> {
    console.log("getVelocityListByStatusUrl  list.." + pId);
    return this._http.get(`${this.getVelocityListByStatusUrl}` + '?pId=' + pId+'&status='+status, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

//getVelocityListByDestCountryIdUrl
public getVelocityListByDestCountryId(destCounryId: string,pId: string): Observable<any> {
  console.log("getVelocityListByDestCountryIdUrl  list.." + pId);
  return this._http.get(`${this.getVelocityListByDestCountryIdUrl}` + '?pId=' + pId+'&destCounry='+destCounryId, this.httpOptions).pipe(
    map(this.extractData)
  );
}
//getVelocityListByPeriodTypeUrl
public getVelocityListByPeriodType(periodType: string,pId: string): Observable<any> {
  console.log("getVelocityListByPeriodTypeUrl  list.." + pId);
  return this._http.get(`${this.getVelocityListByPeriodTypeUrl}` + '?pId=' + pId+'&periodType='+periodType, this.httpOptions).pipe(
    map(this.extractData)
  );
}

//addvelocityUrl
public addvelocity(record: any,pId:string): Observable<any> {
  console.log('addvelocityUrl....',pId);
  return this._http.post(this.addvelocityUrl + '?pId=' + pId, record, this.httpOptions).pipe(
    map(this.extractData)
  );
}

  public getAllMappingCountries(pId:string): Observable<any> {
    console.log('mapping countries....',pId);
    return this._http.get(this.getAllMappingCountriesUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAllApiNamesList(pId:string): Observable<any> {
    console.log('getAllApiNames....',pId);
    return this._http.get(this.getApiNamesUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
//velocityListUrl
public velocityList(pId:string): Observable<any> {
  console.log('velocityListUrl....',pId);
  return this._http.get(this.velocityListUrl + '?pId=' + pId, this.httpOptions).pipe(
    map(this.extractData)
  );
}

  public updateApiNameById(pId:string,id:any,val:any): Observable<any> {
    console.log('updateApiNameById....',pId);
    return this._http.put(this.updateApiNameByIdUrl + '?pId=' + pId+'&apiInfoId='+id+'&apiName='+val, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public updateExchRateApiNameById(pId:string,id:any,val:any): Observable<any> {
    console.log('updateExchRateApiNameById....',pId);
    return this._http.put(this.updateExchRateApiNameByIdUrl + '?pId=' + pId+'&exchRateId='+id+'&apiName='+val, this.httpOptions).pipe(
      map(this.extractData)
    );
  }


  public updateMappingStatus(pId:string,id:any,status:any): Observable<any> {
console.log('updateMappingStatusUrl....',pId);
return this._http.get(this.updateMappingStatusUrl + '?pId=' + pId+'&id='+id+'&status='+status).pipe(
map(this.extractData)
);
}

//retriveAgentBenifPaymentTypeListUrl
public retriveAgentBenifPaymentTypeList(pId:string): Observable<any> {
console.log('mapping retriveAgentBenifPaymentTypeListUrl....',pId);
return this._http.get(this.retriveAgentBenifPaymentTypeListUrl + '?pId=' + pId).pipe(
map(this.extractData)
);
}
//updateBenifPaymentTypeStatusUrl
public updateBenifPaymentTypeStatus(pId:string,id:any,status:any): Observable<any> {
console.log('updateBenifPaymentTypeStatusUrl....',pId);
return this._http.get(this.updateBenifPaymentTypeStatusUrl + '?pId=' + pId+'&id='+id+'&status='+status).pipe(
map(this.extractData)
);
}

}
