import { Injectable } from '@angular/core';
import {HttpHeaders,HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators'
import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {

  apiUrl=environment.baseUrl
  public getAllPendingTransactionsUrl:string=`${this.apiUrl}/transaction/getAllPendingTransactions`;
public sendFliqPayRequestUrl:string =`${this.apiUrl}/fliqpay/makeAPayout`;
public maxAmountUrl:string=`${this.apiUrl}/customer/getMaxAmountByCountryId`;
public sendGeoSwiftRequestUrl:string =`${this.apiUrl}/geoSwift/remitRequest`;
public getAllPendingFliqpayTransactionsUrl:string=`${this.apiUrl}/transaction/getAllPendingFliqpayTransactions`;
public getAllFliqpayTransactionsUrl:string=`${this.apiUrl}/fliqpay/getAllFliqpayTransactions`;
public getAllFliqpayTransactionsBasedOnDateAndStatusUrl:string=`${this.apiUrl}/fliqpay/getAllFliqpayTransactionsBasedOnDateAndStatus`;
public getAllGswiftTransactionsBasedOnDateAndStatusUrl:string=`${this.apiUrl}/geoSwift/getAllGswiftTransactionsBasedOnDateAndStatus`;
public getAllNsanoTransactionsBasedOnDateAndStatusUrl:string=`${this.apiUrl}/nsano/getAllNsanoTransactionsBasedOnDateAndStatuss`;
public getAllAlianzaTransactionsBasedOnDateAndStatussUrl:string=`${this.apiUrl}/allianza/getAllAlianzaTransactionsBasedOnDateAndStatuss`;
public fliqpayBankValidateUrl:string=`${this.apiUrl}/fliqpay/resolveBankBasedOnAccountAndCode`;
public sendNsanoRequestUrl:string=`${this.apiUrl}/nsano/performNsanoDeposit`;
public AlianzaMakePaymentUrl:string=`${this.apiUrl}/allianza/makePayment`;
public AlianzaGetPaymentUrl:string=`${this.apiUrl}/allianza/getPaymentStatus`;
public changeAllFliqpayStatusUrl:string =`${this.apiUrl}/fliqpay/getFliqpayTransactionStatusByTransactionId`;
public getAllFliqpayTransactionsBasedOnStatusUrl:string=`${this.apiUrl}/fliqpay/getAllFliqpayTransactionsBasedOnStatus`;
public getGswiftTransactionsBasedOnTransactionStatusUrl:string=`${this.apiUrl}/geoSwift/getTransactionListByStatus`;
public changeTransactionStatusUrl:string=`${this.apiUrl}/geoSwift/remitEnquiry`;
public getSupportedCardEnquiryUrl:string=`${this.apiUrl}/geoSwift/getSupportedCardEnquiry`;
public getVirtualAccountsByStatusUrl:string=`${this.apiUrl}/fincra/getVirtualAccountsByStatus`;
public getVirtualAccountDetailsByUserIdUrl:string=`${this.apiUrl}/fincra/getVirtualAccountDetailsByUserId`;
public fkaaraBankListUrl:string=`${this.apiUrl}/kaara/getBanksList`;
public kaaraBankValidateUrl:string=`${this.apiUrl}/kaara/validateAccountNumber`;

public getAllKaaraTransactionsBasedOnDateUrl:string=`${this.apiUrl}/kaara/getKaaraBankTransactionsHistory`;
public getAllKaaraTransactionsBasedOnDateAndStatussUrl:string=`${this.apiUrl}/kaara/getAllKaaraaTransactionsBasedOnDateAndStatuss`;
public KaaraMakePaymentUrl:string=`${this.apiUrl}/kaara/makeBankDisbursement`;
public KaaraMakeRePaymentUrl:string=`${this.apiUrl}/kaara/resendToMakeBankDisbursement`;
public KaaraGetPaymentUrl:string=`${this.apiUrl}/kaara/getKaaraTransactionsBasedOnrequestId`;
public getWalletBalenceUrl:string=`${this.apiUrl}/kaara/getWalletBalance`;
public getAllKaaraBankDisbursementHistory:string=`${this.apiUrl}/kaara/getKaaraBankTransactionsHistory`;
public changePaymentStatusToPaidManuallyUrl:string=`${this.apiUrl}/kaara/changeTransactinStatusToPaidManullay`;
public getAllProcessingKaaraTransactionsUrl:string=`${this.apiUrl}/kaara/getAllProcessingKaaraTransactions`;
public getAllProcessingKaaraTransactionslessThanSendingAmountsUrl:string=`${this.apiUrl}/kaara/getAllProcessingKaaraTransactionsLessThanSendingAmount`;
public mtbsOutgointPaymentUrl:string=`${this.apiUrl}/mtbs/mtbsOutGoingPayment`;
public getAllMtbsTransactionHistotyUrl:string=`${this.apiUrl}/mtbs/getAccountStatements`;
public getPayeeOrPaymentComplianceStatusByIUrl:string=`${this.apiUrl}/mtbs/PayeeOrPaymentComplianceStatusById`;



httpOptions = {
      headers: new HttpHeaders({ 'content-type': 'application/json' })
      //.set('Authorization', localStorage.getItem("datas"))

    };



    constructor(private _http:HttpClient) { }

    private extractData(res:Response): Array <any> | {}{
      const body=res;
      console.log('res',res)
      return body || {}



    }

    public sendGswiftRequest(pId:string,id:any):Observable<any>{
      return this._http.post(`${this.sendGeoSwiftRequestUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
        map(this.extractData)
      )
    }
    public getMaxAmountByCountryId(countryId:any,pId: string): Observable<any> {
       console.log("getMaxAmountByCountryId..." + countryId);
       return this._http.get(`${this.maxAmountUrl}` + '?pId=' + pId+'&countryId='+countryId).pipe(
         map(this.extractData)
       );
     }

     public getVirtualAccountsByStatus(status:any,pId: string): Observable<any> {
      console.log("getVirtualAccountsByStatusUrl..." + status);
      return this._http.get(`${this.getVirtualAccountsByStatusUrl}` + '?pId=' + pId+'&status='+status).pipe(
        map(this.extractData)
      );
    }

    public getVirtualAccountDetailsByUserId(userId:any,pId: string): Observable<any> {
      console.log("getVirtualAccountDetailsByUserId..." + userId);
      return this._http.get(`${this.getVirtualAccountDetailsByUserIdUrl}` + '?pId=' + pId+'&userId='+userId).pipe(
        map(this.extractData)
      );
    }


    public sendFliqPayRequest(pId:string,id:any):Observable<any>{
      return this._http.post(`${this.sendFliqPayRequestUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
        map(this.extractData)
      )
    }


   public getAllPendingTransactions(pId:string,status:any,apiName:any,apiStatus:any):Observable<any>{
     return this._http.get(`${this.getAllPendingTransactionsUrl}`+'?pId='+pId+'&status='+status+'&apiName='+apiName+'&apiStatus='+apiStatus,this.httpOptions).pipe(
       map(this.extractData)
     )
   }
   public getAllPendingFliqpayTransactions(pId:string,status:any,apiStatus:any):Observable<any>{
     return this._http.get(`${this.getAllPendingFliqpayTransactionsUrl}`+'?pId='+pId+'&status='+status+'&apiStatus='+apiStatus,this.httpOptions).pipe(
       map(this.extractData)
     )
   } 

   public getAllFliqpayTransactions(pId:string):Observable<any>{
     return this._http.get(`${this.getAllFliqpayTransactionsUrl}`+'?pId='+pId,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public getAllFliqpayTransactionsBasedOnStatusAndDate(pId:string,fromDate:any,toDate:any,status:any):Observable<any>{
     return this._http.get(`${this.getAllFliqpayTransactionsBasedOnDateAndStatusUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public getAllGswiftTransactionsBasedOnStatusAndDate(pId:string,fromDate:any,toDate:any,status:any):Observable<any>{

     return this._http.get(`${this.getAllGswiftTransactionsBasedOnDateAndStatusUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public getAllNsanoTransactionsBasedOnDateAndStatus(pId:string,fromDate:any,toDate:any,status:any):Observable<any>{

     return this._http.get(`${this.getAllNsanoTransactionsBasedOnDateAndStatusUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public getAllAlianzaTransactionsBasedOnDateAndStatus(pId:string,fromDate:any,toDate:any,status:any):Observable<any>{

     return this._http.get(`${this.getAllAlianzaTransactionsBasedOnDateAndStatussUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public sendNsanoRequest(pId:string,id:any):Observable<any>{
     return this._http.post(`${this.sendNsanoRequestUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
       map(this.extractData)
     )
   }


   public changePaymentStatus(pId:string,id:any):Observable<any>{
     return this._http.get(`${this.AlianzaGetPaymentUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
       map(this.extractData)
     )
   }


   public sendAlianzaPayment(pId:string,id:any):Observable<any>{
     return this._http.post(`${this.AlianzaMakePaymentUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
       map(this.extractData)
     )
   }
   public getAllFliqpayTransactionsBasedOnStatus(status:string,pId:string):Observable<any>{

     return this._http.get(`${this.getAllFliqpayTransactionsBasedOnStatusUrl}`+'?pId='+pId+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public changeAllFliqpayStatus(id:any,pId:string):Observable<any>{

     return this._http.get(`${this.changeAllFliqpayStatusUrl}`+'?pId='+pId+'&transactionId='+id,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public getGswiftTransactionsBasedOnTransactionStatus(pId:string,status:string):Observable<any>{

     return this._http.get(`${this.getGswiftTransactionsBasedOnTransactionStatusUrl}`+'?pId='+pId+'&status='+status,this.httpOptions).pipe(
       map(this.extractData)
     )
   }
   public changeTransactionStatus(pId:string,txnId:any):Observable<any>{

     return this._http.get(`${this.changeTransactionStatusUrl}`+'?pId='+pId+'&txnId='+txnId,this.httpOptions).pipe(
       map(this.extractData)
     )
   }
   public getSupportedCardEnquiry(pId:string,cardNumber:any):Observable<any>{

     return this._http.get(`${this.getSupportedCardEnquiryUrl}`+'?pId='+pId+'&cardNumber='+cardNumber,this.httpOptions).pipe(
       map(this.extractData)
     )
   }

   public fliqpayBankValidate(pId:string,accountNumber:any,bankCode:any):Observable<any>{

    return this._http.get(`${this.fliqpayBankValidateUrl}`+'?pId='+pId+'&accountNumber='+accountNumber+'&bankCode='+bankCode,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getKaaraBanks(pId:string):Observable<any>{

    return this._http.get(`${this.fkaaraBankListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public validateBankAccount(pId:string,accountNumber:any,bankCode:any):Observable<any>{

    return this._http.get(`${this.kaaraBankValidateUrl}`+'?pId='+pId+'&accountNumber='+accountNumber+'&bankCode='+bankCode,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  


  public getAllKaaraTransactionsBasedOnDate(pId:string,fromDate:any,toDate:any):Observable<any>{

    return this._http.get(`${this.getAllKaaraTransactionsBasedOnDateUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllKaaraTransactionsBasedOnDateAndStatus(pId:string,fromDate:any,toDate:any,status:any):Observable<any>{

    return this._http.get(`${this.getAllKaaraTransactionsBasedOnDateAndStatussUrl}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate+'&status='+status,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllProcessingKaaraTransactions(pId:string,status:any):Observable<any>{

    return this._http.get(`${this.getAllProcessingKaaraTransactionsUrl}`+'?pId='+pId+'&status='+status,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  
  public getAllProcessingKaaraTransactionslessThanSendingAmount(pId:string,status:any,amount:any):Observable<any>{

    return this._http.get(`${this.getAllProcessingKaaraTransactionslessThanSendingAmountsUrl}`+'?pId='+pId+'&status='+status+'&amount='+amount,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  // public sendKaaraPayment(pId:string,id:any):Observable<any>{
  //   return this._http.post(`${this.KaaraMakePaymentUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
  //     map(this.extractData)
  //   )
  // }


  public sendKaaraPayment(pId: string, id: any, ): Observable<any> {
    return this._http.post(`${this.KaaraMakePaymentUrl}`+'?pId='+pId,id,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public sendMtbsOutgointPayment(pId: string, id: any, ): Observable<any> {
    return this._http.post(`${this.mtbsOutgointPaymentUrl}`+'?pId='+pId,id,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public reSendKaaraPayment(pId: string, id: any, ): Observable<any> {
    return this._http.post(`${this.KaaraMakeRePaymentUrl}`+'?pId='+pId,id,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public changePaymentStatuss(pId:string,id:any):Observable<any>{
    return this._http.get(`${this.KaaraGetPaymentUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
      map(this.extractData)
    )
  }  public getWalletBalence(pId:string):Observable<any>{
    return this._http.get(`${this.getWalletBalenceUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllKaaraBankDisburesementHistory(pId:string,fromDate:any,toDate:any):Observable<any>{

    return this._http.get(`${this.getAllKaaraBankDisbursementHistory}`+'?pId='+pId+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getAllKaaraBankDisburesementHistory1():Observable<any>{

    return this._http.get("https://dev.tellerpoint.hextremelabs.com/Tellerpoint/rpc/merchant/external/disbursement/history?start=2023-10-02%2000%3A00%3A00%20%2B0100&end=2023-10-16%2000%3A00%3A00%20%2B0100",this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  
  public changePaymentStatusToPaidManually(pId:string,id:any):Observable<any>{
    return this._http.get(`${this.changePaymentStatusToPaidManuallyUrl}`+'?pId='+pId+'&txId='+id,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllMtbsTransactionHistoty(IBAN:string,fromDate:any,toDate:any):Observable<any>{

    return this._http.post(`${this.getAllMtbsTransactionHistotyUrl}`+'?IBAN='+IBAN+'&fromDate='+fromDate+'&toDate='+toDate,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getPayeeOrPaymentComplianceStatusById(pId:string):Observable<any>{

    return this._http.post(`${this.getPayeeOrPaymentComplianceStatusByIUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


}


