
<style media="screen">
	.bg-dark{
			opacity:1;
		        background: linear-gradient(to bottom, #3b64bf 0%,#142854 100%);

		}
</style>
<div class="be-top-header  be-top-header bg-dark"  >
<div class="container-auto " >
<div>
 <nav class="navbar navbar-expand fixed-top be-top-header bg-dark">
     <div class="container">

        <div class="be-navbar-header"  >
             <a class="navbar-brand"   href="javascript:void(0)" (click)="onindex()"  >
                 <img class="img" src="/assets/images/logo-png.png"></a>
         </div>

         <!-- <div class="be-navbar-header" >
             <a class="navbar-brand"  href="javascript:void(0)" ><img class="img" src="/assets/images/logo-png.png"></a>
         </div> -->
         <!-- <div class="page-title text-white"><span> Activities</span></div> -->
         <div class="be-right-navbar" >
             <div class="mobile-brand mr-auto">
                  <a href="javascript:void(0)" (click)="onindex()">
                      <img src="/assets/images/logo-png.png" class="img"></a> </div>
             <ul class="nav navbar-nav float-right be-user-nav">
                 <!--<li class="nav-item show-min-768">
                                         <a class="nav-link" href="#"> Abjkbout ABC money </a>
                                            </li>-->
                 <li class="nav-item show-min-768 text-white" >
                      <a class="nav-link mr-15 text-white" href="https://support.tayrexchange.com.au/"                      >Customer Support?
                                         </a> </li>
                                         <li class="nav-item show-min-768 text-white"  >
                                             <a class="nav-link mr-15 text-white"  href="javascript:void(0)" (click)="onindex()" >
                                                 <img class="snd-cancel-img" src="/assets/images/cancel.png"></a>
                                         </li>
                 <li class="nav-item dropdown mob-mr-20" >
                     <a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" role="button" aria-expanded="false">
                            <img src="/assets/images/avatar.svg" alt="Avatar"><div class="user-name pro-down-action text-white">{{userFirstName | titlecase}}
                            </div> </a>
                     <div class="dropdown-menu" role="menu" >
                         <div class="user-info">
                                                        <div class="user-name"> {{userFirstName}} {{userLastName}}</div>
                             <div class="user-position online">Available</div>
                         </div>
                         <a class="dropdown-item" href="javascript:void(0)" (click)="openmyprofile()" > <i class="fas fa-user-circle fa-dr-user-pro"></i> Profile</a>
                         <a class="dropdown-item" href="javascript:void(0)" (click)="onchangepassword()"> <i class="fas fa-lock fa-dr-user-pro"></i> Change Password</a>
                         <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"> <i class="fas fa-sign-out-alt fa-dr-user-pro"></i> Logout</a>
                     </div>
                 </li>
             </ul>
         </div>
     </div>
 </nav>
<!--
 <nav class="navbar navbar-light">
     <a class="navbar-brand" href="dashboard"> <img class="send-logo img" src="/assets/images/logo-png.png"> </a>
     <ul class="snd-right-txt">
         <li class="nav-item show-min-576"> <a class="nav-link" href="/supports">  Customer Support? </a> </li>
         <li class="nav-item">
             <a class="nav-link" href="javascript:void(0)" (click)="openindex()"><img class="snd-cancel-img" src="/assets/images/cancel.png"></a>
         </li>

     </ul>

 </nav> -->


</div>
</div>
</div>
<br><br>
