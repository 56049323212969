import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  TransactionService,
  LogOutService,
  LoginserviceService,
} from '../../service';
import { ProcessIdGeneraterService } from '../../service';
import { AccessLogService } from '../../service';
import { FormGroup, FormControl } from '@angular/forms';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-indexheader',
  templateUrl: './indexheadernew.component.html',
  styleUrls: ['./indexheadernew.component.css'],
})
export class IndexheaderComponent implements OnInit {
  isnaviagationbar: boolean;
  isindexnavbar: boolean;
  isShow: boolean;
  userLastName: any;
  userFirstName: any;
  senderdetailsnavbar: boolean;
  userId: any;
  documentsValidDate: boolean;
  beneficiaryFlag: boolean;
  senderFlag: any;
  idVerifiedStatusFlag: any;
  mobileShow: boolean;
  AccessLogForm: FormGroup;
  sourceCountryName = 'NIGERIA';
  registerName: any;
  constructor(
    public router: Router,

    private bnIdle: BnNgIdleService,
    private _transactionService: TransactionService,
    private processIdGeneraterService: ProcessIdGeneraterService,
    private _route: ActivatedRoute,
    private _accessLogService: AccessLogService,
    private _loginService: LoginserviceService,
    public _router:Router,
    private logoutService: LogOutService
  ) {}

  activeButton: string | null = null; // Nitin

  ngOnInit(): void {
    this.senderFlag = localStorage.getItem('senderFlag');
    this.idVerifiedStatusFlag = localStorage.getItem('idVerifiedStatusFlag');
    this.AccessLogForm = new FormGroup({
      ipAddress: new FormControl(''),
      userType: new FormControl(''),
      userName: new FormControl(''),
      acessResult: new FormControl(''),
      userid: new FormControl(''),
    });

    this.isindexnavbar = false;
    this.senderdetailsnavbar = true;
    this.userId = localStorage.getItem('userId');
    this._transactionService
      .getCustomerById(this.userId, this.processIdGeneraterService.getPid())
      .subscribe(
        (res) => {
          if (res) {
            this.sourceCountryName = res.response.countryName;
            this.registerName=res.response.registerName;
            var d = new Date();
            var e = new Date(res.response.validTo);
            var diff = e.getTime() - d.getTime();
            var diffDays = Math.floor(diff / (1000 * 3600));

            if (diffDays < 7) {
              this.documentsValidDate = true;
            } else {
              this.documentsValidDate = false;
            }

            this.senderFlag = 'full';
            if (res.response.identityVerifiedStatus === 'Verified') {
              this.idVerifiedStatusFlag = 'true';
            } else {
              this.idVerifiedStatusFlag = 'false';
            }
            console.log('user', res.response);
            this.userFirstName = res.response.firstName;

            this.userLastName = res.response.lastName;
            localStorage.setItem(
              'fullName',
              this.userFirstName + ' ' + this.userLastName
            );
          }
        },
        (err) => {
          this._loginService
            .getUserDetailsById(
              this.userId,
              this.processIdGeneraterService.getPid()
            )
            .subscribe((res) => {
              if (res) {
                console.log('user responde....', res.response);
                this.sourceCountryName = res.response.countryName;
                this.idVerifiedStatusFlag = 'false';
                this.senderFlag = 'partial';
                this.userFirstName = res.response.firstName;

                this.userLastName = res.response.lastName;
              }
            });
        }
      ); //
    // if(this._route.routeConfig.path=='/login'  ){
    //
    // this.isShow=false;
    // this.senderdetailsnavbar=true;
    // }
    // if(this._route.routeConfig.path== '/signup'  ){
    //
    // this.isShow=false;
    // this.senderdetailsnavbar=true;
    // }
    // else{
    //   this.isShow=true;
    // }



      // const buttons = document.querySelectorAll('.new-btn');
      //   buttons.forEach((button: HTMLButtonElement) => {
      //     button.addEventListener('click', () => {
      //       // Remove red color from all buttons
      //       document.querySelectorAll('.new-btn2').forEach(btn => btn.classList.remove('new-btn2'));
    
      //       // Add red color to the clicked button
      //       button.classList.add('new-btn2');
    
      //       // Store the clicked button ID in localStorage
      //       localStorage.setItem('activeButtonId', button.id);
      //       console.log('activeBUttonId', button.id)
     
      //     });
    
      //   });


    const userProfileContainer = document.querySelector(
      '.user_profile_dropdown_content'
    ) as HTMLDivElement;
    const languageContainer = document.querySelector(
      '.language_dropdown_content'
    ) as HTMLDivElement;
    const notificationContainer = document.querySelector(
      '.notification_dropdown_content'
    ) as HTMLDivElement;

    notificationContainer.style.display = 'none';
    userProfileContainer.style.display = 'none';
    languageContainer.style.display = 'none';
  }

  
  // addNewBtn2Class() {
  //   const activeButtonId = localStorage.getItem('activeButtonId');
  //   console.log('active Id', activeButtonId);
  //   if (activeButtonId) {
  //     const button = document.getElementById(activeButtonId);
  //     console.log('button', button);
  //     if (button) {
  //       button.classList.add('new-btn2');
  //       console.log('button', button);
  //       console.log("active");
  //     }
  //   }
  // } 

 

  

  // onShow() {
  //   this.mobileShow = !this.mobileShow;
  // }

  onSend() {
    if (this.documentsValidDate) {
      alert(' Your id proof is expired, Please update to make a transaction');
      return;
    }
    if (this.idVerifiedStatusFlag === 'true') {
      if (this.senderFlag === 'full') {
        this.router.navigate(['/senderdetails']);
      } else {
        alert(
          'Sorry, Your id proof is under review and once approved you will be able to make a transaction'
        );
      }
    } else {
      alert(
        'Sorry, Your id proof is under review and once approved you will be able to make a transaction'
      );
    }
  }

  onTransaction() {
    if (this.documentsValidDate) {
      alert(' Your id proof is expired, Please update to make a transaction');
      return;
    }
    if (this.idVerifiedStatusFlag === 'true') {
      if (this.senderFlag === 'full') {
        this.router.navigate(['/trlist']);
      } else {
        alert(
          'Sorry, Unable to list Transactions as there are no transactions available to list'
        );
      }
    } else {
      alert(
        'Sorry, Unable to list Transactions as there are no transactions available to list'
      );
    }
  }
  onBenif() {
    this.router.navigate(['/recipient']);
    //   if(this.idVerifiedStatusFlag==='true'){
    //     if(this.senderFlag==='full'){
    //       this.router.navigate(['/recipient'])
    //     }
    //     else{
    //       alert('Sorry, Unable to list recipient')
    //     }
    //
    // }
    // else{
    //   alert('Sorry, Unable to list recipient')
    // }
  }
  deleteAccount(){
    this._loginService.deactivateAccount(localStorage.getItem('userId'),
  'inActive',this.processIdGeneraterService.getPid()).subscribe(res=>{
    if(res.response=='record updated'){

     localStorage.clear();
     this._router.navigate(['/login'])

    }
  })
}


  logout() {
    if (
      localStorage.getItem('accessId') != null &&
      localStorage.getItem('accessId') != 'undefined'
    ) {
      this._accessLogService
        .getAccessLogByAccessIdAndUserId(
          localStorage.getItem('accessId'),
          localStorage.getItem('userId'),
          this.processIdGeneraterService.getPid()
        )
        .subscribe((res) => {
          if (res) {
            console.log('accesslog....', res.response);
            this._accessLogService
              .updateAccessLogByAccessId(
                localStorage.getItem('accessId'),
                this.processIdGeneraterService.getPid()
              )
              .subscribe((res) => {
                if (res) {
                  console.log('accesslog.....', res.response);
                  localStorage.setItem('accessId', res.response.accessId);
                  this.logoutService.logOut().subscribe((res) => {
                    if (res) {
                      localStorage.clear();
                      this.router.navigate(['/']);
                    }
                  }); //logout closed
                } //if closed
              }); //update accesslog closed
          } //if closed
        }); //get accesslog closed
    } //if access id closed
    else {
      localStorage.clear();
      this.router.navigate(['/']);
    }
  }
  onindex() {
    this.router.navigate(['index']);
  }
  omhome() {
    this.router.navigate(['/']);
  }
  onforget() {
    this.router.navigate(['/forget']);
  }
  opencustomersupport() {
    this.router.navigate(['/customersupport']);
  }
  opendocuments() {
    this.router.navigate(['/documents']);
  }
  openmyprofile() {
    this.router.navigate(['/myprofile']);
  }
  openwallet() {
    this.router.navigate(['/wallet']);
  }
  openreferal() {
    this.router.navigate(['/myreferal']);
  }
  onchangepassword() {
    this.router.navigate(['/changepassword']);
  }
  ondesiredrate() {
    this.router.navigate(['/desiredrate']);
  }
  onmarketrate() {
    this.router.navigate(['/marketrate']);
  }
  onairtimetopup() {
    this.router.navigate(['/airtimetopup']);
  }
  onbillpayment() {
    this.router.navigate(['/billpayment']);
  }

  // Added by Martins

  toggleShowLanguage() {
    const languageContainer = document.querySelector(
      '.language_dropdown_content'
    ) as HTMLDivElement;
    const notificationContainer = document.querySelector(
      '.notification_dropdown_content'
    ) as HTMLDivElement;
    const userProfileContainer = document.querySelector(
      '.user_profile_dropdown_content'
    ) as HTMLDivElement;
    if (
      notificationContainer.style.display === 'flex' ||
      userProfileContainer.style.display === 'flex'
    ) {
      notificationContainer.style.display = 'none';
      userProfileContainer.style.display = 'none';
    }

    if (languageContainer.style.display === 'flex') {
      languageContainer.style.display = 'none';
    } else {
      languageContainer.style.display = 'flex';
    }
  }

  toggleNotification() {
    const languageContainer = document.querySelector(
      '.language_dropdown_content'
    ) as HTMLDivElement;
    const notificationContainer = document.querySelector(
      '.notification_dropdown_content'
    ) as HTMLDivElement;
    const userProfileContainer = document.querySelector(
      '.user_profile_dropdown_content'
    ) as HTMLDivElement;

    if (
      userProfileContainer.style.display === 'flex' ||
      languageContainer.style.display === 'flex'
    ) {
      languageContainer.style.display = 'none';
      userProfileContainer.style.display = 'none';
    }

    if (notificationContainer.style.display === 'flex') {
      notificationContainer.style.display = 'none';
    } else {
      notificationContainer.style.display = 'flex';
    }
  }

  toggleUserProfile() {
    const languageContainer = document.querySelector(
      '.language_dropdown_content'
    ) as HTMLDivElement;
    const notificationContainer = document.querySelector(
      '.notification_dropdown_content'
    ) as HTMLDivElement;
    const userProfileContainer = document.querySelector(
      '.user_profile_dropdown_content'
    ) as HTMLDivElement;

    if (
      notificationContainer.style.display === 'flex' ||
      languageContainer.style.display === 'flex'
    ) {
      languageContainer.style.display = 'none';
      notificationContainer.style.display = 'none';
    }

    if (userProfileContainer.style.display === 'flex') {
      userProfileContainer.style.display = 'none';
    } else {
      userProfileContainer.style.display = 'flex';
    }
  }

  toggleSideNavbar() {
    const sideNav = document.querySelector(".user_dashboard_aside") as HTMLDivElement;
    sideNav.classList.toggle("aside_out");
  }

  openmybusinessprofile(){
    this.router.navigate(['/businessprofile'])
  }
  
}
