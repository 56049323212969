import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutusComponent } from './headerpages/aboutus/aboutus.component';
import { ContactusComponent } from './headerpages/contactus/contactus.component';
import { FaqsComponent } from './headerpages/faqs/faqs.component';
import { HowitworksComponent } from './headerpages/howitworks/howitworks.component';
import { DeleteaccountComponent } from './headerpages/deleteaccount/deleteaccount.component';



const routes: Routes = [
  {
    path:'',
    loadChildren:()=>import('./auth/auth.module').then(m=> m.AuthModule)
  },
  
  {
path:'contactus',
component:ContactusComponent
  },
  {
path:'faqs',
component:FaqsComponent
  },
  {
path:'aboutus',
component:AboutusComponent
  },
  {
path:'help',
component:HowitworksComponent
  },{
  path:'deleteaccount',
component:DeleteaccountComponent
  },
  { path: 'adminCustomerprofile', loadChildren: () => import('./remitz/admin-customerprofile/admin-customerprofile.module').then(m => m.AdminCustomerprofileModule) },
   { path: 'deposit', loadChildren: () => import('./remitz/adminagentdeposit/adminagentdeposit.module').then(m => m.AdminagentdepositModule) },
    { path: 'adminCustomerid', loadChildren: () => import('./remitz/admincustomer/admincustomer.module').then(m => m.AdmincustomerModule) },
     { path: 'agenttt', loadChildren: () => import('./remitz/agent/agent.module').then(m => m.AgentModule) },
   { path: 'agent-collection-markup', loadChildren: () => import('./remitz/agent-collection-markup/agent-collection-markup.module').then(m => m.AgentCollectionMarkupModule) },
   { path: 'agentmapping', loadChildren: () => import('./remitz/agent-mapping/agent-mapping.module').then(m => m.AgentMappingModule) },
    { path: 'agent', loadChildren: () => import('./remitz/agent-with/agent-with.module').then(m => m.AgentWithModule) },
     { path: 'agentdeposit', loadChildren: () => import('./remitz/agentdepositlist/agentdepositlist.module').then(m => m.AgentdepositlistModule) },
     { path: 'agentdepositlist', loadChildren: () => import('./remitz/agentdepositt/agentdepositt.module').then(m => m.AgentdeposittModule) },
   { path: 'agentwithbranch', loadChildren: () => import('./remitz/agentwith-branch/agentwith-branch.module').then(m => m.AgentwithBranchModule) },

    { path: 'internetbankdetails', loadChildren: () => import('./remitz/bank-details/bank-details.module').then(m => m.BankDetailsModule) },
     { path: 'banks', loadChildren: () => import('./remitz/banks/banks.module').then(m => m.BanksModule) },
   { path: 'branch', loadChildren: () => import('./remitz/branch/branch.module').then(m => m.BranchModule) },

   { path: 'branchcreatt', loadChildren: () => import('./remitz/branchcreatt/branchcreatt.module').then(m => m.BranchcreattModule) },
    { path: 'cashpickup', loadChildren: () => import('./remitz/cashpickup/cashpickup.module').then(m => m.CashpickupModule) },
    { path: 'createemail', loadChildren: () => import('./remitz/create-email/create-email.module').then(m => m.CreateEmailModule) },
    { path: 'customer', loadChildren: () => import('./remitz/customer/customer.module').then(m => m.CustomerModule) },
     { path: 'customerlist', loadChildren: () => import('./remitz/customerlistr/customerlistr.module').then(m => m.CustomerlistrModule) },
      { path: 'destagentexchratest', loadChildren: () => import('./remitz/dest-agent-exch-rates/dest-agent-exch-rates.module').then(m => m.DestAgentExchRatesModule) },
    { path: 'destagentexchratestlist', loadChildren: () => import('./remitz/destination-agent-tr-list/destination-agent-tr-list.module').then(m => m.DestinationAgentTrListModule) },
     { path: 'edit', loadChildren: () => import('./remitz/edit-branch/edit-branch.module').then(m => m.EditBranchModule) },
      { path: 'emailedit/:id', loadChildren: () => import('./remitz/edit-email/edit-email.module').then(m => m.EditEmailModule) },
      { path: 'editlist', loadChildren: () => import('./remitz/edit-list/edit-list.module').then(m =>m.EditListModule) },
     { path: 'email', loadChildren: () => import('./remitz/email/email.module').then(m => m.EmailModule) },
     { path: 'exchangerate', loadChildren: () => import('./remitz/exchange-rate/exchange-rate.module').then(m => m.ExchangeRateModule) },
     { path: 'exchangerates', loadChildren: () => import('./remitz/exchange-rates/exchange-rates.module').then(m => m.ExchangeRatesModule) },
     { path: 'fliqpay', loadChildren: () => import('./remitz/fliqpay/fliqpay.module').then(m => m.FliqpayModule) },
      { path: 'geoswift', loadChildren: () => import('./remitz/geoswift/geoswift.module').then(m => m.GeoswiftModule) },
       { path: 'CustomerManualIdVerification', loadChildren: () => import('./remitz/kycapproval/kycapproval.module').then(m => m.KycapprovalModule) },
        { path: 'ledger', loadChildren: () => import('./remitz/ledgerl/ledgerl.module').then(m => m.LedgerlModule) },
        { path: 'managebrandpayments', loadChildren: () => import('./remitz/managebrandpayments/managebrandpayments.module').then(m => m.ManagebrandpaymentsModule) },
         { path: 'maxamount', loadChildren: () => import('./remitz/maxamount/maxamount.module').then(m => m.MaxamountModule) },
          { path: 'receipt', loadChildren: () => import('./remitz/receipt/receipt.module').then(m => m.ReceiptModule) },
        { path: 'relation-ship', loadChildren: () => import('./remitz/relation-ship/relation-ship.module').then(m => m.RelationShipModule) },
        { path: 'reasonfortransfer', loadChildren: () => import('./remitz/relation-ship1/relation-ship1.module').then(m => m.RelationShip1Module) },

      { path: 'sanctionlist', loadChildren: () => import('./remitz/sactionlist/sactionlist.module').then(m => m.SactionlistModule) },
       { path: 'sendingagentrlist', loadChildren: () => import('./remitz/sending-agent-transaction-list/sending-agent-transaction-list.module').then(m => m.SendingAgentTransactionListModule) },
       { path: 'servicefee', loadChildren: () => import('./remitz/service-fee/service-fee.module').then(m => m.ServiceFeeModule) },
       { path: 'sourceoffound', loadChildren: () => import('./remitz/sourceof-found/sourceof-found.module').then(m => m.SourceofFoundModule) },
        { path: 'srcagentexchangerate', loadChildren: () => import('./remitz/src-agent-exch-ratesd/src-agent-exch-ratesd.module').then(m => m.SrcAgentExchRatesdModule) },
        { path: 'transactionlist', loadChildren: () => import('./remitz/transactionlist/transactionlist.module').then(m => m.TransactionlistModule) },
       { path: 'transactionreview', loadChildren: () => import('./remitz/transactionreview/transactionreview.module').then(m => m.TransactionreviewModule) },
       { path: 'transcation', loadChildren: () => import('./remitz/transcation/transcation.module').then(m => m.TranscationModule) },
        { path: 'txdlist', loadChildren: () => import('./remitz/txdlist/txdlist.module').then(m => m.TxdlistModule) },
        { path: 'updateagentwithbranch', loadChildren: () => import('./remitz/update-agent-with-branch/update-agent-with-branch.module').then(m => m.UpdateAgentWithBranchModule) },
  // { path: 'transactionlisttt', loadChildren: () => import('./remitzfront/transactionlist/transactionlist.module')
  // .then(m => m.TransactionlistModule) },
  { path: 'recipient', loadChildren: () => import('./remitzfront/recipient/recipient.module').then(m => m.RecipientModule) },
  { path: 'documents', loadChildren: () => import('./remitzfront/documents/documents.module').then(m => m.DocumentsModule) },
  { path: 'customersupport', loadChildren: () => import('./remitzfront/customersupport/customersupport.module').then(m => m.CustomersupportModule) },
  { path: 'myprofile', loadChildren: () => import('./remitzfront/myprofile/myprofile.module').then(m => m.MyprofileModule) },
  { path: 'changepassword', loadChildren: () => import('./remitzfront/changepassword/changepassword.module').then(m => m.ChangepasswordModule) },
  { path: 'index', loadChildren: () => import('./remitzfront/index/index.module').then(m => m.IndexModule) },
  // { path: 'addpersonaldetailss', loadChildren: () => import('./remitzfront/transactiondetails/transactiondetails.module').then(m => m.
  //   TransactiondetailsModule) },
  { path: 'addpersonaldetailsl', loadChildren: () => import('./remitzfront/addpersonaldetails/addpersonaldetails.module').then(m => m.AddpersonaldetailsModule) },
  { path: 'addrecipirent', loadChildren: () => import('./remitzfront/addrecipirent/addrecipirent.module').then(m => m.AddrecipirentModule) },
  { path: 'payment', loadChildren: () => import('./remitzfront/payment/payment.module').then(m => m.PaymentModule) },
  { path: 'recipientttt', loadChildren: () => import('./remitzfront/recipientf/recipientf.module').then(m => m.RecipientfModule) },
  { path: 'success', loadChildren: () => import('./remitzfront/success/success.module').then(m => m.SuccessModule) },
  { path: 'senderdetails', loadChildren: () => import('./remitzfront/senderdetails/senderdetails.module').then(m => m.SenderdetailsModule) },
  { path: 'addcomplaint', loadChildren: () => import('./remitzfront/addcomplaints/addcomplaints.module').then(m => m.AddcomplaintsModule) },
  { path: 'trlist', loadChildren: () => import('./remitzfront/trlist/trlist.module').then(m => m.TrlistModule) },
  { path: 'trnnnlist', loadChildren: () => import('./remitz/strlist/strlist.module').then(m => m.StrlistModule) },
  { path: 'updaterecipientt/:id', loadChildren: () => import('./remitzfront/updaterecipient/updaterecipient.module').then(m => m.UpdaterecipientModule) },
  { path: 'edit/:id', loadChildren: () => import('./remitzfront/edit/edit.module').then(m => m.EditModule) },
  { path: 'error', loadChildren: () => import('./remitzfront/unsuccess/unsuccess.module').then(m => m.UnsuccessModule) },
  { path: 'beneficiary', loadChildren: () => import('./remitz/beneficiary/beneficiary.module').then(m => m.BeneficiaryModule) },
  { path: 'samsara', loadChildren: () => import('./remitz/samsara/samsara.module').then(m => m.SamsaraModule) },
  { path: 'revenuepercentage', loadChildren: () => import('./remitz/revenuepercentagee/revenuepercentagee.module').then(m => m.RevenuepercentageeModule) },
  { path: 'arctransaction', loadChildren: () => import('./remitz/arctransaction/arctransaction.module').then(m => m.ArctransactionModule) },
  { path: 'austrac', loadChildren: () => import('./remitz/austrac/austrac.module').then(m => m.AustracModule) },
  { path: 'access_logs', loadChildren: () => import('./remitz/access-logs/access-logs.module').then(m => m.AccessLogsModule) },
  { path: 'audit_trail', loadChildren: () => import('./remitz/audit-trail/audit-trail.module').then(m => m.AuditTrailModule) },
  { path: 'sactionsearch', loadChildren: () => import('./remitz/sactionearch/sactionearch.module').then(m => m.SactionearchModule) },
  { path: 'buy-rates', loadChildren: () => import('./remitz/buy-ratess/buy-ratess.module').then(m => m.BuyRatessModule) },
  { path: 'forget', loadChildren: () => import('./remitzfront/forget/forget.module').then(m => m.ForgetModule) },
  { path: 'forgetpassword/:id', loadChildren: () => import('./remitzfront/forgetpassword/forgetpassword.module').then(m => m.ForgetpasswordModule) },
  { path: 'apimarkup', loadChildren: () => import('./remitz/api-markup/api-markup.module').then(m => m.ApiMarkupModule) },
  { path: 'apimapping', loadChildren: () => import('./remitz/apimapping/apimapping.module').then(m => m.ApimappingModule) },
  // { path: 'velocity_check', loadChildren: () => import('./remitz/velocity-check/velocity-check.module').then(m => m.VelocityCheckModule) },
  { path: 'addvelocity', loadChildren: () => import('./remitz/addvelocity/addvelocity.module').then(m => m.AddvelocityModule) },
  { path: 'complaincelistt', loadChildren: () => import('./remitz/complaincekyc/complaincekyc.module').then(m => m.ComplaincekycModule) },
  { path: 'complaincelist', loadChildren: () => import('./remitz/aacomplaincerole/complaincekyc/complaincekyc.module').then(m => m.ComplaincekycModule) },
  { path: 'sactionlistt', loadChildren: () => import('./remitz/aacomplaincerole/sactionlist/sactionlist.module').then(m => m.SactionlistModule) },
  { path: 'kycapprovall', loadChildren: () => import('./remitz/aacomplaincerole/kycapprovall/kycapprovall.module').then(m => m.KycapprovallModule) },
  { path: 'cstlist', loadChildren: () => import('./remitz/aacomplaincerole/customerlist/customerlist.module').then(m => m.CustomerlistModule) },
  { path: 'approvelist', loadChildren: () => import('./remitz/aacomplaincerole/approvelist/approvelist.module').then(m => m.ApprovelistModule) },
  { path: 'createrecipient', loadChildren: () => import('./remitzfront/createrecipienttt/createrecipienttt.module').then(m => m.CreaterecipientttModule) },
  { path: 'nsano', loadChildren: () => import('./remitz/nsano/nsano.module').then(m => m.NsanoModule) },
  { path: 'paymenttypes', loadChildren: () => import('./remitz/paymenttypes/paymenttypes.module').then(m => m.PaymenttypesModule) },
  { path: 'profitloss', loadChildren: () => import('./remitz/profitandloss/profitandloss.module').then(m => m.ProfitandlossModule) },
  { path: 'adddocuments', loadChildren: () => import('./remitzfront/adddocuments/adddocuments.module').then(m => m.AdddocumentsModule) },
  { path: 'ambassadors', loadChildren: () => import('./remitz/ambassadors/ambassadors.module').then(m => m.AmbassadorsModule) },

  { path: 'transactionlisst', loadChildren: () => import('./remitz/rec-transactonlistt/rec-transactonlistt.module').then(m => m.RecTransactonlisttModule) }
,
  { path: 'customerreceipt', loadChildren: () => import('./remitzfront/recipt/recipt.module').then(m => m.ReciptModule) },
  { path: 'receivingagentreceipt', loadChildren: () => import('./remitz/ragentreceiptt/ragentreceiptt.module').then(m => m.RagentreceipttModule) },
  { path: 'receiptt', loadChildren: () => import('./remitz/receiptt/receiptt.module').then(m => m.ReceipttModule) },
  { path: 'promotions', loadChildren: () => import('./remitz/prmpotions/prmpotions.module').then(m => m.PrmpotionsModule) },
  { path: 'test', loadChildren: () => import('./unknpown/unknpown.module').then(m => m.UnknpownModule) },
  { path: 'velocitycheck', loadChildren: () => import('./remitz/velocitycheck/velocitycheck.module').then(m => m.VelocitycheckModule) },
  { path: 'mgcustomer', loadChildren: () => import('./remitz/mgcustomer/mgcustomer.module').then(m => m.MgcustomerModule) },
  { path: 'expclist', loadChildren: () => import('./expiredcustomerlist/expiredcustomerlist.module').then(m => m.ExpiredcustomerlistModule) },
  { path: 'expclistt', loadChildren: () => import('./remitz/expiredcustomerlist/expiredcustomerlist.module').then(m => m.ExpiredcustomerlistModule) },
  { path: 'blocked', loadChildren: () => import('./remitz/blocked/blocked.module').then(m => m.BlockedModule) },
  { path: 'wallet', loadChildren: () => import('./remitzfront/wallet/wallet.module').then(m => m.WalletModule) },
  { path: 'myreferal', loadChildren: () => import('./remitzfront/myreferal/myreferal.module').then(m => m.MyreferalModule) },
  { path: 'referalcoderates', loadChildren: () => import('./remitz/referralcoderates/referralcoderates.module').then(m => m.ReferralcoderatesModule) },
  { path: 'beniftransction/:id', loadChildren: () => import('./remitzfront/beniftransaction/beniftransaction.module').then(m => m.BeniftransactionModule) },
  { path: 'createuser', loadChildren: () => import('./remitzfront/createuser/createuser.module').then(m => m.CreateuserModule) },
  { path: 'createrole', loadChildren: () => import('./remitzfront/createrole/createrole.module').then(m => m.CreateroleModule) },
  { path: 'createbranch', loadChildren: () => import('./remitz/branchcreate/branchcreate.module').then(m => m.BranchcreateModule) },
  { path: 'createagent', loadChildren: () => import('./remitz/createagent/createagent.module').then(m => m.CreateagentModule) },
  { path: 'mobileverification', loadChildren: () => import('./remitzfront/mobileotp/mobileotp.module').then(m => m.MobileotpModule) },
  { path: 'emailverification', loadChildren: () => import('./remitzfront/emailotp/emailotp.module').then(m => m.EmailotpModule) },

  { path: 'testing', loadChildren: () => import('./testingg/testingg.module').then(m => m.TestinggModule) },
  { path: 'transctionreport', loadChildren: () => import('./remitz/transactionreport/transactionreport.module').then(m => m.TransactionreportModule) },
  { path: 'partialcustomerlist', loadChildren: () => import('./remitz/partial/partial.module').then(m => m.PartialModule) },
  { path: 'beneficiaryreport', loadChildren: () => import('./remitz/beneficiaryreport/beneficiaryreport.module').then(m => m.BeneficiaryreportModule) },


  { path: 'privacypolicy', loadChildren: () => import('./headerpages/privateandpolicy/privateandpolicy.module').then(m => m.PrivateandpolicyModule) },
  { path: 'sendmoney', loadChildren: () => import('./remitzfront/sendmoney1/sendmoney1.module').then(m => m.Sendmoney1Module) },
  { path: 'refund', loadChildren: () => import('./remitzfront/refund/refund.module').then(m => m.RefundModule) },
  { path: 'useraggrement', loadChildren: () => import('./remitzfront/useraggrement/useraggrement.module').then(m => m.UseraggrementModule) },
  { path: 'alianza', loadChildren: () => import('./remitz/alianza/alianza.module').then(m => m.AlianzaModule) },
  { path: 'monthlytransactions', loadChildren: () => import('./remitz/monthlytransactions/monthlytransactions.module').then(m => m.MonthlytransactionsModule) },
  // Added by Martins
  { path: 'monthlystatustime', loadChildren: () => import('./remitz/monthlystatustime/monthlystatustime.module').then(m => m.MonthlystatustimeModule) },
  { path: 'exchangeratehistory', loadChildren: () => import('./remitz/exhangeratehistory/exchangeratehistory.module').then(m => m.ExchangeRateHistoryModule) },
  // End here

  { path: 'compliancecustomer', loadChildren: () => import('./remitz/compliancecustomer/compliancecustomer.module').then(m => m.CompliancecustomerModule) },
  { path: 'completedcustomer', loadChildren: () => import('./remitz/completedcustomer/completedcustomer.module').then(m => m.CompletedcustomerModule) },
  { path: 'customerriskscore', loadChildren: () => import('./remitz/customerriskscore/customerriskscore.module').then(m => m.CustomerriskscoreModule) },
  { path: 'compliancedocuments/:id', loadChildren: () => import('./remitzfront/compliancedocuments/compliancedocuments.module').then(m => m.CompliancedocumentsModule) },
  { path: 'desiredrate', loadChildren: () => import('./remitzfront/desiredrate/desiredrate.module').then(m => m.DesiredrateModule) },
  { path: 'marketrate', loadChildren: () => import('./remitzfront/marketrate/marketrate.module').then(m => m.MarketrateModule) },
  { path: 'neworder', loadChildren: () => import('./remitzfront/neworder/neworder.module').then(m => m.NeworderModule) },
  { path: 'markettransactions', loadChildren: () => import('./remitz/markettransactions/markettransactions.module').then(m => m.MarkettransactionsModule) },
  { path: 'marketmappinglist', loadChildren: () => import('./remitz/marketmappinglist/marketmappinglist.module').then(m => m.MarketmappinglistModule) },
  { path: 'createmarketcurrency', loadChildren: () => import('./remitz/createmarketcurrency/createmarketcurrency.module').then(m => m.CreatemarketcurrencyModule) },
  { path: 'nansanomultiplelogin', loadChildren: () => import('./remitz/nansanomultiplelogin/nansanomultiplelogin.module').then(m => m.NansanomultipleloginModule) },
  { path: 'marketcustomers', loadChildren: () => import('./remitz/marketcustomers/marketcustomers.module').then(m => m.MarketcustomersModule) },
  { path: 'marketbank', loadChildren: () => import('./remitzfront/marketbank/marketbank.module').then(m => m.MarketbankModule) },
  { path: 'marketplace', loadChildren: () => import('./headerfront/marketplace/marketplace.module').then(m => m.MarketplaceModule) },
  { path: 'marketplace', loadChildren: () => import('./remitzfront/marketplace/marketplace.module').then(m => m.MarketplaceModule) },
  { path: 'headerr', loadChildren: () => import('./remitzfront/marketplace/headerr/headerr.module').then(m => m.HeaderrModule) },
  { path: 'footermarketrate', loadChildren: () => import('./auth/footermarketrate/footermarketrate.module').then(m => m.FootermarketrateModule) },
  { path: 'airtimetopup', loadChildren: () => import('./remitzfront/airtimetopup/airtimetopup.module').then(m => m.AirtimetopupModule) },
  { path: 'topup', loadChildren: () => import('./remitzfront/topup/topup.module').then(m => m.TopupModule) },
  { path: 'success-reloadly', loadChildren: () => import('./remitzfront/success-reloadly/success-reloadly.module').then(m => m.SuccessReloadlyModule) },
  { path: 'billers', loadChildren: () => import('./remitzfront/billers/billers.module').then(m => m.BillersModule) },
  { path: 'air-transaction', loadChildren: () => import('./remitz/air-transaction/air-transaction.module').then(m => m.AirTransactionModule) },
  { path: 'airpayment-type', loadChildren: () => import('./remitzfront/airpayment-type/airpayment-type.module').then(m => m.AirpaymentTypeModule) },
  { path: 'billpayment', loadChildren: () => import('./remitzfront/billpayment/billpayment.module').then(m => m.BillpaymentModule) },
  { path: 'marketorderlist', loadChildren: () => import('./remitz/marketorderlist/marketorderlist.module').then(m => m.MarketorderlistModule) },
  { path: 'bill-transactions', loadChildren: () => import('./remitz/bill-transactions/bill-transactions.module').then(m => m.BillTransactionsModule) },
  { path: 'addBusinessDocuments', loadChildren: () => import('./remitzfront/add-business-documents/add-business-documents.module').then(m => m.AddBusinessDocumentsModule) },
  { path: 'businesscustomeridverification', loadChildren: () => import('./remitz/businesscustomeridverification/businesscustomeridverification.module').then(m => m.BusinesscustomeridverificationModule) },
  { path: 'businessprofile', loadChildren: () => import('./remitzfront/businessprofile/businessprofile.module').then(m => m.BusinessprofileModule) },
  { path: 'adminchangepassword', loadChildren: () => import('./remitz/adminchangepassword/adminchangepassword.module').then(m => m.AdminchangepasswordModule) },
  { path: 'transactionreport', loadChildren: () => import('./remitzfront/transactionreport/transactionreport.module').then(m => m.TransactionreportModule) },
  { path: 'kaara', loadChildren: () => import('./remitz/kaara/kaara.module').then(m => m.KaaraModule) },
  { path: 'kaarahistory', loadChildren: () => import('./remitz/kaarahistory/kaarahistory.module').then(m => m.KaarahistoryModule) },
  { path: 'air-top-up-exchangerate', loadChildren: () => import('./remitz/air-top-up-exchangerate/air-top-up-exchangerate.module').then(m => m.AirTopUpExchangerateModule) },
  { path: 'adleteaccount', loadChildren: () => import('./headerpages/deleteaccount/deleteaccount.module').then(m => m.DeleteaccountModule) },
  { path: 'deposits', loadChildren: () => import('./remitz/deposits/deposits.module').then(m => m.DepositsModule) },
  { path: 'depositlist', loadChildren: () => import('./remitz/depositlist/depositlist.module').then(m => m.DepositlistModule) },
  { path: 'customerreciptt', loadChildren: () => import('./remitzfront/customerreciptt/customerreciptt.module').then(m => m.CustomerrecipttModule) },
  { path: 'customerrecipttt', loadChildren: () => import('./remitzfront/customerreciptttt/customerreciptttt.module').then(m => m.CustomerrecipttttModule) },
  { path: 'fbmdashboard', loadChildren: () => import('./remitz/fbmdashboard/fbmdashboard.module').then(m => m.FbmdashboardModule) },
  { path: 'popup', loadChildren: () => import('./remitz/popup/popup.module').then(m => m.PopupModule) },
  { path: 'mtbs', loadChildren: () => import('./remitz/mtbs/mtbs.module').then(m => m.MtbsModule) },
  { path: 'mtbstransactionhistory', loadChildren: () => import('./remitz/mtbstransactionhistory/mtbstransactionhistory.module').then(m => m.MtbstransactionhistoryModule) },
  { path: 'mtbscompliance', loadChildren: () => import('./remitz/mtbscompliance/mtbscompliance.module').then(m => m.MtbscomplianceModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
