import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-contactus',
  templateUrl: './contactusnew.component.html',
  styleUrls: ['./contactus.component.css'],
})
export class ContactusComponent implements OnInit {
  constructor(private _router: Router) {}

  ngOnInit(): void {
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }
  }

  openlogin() {
    this._router.navigate(['login']);
  }
  opensignup() {
    this._router.navigate(['signup']);
  }
  openaboutus() {
    this._router.navigate(['aboutus']);
  }
  opencontact() {
    this._router.navigate(['contactus']);
  }
  openfaqs() {
    this._router.navigate(['faqs']);
  }
  onhome() {
    this._router.navigate(['/']);
  }
  openhelp() {
    this._router.navigate(['help']);
  }
  navbarfixed: boolean = false;
  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.scrollY > 100) {
      this.navbarfixed = true;
    } else {
      this.navbarfixed = false;
    }
  }
}
