import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpErrorResponse} from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TestingService {

  public apiUrl : string = 'http://localhost:3000/data';

  httpOptions = {
    headers : new HttpHeaders ({'content-type' : 'application/json'})

  };
    constructor( private _http : HttpClient) { }


    private  extractData( res: Response ) : Array <any> | {} {
     const body = res;
     console.log('res',res)
     return body || {} ;
    }


    public postUser(userRecord:any):Observable <any>{
      return this._http.post(this.apiUrl,userRecord,this.httpOptions).pipe(
        map(this.extractData)
      )
    }







}
