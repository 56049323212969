import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { CookieService } from 'ngx-cookie-service';
import { AddsenderdetailsService } from 'src/app/frontservice';
import { LoginserviceService, AccessLogService, ProcessIdGeneraterService } from 'src/app/service';

@Component({
  selector: 'app-deleteaccount',
  templateUrl: './deleteaccount.component.html',
  styleUrls: ['./deleteaccount.component.css']
})
export class DeleteaccountComponent implements OnInit {
  public AccessLogForm: FormGroup;
  ipAddress: any;
  public userForm: FormGroup;
  public showForm: boolean;
  userType: any;
  pId: any;
  clearTime: any;
  fieldTextType: boolean;
  isloader: boolean;
  submitted = false;
  userId: any;
  invalidResponse: any;
  invalidResponseFlag: boolean;
  sendReceiveName: any;
  deletepopup: boolean = false; 

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private http: HttpClient,
    public bnIdle: BnNgIdleService,
    private _loginService: LoginserviceService,
    private _accessLogService: AccessLogService,
    private cookieService: CookieService,
    private _renderer2: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    private addSenderdetails: AddsenderdetailsService,
    private processIdGeneraterService: ProcessIdGeneraterService
  ) {
    //     this._router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   /** START : Code to Track Page View  */
    //    gtag('event', 'page_view', {
    //       page_path: event.urlAfterRedirects
    //    })
    //   /** END */
    // })
  }

  ngOnInit(): void {
    this.getIPAddress();

const o={
  'userName':this.cookieService.get('username'),
  'password':this.cookieService.get('password'),
  'rememberme':this.cookieService.get('remember')
}
    this.pId=this.processIdGeneraterService.getPid();
    this.isloader=true;
   this.userForm = new FormGroup({
    userName:new FormControl('',Validators.required),
    rememberme:new FormControl(''),
      password:new FormControl('',Validators.required)
  })
if(this.cookieService.get('remember')){
  this.userForm.patchValue(o)
}
  this.AccessLogForm=new FormGroup({
   ipAddress:new FormControl(''),
   userType:new FormControl(''),
   userName:new FormControl(''),
   acessResult:new FormControl(''),
   userid:new FormControl('')

  })

  }
  onforget(){
    this._router.navigate(['/forget'])
  }
  getIPAddress()
  {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
      this.ipAddress = res.ip;
      //alert(this.ipAddress)
    });
  }
  opensignup(){
    this._router.navigate(['/signup'])
  }
public get f(){
  return this.userForm.controls
}
onHome(){
  this._router.navigate(['/'])
}
login(){
  this._router.navigate(['/login'])
}
openHome(){
  this._router.navigate(['/'])
}
openprivacypolicy(){
  this._router.navigate(['privacypolicy'])
}

openaboutus(){
  this._router.navigate(['aboutus'])
}
opencontact(){
  this._router.navigate(['contactus'])
}
openrefund(){
  this._router.navigate(['refund'])
}
openuseraggrement(){
this._router.navigate(['/useraggrement'])
}
opensendmoney(){
  this._router.navigate(['/sendmoney'])

}
openfaqs(){
  this._router.navigate(['faqs'])
}
openhelp(){
  this._router.navigate(['help'])
}
deleteaccount(){
  this._router.navigate(['deleteaccount'])
}

// autologout(exp:number){
//   //alert(exp)
//   this.clearTime=setTimeout(() => {
//     alert('session expired please login to continue..')
//    localStorage.removeItem('userId')
//    localStorage.removeItem('datas')
//    localStorage.removeItem('uname')
//    localStorage.removeItem('userTypeName')
//    localStorage.removeItem('accessId')
//    this._router.navigate(['/']);
//
//  }, 60000*exp);
// }

  public onSubmit(){
clearTimeout(this.clearTime);
      this.submitted=true;
    if(this.userForm.invalid){
      alert("Please enter the details")
            return;
    }
    else{
      this.isloader=false;
     this._loginService.postUser(this.userForm.value,this.pId).subscribe(res =>{
       if(res){

         console.log("login new user.."+JSON.stringify(res.response));
            let JSONDatas = res;
            localStorage.setItem("datas", JSONDatas.response.access_token);
            localStorage.setItem("uname", JSONDatas.response.user_name);
            localStorage.setItem("refresh_token", JSONDatas.response.refresh_token);
            this.cookieService.set('refresh_token',JSONDatas.response.refresh_token);
            // localStorage.setItem("registerAs", res.response.registerName);

            this.userType= res.response.user_scope;
            this.invalidResponse=res.response.server_message;
          if(this.invalidResponse!=null){
            this.invalidResponseFlag=true;
            setTimeout(function () {
              this.invalidResponseFlag=false;
            }.bind(this),3000);
          }
          else{
            if(this.userForm.value.rememberme){
                  this.cookieService.set('username',this.userForm.value.userName);
                      this.cookieService.set('password',this.userForm.value.password);
                      this.cookieService.set('remember',this.userForm.value.rememberme);
                    }

          }



            this.userId=res.response.userId;
          //  this.bnIdle.resetTimer();
            localStorage.setItem("userTypeName",res.response.user_scope);
            if(this.userType=='remitz_role_customer'){
           localStorage.setItem("userId", res.response.userId);
         }
         else{
           localStorage.setItem("adminId", res.response.userId);

         }
         console.log('userType==',this.userType);
            this.isloader=true;
            if(this.userType==undefined){
               }else{
                 // this.autologout(60000);
               }
            if(this.userType=='remitz_role_customer'){

  //             const trObj={
  //   ipAddress:this.ipAddress,
  //   userType:this.userType,
  //   userName:JSONDatas.response.user_name,
  //   acessResult:'Customer Login Success',
  //     userid:this.userId
  // }
  // this.AccessLogForm.patchValue(trObj);
  // console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

  // this._accessLogService.saveAccessLog(
  // this.AccessLogForm.value,
  //  this.processIdGeneraterService.getPid()).subscribe(res => {
  // if (res) {
  //   console.log('accesslog.....',res.response);
  //   localStorage.setItem("accessId", res.response.acessId);

  // }
  // });

              localStorage.setItem("customerId", res.response.userId);

              this.addSenderdetails.getCustomerByUserId(
              localStorage.getItem('userId'),this.processIdGeneraterService.getPid()
              ).subscribe(
              res => {
                this.deletepopup=true
                // alert(this.deletepopup)
                //alert('full')
             
              })


            }


        }
      }
     )
      }
    }
    deleteAccount(){
      this._loginService.deactivateAccount(localStorage.getItem('userId'),
    'inActive',this.processIdGeneraterService.getPid()).subscribe(res=>{
      if(res.response=='record updated'){
  
       localStorage.clear();
       this._router.navigate(['/login'])
  
      }
    })
  }

  no(){
// alert("no")
this.deletepopup=false
     localStorage.clear();
     this._router.navigate(['/deleteaccount'])

    }
 
    toggleFieldTextType(){
      this.fieldTextType=!this.fieldTextType
    }

    
  }