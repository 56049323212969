import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ReasonalForTransferService {
  apiUrl=environment.baseUrl;
  public getreasonalnalForApi :string = `${this.apiUrl}/utility/getReasonForTransfer`;
  public setReasonalApi:string= `${this.apiUrl}/utility/setReasonForTransferDetails`;
  public updateReasonalApi:string = `${this.apiUrl}/utility/updateReasonForTransferDetails`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http:HttpClient) { }

  private extractData(res:Response): Array <any> | {}{
    const body=res;
    console.log('res',res)
    return body || {}



  }


  public getreasonalnalForList(pId:string):Observable<any>{

    return this._http.get(`${this.getreasonalnalForApi}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )

  }

  public setReasonalList(pId:string,record:any):Observable<any>{
    return this._http.post(`${this.setReasonalApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
 public updateReasonal(pId:string,reasonForTransferId:any,record:any){
   return this._http.put(`${this.updateReasonalApi}`+'?pId='+pId+'&reasonForTransferId='+reasonForTransferId,record,this.httpOptions).pipe(
     map(this.extractData)
   )
 }
}
