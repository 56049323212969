import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders,HttpBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import { map} from 'rxjs/operators';

import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddsenderdetailsService {
  apiUrl=environment.baseUrl;

  public addsenderdetailsApi:string = `${this.apiUrl}/transaction/addSenderDetails`
  public savesenderdetailsApi:string = `${this.apiUrl}/transaction/saveSenderDetails`
  public getsavedDetailsUrl:string = `${this.apiUrl}/transaction/getsavedDetails`
//addSenderDetailsWithIdProofs
public addSenderDetailsWithIdProofsUrl:string = `${this.apiUrl}/transaction/addSenderDetailsWithIdProofs`

public apiUrl9: string = `${this.apiUrl}/keyclock/getAllCountrys`;
public findFullAddressUrl:string=`${this.apiUrl}/address/findAddress`;
public findFullUkAddressUrl:string=`${this.apiUrl}/getAddress/findAddress`;
public maxAmountUrl:string=`${this.apiUrl}/customer/getMaxAmountByCountryId`;
public custUrl:string=`${this.apiUrl}/customer/getCustomerByUserId`;
public addressMetaDataUrl:string=`${this.apiUrl}/address/addressMetaData`;
public getBusinessDetailsUrl:string = `${this.apiUrl}/user/getBusinessDetails`
public ukAddressMetaDataUrl:string=`${this.apiUrl}/getAddress/addressMetaData`;
public updateUserAddressDetailsByUserIdUrl:string=`${this.apiUrl}/user/updateUserAddressDetailsByUserId`;
public updateUserAddressDetailsByUserIddUrl:string=`${this.apiUrl}/user/updateUserAddressDetailsByUserIdd`;
public updateUserAddressDetailsByUserIdWithoutImageUrl:string=`${this.apiUrl}/user/updateUserAddressDetailsByUserIdWithoutImage`;
public updateUserIdentityDetailsByUserIdUrl:string=`${this.apiUrl}/user/updateUserIdentityDetailsByUserId`;
public updateUserIdentityDetailsByUserIddUrl:string=`${this.apiUrl}/user/updateUserIdentityDetailsByUserIdd`;
public updateUserIdentityDetailsByUserIdWithoutUploadsUrl:string=`${this.apiUrl}/user/updateUserIdentityDetailsByUserIdWithoutImage`;
public saveBusinessDetailsUrl:string = `${this.apiUrl}/user/saveBusinessDetails`
public findByMembershipIdUrl:string = `${this.apiUrl}/customer/findByMembershipId`
  httpOptions = {
    headers: new HttpHeaders({ })

  };


  constructor(private _http:HttpClient,private _httpp:HttpClient,handler: HttpBackend) {
    this._httpp = new HttpClient(handler);
   }
  public extractData(res:Response):Array<any> | {}{
    const body=res;
    return body || {};
  }


    public getAllCountrys(pId: string): Observable<any> {
      console.log("working getusers client countries..." + pId);
      return this._http.get(`${this.apiUrl9}`+'?pId='+pId, this.httpOptions).pipe(
        map(this.extractData)
      );
    }


    public getMaxAmountByCountryId(countryId:any,pId: string): Observable<any> {
      console.log("getMaxAmountByCountryId..." + pId);
      return this._http.get(`${this.maxAmountUrl}` + '?pId=' + pId+'&countryId='+countryId, this.httpOptions).pipe(
        map(this.extractData)
      );
    }

  public postaddSenderdetails(pId:string,userId:any,record:any):Observable<any>{

    return this._http.post(`${this.addsenderdetailsApi}`+'?pId='+pId+'&userId='+userId,record).pipe(
      map(this.extractData)
    )
  }

  public saveaddSenderdetails(pId:string,userId:any,record:any):Observable<any>{

    return this._http.post(`${this.savesenderdetailsApi}`+'?pId='+pId+'&userId='+userId,record).pipe(
      map(this.extractData)
    )
  }
  public getsavedDetails(pId:string,userId:any):Observable<any>{

    return this._http.get(`${this.getsavedDetailsUrl}`+'?pId='+pId+'&userId='+userId).pipe(
      map(this.extractData)
    )
  }
  //addSenderDetailsWithIdProofs
  public addDetailsWithIdProofs(pId:string,userId:any,record:any):Observable<any>{
    console.log(JSON.stringify(record));
    return this._http.post(`${this.addSenderDetailsWithIdProofsUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public getCustomerByUserId(userId:any,pId: string): Observable<any> {
    console.log("getCustomerByUserId..." + userId);
    return this._http.get(`${this.custUrl}` + '?pId=' + pId+'&userId='+userId).pipe(
      map(this.extractData)
    );
  }

  public findFullAddress(address:any,pId: string): Observable<any> {
    console.log("findFullAddress..." + pId);
    return this._httpp.get(`${this.findFullAddressUrl}` + '?pId=' + pId+'&address='+address).pipe(
      map(this.extractData)
    );
  }

  public addressMetaData(address:any,pId: string): Observable<any> {
    console.log("addressMetaData..." + pId);
    return this._httpp.get(`${this.addressMetaDataUrl}` + '?pId=' + pId+'&addressId='+address).pipe(
      map(this.extractData)
    );
  }


  public ukAddressMetaData(address:any,pId: string): Observable<any> {
    console.log("addressMetaData..." + pId);
    return this._httpp.get(`${this.ukAddressMetaDataUrl}` + '?pId=' + pId+'&addressId='+address).pipe(
      map(this.extractData)
    );
  }

  public findFullUkAddress(address:any,pId: string): Observable<any> {
    console.log("findFullUkAddressUrl..." + pId);
    return this._httpp.get(`${this.findFullUkAddressUrl}` + '?pId=' + pId+'&address='+address).pipe(
      map(this.extractData)
    );
  }


  //addSenderDetailsWithIdProofs
  public updateUserIdentityDetailsByUserId(pId:string,userId:any,record:any):Observable<any>{
    console.log('sdfsdfdsff..'+JSON.stringify(record));
    return this._http.post(`${this.updateUserIdentityDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  //addSenderDetailsWithIdProofs
  public updateUserIdentityDetailsByUserIdd(pId:string,record:any):Observable<any>{
    console.log('sdfsdfdsff..'+JSON.stringify(record));
    return this._http.post(`${this.updateUserIdentityDetailsByUserIddUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public updateUserIdentityDetailsByUserIdWithoutImage(pId:string,userId:any,record:any):Observable<any>{
    console.log(JSON.stringify(record));
    return this._http.post(`${this.updateUserIdentityDetailsByUserIdWithoutUploadsUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateUserAddressDetailsByUserId(pId:string,userId:any,record:any):Observable<any>{
    console.log(JSON.stringify(record));
    return this._http.post(`${this.updateUserAddressDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateUserAddressDetailsByUserIdd(pId:string,record:any):Observable<any>{
    console.log(JSON.stringify(record));
    return this._http.post(`${this.updateUserAddressDetailsByUserIddUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateUserAddressDetailsByUserIdWithoutImage(pId:string,userId:any,record:any):Observable<any>{
    console.log(JSON.stringify(record));
    return this._http.post(`${this.updateUserAddressDetailsByUserIdWithoutImageUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getBusinessDetails(pId:string,userId:any):Observable<any>{
    return this._http.get(`${this.getBusinessDetailsUrl}`+'?pId='+pId+'&userId='+userId).pipe(
      map(this.extractData)
    )
  }

  public saveBusinessDetails(pId:string,formData:any):Observable<any>{
    console.log(formData);
    return this._http.post(`${this.saveBusinessDetailsUrl}`+'?pId='+pId,formData,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public findByMembershipId(userId:any,pId: string): Observable<any> {
    console.log("findByMembershipId..." + userId);
    return this._http.get(`${this.findByMembershipIdUrl}` + '?pId=' + pId+'&userId='+userId).pipe(
      map(this.extractData)
    );
  }

}
