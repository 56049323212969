import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpBackend} from '@angular/common/http';
import { Observable, throwError} from 'rxjs';
import {  map} from 'rxjs/operators';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SignupService {
    apiUrl=environment.baseUrl;
public transferApi :string = `${this.apiUrl}/transaction/createSignUp`;
public duplicateCustomerUrl :string = `${this.apiUrl}/customer/findDuplicateCustomerByEmailId`;
public findDuplicateUser :string = `${this.apiUrl}/customer/findDuplicateUser`;
public verifyingEmailByOtpUrl:string=`${this.apiUrl}/transaction/verifyingEmailByOtp`;
public sendingOtptoEmailIdUrl:string=`${this.apiUrl}/transaction/sendingOtptoEmailId`;
public updatePassword :string = `${this.apiUrl}/user/updatePassword`;
public sendOtpToCustomerNumberUrl:string=`${this.apiUrl}/customer/sendOtpToCustomerNumber`;
public onMobileVerificationOtpUrl:string=`${this.apiUrl}/customer/verifyMobileNumberOtp`;


httpOptions = {
  headers: new HttpHeaders({ 'content-type': 'application/json' })
  //.set('Authorization', localStorage.getItem("datas"))

};

constructor(private _http:HttpClient,private _httpp:HttpClient,
handler: HttpBackend) {
  this._httpp = new HttpClient(handler);
}

  private  extractData( res: Response ) : Array <any> | {} {
   const body = res;
   console.log('res',res)
   return body || {} ;
  }

  public updatePwd(updateRecord:any,pId:string) : Observable <any>{
    return this._http.post(`${this.updatePassword}`+'?pId='+pId,updateRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }

  public sendOtpToCustomerNumber(pId:string,mobile:any) : Observable <any>{
    return this._httpp.get(`${this.sendOtpToCustomerNumberUrl}`+'?pId='+pId+'&mobileNumber='+mobile,this.httpOptions)

  }

  public onMobileVerificationWithOtp(pId:string,phoneNumber:any,otp:any, firstName:any,emailId:any) : Observable <any>{
    return this._httpp.get(`${this.onMobileVerificationOtpUrl}`+'?pId='+pId+'&phoneNumber='+phoneNumber+'&otp='+otp+'&firstName='+firstName+'&emailId='+emailId,this.httpOptions)

  }

  public findDuplicateUsers(userName:any) : Observable <any>{
  console.log('findDuplicateUser===='+userName)
    return this._httpp.get(`${this.findDuplicateUser}`+'?userName='+userName,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

public postSignup(pId:string,record:any) : Observable <any>{
  return this._httpp.post(`${this.transferApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)


  )

}
public findDuplicateCustomerByEmailId(emailId:any,pId:string) : Observable <any>{
console.log('findDuplicateCustomerByEmailId====',pId)
  return this._http.get(`${this.duplicateCustomerUrl}`+'?pId='+pId+'&emailId='+emailId,this.httpOptions).pipe(
    map(this.extractData)
  )

}


public otpVerify(pId:string,userName:any,emailId:any,otp:any) : Observable <any>{
  return this._httpp.get(`${this.verifyingEmailByOtpUrl}`+'?pId='+pId+'&userName='+userName+'&emailId='+emailId+'&otp='+otp,this.httpOptions)

}
public sendingOtptoEmailId(pId:string,userName:any,emailId:any) : Observable <any>{
  return this._httpp.get(`${this.sendingOtptoEmailIdUrl}`+'?pId='+pId+'&userName='+userName+'&emailId='+emailId,this.httpOptions)

}





}
