import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import { LoginserviceService } from '../../service';
import { ProcessIdGeneraterService } from '../../service';
import { HttpClient  } from '@angular/common/http';
import {AccessLogService,UtilityService } from '../../service';
import { AddsenderdetailsService } from '../../frontservice';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})


export class LoginComponent implements OnInit {
  public userForm:FormGroup;
  public AccessLogForm:FormGroup;
  public showForm : boolean;
  userType:any;
  pId : any;
  ipAddress:any;
  clearTime:any;
  subUserId:any;
  pagenames:any;
  pagenamesr:any;
  temp1:any;
  invalidResponse:any;
  invalidResponseFlag:boolean;
  userId:any;
  fieldTextType:boolean;
  sendReceiveName:any;
isloader:boolean;

  constructor(private _router :Router,
    private _route:ActivatedRoute,
    private _accessLogService: AccessLogService,
    private _loginService:LoginserviceService,
    private http:HttpClient,
    private utilityService:UtilityService,
    private addSenderdetails:AddsenderdetailsService,
    private processIdGeneraterService : ProcessIdGeneraterService) { }
   ngOnInit(): void {
    this.utilityService.getIpAddress().subscribe(res =>{
      this.ipAddress=res.ip;
    }
    );

       this.pId=this.processIdGeneraterService.getPid();
      this.userForm = new FormGroup({
       userName:new FormControl(''),
       password:new FormControl('')
     })
this.AccessLogForm=new FormGroup({
 ipAddress:new FormControl(''),
 userType:new FormControl(''),
 userName:new FormControl(''),
 acessResult:new FormControl(''),
 userid:new FormControl('')

})
   }

   onHome(){
    this._router.navigate(['/'])
  }
  openHome(){
    this._router.navigate(['/'])
  }

   getIPAddress()
   {
     this.http.get("https://api.ipify.org/?format=json").subscribe((res:any)=>{
       this.ipAddress = res.ip;
       //alert(this.ipAddress)

     });
   }

public onSubmit() :void{
  if(this.userForm.invalid){
    clearTimeout(this.clearTime);
      alert('Please enter details');
          return;
  }
  else{
    this._loginService.postUser(this.userForm.value,this.pId).subscribe(res =>{
      if(res){
        this.invalidResponse=res.response.server_message;

        if(this.invalidResponse!=null){
          this.invalidResponseFlag=true;
          setTimeout(function () {
            this.invalidResponseFlag=false;
          }.bind(this),3000);
        }
        if(this.userType==undefined){
   }else{
    // this.autologout(60000);
   }
        console.log("login new user.."+res.response);
           let JSONDatas = res;
           localStorage.setItem("datas", JSONDatas.response.access_token);
           localStorage.setItem("uname", JSONDatas.response.user_name);
           this.userType= res.response.user_scope;
           this.userId=res.response.userId;
           localStorage.setItem("userTypeName",res.response.user_scope);
            if(this.userType=='remitz_role_customer'){
          //  localStorage.setItem("userId", res.response.userId);
         }
         else{
           localStorage.setItem("adminId", res.response.userId);
           localStorage.setItem("createdBy", res.response.createdBy);
         }
           console.log('userType==',this.userType);
           this.isloader=true;



           if(this.userType=='remitz_role_admin'){
             this._router.navigate(['branch']);
             const trObj={
   ipAddress:this.ipAddress,
   userType:this.userType,
   userName:JSONDatas.response.user_name,
   acessResult:'Admin Login Success',
     userid:this.userId
 }
 this.AccessLogForm.patchValue(trObj);
 console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

 this._accessLogService.saveAccessLog(
 this.AccessLogForm.value,
  this.processIdGeneraterService.getPid()).subscribe(res => {
 if (res) {
   console.log('accesslog.....',res.response);
   localStorage.setItem("accessId", res.response.acessId);

 }
 });

             this.userForm.reset();
           }
           if(this.userType=='remitz_role_send_agent'){

             this._router.navigate(['srcagentexchangerate']);
             const trObj={
   ipAddress:this.ipAddress,
   userType:this.userType,
   userName:JSONDatas.response.user_name,
   acessResult:'Sending Agent Login Success',
     userid:this.userId
 }
 this.AccessLogForm.patchValue(trObj);
 console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

 this._accessLogService.saveAccessLog(
 this.AccessLogForm.value,
  this.processIdGeneraterService.getPid()).subscribe(res => {
 if (res) {
   console.log('accesslog.....',res.response);
   localStorage.setItem("accessId", res.response.acessId);

 }
 });

             this.userForm.reset();
           }

           if(this.userType=='remitz_role_rec_agent'){    

             this._router.navigate(['transactionlisst']);
             const trObj={
   ipAddress:this.ipAddress,
   userType:this.userType,
   userName:JSONDatas.response.user_name,
   acessResult:'Receiving Agent Login Success',
     userid:this.userId
 }
 this.AccessLogForm.patchValue(trObj);
 console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

 this._accessLogService.saveAccessLog(
 this.AccessLogForm.value,
  this.processIdGeneraterService.getPid()).subscribe(res => {
 if (res) {
   console.log('accesslog.....',res.response);
   localStorage.setItem("accessId", res.response.acessId);

 }
 });

             this.userForm.reset();
           }
           if(this.userType=='remitz_role_complaince'){

             const trObj={
   ipAddress:this.ipAddress,
   userType:this.userType,
   userName:JSONDatas.response.user_name,
   acessResult:'compilance officer Login Success',
     userid:this.userId
 }
 this.AccessLogForm.patchValue(trObj);
 console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

 this._accessLogService.saveAccessLog(
 this.AccessLogForm.value,
  this.processIdGeneraterService.getPid()).subscribe(res => {
 if (res) {
   console.log('accesslog.....',res.response);
   localStorage.setItem("accessId", res.response.acessId);

 }
 });


             this._router.navigate(['complaincelist']);




           }

           if(this.userType=='remitz_role_ticket_issue'){

             const trObj={
         ipAddress:this.ipAddress,
         userType:this.userType,
         userName:JSONDatas.response.user_name,
         acessResult:'ticketIssue officer Login Success',
         userid:this.userId
         }
         this.AccessLogForm.patchValue(trObj);
         console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

         this._accessLogService.saveAccessLog(
         this.AccessLogForm.value,
         this.processIdGeneraterService.getPid()).subscribe(res => {
         if (res) {
         console.log('accesslog.....',res.response);
         localStorage.setItem("accessId", res.response.acessId);

         }
         });

         this._router.navigate(['partialcustomerlist']);




           }


           if(this.userType=='remitz_role_bdm'){

             const trObj={
           ipAddress:this.ipAddress,
           userType:this.userType,
           userName:JSONDatas.response.user_name,
           acessResult:'business development officer Login Success',
           userid:this.userId
           }
           this.AccessLogForm.patchValue(trObj);
           console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

           this._accessLogService.saveAccessLog(
           this.AccessLogForm.value,
           this.processIdGeneraterService.getPid()).subscribe(res => {
           if (res) {
           console.log('accesslog.....',res.response);
           localStorage.setItem("accessId", res.response.acessId);

           }
           });

           this._router.navigate(['transactionlist']);




           }
           if(this.userType=='remitz_role_accountant'){

             const trObj={
           ipAddress:this.ipAddress,
           userType:this.userType,
           userName:JSONDatas.response.user_name,
           acessResult:'accountant officer Login Success',
           userid:this.userId
           }
           this.AccessLogForm.patchValue(trObj);
           console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

           this._accessLogService.saveAccessLog(
           this.AccessLogForm.value,
           this.processIdGeneraterService.getPid()).subscribe(res => {
           if (res) {
           console.log('accesslog.....',res.response);
           localStorage.setItem("accessId", res.response.acessId);

           }
           });

           this._router.navigate(['transactionlist']);




           }
           if(this.userType=='remitz_role_payout'){

             const trObj={
         ipAddress:this.ipAddress,
         userType:this.userType,
         userName:JSONDatas.response.user_name,
         acessResult:'payout officer Login Success',
         userid:this.userId
         }
         this.AccessLogForm.patchValue(trObj);
         console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

         this._accessLogService.saveAccessLog(
         this.AccessLogForm.value,
         this.processIdGeneraterService.getPid()).subscribe(res => {
         if (res) {
         console.log('accesslog.....',res.response);
         localStorage.setItem("accessId", res.response.acessId);

         }
         });

         this._router.navigate(['transactionlisst']);




           }

           if(this.userType=='remitz_role_customer_service'){

             const trObj={
           ipAddress:this.ipAddress,
           userType:this.userType,
           userName:JSONDatas.response.user_name,
           acessResult:'customer service officer Login Success',
           userid:this.userId
           }
           this.AccessLogForm.patchValue(trObj);
           console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

           this._accessLogService.saveAccessLog(
           this.AccessLogForm.value,
           this.processIdGeneraterService.getPid()).subscribe(res => {
           if (res) {
           console.log('accesslog.....',res.response);
           localStorage.setItem("accessId", res.response.acessId);

           }
           });

           this._router.navigate(['transactionlist']);




           }
           if(this.userType=='remitz_role_multiple'){

            const trObj={
          ipAddress:this.ipAddress,
          userType:this.userType,
          userName:JSONDatas.response.user_name,
          acessResult:'multiple role agent login success',
          userid:this.userId
          }
          this.AccessLogForm.patchValue(trObj);
          console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

          this._accessLogService.saveAccessLog(
          this.AccessLogForm.value,
          this.processIdGeneraterService.getPid()).subscribe(res => {
          if (res) {
          console.log('accesslog.....',res.response);
          localStorage.setItem("accessId", res.response.acessId);

          }
          });

          this._router.navigate(['nansanomultiplelogin']);






          }

          if(this.userType=='remitz_role_call_center'){

            const trObj={
          ipAddress:this.ipAddress,
          userType:this.userType,
          userName:JSONDatas.response.user_name,
          acessResult:'Payment Clearance login success',
          userid:this.userId
          }
          this.AccessLogForm.patchValue(trObj);
          console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

          this._accessLogService.saveAccessLog(
          this.AccessLogForm.value,
          this.processIdGeneraterService.getPid()).subscribe(res => {
          if (res) {
          console.log('accesslog.....',res.response);
          localStorage.setItem("accessId", res.response.acessId);

          }
          });

          this._router.navigate(['transactionlist']);




          }
           
          if(this.userType=='remitz_role_payment_clearance'){

            const trObj={
          ipAddress:this.ipAddress,
          userType:this.userType,
          userName:JSONDatas.response.user_name,
          acessResult:'Payment Clearance login success',
          userid:this.userId
          }
          this.AccessLogForm.patchValue(trObj);
          console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

          this._accessLogService.saveAccessLog(
          this.AccessLogForm.value,
          this.processIdGeneraterService.getPid()).subscribe(res => {
          if (res) {
          console.log('accesslog.....',res.response);
          localStorage.setItem("accessId", res.response.acessId);

          }
          });

          this._router.navigate(['transactionlist']);




          }
          if(this.userType=='remitz_role_marketing'){

            this._router.navigate(['partialcustomerlist']);
            const trObj={
  ipAddress:this.ipAddress,
  userType:this.userType,
  userName:JSONDatas.response.user_name,
  acessResult:'Sending Agent Login Success',
    userid:this.userId
}
this.AccessLogForm.patchValue(trObj);
console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

this._accessLogService.saveAccessLog(
this.AccessLogForm.value,
 this.processIdGeneraterService.getPid()).subscribe(res => {
if (res) {
  console.log('accesslog.....',res.response);
  localStorage.setItem("accessId", res.response.acessId);

}
});


          }

           if(this.userType=='remitz_role_sub_admin'){
              this.subUserId=localStorage.getItem("adminId");
              localStorage.removeItem('userId');
              localStorage.setItem("subUserId", this.subUserId);
               this.utilityService.getUserIdbySubUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                if (res) {
                   this.temp1=res.response;
                   localStorage.setItem("userId", this.temp1);
                   this.utilityService.getUserFormActonByUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                     if (res) {
                       this.pagenamesr=res.response
                       localStorage.setItem("pagenames", JSON.stringify(this.pagenamesr))
                      this._router.navigate(['branch'])

                     }
                   });//closing getUserFormActonByUserId
                }
               });//closing getUserIdBySubUserId
               const trObj={
                  ipAddress:this.ipAddress,
                  userType:this.userType,
                  userName:JSONDatas.response.userName,
                  acessResult:'remitz_role_sub_admin Login Success',
                  userid:this.subUserId
                }
                this.AccessLogForm.patchValue(trObj);
                this._accessLogService.saveAccessLog(this.AccessLogForm.value,this.processIdGeneraterService.getPid()).subscribe(res => {
                if (res) {
                  localStorage.setItem("accessId", res.response.acessId);
                }
                });
            }//closing remitz_role_sub_admin
            if(this.userType=='remitz_role_sub_send_agent'){
              this.subUserId=localStorage.getItem("adminId");
              localStorage.removeItem('userId');
              localStorage.setItem("subUserId", this.subUserId);
              // alert(localStorage.getItem("subUserId"))
               this.utilityService.getUserIdbySubUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                if (res) {
                   this.temp1=res.response;
                   // alert("temp1.."+this.temp1)
                   localStorage.setItem("userId", this.temp1);
                   this.utilityService.getUserFormActonByUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                     if (res) {

                  this.pagenamesr=JSON.stringify(res.response)
                          localStorage.setItem('pagenamesend',this.pagenamesr)
                       this._router.navigate(['srcAgentExchRate'])
                     }
                   });//closing getUserFormActonByUserId
                }
               });//closing getUserIdBySubUserId
               const trObj={
                  ipAddress:this.ipAddress,
                  userType:this.userType,
                  userName:JSONDatas.response.userName,
                  acessResult:'remitz_role_sub_send_agent Login Success',
                  userid:this.subUserId
                }
                this.AccessLogForm.patchValue(trObj);
                this._accessLogService.saveAccessLog(this.AccessLogForm.value,this.processIdGeneraterService.getPid()).subscribe(res => {
                if (res) {
                  localStorage.setItem("accessId", res.response.acessId);
                }
                });

            }//closing remitz_role_sub_send_agent
            if(this.userType=='remitz_role_sub_rec_agent'){
              this.subUserId=localStorage.getItem("adminId");
              localStorage.removeItem('userId');
              localStorage.setItem("subUserId", this.subUserId);
               this.utilityService.getUserIdbySubUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                if (res) {
                   this.temp1=res.response;
                   localStorage.setItem("userId", this.temp1);
                   this.utilityService.getUserFormActonByUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                     if (res) {
                      this.pagenamesr=JSON.stringify(res.response)
                          localStorage.setItem('pagenamereceive',this.pagenamesr)
                      this._router.navigate(['destAgentExchRates'])

                     }
                   });//closing getUserFormActonByUserId
                }
               });//closing getUserIdBySubUserId
               const trObj={
                  ipAddress:this.ipAddress,
                  userType:this.userType,
                  userName:JSONDatas.response.userName,
                  acessResult:'remitz_role_sub_send_agent Login Success',
                  userid:this.subUserId
                }
                this.AccessLogForm.patchValue(trObj);
                this._accessLogService.saveAccessLog(this.AccessLogForm.value,this.processIdGeneraterService.getPid()).subscribe(res => {
                if (res) {
                  localStorage.setItem("accessId", res.response.acessId);
                }
                });

            }//closing remitz_role_sub_rec_agent
            if(this.userType=='remitz_role_sub_complaince'){
              this.subUserId=localStorage.getItem("adminId");
              localStorage.removeItem('userId');
              localStorage.setItem("subUserId", this.subUserId);
               this.utilityService.getUserIdbySubUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                if (res) {
                   this.temp1=res.response;
                   localStorage.setItem("userId", this.temp1);
                   this.utilityService.getUserFormActonByUserId(this.processIdGeneraterService.getPid(),localStorage.getItem("subUserId")).subscribe(res=>{
                     if (res) {
                       this.pagenamesr=JSON.stringify(res.response)
                     localStorage.setItem('pagenamecomplaince',this.pagenamesr)
                      this._router.navigate(['sactionlist']);


                     }
                   });//closing getUserFormActonByUserId
                }
               });//closing getUserIdBySubUserId
               const trObj={
                  ipAddress:this.ipAddress,
                  userType:this.userType,
                  userName:JSONDatas.response.userName,
                  acessResult:'remitz_role_sub_send_agent Login Success',
                  userid:this.subUserId
                }
                this.AccessLogForm.patchValue(trObj);
                this._accessLogService.saveAccessLog(this.AccessLogForm.value,this.processIdGeneraterService.getPid()).subscribe(res => {
                if (res) {
                  localStorage.setItem("accessId", res.response.acessId);
                }
                });

            }//closing remitz_role_sub_complaince
  //           if(this.userType=='remitz_role_marketing'){

  //             this._router.navigate(['partialcustomerlist']);
  //             const trObj={
  //   ipAddress:this.ipAddress,
  //   userType:this.userType,
  //   userName:JSONDatas.response.user_name,
  //   acessResult:'Sending Agent Login Success',
  //     userid:this.userId
  // }
  // this.AccessLogForm.patchValue(trObj);
  // console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

  // this._accessLogService.saveAccessLog(
  // this.AccessLogForm.value,
  //  this.processIdGeneraterService.getPid()).subscribe(res => {
  // if (res) {
  //   console.log('accesslog.....',res.response);
  //   localStorage.setItem("accessId", res.response.acessId);

  // }
  // });


  //           }
       }
     }, err => {
       this._router.navigate(['/admin'])
       console.log(err);
     }

     );
}
}//onSubmit close
toggleFieldTextType(){
  this.fieldTextType=!this.fieldTextType
}


}
