<div *ngIf="(router.url === '/adminCustomerprofile' || router.url === '/branch' || router.url === '/deposit' ||
  router.url === '/adminCustomerid' || router.url === '/agenttt' || router.url === '/agent-collection-markup' ||
  router.url === '/agentmapping' || router.url === '/agent' || router.url === '/agentdeposit' || router.url === '/austrac' ||
  router.url === '/agentdepositlist' || router.url === '/agentwithbranch' || router.url === '/internetbankdetails' ||
   router.url === '/banks' || router.url === '/branchcreatt'  || router.url === '/cashpickup' || router.url === '/email' ||
    router.url === '/customer' || router.url === '/customerlist' || router.url === '/destagentexchratest' ||
     router.url === '/destagentexchratestlist' || router.url === '/edit' || router.url === '/emaileditt' || router.url ==='/approvelist'
     || router.url === '/editlist' || router.url === '/email' || router.url === '/exchangerate' || router.url ==='/apimarkup' || router.url ==='/ambassadors'
     || router.url === '/exchangerates' || router.url === '/fliqpay' || router.url === '/transactionlisst' ||
      router.url === '/geoswift' ||  router.url === '/complaincelist' || router.url === '/CustomerManualIdVerification' || router.url === '/promotions'

      || router.url === '/bill-transactions' || router.url === '/air-transaction' || router.url === '/ledger' || router.url === '/velocitycheck' || router.url === '/managebrandpayments' || router.url === '/maxamount' || router.url === '/sactionlistt' || router.url === '/kycapprovall' || router.url === '/cstlist'
     || router.url === '/relation-ship' || router.url === '/reasonfortransfer' || router.url === '/sanctionlist' || router.url ==='/profitloss'
     || router.url === '/sendingagentrlist' || router.url === '/servicefee' || router.url === '/revenuepercentage' || router.url === '/sourceoffound' || router.url === '/sactionsearch' ||
     router.url === '/srcagentexchangerate'  || router.url ==='/buy-rates' || router.url === '/apimapping' || router.url ==='/velocity_check' || router.url ==='/addvelocity'
     || router.url === '/transactionlist' || router.url === '/transactionreviewt' || router.url === '/audit_trail' || router.url === '/access_logs' || router.url === '/samsara' || router.url === '/transcation' ||
      router.url === '/txdlist'|| router.url === '/nsano' || router.url ==='/paymenttypes' || router.url === '/arctransaction' ||
      router.url === '/updateagentwithbranch' ||  router.url === '/alianza' ||router.url === '/monthlytransactions' ||
      router.url === '/partialcustomerlist'
       || router.url === '/blocked'   || router.url === '/mtbstransactionhistory'|| router.url === '/mtbscompliance' ||  router.url==='/marketorderlist'||router.url==='/fbmdashboard'||router.url==='/air-top-up-exchangerate'|| router.url==='/marketcustomers'|| router.url === '/adminchangepassword' ||  router.url === '/businesscustomeridverification' ||  router.url === '/createuser' || router.url === '/createagent' || router.url === '/createrole' || router.url === '/expclistt' || router.url === '/referalcoderates' ||router.url === '/promotions/editt/:id' || router.url==='/nansanomultiplelogin' ||router.url==='/mtbs' ||router.url === '/createmarketcurrency'||  router.url === '/createbranch'||   router.url === '/marketmappinglist' || router.url === '/markettransactions' || router.url === '/mgcustomer' || router.url === '/trnnnlist' || router.url ==='/beneficiary' || router.url ==='/compliancecustomer'|| router.url ==='/customerriskscore' || router.url ==='/completedcustomer' || router.url === '/receivingagentreceipt' || router.url === '/monthlystatustime' || router.url === '/exchangeratehistory'|| router.url === '/kaara'||router.url === '/kaarahistory' || router.url === '/deposits'|| router.url === '/depositlist' 
     )
  ">

  <app-backdashboard></app-backdashboard>
</div>

<!-- monthlystatustime
exchangeratehistory added by martins -->

<router-outlet></router-outlet>

