<!-- Register In start -->
<section class="log-reg register">
  
  <div class="overlay">
    <div class="container">
      <!-- <div *ngIf="!popUpFlag" class="modal" id="myModal">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h4 class="modal-title">Email verification Code</h4>
                      <button type="button" class="close" data-dismiss="modal">
                        &times;
                      </button>
                    </div>
          
                    <div class="modal-body">
                      <p style="text-align: center; color: blue; font-size: 28px">
                        {{ timeLeft }} {{ emsg }}
                      </p>
                      <p>Seconds Left....</p>
          
                      <div class="form-group">
                        <input
                          #otp
                          type="text"
                          class="form-control"
                          name="otp"
                          value=""
                        />
                      </div>
                      <div class="form-group">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </div>
                      <div class="row">
                        <div class="col-md-6 form-group">
                          <button
                            type="button"
                            class="btn btn-primary btn-block"
                            name="button"
                            (click)="reSend()"
                          >
                            Resend
                          </button>
                        </div>
                        <div class="col-md-6">
                          <button
                            type="button"
                            class="btn btn-primary btn-block"
                            name="button"
                            data-dismiss="modal"
                            (click)="onOtpSubmit(otp.value)"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
          
                    <div class="modal-footer">
                      <button type="button" class="btn btn-danger" data-dismiss="modal">
                        Close
                      </button>
                    </div>
                  </div>
                </div>
            </div> -->

      <div class="top-head-area mb-2">
        <div class="row d-flex align-items-center">
         
          <div class="col-sm-6 col">
            <a href="javascript:void(0)" (click)="onHome()">
              <img src="assetsnew/img/logo-png.png" class="w-100px" alt="image" width="55%" />
            </a>
          </div>

          <div class="col-sm-6 col text-end">
            <!-- <a class="back-home text-light" routerLink="/">
              <img src="assetsnew/img/left-icon.png" alt="image" />
              Back To Home
            </a> -->
          </div>
        </div>
      </div>

      <div class="row justify-content-center align-items-center">
        <div class="col-md-5">
          <div class="img-area">
            <img src="assetsnew/img/register-illus-1.png" alt="image" />
          </div>
        </div>
        <div
          class="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120"
        >
          <div class="form-box">
            <h4>Register with Tayrex</h4>
            <p class="alr-acc dont-acc">
              Already have an account? <a routerLink="/login">Log in now.</a>
            </p>
            <p></p>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="personal"
                role="tabpanel"
                aria-labelledby="personal-tab"
              >
                <form [formGroup]="signupForm">
                  <input type="hidden" name="_token" />
                  <div class="row">
                    <div class="col-12">

                      <div class="form-group snd-log-lable">
                        <label for="">Register As <span class="errorr">*</span></label><br>
                        <div class="input-group">
                  
                          <select class="form-controll log-form-custom form1"
                           (change)="onChangeRegister($event.target.value)"
                          [(ngModel)]="selectedValue" formControlName="registerId">
                          <option *ngFor="let r of regiterList" value="{{r.registerId}}">{{r.registerName}}</option>
                          </select>
                  
                        </div>
                        </div>

                    </div>
                    <div class="col-6">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="First Name"
                          formControlName="firstName"
                          oninput="this.value = this.value.toUpperCase();"
                          name="FirstName"
                          id="FirstName"
                          required
                          pattern="[A-Za-z]{1,50}"
                          value=""
                          [ngClass]="{
                            'is-invalid': submitted && f.firstName.errors
                          }"
                        />
                      </div>
                      <div
                        class="error"
                        *ngIf="submitted && f.firstName.errors"
                      >
                        No special character allowed
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="text"
                          placeholder="Last Name"
                          required
                          formControlName="lastName"
                          oninput="this.value = this.value.toUpperCase();"
                          name="LastName"
                          id="LastName"
                          pattern="[A-Za-z]{1,50}"
                          [ngClass]="{
                            'is-invalid': submitted && f.lastName.errors
                          }"
                          value=""
                        />

                        <div
                          class="error"
                          *ngIf="submitted && f.lastName.errors"
                        >
                          No special character allowed
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <select
                          [(ngModel)]="countryIdd"
                          placeholder="select Country"
                          class="counter-id col-md-12"
                          formControlName="countryIdd"
                          (change)="onChangeCountry($event.target.value)"
                        >
                          <option
                            *ngFor="let list of sendingAgentsList"
                            selected
                            value="{{ list.countryId }}&{{ list.userId }}"
                          >
                            {{ list.countryName }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="!isShowss">
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="email"
                          placeholder="Email"
                          required
                          (keyup)="checkUserName($event.target.value)"
                          formControlName="emailId"
                          [ngClass]="{
                            'is-invalid': submitted && f.emailId.errors
                          }"
                          name="Email"
                          id="Email"
                          value=""
                        />
                      </div>
                      <p
                        style="color: red; font-family: glory; font-size: 12px"
                      >
                        {{ tryDuplicate }}
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="!isShowss">
                    <div class="col-12">
                      <div
                        class="single-input country-code d-flex align-items-center"
                      >
                        <div class="d-flex align-items-center gap-2 w-100">
                          <img
                            src="/assets/images/{{ countryName }}.png"
                            width="20px"
                            class="fnone"
                          />&nbsp;
                          <div class="ddlabel" style="font-size: 20px">
                            (+{{ dailingCode }}) {{ countryName }}
                          </div>
                        </div>
                        <input
                          type="number"
                          placeholder="0000 000000"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          value=""
                          formControlName="phoneNumber"
                          placeholder="Phone number"
                          required
                          [ngClass]="{
                            'is-invalid': submitted && f.phoneNumber.errors
                          }"
                          maxlength="10"
                        />
                      </div>
                    </div>
                  </div>
              
                  <div class="row" *ngIf="!isShowss">
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input
                          class="passInput"
                          placeholder="Password"
                          [type]="fieldTextType ? 'text' : 'password'"
                          formControlName="password"
                          name="ConfirmPasword"
                          id="ConfirmPasword"
                          [ngClass]="{
                            'is-invalid': submitted && f.password.errors
                          }"
                          class="llog-form-custom"
                          value=""
                          required
                        />
                        <img
                          class="showPass"
                          src="assetsnew/img/show-hide.png"
                          alt="image"
                          (click)="toggleFieldTextType()"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="form-group snd-log-lable">
                        <!-- <p class="error_msg">
                          Password must be minimum of 6 characters and should
                          contain One number and character.
                        </p> -->
                      </div>
                    </div>
                  </div>

                  <div class="form-group" *ngIf="!isShowss">
                    <!-- <label>Business Password<span class="error">*</span></label> -->
                    <div class="single-input d-flex align-items-center">
                    <input [type]=" showPassword ? 'text' : 'password' " 
                    class="passInput llog-form-custom"  (keyup)="passworKeyup($event.target.value)"
                    name="confirmPassword" 
                    id="confirmPassword" 
                    [ngClass]="{'is-invalid': submitted && f.confirmPassword.errors}"
                    placeholder="Confirm Password" 
                    formControlName="confirmPassword">
                    <!-- <i class="fa fa-eye px-2" aria-hidden="true" (click)='showHidePassword()'> </i> -->
                    <img
                          class="showPass"
                          src="assetsnew/img/show-hide.png"
                          alt="image"
                          (click)='showHidePassword()'
                          aria-hidden="true"
                        />
                  </div>
                  <span class="errorr text-danger" *ngIf="confirmPasswordFlag"> Password and confirm password doesn't match</span>
                    <div *ngIf="f.confirmPassword.invalid && isValid != null && !isValid || f.confirmPassword.touched && f.confirmPassword.invalid">
                      <span class="error" *ngIf="f.confirmPassword.errors.required">Confirm Password is required</span>
                        </div>
                    
                  </div>


                  <div class="business-block" *ngIf="isShowss" style="margin-top: 39px;">
                    <!-- <h3 class="inner-title">Business</h3> -->
                    <div class="form-group">
                    <div class="single-input d-flex align-items-center">
                      <!-- <label>Business Name <span class="error">*</span></label> -->
                      <input type="text" 
                      class="form-contro llog-form-custom" 
                      name="businessName" 
                      id="businessName" 
                      [ngClass]="{'is-invalid': submitted && f.businessName.errors}"
                      placeholder="Enter your Business Name" 
                      formControlName="businessName">
      
                    </div>
                      <div *ngIf="f.businessName.invalid && isValid != null && !isValid || f.businessName.touched && f.businessName.invalid">
                        <span class="errorr text-danger" *ngIf="f.businessName.errors.required">Business Name is required</span>
                          </div>
          
                  </div>

                  <div class="form-group">
                    <div class="single-input d-flex align-items-center">
                      <!-- <label>Business Email<span class="error">*</span></label> -->
                      <input type="text" 
                      name="businessEmail" 
                      id="businessEmail"
                      (keyup)="checkBussinessUserName($event.target.value)" 
                      [ngClass]="{'is-invalid': submitted && f.businessEmail.errors}"
                      class="form-contro llog-form-custom" 
                      placeholder="Enter your Business Email" 
                      formControlName="businessEmail">
                      <label style="color:red;font-family:glory;">{{tryDuplicates}}</label>
                    </div>
                      <div *ngIf="f.businessEmail.invalid && isValid != null && !isValid || f.businessEmail.touched && f.businessEmail.invalid">
                        <span class="errorr text-danger" *ngIf="f.businessEmail.errors.required">Business Email is required</span>
                          </div>
                  </div>
                  
      
                    <div class="form-group">
                      <div class="row single-input d-flex align-items-center">
                      <div class="col-md-5 pr-10">
                        <div class="ddOutOfVision" id="CountryIsoCode_msddHolder" style="height: 0px; overflow: hidden; position: absolute;">
                          <select name="CountryIsoCode"
      
                          id="CountryIsoCode" class="form-control llog-form-custom" tabindex="-1">
                            <option data-image="images/GBR.png" value="GBR"> (+44) United Kingdom</option>
                          </select>
                        </div>
      
      
                        <div class="form-group " >
                          <div class="mt-2"><span class="divider">
                          </span>
                          <span class="" style="border-style:hidden">
                          </span><span  >
                            <img src="/assets/images/{{countryName}}.png" width="20px" class="fnone">&nbsp;
                            <span class="ddlabel" style="font-size:30px">(+{{dailingCode}}) {{countryName}}</span><span class="description" style="display: none;"></span>
                          </span>
      
                          </div>
                          <input id="CountryIsoCode_titleText"
                           type="text" autocomplete="off"
                            class="text shadow borderRadius"
                            style="display: none;">
                          <div class="ddChild ddchild_ border shadow" id="CountryIsoCode_child" style="z-index: 1; position: absolute; visibility: visible; height: 59px; top: 67.3333px; display: none;">
                            <ul>
                              <li class="enabled _msddli_ selected"><img src="images/GBR.png" class="fnone"><span class="ddlabel">(+44) United Kingdom</span>
                                <div class="clear"></div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- <label>Business PhoneNumber<span class="error">*</span></label> -->
                      <div class="col-md-7">
                      <input type="text" 
                      class="form-contro llog-form-custom" 
                      name="businessPhoneNumber" 
                      id="businessPhoneNumber" 
                      [ngClass]="{'is-invalid': submitted && f.businessPhoneNumber.errors}"
                      placeholder="Enter your Business Phone Number" 
                      formControlName="businessPhoneNumber">
      
                    </div>
                      <div *ngIf="f.businessPhoneNumber.invalid && isValid != null && !isValid || f.businessPhoneNumber.touched && f.businessPhoneNumber.invalid">
                        <span class="errorr text-danger" *ngIf="f.businessPhoneNumber.errors.required">Business Phone Number is required</span>
                          </div>
                      </div>
                    </div>
                  
      
                        <div class="form-group">
                        <!-- <label>Business Password<span class="error">*</span></label> -->
                        <div class="single-input d-flex align-items-center">
                        <input [type]=" showPassword ? 'text' : 'password' " 
                        class="form-contro llog-form-custom px-2" 
                        name="businessPassword" 
                        id="businessPassword" 
                        [ngClass]="{'is-invalid': submitted && f.businessPassword.errors}"
                        placeholder="Enter your Business Password" 
                        formControlName="businessPassword">
                                          <i class="fa fa-eye px-2" aria-hidden="true" (click)='showHidePassword()'> </i>
                      </div>
                    </div>
                        <div *ngIf="f.businessPassword.invalid && isValid != null && !isValid || f.businessPassword.touched && f.businessPassword.invalid">
                          <span class="errorr text-danger" *ngIf="f.businessPassword.errors.required">Password is required</span>
                            </div>
                        
      
                      <div class="form-group" >
                        <!-- <label>Business Password<span class="error">*</span></label> -->
                        <div class="single-input d-flex align-items-center">
                        <input [type]=" showPassword ? 'text' : 'password' " 
                        class="form-contro llog-form-custom px-2"  (keyup)="passworKeyup($event.target.value)"
                        name="confirmPassword" 
                        id="confirmPassword" 
                        [ngClass]="{'is-invalid': submitted && f.confirmPassword.errors}"
                        placeholder="Confirm Password" 
                        formControlName="confirmPassword">
                        <i class="fa fa-eye px-2" aria-hidden="true" (click)='showHidePassword()'> </i>
                      </div>
                      <span class="errorr text-danger" *ngIf="confirmPasswordFlag"> Password and confirm password doesn't match</span>
                        <div *ngIf="f.confirmPassword.invalid && isValid != null && !isValid || f.confirmPassword.touched && f.confirmPassword.invalid">
                          <span class="error" *ngIf="f.confirmPassword.errors.required">Confirm Password is required</span>
                            </div>
                        
                      </div>
                      
                  
      
                          <div class="form-group">
                          <!-- <label>Business Structure<span class="error">*</span></label> -->
                          <div class="single-input d-flex align-items-center">
                            <select name="businessStructure" formControlName="businessStructure" id="businessStructure" class="custom-select form-contro llog-form-custom">
                              <option value="">-- Select your Business Structure--</option>
                              <option value="Limited liability">Limited liability</option>
                              <option value="Sole proprietor">Sole proprietor</option>
                              <option value="Partnership">Partnership</option>
                             </select>
                          </div>
                        </div> 
                  
              
                        <div *ngIf="isShowws" class="error" class="col-md-12 col-xs-12 px-0 pb-3">
                          <!-- <label class="send-transfer-label" for="BeneAddress">Address  </label> -->
                          <div class="form-contro llog-form-custom">
                                    <div class="form-group" formGroupName="businessAddress" style="">
                            <ng-autocomplete
                            [data]="Array"
                            [searchKeyword]="keyword"
                            [itemTemplate]="itemTemplate"
                              placeholder="Enter Business Address"
                            (selected)='selectEvent($event)'
                            (inputChanged)='onChangeAddress($event)'
            
                            [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
            
                            <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.full_address"></a>
                            </ng-template>
            
                            <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                            </ng-template>
                          </div>
                        </div>
                              </div>
      
                              <div class="error"  *ngIf="isShoww" class="col-md-12 col-xs-12 px-0 pb-3">
                                <!-- <label class="send-transfer-label" for="BeneAddress">Address</label> -->
                                          <div class="form-group" formGroupName="businessAddress" style="">
                                        <ng-autocomplete
                                        [data]="Array1"
                                        [searchKeyword]="keyword1"
                                        [itemTemplate]="itemTemplate1"
                                       formControlName="abc"
                        
                                        placeholder="Enter Business Address"
                                        (selected)='selectEvent1($event)'
                                        (inputChanged)='onChangeGetAddress($event)'
                                        [customFilter]="filterName"
                        
                                        [notFoundTemplate]="notFoundTemplate">
                                      </ng-autocomplete>
                        
                                      <ng-template #itemTemplate1 let-item>
                                        <a [innerHTML]="item.address"></a>
                                      </ng-template>
                        
                                      <ng-template #notFoundTemplate let-notFound>
                                        <div [innerHTML]="notFound"></div>
                                      </ng-template>
                                    </div>
                                    </div>
      
                                    <div class="form-group" *ngIf="isShowwss">
                                      <!-- <label>Business Name <span class="error">*</span></label> -->
                                      <div class="single-input d-flex align-items-center">
                                      <input type="text" 
                                      class="form-contro llog-form-custom" 
                                      name="businessAddress" 
                                      id="businessAddress" 
                                      [ngClass]="{'is-invalid': submitted && f.businessAddress.errors}"
                                      placeholder="Enter your Business Address" 
                                      formControlName="businessAddress">
                                    </div>
                                      <div *ngIf="f.businessAddress.invalid && isValid != null && !isValid || f.businessAddress.touched && f.businessAddress.invalid">
                                        <span class="errorr text-danger" *ngIf="f.businessAddress.errors.required">Business Address is required</span>
                                          </div>
                          
                                    </div>
                    
      
                        <div class="form-group">
                        <!-- <label>Business Category<span class="error">*</span></label> -->
                        <div class="single-input d-flex align-items-center">
                          <select name="businessCategory" formControlName="businessCategory" id="businessCategory" class="custom-select form-contro llog-form-custom">
                            <option value="">-- Select your Business Category--</option>
                            <option value="Retail">Retail</option>
                            <option value="Accounting">Accounting</option>
                            <option value="Advertising">Advertising</option>
                            <option value="Real Estate">Real Estate</option>
                            <option value="Trade">Trade</option>
                            <option value="Photography">Photography</option>
                            <option value="Restaurant">Restaurant</option>
                            <option value="Manufacturing">Manufacturing</option>
                           </select>
                        </div>
                      </div> 
      
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div class="single-input d-flex align-items-center">
                          <select
                            required
                            formControlName="userSource"
                            [ngClass]="{
                              'is-invalid': submitted && f.userSource.errors
                            }"
                            name="userSource"
                            id="userSource"
                            placeholder="where did you"
                            class="llog-form-custom form-controll col-md-12"
                            value=""
                            required=""
                          >
                            <option value="">Where did you hear about us</option>
                            <option value="Email Campaign">Email Campaign</option>
                            <option value="Facebook">Facebook</option>
                            <option value="Family and Friends">
                              Family and Friends
                            </option>
                            <option value="Google">Google</option>
                            <option value="Instagram">Instagram</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      </div>
                    </div>



                  <div class="remember-forgot d-flex justify-content-between">
                    <div class="form-group d-flex">
                      <div class="checkbox_wrapper">
                        <input class="check-box" id="check1" type="checkbox" />
                        <label for="check1"></label>
                      </div>
                      <label for="check1"
                        ><span class="check_span">Remember Me</span></label
                      >
                    </div>
                    <!-- <div class="forget-pw">
                      <a routerLink="/forgot-password">Forgot your password?</a>
                    </div> -->
                  </div>
                  <div class="btn-area" *ngIf="!isloader">
                    <button
                      class="cmn-btn"
                      (click)="onSubmit()"
                      data-toggle="modal"
                      data-target="#myModal"
                      name="button"
                    >
                      Register Now
                    </button>
                  </div>
                  <div class="btn-area" *ngIf="isloader">
                    <div class="col-md-12 text-center">
                      
                      <img class="mb-mode" src="assets/images/dots-loader.gif" width="40%" alt="">
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="bottom-area">
              <div class="login-with d-flex align-items-center">
                <!-- Content here if needed -->
              </div>
            </div>
            <div class="privacy">
              <p>
                By registering, you accept our
                <a routerLink="/useraggrement">Terms & Conditions</a> and
                <a routerLink="/privacypolicy">Privacy Policy</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <script
    async
    src="https://www.googletagmanager.com/gtag/js?id=G-WMDPCVE6LL"
  ></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "G-WMDPCVE6LL");
  </script>
</section>
<!-- Register In end -->
