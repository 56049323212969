import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ExchangeRatesService {
  apiUrl = environment.baseUrl;
  public exchangeRateListUrl: string = `${this.apiUrl}/exchRate/getAllExchangeRates`;
  public destExchangeRateListUrl: string = `${this.apiUrl}/exchRate/getAllExchangeRatesByDestAgentId`;
  public srcExchangeRateListUrl: string = `${this.apiUrl}/exchRate/getAllExchangeRatesBySrcAgentId`;
  public getExchangeDetailsByRateFlagUrl: string = `${this.apiUrl}/exchRate/getExchangeDetailsByRateFlag`;
  public getExchangeDetailsByRateFlagAndApiNameUrl: string = `${this.apiUrl}/exchRate/getExchangeDetailsByRateFlagAndName`;
  public updateUrl: string = `${this.apiUrl}/exchRate/updateExchangeRateById`;
  public getExchangeRatesListByDestAgentIdAndRateFlagUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesListByDestAgentIdAndRateFlag`;
  public getExchangeRatesListBySrcAgentIdAndRateFlagUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesListBySrcAgentIdAndRateFlag`;
  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesListByDestAgentIdAndRateFlag`;
  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiNameUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName`;
  public getApiMarkupByApiNameUrl: string = `${this.apiUrl}/exchRate/getByApiName`;
  public updateApiMarkupByApiNameUrl: string = `${this.apiUrl}/exchRate/updateApiMarkupByApiName`;
  public getApiMarkupListUrl: string = `${this.apiUrl}/exchRate/getApiMarkupList`;
  public updateApiMarkupTypeByNameUrl: string = `${this.apiUrl}/exchRate/updateApiMarkupTypeByName`;
  public getExchangeRatesListByRegisterIdAndRateFlagUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesListByDestRegisterIdAndRateFlag`;
  public getExchangeRatesListByDestAgentIdAndRateFlagReloadlyUrll: string = `${this.apiUrl}/reloadly/getExchangeRatesListByDestAgentIdAndRateFlagReloadly`;
  public getAmbassadorsUrl: string = `${this.apiUrl}/ambassador/getAll`;
  public addOrUpdateAmbassadorsUrl: string = `${this.apiUrl}/ambassador/addOrUpdate`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }),
    //.set('Authorization', localStorage.getItem("datas"))
  };

  constructor(
    private _http: HttpClient,
    private _httpp: HttpClient,
    handler: HttpBackend
  ) {
    this._httpp = new HttpClient(handler);
  }
  private extractData<T>(res: Response): Array<T> | {} {
    const body = res;

    return body || {};
  }

  //getExchangeDetailsByRateFlagUrl
  public getExchangeDetailsByRateFlagAndApiName(
    rateFlag: String,
    apiName: string,
    pId: string
  ): Observable<any> {
    console.log('working getExchangeDetailsByRateFlagAndApiName=' + pId);
    return this._http
      .get(
        `${this.getExchangeDetailsByRateFlagAndApiNameUrl}` +
          '?pId=' +
          pId +
          '&rateFlag=' +
          rateFlag +
          '&apiName=' +
          apiName,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getExchangeDetailsByRateFlagUrl
  public getExchangeDetailsByRateFlag(
    rateFlag: String,
    pId: string
  ): Observable<any> {
    console.log('working getExchangeDetailsByRateFlagUrl=' + pId);
    return this._http
      .get(
        `${this.getExchangeDetailsByRateFlagUrl}` +
          '?pId=' +
          pId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAllExchangeRates(pId: string): Observable<any> {
    console.log('working getAllExchangeRates=' + pId);
    return this._http
      .get(`${this.exchangeRateListUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getAllDestExchangeRates(agentId: any, pId: string): Observable<any> {
    console.log('working getAllDestExchangeRates=' + pId);
    return this._http
      .get(
        `${this.destExchangeRateListUrl}` +
          '?pId=' +
          pId +
          '&destAgentId=' +
          agentId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getExchangeRatesListByDestAgentIdAndRateFlagUrl
  public getExchangeRatesListByDestAgentIdAndRateFlag(
    agentId: any,
    rateFlag: any,
    pId: string
  ): Observable<any> {
    console.log(
      'working getExchangeRatesListByDestAgentIdAndRateFlagUrl=' + pId
    );
    return this._http
      .get(
        `${this.getExchangeRatesListByDestAgentIdAndRateFlagUrl}` +
          '?pId=' +
          pId +
          '&destAgentId=' +
          agentId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAllSrcExchangeRates(agentId: any, pId: string): Observable<any> {
    console.log('working getAllSrcExchangeRates=' + pId);
    return this._http
      .get(
        `${this.srcExchangeRateListUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          agentId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getExchangeRatesListBySrcAgentIdAndRateFlagUrl
  public getExchangeRatesListBySrcAgentIdAndRateFlag(
    agentId: any,
    rateFlag: any,
    pId: string
  ): Observable<any> {
    console.log('working getAllSrcExchangeRates=' + pId);
    return this._http
      .get(
        `${this.getExchangeRatesListBySrcAgentIdAndRateFlagUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          agentId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public updateExchangeRateById(
    exchangeRecord: any,
    pId: string,
    exchRateId: number
  ) {
    console.log(
      'working updateExchangeRateById=' + pId + '..exchRateId=' + exchangeRecord
    );
    return this._http
      .put(
        `${this.updateUrl}` + '?pId=' + pId + '&exchRateId=' + exchRateId,
        exchangeRecord,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getExchangeRatesListByDestAgentIdAndRateFlagReloadlyUrl(
    agentId: any,
    rateFlag: any,
    pId: string
  ): Observable<any> {
    console.log(
      'working getExchangeRatesListByDestAgentIdAndRateFlagUrl=' + pId
    );
    return this._http
      .get(
        `${this.getExchangeRatesListByDestAgentIdAndRateFlagReloadlyUrll}` +
          '?pId=' +
          pId +
          '&destAgentId=' +
          agentId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagUrl
  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlag(
    srcAgentId: any,
    destAgentId: any,
    rateFlag: any,
    pId: string
  ): Observable<any> {
    console.log('working getAllSrcExchangeRates=' + pId);
    return this._http
      .get(
        `${this.getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName(
    srcAgentId: any,
    destAgentId: any,
    rateFlag: any,
    apiName: string,
    pId: string,
    registerId: any
  ): Observable<any> {
    console.log(
      'working getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiName=' +
        pId
    );
    return this._http
      .get(
        `${this.getExchangeRatesListBySrcAgentAndDestAgentIdAndRateFlagAndApiNameUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&rateFlag=' +
          rateFlag +
          '&apiName=' +
          apiName +
          '&registerId=' +
          registerId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getApiMarkupByApiName
  public getApiMarkupByApiName(apiName: any, pId: string): Observable<any> {
    console.log('working getApiMarkupByApiName=' + apiName);
    return this._httpp
      .get(
        `${this.getApiMarkupByApiNameUrl}` +
          '?pId=' +
          pId +
          '&apiName=' +
          apiName
      )
      .pipe(map(this.extractData));
  }

  //updateApiMarkupByApiName
  public updateApiMarkupByApiName(
    apiName: any,
    markup: number,
    pId: string
  ): Observable<any> {
    console.log('working getApiMarkupByApiName=' + pId);
    return this._http
      .get(
        `${this.updateApiMarkupByApiNameUrl}` +
          '?pId=' +
          pId +
          '&apiName=' +
          apiName +
          '&markup=' +
          markup,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public updateApiMarkupTypeByName(
    apiName: any,
    markup: number,
    pId: string
  ): Observable<any> {
    console.log('working updateApiMarkupTypeByName=' + pId);
    return this._http
      .put(
        `${this.updateApiMarkupTypeByNameUrl}` +
          '?pId=' +
          pId +
          '&apiName=' +
          apiName +
          '&markupType=' +
          markup,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getApiMarkupList
  public getApiMarkupList(pId: string): Observable<any> {
    console.log('working getApiMarkupList=' + pId);
    return this._http
      .get(`${this.getApiMarkupListUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getExchangeRatesListByRegisteryIdAndRateFlag(
    registerId: any,
    rateFlag: any,
    pId: string
  ): Observable<any> {
    console.log('getExchangeRateFlagUrl=' + pId);
    return this._http
      .get(
        `${this.getExchangeRatesListByRegisterIdAndRateFlagUrl}` +
          '?pId=' +
          pId +
          '&registerId=' +
          registerId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAmbassadors(pId: string) {
    return this._http
      .get(`${this.getAmbassadorsUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public addOrUpdateAmbassadors(pId: string, payload: any) {
    return this._http
      .post(
        `${this.addOrUpdateAmbassadorsUrl}` + '?pId=' + pId,
        payload,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
}
