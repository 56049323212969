import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders,HttpBackend} from "@angular/common/http";
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MyAccountServiceService {
    apiUrl=environment.baseUrl;
  public listBySendingCustomerId:string = `${this.apiUrl}/transaction/getTransactionListBySendingCustomerUserId`;
  public getTransactionListDetailsBySendingCustomerUserIdUrl:string = `${this.apiUrl}/transaction/getTransactionListDetailsBySendingCustomerUserId`;
  public beneficiaryListByCustomerId:string =`${this.apiUrl}/customer/getBeneficiaryListByCustomerId`;
  public deleteBeneficiaryDetailsById:string =`${this.apiUrl}/transaction/deleteBeneficiaryByBeneficiaryId`;
  public getBeneficiaryByBeneficiaryId:string =`${this.apiUrl}/transaction/getBeneficiaryDetailsByBeneficiaryId`;
    public getBeneficiaryByBeneficiaryIdUrl:string =`${this.apiUrl}/transaction/getBeneficiaryDetailsByBeneficiaryIdAndCountryId`;
  public userIdentityDetailsPath:string=`${this.apiUrl}/user/getUserIdentityDetailsByUserId`;
  public userAddressDetailsPath:string=`${this.apiUrl}/user/getUserAddressDetailsByUserId`;
  public updateUserAddressDetailsPath:string=`${this.apiUrl}/user/updateUserAddressDetailsByUserId`;
  public updateUserIdentityDetailsPath:string=`${this.apiUrl}/user/updateUserIdentityDetailsByUserId`;
  public forgotPasswordEmailUrl:string =`${this.apiUrl}/customer/forgotPasswordEmailToCustomer`;
  public updatePasswordUrl:string =`${this.apiUrl}/customer/updateForgotPassword`;
  public getUserWalletDetailsByUserIdUrl:string=`${this.apiUrl}/user/getUserWalletDetailsByUserId`;
  public getUserWalletDetailsByUserIddUrl:string=`${this.apiUrl}/user/getUserWalletDetailsByUserIdd`;
public getUserWalletDetailsByUserIdForIndex:string=`${this.apiUrl}/user/getUserWalletDetailsByUserIdInDisendingOrder`;
public getTransactionListByBeneficiaryIdUrl:string=`${this.apiUrl}/transaction/getTransactionListByBeneficiaryId`;
public getUserWalletDetailsByUserIdAndCreatedOnUrl:string=`${this.apiUrl}/user/getUserWalletDetailsByUserIdAndCreatedOn`;

public getUserWalletDetailsByUserIdAndBetweenDatesUrl:string=`${this.apiUrl}/user/getUserWalletDetailsByUserIdAndBetweenDates`;

public updateUserWalletDetailsByUserIdUrl:string=`${this.apiUrl}/user/updateUserWalletDetailsByUserId`;
public updateUserWalletDetailsByUserIddUrl:string=`${this.apiUrl}/user/updateUserWalletDetailsByUserIdd`;
public getUserWalletDetailsByUserWalletIdUrl:string=`${this.apiUrl}/user/getUserWalletDetailsByUserWalletId`;
public getUserWalletDetailsByUserIdAndBwDates:string=`${this.apiUrl}/user/getUserWalletDetailsByUserIdBwDates`;

  httpOptions = {
    headers: new HttpHeaders({}) //.set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http:HttpClient ,handler: HttpBackend,private _httpp:HttpClient) {
      this._httpp = new HttpClient(handler); }


  public extractData(res:Response):Array<any> | {} {
    const  body=res;
    console.log('res',res)
    return body || {};
  }
  public getTransactionListBySendingCustomerUserId(Id:any, pId: string): Observable<any> {
    console.log('TransactionListBySendingCustomerUserId....', pId);
    return this._http.get(`${this.listBySendingCustomerId}`+'?pId=' + pId+'&sendingCustomerUserId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getTransactionListDetailsBySendingCustomerUserId( pId: string): Observable<any> {
    console.log('TransactionListBySendingCustomerUserId....', pId);
    return this._http.get(`${this.getTransactionListDetailsBySendingCustomerUserIdUrl}`+'?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  //getUserIdentityByUserId
  public getUserIdentityByUserId(Id:any, pId: string): Observable<any> {
    console.log('userIdentityDetails....', pId);
    return this._http.get(`${this.userIdentityDetailsPath}`+'?pId=' + pId+'&userId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //getUserAddressByUserId

  public getUserAddressByUserId(Id:any, pId: string): Observable<any> {
    console.log('userAddressDetails....', pId);
    return this._http.get(`${this.userAddressDetailsPath}`+'?pId=' + pId+'&userId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //update user address
  public updateUserAddressByUserId( pId: string,Id:any,record: any): Observable<any> {
    console.log('userAddressDetails....', pId);


    return this._http.post(`${this.updateUserAddressDetailsPath}`+'?pId='+pId+'&userId='+Id,record,this.httpOptions).pipe(

      map(this.extractData)
    )
  }
  //update user identity


  public updateUserIdentityByUserId(pId:string,Id:any,record:any): Observable<any> {
    console.log('userIdentity....', pId);
    return this._http.post(`${this.updateUserIdentityDetailsPath}`+'?pId='+pId+'&userId='+Id,record,this.httpOptions).pipe(
      map(this.extractData))
    }

    public getTransactionListByBeneficiaryId(pId:string,Id:any): Observable<any> {
      return this._http.get(`${this.getTransactionListByBeneficiaryIdUrl}`+'?pId='+pId+'&beneficiaryId='+Id,this.httpOptions).pipe(
        map(this.extractData))
      }
    //
    // public updateUserIdentityByUserId(pId:string, Id:any,record:any): Observable<any> {
    //
    //   console.log('userAddressDetails....', pId);
    //   return this._http.post(`${this.updateUserIdentityDetailsPath}`+'?pId=' + pId+'&userId='+Id,record, this.httpOptions).pipe(
    //
    //     map(this.extractData)
    //   )

    //public updateUserIdentityByUserId(Id:any, pId: string,record: any): Observable<any> {
    //  console.log('userAddressDetails....', pId);
    //  return this._http.get(`${this.updateUserIdentityDetailsPath}`+'?pId='+pId+'&userId='+Id, record,this.httpOptions).pipe(

    // public updateUserIdentityByUserId1(pId:string, Id:any,record:any): Observable<any> {
    //
    //   console.log('userAddressDetails....', pId);
    //   return this._http.post(`${this.updateUserIdentityDetailsPath}`+'?pId=' + pId+'&userId='+Id,record, this.httpOptions).pipe(
    //
    //
    //     map(this.extractData)
    //   )
    // }


    public getBeneficiaryDetailsByBeneficiaryIdAndCountryId(Id:any, pId:string,countryId:any): Observable<any> {
      console.log('beneficiaryDetails....', pId);
      console.log("id   "+Id)
      return this._http.get(`${this.getBeneficiaryByBeneficiaryIdUrl}`+'?pId=' + pId+'&beneficiaryId='+Id+'&countryId='+countryId, this.httpOptions).pipe(
        map(this.extractData)
      )
    }
    public getBeneficiaryDetailsByBeneficiaryId(Id:any, pId:string): Observable<any> {
      console.log('beneficiaryDetails....', pId);
      console.log("id   "+Id)
      return this._http.get(`${this.getBeneficiaryByBeneficiaryId}`+'?pId=' + pId+'&beneficiaryId='+Id, this.httpOptions).pipe(
        map(this.extractData)
      )
    }


    public deleteBeneficiaryById(Id:any, pId: string): Observable<any> {
      console.log('deleteBeneficiaryById....', pId);
      return this._http.delete(`${this.deleteBeneficiaryDetailsById}`+'?pId=' + pId+'&beneficiaryId='+Id, this.httpOptions).pipe(
        map(this.extractData)
      )
    }


    public getBeneficiaryListByCustomerId(Id:any, pId: string): Observable<any> {
      console.log('getBeneficiaryListByCustomerId....', pId);
      return this._http.get(`${this.beneficiaryListByCustomerId}`+'?pId=' + pId+'&customerId='+Id, this.httpOptions).pipe(
        map(this.extractData)
      )
    } 

    public forgotPasswordEmail(userName:any, pId: string): Observable<any> {
    console.log('forgotPasswordEmailUrl....', pId);
    return this._httpp.get(`${this.forgotPasswordEmailUrl}`+'?pId=' + pId+'&userName='+userName, this.httpOptions)
  }

  public updatePassword(record:any, pId: string): Observable<any> {
        console.log('updatePasswordUrl....', pId);
        return this._httpp.post(`${this.updatePasswordUrl}`+'?pId=' + pId,record, this.httpOptions)
      }

      //getUserWalletDetailsByUserIdUrl

      public getUserWalletDetailsByUserId(userId:any,pId: string): Observable <any>{
          console.log("userId...!"+userId);
          return this._http.get(`${this.getUserWalletDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId).pipe(
          map(this.extractData)
          );
        }

        public getUserWalletDetailsByUserIdd(pId: string): Observable <any>{

            return this._http.get(`${this.getUserWalletDetailsByUserIddUrl}`+'?pId='+pId,this.httpOptions).pipe(
            map(this.extractData)
            );
          }


        //getUserWalletDetailsByUserIdUrl

        public getUserWalletDetailsByUserIdIndex(userId:any,pId: string): Observable <any>{
            console.log("userId...!"+userId);
            return this._http.get(`${this.getUserWalletDetailsByUserIdForIndex}`+'?pId='+pId+'&userId='+userId).pipe(
            map(this.extractData)
            );
          }

      //updateUserWalletDetailsByUserIdUrl
      public updateUserWalletDetailsByUserId(record:any,userId:any,pId : string){
        return this._http.put(`${this.updateUserWalletDetailsByUserIdUrl}`+'?pId='+pId+'&userId='+userId,record,this.httpOptions).pipe(
            map(this.extractData)
        )
      }


      //updateUserWalletDetailsByUserIdUrl
      public updateUserWalletDetailsByUserIdd(record:any,pId : string){
        return this._http.put(`${this.updateUserWalletDetailsByUserIddUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
            map(this.extractData)
        )
      }


      //getUserWalletDetailsByUserIdAndBetweenDatesUrl
      public getUserWalletDetailsByUserIdAndCreatedOn(userId:any,fromDate:any,toDate:any,pId: string): Observable <any>{
          console.log("userId...!"+userId);
          return this._http.get(`${this.getUserWalletDetailsByUserIdAndCreatedOnUrl}`+'?pId='+pId+'&userId='+userId+'&fromDate='+fromDate+'&toDate='+toDate).pipe(
          map(this.extractData)
          );
        }
      //getUserWalletDetailsByUserIdAndBetweenDatesUrl
      public getUserWalletDetailsByUserIdAndBetweenDates(userId:any,days:any,pId: string): Observable <any>{
          console.log("userId...!"+userId+"days..!"+days);
          return this._http.get(`${this.getUserWalletDetailsByUserIdAndBetweenDatesUrl}`+'?pId='+pId+'&userId='+userId+'&days='+days).pipe(
          map(this.extractData)
          );
        }
      //getUserWalletDetailsByUserWalletIdUrl
      public getUserWalletDetailsByUserWalletId(userWalletId:any,pId: string): Observable <any>{
          console.log("userId...!"+userWalletId);
          return this._http.get(`${this.getUserWalletDetailsByUserWalletIdUrl}`+'?pId='+pId+'&userWalletId='+userWalletId).pipe(
          map(this.extractData)
          );
        }
        //getUserWalletDetailsByUserWalletIdUrl
        public getUserWalletDetailsByUserIdBwDates(pId:any,userId: any,fromDate:any,toDate: any): Observable <any>{
            console.log("userId...!"+userId);
            console.log("fromDate...!"+fromDate);
            console.log("toDate...!"+toDate);
            return this._http.get(`${this.getUserWalletDetailsByUserIdAndBwDates}`+'?pId='+pId+'&userId='+userId+'&fromDate='+fromDate+' 00:00:00'+'&toDate='+toDate+' 23:59:59').pipe(
            map(this.extractData)
            );
          }


  }
