import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators'


@Injectable({
  providedIn: 'root'
})
export class AgentCollectionService {

  public collectionApi:string = 'http://localhost:3000/serviceFee'

  httpOptions={
    headers:new HttpHeaders ({'content-type':'application/json'})
  }

  constructor(private _http:HttpClient) { }

private extractData(res:Response):Array<any> | {} {
  const body=res;
  console.log('res',res)
  return body || {};
}

public getCollectionList():Observable<any>{
  return this._http.get(this.collectionApi).pipe(
    map(this.extractData)
  )
}
public postCollectionList(collectionRecord:any):Observable<any>{
  return this._http.post(collectionRecord,this.collectionApi).pipe(
    map(this.extractData
    )
  )
}



}
