import { OnInit, Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-new-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class NewFooterComponent implements OnInit {
  constructor(
    private _router:Router
  ) { }

  ngOnInit(): void {
  }
  openHome(){
    this._router.navigate(['/'])
  }
  openprivacypolicy(){
    this._router.navigate(['privacypolicy'])
  }
  
  openaboutus(){
    this._router.navigate(['aboutus'])
  }
  opencontact(){
    this._router.navigate(['contactus'])
  }
  openrefund(){
    this._router.navigate(['refund'])
  }
openuseraggrement(){
  this._router.navigate(['/useraggrement'])
}
  opensendmoney(){
    this._router.navigate(['/sendmoney'])

  }
  openfaqs(){
    this._router.navigate(['faqs'])
  }
  openhelp(){
    this._router.navigate(['help'])
  }
  deleteaccount(){
    this._router.navigate(['deleteaccount'])
  }
}
