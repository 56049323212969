// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // baseUrl: 'http://localhost:2021',

  // baseUrl:'https://testapi.tayrexchange.com'
  // baseUrl:'https://testapi.tayrexchange.com'

 baseUrl:'https://api.tayrexchange.com'

  // baseUrl: 'http://localhost:2021',
  // baseUrl: 'http://localhost:2075',
// 
  // baseUrl: 'https://nairelam.remitz.co.uk',

  // baseUrl:'https://api.tayrexchange.com'
  // baseUrl: 'https://staging-api.tayrexchange.com',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
