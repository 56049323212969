import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders ,HttpBackend} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable } from 'rxjs';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginserviceService {
  apiUrll=environment.baseUrl;
  public apiUrl : string = `${this.apiUrll}/user/login`;
  public apiUrl1: string = `${this.apiUrll}/user/getUserByUserNameAndPassword`;
  public apiUrl2: string = `${this.apiUrll}/user/getAgentById`;
  public userById: string = `${this.apiUrll}/user/getUserDetailsByUserId`;
  public userBusinessDetailsById: string = `${this.apiUrll}/transaction/getUserBusinessDetailsByUserId`;
  public deactivateAccountUrl:string=`${this.apiUrll}/user/deactivateUser`;

  httpOptions = {
    headers : new HttpHeaders ({'content-type' : 'application/json'})

  };

  constructor(private _http:HttpClient,private _httpp:HttpClient,
  handler: HttpBackend) {
    this._httpp = new HttpClient(handler);
  }


    private  extractData( res: Response ) : Array <any> | {} {
      const body = res;
      console.log('res ',res)
      return body || {} ;
    }
    public postUser(userRecord:any,pId : string): Observable <any>{
      console.log("userRecord...!"+userRecord.userName+", "+userRecord.password);
      // return this._http.post(`${this.apiUrl}`+'?userName='+`${userRecord.userName}`+'&password='+`${userRecord.password}`,
      // this.httpOptions)
      return this._httpp.post(`${this.apiUrl}`,userRecord,this.httpOptions)

    }

    public getUser(userRecord:any,pId : string): Observable <any>{
      console.log("userRecord...!"+userRecord.userName+", "+userRecord.password);
      return this._http.get(`${this.apiUrl1}`+'?pId='+pId+'&userName='+`${userRecord.userName}`+'&password='+`${userRecord.password}`,this.httpOptions).pipe(
      map(this.extractData)
      );
    }

    public getUserById(userId:any,pId : string): Observable <any>{
      console.log("userId...!"+userId);
      return this._http.get(`${this.apiUrl2}`+'?pId='+pId+'&Id='+userId,this.httpOptions).pipe(
      map(this.extractData)
      );
    }

    public getUserDetailsById(userId:any,pId : string): Observable <any>{
        console.log("userId...!"+userId);
        return this._http.get(`${this.userById}`+'?pId='+pId+'&userId='+userId,this.httpOptions).pipe(
        map(this.extractData)
        );
      }

      public getUserBusinessDetailsById(userId:any,memberShipId:any,pId : string): Observable <any>{
        console.log("userId...!"+userId);
        return this._http.get(`${this.userBusinessDetailsById}`+'?pId='+pId+'&userId='+userId+'&memberShipId='+memberShipId,this.httpOptions).pipe(
        map(this.extractData)
        );
      }

      public deactivateAccount(userId:any,status:any,pId: string): Observable<any> {
        console.log("deactivateAccount..." + userId);
        return this._http.put(`${this.deactivateAccountUrl}` + '?pId=' + pId+'&userId='+userId+'&status='+status,this.httpOptions).pipe(
          map(this.extractData)
        );
      }

}
