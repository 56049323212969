<!-- Navbar start -->
<header class="header-section fixed" (scroll)="onScroll()">
  <div class="overlay">
    <div class="container">
      <div class="row d-flex header-area">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" routerLink="/">
            <img
              src="assetsnew/images/logo-light.png"
              class="logo"
              alt="logo"
              loading="lazy"
            />
          </a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbar-content"
          >
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown main-navbar">
                <!-- <a class="nav-link" routerLink="/aboutus">About Us</a> -->
                <a class="nav-link" (click)="openaboutus()">About Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/contactus">Contact Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/faqs">Faqs</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/help">How it works</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a routerLink="/login" class="nav-link">Login</a>
              </li>
            </ul>
            <div
              class="right-area header-action d-flex align-items-center max-un"
            >
              <a routerLink="/signup" class="cmn-btn command_btn"
                >Sign Up
                <i class="icon-d-right-arrow-2"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Navbar end -->

<!-- banner-section start -->
<section class="banner-section inner-banner privacy-content">
  <div class="overlay">
    <div class="banner-content d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-start">
          <div class="col-lg-7 col-md-10">
            <div class="main-content">
              <h1 class="title3">FAQS</h1>
              <div class="breadcrumb-area">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb d-flex align-items-center">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      Faqs
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="faqs-section">
  <div class="overlay pt-120 pb-120">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-lg-7">
          <div class="section-header text-center">
            <h5 class="sub-title">Frequently Asked Questions</h5>
            <h2 class="title">If you've got questions we have answer</h2>
            <p>We have a list of frequently asked questions about us</p>
          </div>
        </div>
      </div>
      <div class="row cus-mar">
        <div class="col-lg-6">
          <div class="accordion accordion-flush" id="accordionFlushExample">
            <div class="accordion-item">
              <p class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne"
                >
                  What services do Tayrex Exchange offer?
                </button>
              </p>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>
                    Tayrex Exchange offers a channel to pass money from a
                    surplus side to a deficit side, usually between a source
                    country and an overseas corridor. Transactions could be
                    delivered via various channels depending on the chosen
                    corridor. This includes:
                  </p>
                  <p>1. Bank Account Credit</p>
                  <p>2. Cash Delivery</p>
                  <p>3. Mobile Money</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="flush-headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  How can I pay for my transfer ?
                </button>
              </p>
              <div
                id="flush-collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>1. Bank Account Transfer</p>
                  <p>2. Pay ID</p>
                  <p>3. Poli</p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="flush-headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseThree"
                  aria-expanded="false"
                  aria-controls="flush-collapseThree"
                >
                  Where can I send money to ?
                </button>
              </p>
              <div
                id="flush-collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingThree"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>
                    Currently we are live in Six (6) Countries with a prospect
                    to increase in the second quarter of 2024.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="flush-headingFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFour"
                  aria-expanded="false"
                  aria-controls="flush-collapseFour"
                >
                  How to use Referral Code?
                </button>
              </p>
              <div
                id="flush-collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFour"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>
                    Visit your dashboard and click on my referral, you will see
                    and option for Generate code, click to get referral code and
                    share with friends and families
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="flush-headingFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseFive"
                  aria-expanded="false"
                  aria-controls="flush-collapseFive"
                >
                  How long will it take for the money to arrive?
                </button>
              </p>
              <div
                id="flush-collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingFive"
                data-bs-parent="#accordionFlushExample"
              >
                <div class="accordion-body">
                  <p>
                    It takes minutes or hours depending on your circumstances
                    like which day of the week, your bank or the bank of your
                    recipient and the respective corridor.
                  </p>

                  <p>
                    We aim to complete all transactions within the shortest
                    possible period, usually minutes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="accordion" id="accordionRight">
            <div class="accordion-item">
              <p class="accordion-header" id="headingRightOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseRightOne"
                  aria-expanded="false"
                  aria-controls="collapseRightOne"
                >
                  Why do I need to supply my identification ?
                </button>
              </p>
              <div
                id="collapseRightOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingRightOne"
                data-bs-parent="#accordionRight"
              >
                <div class="accordion-body">
                  <p>
                    We are obligated by law to know our client (KYC) and
                    facilitate transactions for only our known clients.
                    Therefore, in order to fulfil this requirement and maintain
                    good stance with the regulatory body, we would need to have
                    your ID document and address document.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="headingRightTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseRightTwo"
                  aria-expanded="false"
                  aria-controls="collapseRightTwo"
                >
                  How much money can I send?
                </button>
              </p>
              <div
                id="collapseRightTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingRightTwo"
                data-bs-parent="#accordionRight"
              >
                <div class="accordion-body">
                  <p>
                    You can send anything from the baseline of your country, as
                    every country is different. For instance, if you are in
                    Australia the lowest amount you can send is $10. In terms of
                    maximum money you can send, it depends on the single obligor
                    limit of your source country.
                  </p>

                  <p>
                    <b>NOTE:</b> There is a limit per transaction and this is
                    always updated on our website by our compliance officer
                    after proper risk analysis of your corridor has been carried
                    out.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="headingRightThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseRightThree"
                  aria-expanded="false"
                  aria-controls="collapseRightThree"
                >
                  How is my money protected ?
                </button>
              </p>
              <div
                id="collapseRightThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingRightThree"
                data-bs-parent="#accordionRight"
              >
                <div class="accordion-body">
                  <p>
                    We have taken our time to carefully choose a registered bank
                    in each source country for fund collection to ensure we
                    could rely on the robustness of their service to ensure safe
                    collection of funds.
                  </p>

                  <p>
                    Furthermore, our payout partners in all corridors are
                    registered companies and would maintain probity at all times
                    to ensure safe delivery of clients’ funds.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="headingRightFour">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseRightFour"
                  aria-expanded="false"
                  aria-controls="collapseRightFour"
                >
                  How do I reset my password?
                </button>
              </p>
              <div
                id="collapseRightFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingRightFour"
                data-bs-parent="#accordionRight"
              >
                <div class="accordion-body">
                  <p>
                    Click on forget password on the login page and follow the
                    prompts.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <p class="accordion-header" id="headingRightFive">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseRightFive"
                  aria-expanded="false"
                  aria-controls="collapseRightFive"
                >
                  Where can I find the Tayrex Exhange App?
                </button>
              </p>
              <div
                id="collapseRightFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingRightFive"
                data-bs-parent="#accordionRight"
              >
                <div class="accordion-body">
                  <p>
                    You can find the Tayrex Exchange app by going to playstore
                    on your android phone. You can find theTayrex Exchange app
                    by going to Appstore on your iphone.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-new-footer></app-new-footer>
