import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ExchangerateService {
  apiUrll=environment.baseUrl
public baseUrl : string=`${this.apiUrll}/exchRate/createMasterExchangeRates`;
public apiUrl :string=`${this.apiUrll}/keyclock/getClientSendingCountrys`;
public apiUrl2 :string=`${this.apiUrll}/keyclock/getClientReceivingCountrys`;
public apiUrl3 : string=`${this.apiUrll}/keyclock/getClientBenePayTypes`;
public apiUrl4 : string=`${this.apiUrll}/exchRate/getAllMasterExchangeRates`;
public apiUrl5:string=`${this.apiUrll}/exchRate/updateMasterExchangeRates`;
public paymentTypeUrl: string = `${this.apiUrll}/agent/getAgentBenifPaymentTypeList`;



httpOptions = {
  headers : new HttpHeaders ({'content-type' : 'application/json'})
  //.set('Authorization', localStorage.getItem("datas"))

}

  constructor(private _http:HttpClient) { }

  private extractData( res :Response ) : Array <any> | {} {
    const body = res;
    console.log('res',res);
    return body || {};
  }

  public saveMasterRates(exchangeRateRecord:any,pId : string): Observable <any>{
       console.log("exchangeRateRecord...",exchangeRateRecord);
      return this._http.post(this.baseUrl+'?pId='+pId,exchangeRateRecord,this.httpOptions).pipe(
        map(this.extractData)
       );
 }


  public getClientSendingCountrys(token:any,pId : string) : Observable <any> {
    console.log("working getClientSendingCountrys="+token);
    return this._http.get(`${this.apiUrl}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public getClientReceivingCountrys(token:any,pId : string) : Observable <any> {
    console.log("working getClientReceivingCountrys="+token);
    return this._http.get(`${this.apiUrl2}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getClientBenePayTypes(token:any,pId : string) : Observable <any>{
    console.log("working getClientBenePayTypes="+token);
    return this._http.get(`${this.apiUrl3}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAllMasterExchangeRates(pId : string) : Observable <any>{
    console.log("working getAllMasterExchangeRates=");
    return this._http.get(`${this.apiUrl4}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  // public updateExchange(id:number,exchangeRateRecord:any,pId : string):void{
  //   return this._http.put(`${this.apiUrl5}`+'?pId='+pId,this.httpOptions,exchangeRateRecord).pipe(
  //     map(this.extractData)
  //   );
  //
  // }
  public updateMasterExchangeRate(branchRecord:any,pId:string,masterExchangeRateId:number){
    console.log("working getAllMasterExchangeRates="+pId+".."+masterExchangeRateId);
    console.log("working getAllMasterExchangeRates="+JSON.stringify(branchRecord));
    return this._http.put(`${this.apiUrl5}`+'?pId='+pId+'&id='+masterExchangeRateId,branchRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public getAgentBenifPaymentTypes(agentId: any, pId: string): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._http.get(`${this.paymentTypeUrl}`+'?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


}
