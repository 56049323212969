import { Component } from '@angular/core';
interface Slide {
  imageUrl: string;
  caption: string;
  desc: String;
  author: String
}
@Component({
  selector: 'app-new-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialNewComponent {
  slides: Slide[] = [
    {
      imageUrl: 'assets/images/country/01.png',
      caption:'1 review',
      desc: `Tayrex Exchange is trustworthy, reliable and the best out of 3 others that I have used to do cross border transactions for the past years by getting money within minutes to my family and loved ones without any hidden fees or service charge.`,
      author: 'AMB ADENIYI EKINE'
    },
    {
      imageUrl: 'assets/images/country/02.png',
      caption:'1 review',
      desc: `Tayrex "Tayrex as a financial organisation has really helped lots of people in the diapspora transfer money back home to their loved ones with so much ease. Also, they do this without transactional fees which is hard to find in this sector.`,
      author: 'Richard Akpa NSW'
    },
    {
      imageUrl: 'assets/images/country/03.png',
      caption:'1 review',
      desc: `Tayrex Exchange is trustworthy, reliable and the best out of 3 others that I have used to do cross border transactions for the past years by getting money within minutes to my family and loved ones without any hidden fees or service charge.`,
      author: 'AMB ADENIYI EKINE'
    },
    {
      imageUrl: 'assets/images/country/04.png',
      caption:'1 review',
      desc: `Tayrex "Tayrex as a financial organisation has really helped lots of people in the diapspora transfer money back home to their loved ones with so much ease. Also, they do this without transactional fees which is hard to find in this sector.`,
      author: 'Richard Akpa NSW'
    }
    // Add more slides here as needed
  ];

  currentIndex = 0;

  ngOnInit() {
    this.showSlide(this.currentIndex);
  }

  showSlide(index: number) {
    this.currentIndex = index;
  }

  nextSlide() {
    this.currentIndex = (this.currentIndex + 1) % this.slides.length;
  }

  prevSlide() {
    this.currentIndex = (this.currentIndex - 1 ) % this.slides.length;
  }

}
