import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators,FormArray,FormBuilder} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import {ExchangeRatesService,ProcessIdGeneraterService} from '../../service';
import {AuditTrialService } from '../../service';
import { HttpClient  } from '@angular/common/http';

@Component({
  selector: 'app-buy-rates',
  templateUrl: './buy-rates.component.html',
  styleUrls: ['./buy-rates.component.css']
})
export class BuyRatesComponent implements OnInit {

  isShow:boolean
public List:any;
 sv:any;
 dv:any;
 mv:any;
 adr:any;
 smv:any;
 srate:any;
 ipAddress:any;
exchRateForm:FormGroup;
auditTrialForm:FormGroup;
  constructor(private fb :FormBuilder ,
    private _router :Router,
    private _auditTrialService: AuditTrialService,
    private _route:ActivatedRoute,
    private http:HttpClient,
    private _exchangeratesservice:ExchangeRatesService,
    private processIdGeneraterService : ProcessIdGeneraterService) { }


  ngOnInit(): void {

    //this.getIPAddress();
    this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
      this.ipAddress = res.ip;
     // alert(this.ipAddress)

    });


          if(localStorage.getItem("adminId")==null){
              this._router.navigateByUrl('admin');
          }

    this.isShow=false;

    this.exchRateForm=new FormGroup({
    sourceAgentId:new FormControl(''),
    destinationAgentId:new FormControl(''),
    markUpValue:new FormControl(''),
    sellRate:new FormControl(''),
    adminRate:new FormControl(''),
    srcCurrencyValue:new FormControl(''),
    destCurrencyValue:new FormControl(''),
    agentBenifPayTypeId:new FormControl(''),
    srcMarkUpValue:new FormControl('')
    });
    this.auditTrialForm=new FormGroup({
    category:new FormControl(''),
    userName:new FormControl(''),
    userId:new FormControl(''),
    userType:new FormControl(''),
    ipAddress:new FormControl(''),
    sourceCountry:new FormControl(''),
    agent:new FormControl(''),
    transaction:new FormControl(''),
    message:new FormControl(''),
    originalData:new FormControl(''),
    changedData:new FormControl('')
    });


    this._exchangeratesservice.getExchangeDetailsByRateFlag('buy',
    this.processIdGeneraterService.getPid()).subscribe(res => {
             if (res){
                   this.List =res.response;
                   console.log('List',this.List)

             }
           },err =>{
            //  alert("fail");
             console.log(err);
           }
         );

  }

  onRowClick1(event,id){
  //var ar=event.target.outerText;
  this.dv=event.target.outerText;
console.log("this.dv...!"+this.dv);
  }
  onRowClick2(event,id){
  //var ar=event.target.outerText;
  this.adr=event.target.outerText;
console.log("this.ar...!"+this.adr);
  }
  onRowClick3(event,id){
  //var ar=event.target.outerText;
  this.mv=event.target.outerText;
console.log("this.mv...!"+this.mv);
  }
  onRowClick(event,id){
//  var ar=event.target.outerText;
  this.smv=event.target.outerText;
console.log("this.smv...!"+this.smv);
  }

  public onUpdate(selectedItem: any) :void{
  //alert("onUpdate working..!",selectedItem.agentBenifPayTypeId);
  let originalData="markUpValue="+selectedItem.markUpValue+","+"Source markup value="+selectedItem.srcMarkUpValue+","+"destination value="+selectedItem.destCurrencyValue;
    if(this.mv==null){
      this.mv=selectedItem.markUpValue;
    }
    if(this.dv==null){
      this.dv=selectedItem.destCurrencyValue;
}
if(this.smv==null){
  this.smv=selectedItem.srcMarkUpValue;
}
console.log('source value===',this.smv);


let ar=this.dv*(this.mv/100);
let adm=this.dv-ar;
console.log('adminrate=..,',adm);
console.log('destination value===',this.dv);
  if(this.smv!=0){
     let sr=adm*(this.smv/100);
     this.srate=adm-sr;
    console.log('selectedItem.srcMarkUpValue..,',this.smv);
  }else{
  this.srate=adm;
  }


    const newObj = {
      'sourceAgentId':selectedItem.sourceAgentId,
      'destinationAgentId':selectedItem.destinationAgentId,
      'srcCurrencyValue':selectedItem.srcCurrencyValue,
      'destCurrencyValue':this.dv,
      'adminRate' :adm,
      'markUpValue':this.mv,
      'sellRate':this.srate,
      'srcMarkUpValue':this.smv,
      'agentBenifPayTypeId':selectedItem.agentBenifPayTypeId
    }

    if(this.exchRateForm.invalid){
        return;
    }
    else{
      this.exchRateForm.patchValue(newObj);
      console.log('exchRateForm==',this.exchRateForm.value);
      console.log('exchRateId..',selectedItem.exchRateId);
      this._exchangeratesservice.updateExchangeRateById(this.exchRateForm.value, this.processIdGeneraterService.getPid(),selectedItem.exchRateId).subscribe(res => {
        console.log("update exchRateForm....!.!" + res);

        if (res) {
          let changedData="markUpValue="+this.mv+","+"Source markup value="+this.smv+","+"destination value="+this.dv;

const trObj={
ipAddress:this.ipAddress,
category:'Update Exchange Rate By Admin',
userType:localStorage.getItem("userTypeName"),
userName:localStorage.getItem("uname"),
originalData:originalData,
changedData:changedData,
userId:localStorage.getItem("adminId")
}
this.auditTrialForm.patchValue(trObj);

this._auditTrialService.saveAuditTrailDetails(
  this.auditTrialForm.value,
   this.processIdGeneraterService.getPid()).subscribe(res => {
  if (res) {
    //this.List=res.response;
  }
});
          //let JSONDatas = res;
        this._router.navigate(['/exchage']);
          this.exchRateForm.reset();
        window.location.reload();
        }
      },
        error => {

          this.exchRateForm.reset();
          throw error;
        }
      );


}
}

  open_form(){

    this.isShow=true;
  }


}
