import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SourceofFoundService {
    apiUrl=environment.baseUrl
  public getsourceofFoundApi:string = `${this.apiUrl}/utility/getSourceOfFundList`;
  public postsorceofFoundApi:string =`${this.apiUrl}/utility/setSourceOfFundDetails`
  public updatesorceofFoundApi:string =`${this.apiUrl}/utility/updateSourceOfFundDetails`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http:HttpClient) { }

public extractData(res:Response):Observable<any> | {} {
  const body =res;
  console.log('res',res)
  return body || {};
}

public getSourceofFound(pId:string):Observable<any>{
  return this._http.get(`${this.getsourceofFoundApi}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public postSourceofFound(record:any,pId:string):Observable<any>{
  return this._http.post(`${this.postsorceofFoundApi}`+'?pId='+pId,record,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public updateSourceofFound(pId:string,sourceOfFundId:any,record:any):Observable<any>{
  return this._http.put(`${this.updatesorceofFoundApi}`+'?pId='+pId+'&sourceOfFundId='+sourceOfFundId,record,this.httpOptions).pipe(
    map(this.extractData)
  )
}



}
