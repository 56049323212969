import { Component, OnInit, Input,ElementRef, ViewChild } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  TransactionService,
  ProcessIdGeneraterService,
  LoginserviceService,
  ExchangeRatesService,
  AgentwithbranchService,
} from '../../service';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { forkJoin } from 'rxjs';
import { UtilityService } from '../../service';
import { MyAccountServiceService } from '../../frontservice';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { formatDate } from '@angular/common';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
import { NgxUiLoaderService } from 'ngx-ui-loader';
import jsPDF from 'jspdf';
import { HttpResponse } from '@angular/common/http';

interface Post {
  fromDate: Date;
  toDate: Date;
}

@Component({
  selector: 'app-index',
  templateUrl: './indexnew.component.html',
  styleUrls: ['./index.component.css'],
})
export class IndexComponent implements OnInit {
  @ViewChild('intakePlanTable', {static: false}) intakePlanTable: ElementRef;

  isloader: any;
  isloader1:any;
  isloaderr:any;
  sourceCountryName: any;
  currency1: any;
  sCurrency: any;
  sendingAmountFlag: boolean;
  minAmountFlag: boolean;
  dCurrency: any;
  currency2: any;
  benifApiName: any;
  currency3: any;
  referralFlag: boolean;
  referralFlag1: boolean;
  referralButtonFlag = true;

   // Added by Martins
   showCalculations: boolean = true;

  sagent: any;
  ragent: any;
  totalFee: any;
  mobileShow: boolean;
  public sendAgentPercentage: any;
  markUpTypeId: any;
  @Input() size: string;
  searchText: string;
  public adminPercentage: any;
  public recAgentPercentage: any;
  public bankFilterCtrl: FormControl = new FormControl();
  paymentMethodType: any;
  sendingAgentsList: Array<any>;
  selection: any;
  recAgentsList: {};
  paymentTypeList: {};
  collectionTypeList: {};
  destCountryName: any;
  serviceFee: any;
  promoFlag: boolean;
  promoFlag1: boolean;
  promoButtonFlag = true;
  promotionList: any;
  exchRate: any;
  idVerifiedStatusFlag: any;
  rate: any;
  receivingFliqpayRate = 0.0;
  selectedCountryCode = 'us';
  countryCodes = ['us', 'lu', 'de', 'bs', 'br', 'pt'];
  sAmount: string;
  txId: any;
  cashColtn: any;
  bankDeposit: any;
  txn: any;
  Array: any;
  timeStamp: any;
  custId: any;
  beneficiaryPaymentType: any;
  isShow: boolean;
  isShow1: boolean;
  cust: any;
pdf:any;
  receipt: boolean;
  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }
  sendingAgentsList1 = [
    {
      name: 'hdgj',
      age: 32,
      destination: 'india',
    },
    {
      name: 'hdgjewr',
      age: 32,
      destination: 'indiaewr',
    },
  ];
  srcCurrencyName: any;
  flowflag: any;
  senderDailingCode: any;
  destDailingCode: any;
  destCurrencyName: any;
  recAmount: any;
  transactionForm: FormGroup;
  serviceFeeTypeName: any;
  minval: any;
  maxval: any;
  serviceFeeTypeVal: any;
  sfee: any;
  receivingCurrency: any;
  sendingCurrency: any;
  collectionTypee: any;
  serviceFeeTypeId: any;
  reselection: any;
  selectpaytype: any;
  geoRate: number;
  paymentDestination: any;
  destCountryIso: any;
  srcCountryIso: any;
  benifPayTypeName: any;
  totalPay: any;
  sendingCountry: any;
  benifCountry: any;
  transactionList: any;
  userId: any;
  srcCountryId: any;
  selectedCity: any;
  apiName: any;
  exchangerateId: any;
  apiResponse: any;
  fliqpayRates: any;
  selectedCityIds: string[];
  selectedCityName = 'Vilnius';
  selectedCityId: number;
  selectedUserIds: number[];
  cselection: any;
  cselectionName: any;
  selectpaytypeName: any;
  userList: any;
  sendingCurrencyCode: any;
  destinationCountryId: any;
  markUpTypeName: any;
  collectionTypeName: any;
  cminval: any;
  sendingAgentUserId: any;
  cmaxval: any;
  ColtnFee: any;
  dateOfbirth: any;
  city: any;
  postalCode: any;
  street: any;
  documentsValidDate: boolean;
  tbname: any;
  txnId: any;
  treceivingAmount: any;
  tsendingAmount: any;
  tsrcCurrency: any;
  tdestCurrency: any;
  tsenderName: any;
  tsenderEmail: any;
  tsenderPhone: any;
  tsenderCountry: any;
  referralMinSendingAmount: number;
  tsenderAddress: any;
  tbPhone: any;
  tbBankName: any;
  tbsendingReason: any;
  status: any;
  tAmount: any;
  tFee: any;
  tExchangeRate: any;
  tDate: any;
  gswiftMarkup: number;
  gswiftMarkupType: any;
  accountNumberMaxSize: any;
  fliqpayMarkup: number;
  fliqpayMarkupType: any;
  numberOfTransactions = 0;
  totalTransactionAmount = 0;
  completedTransactions = 0;
  pendingTransactions = 0;

  isTransactionListTableEmpty:boolean;
  selectedTransaction: any;

  cities3 = [
    {
      id: 1,
      name: 'Vilnius',
      avatar:
        '//www.gravatar.com/avatar/b0d8c6e5ea589e6fc3d3e08afb1873bb?d=retro&r=g&s=30 2x',
    },
    {
      id: 2,
      name: 'Kaunas',
      avatar:
        '//www.gravatar.com/avatar/ddac2aa63ce82315b513be9dc93336e5?d=retro&r=g&s=15',
    },
    {
      id: 3,
      name: 'Pavilnys',
      avatar:
        '//www.gravatar.com/avatar/6acb7abf486516ab7fb0a6efa372042b?d=retro&r=g&s=15',
    },
  ];
  post: Post = {
    fromDate: new Date(Date.now()),
    toDate: new Date(Date.now()),
  };

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _transactionService: TransactionService,
    private utilityService: UtilityService,
    private _loginService: LoginserviceService,
    private _agentwithbranchService: AgentwithbranchService,
    private _exchangeratesservice: ExchangeRatesService,
    private bnIdle: BnNgIdleService,
    private ngxService: NgxUiLoaderService,
    private _myAccountServiceService: MyAccountServiceService,
    private processIdGeneraterService: ProcessIdGeneraterService
  ) {}

  ngOnInit(): void {
    this._router.events.subscribe((evt)=>{
      if(!(evt instanceof NavigationEnd)){
        return;
      }
      window.scrollTo(0,0)
    })
    if(localStorage.getItem("userId")==null){
        this._router.navigate(['/login']);
    } 

  
this.pdf='no';
    this.paymentMethodType='Pay ID';
    this.flowflag='empty';
    this.userId=  localStorage.getItem('userId');

this.isloader=false;

// GEO SWIFT EXCHANGE RATE BASED ON RECEIVING COUNTRY
this._transactionService.getGeoSwiftExcRate(this.processIdGeneraterService.getPid()
).subscribe(res=>
{
if(res){

this.geoRate=res.response;
//  this.exchRate = this.geoRate;
// this.recAmount=this.transactionForm.value.sendingAmount*this.exchRate;

}
});

    this._myAccountServiceService.getTransactionListDetailsBySendingCustomerUserId(this.processIdGeneraterService.getPid()).subscribe(res=>{
        if(res){
        this.transactionList=res.response;

for(const key of res.response){
  this.numberOfTransactions=this.numberOfTransactions+1;
  this.totalTransactionAmount=this.totalTransactionAmount+key.sendingAmount;

if(key.paymentStatus==='success'||key.paymentStatus==='PROCESSED'||key.paymentStatus==='funds received'){


  this.completedTransactions=this.completedTransactions+1;
}
else{
  this.pendingTransactions=this.pendingTransactions+1;
}


}


        console.log("transaction list by sending customer user id "+this.transactionList)
    this. tbname=this.transactionList.beneficiaryName;
        this.txnId=this.transactionList.transactionId;
        this.treceivingAmount=this.transactionList.receivingAmount;
        this.tsendingAmount=this.transactionList.sendingAmount;
      this.tsrcCurrency=this.transactionList.sendingCurrency;
        this.tdestCurrency=this.transactionList.receivingCurrency;
       this.tsenderName=this.transactionList.cusName;
      this.tsenderEmail=this.transactionList.senderEmail;
      this.tsenderPhone=this.transactionList.senderPhone;
      this.tsenderCountry=this.transactionList.srcCountryName;
    //    tsenderAddress:any;
      this.tbPhone=this.transactionList.beneficiaryPhone;
       this.tDate=this.transactionList.createdOn;
       this.status=this.transactionList.status;
       this.tExchangeRate=this.transactionList.sellRate;

        }
      }, err => {
        console.log(err);
      }
      );




    if(localStorage.getItem('registerAs') == 'Business'){
        this.sAmount  =  '5000'
     }else {
       this.sAmount  =  '10'
     }




//alert(this.sendingAgentUserId)
    this.transactionForm=new FormGroup({
      sendingAgentUserId:new FormControl(this.sendingAgentUserId),
      sendingBranchId:new FormControl(''),
      sendingCustomerUserId:new FormControl(''),
      sendingCurrency:new FormControl(''),
      sendingAmount:new FormControl(this.sAmount),
      receivingAgentId:new FormControl(''),
      receivingCurrency:new FormControl(''),
      receivingAmount:new FormControl(''),
      beneficiaryPaymentType:new FormControl(''),
      transactionFee:new FormControl(''),
      transactionFeeCurrency:new FormControl(''),
      collectionType:new FormControl(''),
      sellRate:new FormControl(''),
      totalAmount:new FormControl(''),
      sendingReason:new FormControl(''),
      sourceOfFund:new FormControl(''),
      sourceCountryId:new FormControl(''),
      destinationCountryId:new FormControl(''),
      serviceFeeId:new FormControl(''),
      promoCode:new FormControl(''),
      referralCode:new FormControl(''),
      referralCodeRateId:new FormControl(''),
      feeCurrency:new FormControl(''),
      serviceFeeMinVal:new FormControl(''),
      serviceFeeMaxVal:new FormControl(''),
      serviceFeeTypeVal:new FormControl(''),
      agentCollectionId:new FormControl(''),
      agentCollectionMinVal:new FormControl(''),
      agentCollectionMaxVal:new FormControl(''),
      markupTypeId:new FormControl(''),
      markupVal:new FormControl(''),
        paymentMethodType:new FormControl(''),
      agentBenifPayTypeId:new FormControl('')
          });
          this.idVerifiedStatusFlag='true';
          localStorage.setItem("idVerifiedStatusFlag",this.idVerifiedStatusFlag)


          ///////GETTING GSWIFT MARKUP/////////
          this._exchangeratesservice.getApiMarkupByApiName('GSWIFT',
          this.processIdGeneraterService.getPid()).subscribe(responsee=>{
            if(responsee){
              this.gswiftMarkup=responsee.response.markup;
              this.gswiftMarkupType=responsee.response.markupType;
            }
          })
          ///////GETTING FLIQPAY MARKUP/////////
          this._exchangeratesservice.getApiMarkupByApiName('FLIQPAY',
          this.processIdGeneraterService.getPid()).subscribe(responsee=>{
            if(responsee){
                this.fliqpayMarkupType=responsee.response.markupType;
              this.fliqpayMarkup=responsee.response.markup;
            }
          })



                               this._transactionService.getCustomerById(this.userId,
                                 this.processIdGeneraterService.getPid()).subscribe(res=>{
                               console.log("userfhahfho",res.response)

                               if(res){
                               this.utilityService.getReferralAmountByCountryId(this.processIdGeneraterService.getPid(),
                                   res.response.countryId).subscribe(resp=>{
                                   if(resp){
                                       this.referralMinSendingAmount=Number(resp.response.amount)
                                   }
                                 })

                                 localStorage.setItem("senderFlag","full")
                                 this.userList=res.response;
                                 this.sendingCurrencyCode=this.userList.currencyName;
                                 this.sagent=this.userList.sendingAgentId
                                 this.selection=this.userList.sendingAgentId+"-"+this.userList.currencyName;
                                 this.sendingAgentUserId=this.userList.sendingAgentId;
                                this.sendingCountry=this.userList.countryName;
                                this.sCurrency=this.userList.currencyName;
                                this.city=this.userList.userCity;
                                this.postalCode=this.userList.userPostalCode;
                                //alert(this.selection)


                                var d=new Date();
                                var e=new Date(this.userList.validTo);
                                var diff = (e.getTime() - d.getTime());
                                   var diffDays = Math.floor(diff / (1000 * 3600));

                                if(diffDays<7){

this.documentsValidDate=true;
                                  }

                                else{
                          this.documentsValidDate=false;
                                }



if(this.userList.identityVerifiedStatus==='Not-Verified' ){
    localStorage.setItem("idVerifiedStatusFlag",'false')
this.idVerifiedStatusFlag='false';

}
else{
this.idVerifiedStatusFlag='true';
    localStorage.setItem("idVerifiedStatusFlag",'true')
}

this.transactionForm.value.sendingAgentUserId=this.selection;

this._agentwithbranchService.getAgentWithExistingBranchListById(
this.selection.split("-")[0],this.processIdGeneraterService.getPid()).subscribe(res => {
         if (res){
               this.sendingAgentsList =res.response;

               console.log('list....',this.sendingAgentsList)
               this.currency1=res.response.currency1
               this.currency2=res.response.currency2
               this.currency3=res.response.currency3

    }
  });

                                 //GET AGENT COLLECTION TYPES BSED ON SENDING AGENT ID
                                 this._transactionService.getColtnTypesByAgentIdAndSendAmount(
                                   this.selection.split("-")[0],
                                   this.sendingCurrencyCode,
                                   'FRONTEND',
                this.transactionForm.value.sendingAmount.replace(/[^0-9.-]+/g,""),
                                                   this.processIdGeneraterService.getPid()).subscribe(
                                               res => {
          if (res){
                                                                 this.collectionTypeList =res.response;
                                                                    console.log('collectionTypeList',this.collectionTypeList)
                                                                    this.cselection=this.collectionTypeList[0].agentCollectionId
                                                                    this.cselectionName=this.collectionTypeList[0].collectionTypeName

                               //COLLECTION Fee
                               this._transactionService.getCollectionFee(
                               this.processIdGeneraterService.getPid(),
                               this.cselection,
                               this.transactionForm.value.sendingAmount,localStorage.getItem("registerAs")).subscribe(res => {
                                          this.ColtnFee =res.response.markupVal;
                                          this.collectionTypeName=res.response.collectionTypeName;
                                          this.markUpTypeName=res.response.markUpTypeName;
                                         this.cminval=res.response.minVal;
                                         this.cmaxval=res.response.maxVal;
                                         this.markUpTypeId=res.response.markUpTypeId;
                                         if(this.markUpTypeName=='FLATFEE'){
                                           this.ColtnFee=res.response.markupVal;
                                         }else{
                                           this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                                         }
                                         this.ColtnFee=(this.ColtnFee).toFixed(2)

                                          console.log('ColtnFee======',this.ColtnFee)


                                       //GET DEFAULT RECEIVING AGENTS LIST
                                              this._transactionService.getRecAgents(
                                                this.processIdGeneraterService.getPid(),
                                                this.selection.split("-")[0]).subscribe(
                                                res => {
                                                  this.recAgentsList = res.response;
                                                  console.log('recAgentsList', this.recAgentsList)
                                                  this.reselection = this.recAgentsList[0].destAgentId+"-"+this.recAgentsList[0].destCurrencyName
                                                  this.dCurrency=this.recAgentsList[0].destCurrencyName
                                                  this.benifCountry=this.recAgentsList[0].destCountryName
                                                  this.destDailingCode=this.recAgentsList[0].dailingCode
                                                  this.ragent=this.recAgentsList[0].destAgentId
                                      //GET DEFAULT  AGENT PAYTYPES BY RECEIVI
                                      this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
                                       this.reselection.split("-")[0],
                                       this.recAgentsList[0].destCurrencyName,
                                       this.processIdGeneraterService.getPid()).subscribe(
                                       res => {
                                                     if (res){
                                                           this.paymentTypeList =res.response;
                                                           console.log('paymentTypeList',this.paymentTypeList)
                                                           this.selectpaytype = this.paymentTypeList[0].agentBenifPayTypeId
                                                           this.paymentDestination=this.paymentTypeList[0].benifPayTypeName

                                                           this.accountNumberMaxSize=this.paymentTypeList[0].numberMaxSize

                                                           ///////////////MIN SENDING  AMOUNT
                                                           this._transactionService.getServiceFeeBySrcAndDestAgentAndPtype(
                                                             this.processIdGeneraterService.getPid(),
                                                             this.sagent,
                                                             this.ragent,
                                                             this.selectpaytype

                                                           ).subscribe(res => {
                                                                                          if (res){
                                                                                            // const o={
                                                                                            //   'sendingAmount':res.response[0].minVal
                                                                                            // }
                                                                                            // this.transactionForm.patchValue(o)

                               this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
                               this.sendingCountry,
                               this.benifCountry,
                               this.paymentDestination,this.dCurrency).subscribe(
                               res => {
                               this.apiResponse=res.response
                               this.benifApiName=this.apiResponse.apiName






                                                             //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                                                             this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                                                               this.processIdGeneraterService.getPid(),
                                                               this.selection.split("-")[0],
                                                               this.reselection.split("-")[0],
                                                               this.selectpaytype,
                                                               this.sendingCurrencyCode,
                                                             this.recAgentsList[0].destCurrencyName,
                                                             'sell',localStorage.getItem("registerAs")).subscribe(res => {
                                                                                                                                                               if (res){
                                                                                                 this.exchangerateId=res.response.exchRateId;
                                                                                               this.apiName=res.response.apiName;
                                                                                               if(res.response.apiName==='GSWIFT'){
                                                                                                   this.exchRate = this.geoRate;
                                                                                                 if(this.gswiftMarkupType==='FLATFEE'){
                                                                                                     this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                                                                       this.exchRate=(this.exchRate).toFixed(2)
                                                                                                 }
                                                                                                 else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                                                              let rate=this.exchRate*(this.gswiftMarkup/100);
                                                                                              this.exchRate=this.exchRate-Number(rate);
                                                                                                this.exchRate=(this.exchRate).toFixed(2)
                                                                                            }

                                                                                            }

                                                                                            else if(res.response.apiName==='FLIQPAY'){
                                                                                              const obj={
                                                                                              'sourceCurrency':res.response.sourceCurrencyCode,
                                                                                              'destinationCurrency':res.response.destCurrencyCode,
                                                                                              'amount':this.transactionForm.value.sendingAmount,
                                                                                              'paymentDestination':this.paymentDestination,
                                                                                              'action':'send',

                                                                                              }
                                                                                              this._transactionService.getDefaultFliqPayRates(
                                                                                                this.processIdGeneraterService.getPid(),
                                                                                              obj).subscribe(ress => {
                                                                                                  if(ress){
                                                                                                    this.fliqpayRates=ress.response
                                                                                                    this.exchRate = this.fliqpayRates.rate;
                                                                                                  if(this.fliqpayMarkupType==='PERCENTAGE'){
                                                                        let rate=this.exchRate*(this.fliqpayMarkup/100);
                                                                        this.exchRate=this.exchRate-Number(rate);

                                                                      }
                                                                      else {
                                                                          this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                                                                      }
                                                                      this.destinationCountryId = res.response.destCountryId;
                                                                        this.srcCurrencyName=res.response.srcCurrencyName;
                                                                        this.destCurrencyName=res.response.destCurrencyName;
                                                                        this.srcCountryIso=res.response.srcCountryIso;
                                                                        this.destCountryIso=res.response.destCountryIso;
                                                                        this.benifPayTypeName=res.response.benifPayTypeName;
                                                                         this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                                        this.srcCountryId=res.response.srcCountryId;
                                                                               this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                                         this.destCountryName=res.response.destCountryName;
                                                                         this.destCountryName=res.response.destCountryName;
                                                                        console.log('exchRate', this.recAmount)

                                                                        //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                                                                        this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(this.processIdGeneraterService.getPid(),
                                                                        this.selection.split("-")[0],
                                                                        this.reselection.split("-")[0],
                                                                        this.selectpaytype,
                                                                        this.transactionForm.value.sendingAmount,
                                                                        this.sendingCurrencyCode,
                                                                        this.recAgentsList[0].destCurrencyName,
                                                                        localStorage.getItem("registerAs")
                                                                        ).subscribe(res => {
                                                                             if (res){


                                                                               this.serviceFee = res.response;
                                                                               this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                                                                this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                                                               this.minval=res.response.minVal;
                                                                               this.maxval=res.response.maxVal;
                                                                               this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                                                               if(this.apiName=='FLIQPAY'){
                                                                           this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                                                               }
                                                                               else{
                                                                                      if(this.serviceFeeTypeName=='FLATFEE'){
                                                                                        this.sfee=this.serviceFeeTypeVal;
                                                                                      }else{
                                                                                        this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                                                                      }}

                                                                               console.log('serviceFee', this.sfee)
                                                                               this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                                                               this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                                                                  this.totalPay=(this.totalPay).toFixed(2)
                                                                                   this.isloader=true;
                                                                               this.utilityService.getRevenuePercentageRates(
                                                                                 this.processIdGeneraterService.getPid(),
                                                                                 this.selection.split("-")[0],
                                                                                 this.reselection.split("-")[0]
                                                                               ).subscribe(
                                                                                 res1 => {
                                                                                   this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                                                                   this.adminPercentage=res1.response.adminPercentage;
                                                                                   this.recAgentPercentage=res1.response.destAgentPercentage;

                                                                                 }
                                                                               );

                                                                             }
                                                                           },err =>{
                                                                             console.log(err);
                                                                           }
                                                                         );//CLOSE SERVICE FEE


                                                                                                  }  },
                                                                                                      error => {
                                                            console.log(error);

                                                                                            });
                                                                                               }

                                                                                               else{
                                                                                                 this.exchRate=res.response.sellRate.toFixed(2);

                                                                                               }
                                                                                               if(this.apiName!='FLIQPAY'){
                                                                                               this.destinationCountryId = res.response.destCountryId;
                                                                                                 this.srcCurrencyName=res.response.srcCurrencyName;
                                                                                                 this.destCurrencyName=res.response.destCurrencyName;
                                                                                                 this.srcCountryIso=res.response.srcCountryIso;
                                                                                                 this.destCountryIso=res.response.destCountryIso;
                                                                                                 this.benifPayTypeName=res.response.benifPayTypeName;
                                                                                                  this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                                                                 this.srcCountryId=res.response.srcCountryId;
                                                                                                        this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                                                                  this.destCountryName=res.response.destCountryName;
                                                                                                  this.destCountryName=res.response.destCountryName;
                                                                                                 console.log('exchRate', this.recAmount)
                                                                                           }

                                                                                            }
                                                                                          },err =>{
                                                                                            console.log(err);
                                                                                          }
                                                                                        );//CLOSE EXCHANGE RATE



                        if(this.apiName!='FLIQPAY'){
                                                       //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                                                       this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(this.processIdGeneraterService.getPid(),
                                                       this.selection.split("-")[0],
                                                       this.reselection.split("-")[0],
                                                       this.selectpaytype,
                                                       this.transactionForm.value.sendingAmount,
                                                       this.currency1,
                                                       this.recAgentsList[0].destCurrencyName,
                                                       localStorage.getItem("registerAs")
                                                       ).subscribe(res => {
                                                            if (res){

                                                              this.serviceFee = res.response;
                                                              this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                                               this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                                              this.minval=res.response.minVal;
                                                              this.maxval=res.response.maxVal;
                                                              this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                                              if(this.apiName=='FLIQPAY'){
                                                          this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                                              }
                                                              else{
                                                                     if(this.serviceFeeTypeName=='FLATFEE'){
                                                                       this.sfee=this.serviceFeeTypeVal;
                                                                     }else{
                                                                       this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                                                     }}

                                                              console.log('serviceFee', this.sfee)
                                                              this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                                              this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                                                 this.totalPay=(this.totalPay).toFixed(2)
                                                                  this.isloader=true;
                                                              this.utilityService.getRevenuePercentageRates(
                                                                this.processIdGeneraterService.getPid(),
                                                                this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                                                this.transactionForm.value.receivingAgentId.split("-")[0]
                                                              ).subscribe(
                                                                res1 => {
                                                                  this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                                                  this.adminPercentage=res1.response.adminPercentage;
                                                                  this.recAgentPercentage=res1.response.destAgentPercentage;

                                                                }
                                                              );

                                                            }
                                                          },err =>{
                                                            console.log(err);
                                                          }
                                                        );//CLOSE SERVICE FEE
                                                    }
                                                     //}/////CLOSING FLIQPAY IF
                             });

                             /////////CLOSED API NAME/


                                                     }
                                                   },err =>{
                                                     console.log(err);
                                                   }
                                                 );

                                               }
                                               });////////////////////// MIN AMOUNT

                                               });//get rec agents list
                                            });//get coltn fee closed
                                                                        }
                                                                      },err =>{
                                                                        console.log(err);
                                                                      });//get coltn types closed



}
}  ,err=>{

  this._loginService.getUserDetailsById(
    this.userId,this.processIdGeneraterService.getPid()).subscribe(res=>{

if(res){
localStorage.setItem("senderFlag","partial")
  this.userList=res.response;
  this.sendingCurrencyCode=this.userList.currencyName;
  this.selection=this.userList.sendingAgentId;
  this.sendingAgentUserId=this.userList.sendingAgentId;
 this.sendingCountry=this.userList.countryName;
 const signupform=  {
        "countryId":this.userList.countryId,
        "emailId": this.userList.emailId,
        "firstName":this.userList.firstName,
        "lastName":this.userList.lastName,
        "phoneNumber":this.userList.phoneNumber
    }
    localStorage.setItem("signupform",JSON.stringify(signupform));


this.transactionForm.value.sendingAgentUserId=this.selection;



  //GET AGENT COLLECTION TYPES BSED ON SENDING AGENT ID
  this._transactionService.getColtnTypesByAgentIdAndSendAmount(
    this.selection.split("-")[0],
    this.currency1,
    'FRONTEND',
                this.transactionForm.value.sendingAmount.replace(/[^0-9.-]+/g,""),
                   this.processIdGeneraterService.getPid()).subscribe(
    ress => {
           if (ress){
                                     this.collectionTypeList =ress.response;
                                     console.log('collectionTypeList',this.collectionTypeList)
                                     this.cselection=this.collectionTypeList[0].agentCollectionId
                                     this.cselectionName=this.collectionTypeList[0].collectionTypeName


//COLLECTION Fee
this._transactionService.getCollectionFee(
this.processIdGeneraterService.getPid(),
this.cselection,
this.transactionForm.value.sendingAmount,localStorage.getItem("registerAs")).subscribe(col => {
           this.ColtnFee =col.response.markupVal;
           this.collectionTypeName=col.response.collectionTypeName;
           this.markUpTypeName=col.response.markUpTypeName;
          this.cminval=col.response.minVal;
          this.cmaxval=col.response.maxVal;
          this.markUpTypeId=col.response.markUpTypeId;
          if(this.markUpTypeName=='FLATFEE'){
            this.ColtnFee=res.response.markupVal;
          }else{
            this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
          }
          this.ColtnFee=(this.ColtnFee).toFixed(2)

console.log('error ColtnFee======',this.ColtnFee)

        //GET DEFAULT RECEIVING AGENTS LIST
               this._transactionService.getRecAgents(
                 this.processIdGeneraterService.getPid(),this.selection).subscribe(
                 rec => {
                   this.recAgentsList = rec.response;
                   console.log('recAgentsList', this.recAgentsList)
                   this.reselection = this.recAgentsList[0].destAgentId+"-"+this.recAgentsList[0].destCurrencyName
                   this.benifCountry=this.recAgentsList[0].destCountryName
                   this.destDailingCode=this.recAgentsList[0].dailingCode
                   this.sourceCountryName=this.recAgentsList[0].srcCountryName
                   this.dCurrency=this.recAgentsList[0].destCurrencyName
                   this.ragent=this.recAgentsList[0].destAgentId

       //GET DEFAULT  AGENT PAYTYPES BY RECEIVING AGENT ID
       this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
         this.ragent,
         this.dCurrency,
          this.processIdGeneraterService.getPid()).subscribe(
         agent => {
                      if (agent){
                            this.paymentTypeList =agent.response;
                            console.log('paymentTypeList',this.paymentTypeList)
                            this.selectpaytype = this.paymentTypeList[0].agentBenifPayTypeId
                            this.paymentDestination=this.paymentTypeList[0].benifPayTypeName
                         this.accountNumberMaxSize=this.paymentTypeList[0].numberMaxSize
                   ////GET API NAME BY SOURCE AND DEST COUNTRY AND PAYMENT TYPE///////////////////////
this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
this.sendingCountry,
this.benifCountry,
this.paymentDestination,this.dCurrency).subscribe(
apires => {
this.apiResponse=apires.response
this.benifApiName=this.apiResponse.apiName






                                     //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                                     this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                                       this.processIdGeneraterService.getPid(),
                                       this.selection,
                                       this.ragent,
                                       this.selectpaytype,
                                     this.sendingCurrencyCode,
                                   this.dCurrency,'sell',localStorage.getItem("registerAs")).subscribe(
                                       res => {
                                                                    if (res){
                                                                         this.exchangerateId=res.response.exchRateId;
                                                                       this.apiName=res.response.apiName;
                                                                       if(res.response.apiName==='GSWIFT'){
                                                                           this.exchRate = this.geoRate;
                                                                         if(this.gswiftMarkupType==='FLATFEE'){
                                                                             this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                                               this.exchRate=(this.exchRate).toFixed(2)
                                                                         }
                                                                         else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                                      let rate=this.exchRate*(this.gswiftMarkup/100);
                                                                      this.exchRate=this.exchRate-Number(rate);
                                                                        this.exchRate=(this.exchRate).toFixed(2)
                                                                    }

                                                                    }

                                                                    else if(res.response.apiName==='FLIQPAY'){
                                                                      const obj={
                                                                      'sourceCurrency':res.response.sourceCurrencyCode,
                                                                      'destinationCurrency':res.response.destCurrencyCode,
                                                                      'amount':this.transactionForm.value.sendingAmount,
                                                                      'paymentDestination':this.paymentDestination,
                                                                      'action':'send',

                                                                      }
                                                                      this._transactionService.getDefaultFliqPayRates(
                                                                        this.processIdGeneraterService.getPid(),
                                                                      obj).subscribe(ress => {
                                                                          if(ress){
                                                                            this.fliqpayRates=ress.response
                                                                            this.exchRate = this.fliqpayRates.rate;
                                                                          if(this.fliqpayMarkupType==='PERCENTAGE'){
                                                let rate=this.exchRate*(this.fliqpayMarkup/100);
                                                this.exchRate=this.exchRate-Number(rate);

                                              }
                                              else {
                                                  this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                                              }
                                              this.destinationCountryId = res.response.destCountryId;
                                                this.srcCurrencyName=res.response.srcCurrencyName;
                                                this.destCurrencyName=res.response.destCurrencyName;
                                                this.srcCountryIso=res.response.srcCountryIso;
                                                this.destCountryIso=res.response.destCountryIso;
                                                this.benifPayTypeName=res.response.benifPayTypeName;
                                                 this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                        this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                this.srcCountryId=res.response.srcCountryId;
                                                 this.destCountryName=res.response.destCountryName;
                                                 this.destCountryName=res.response.destCountryName;
                                                console.log('exchRate', this.recAmount)

                                                //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                                                this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                                  this.processIdGeneraterService.getPid(),
                                                  this.selection,
                                                  this.ragent,
                                                this.selectpaytype,
                                                  this.transactionForm.value.sendingAmount,
                                                  this.sendingCurrencyCode,
                                                  this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                                                  res => {
                                                     if (res){


                                                       this.serviceFee = res.response;
                                                       this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                                        this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                                       this.minval=res.response.minVal;
                                                       this.maxval=res.response.maxVal;
                                                       this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                                       if(this.apiName=='FLIQPAY'){
                                                   this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                                       }
                                                       else{
                                                              if(this.serviceFeeTypeName=='FLATFEE'){
                                                                this.sfee=this.serviceFeeTypeVal;
                                                              }else{
                                                                this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                                              }}

                                                       console.log('serviceFee', this.sfee)
                                                       this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                                       this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                                          this.totalPay=(this.totalPay).toFixed(2)
                                                           this.isloader=true;
                                                       this.utilityService.getRevenuePercentageRates(
                                                         this.processIdGeneraterService.getPid(),
                                                         this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                                         this.transactionForm.value.receivingAgentId.split("-")[0]
                                                       ).subscribe(
                                                         res1 => {
                                                           this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                                           this.adminPercentage=res1.response.adminPercentage;
                                                           this.recAgentPercentage=res1.response.destAgentPercentage;

                                                         }
                                                       );

                                                     }
                                                   },err =>{
                                                     console.log(err);
                                                   }
                                                 );//CLOSE SERVICE FEE


                                                                          }  },
                                                                              error => {
                                    console.log(error);

                                                                    });
                                                                       }

                                                                       else{
                                                                         this.exchRate=res.response.sellRate.toFixed(2);

                                                                       }
                                                                       if(this.apiName!='FLIQPAY'){
                                                                       this.destinationCountryId = res.response.destCountryId;
                                                                         this.srcCurrencyName=res.response.srcCurrencyName;
                                                                         this.destCurrencyName=res.response.destCurrencyName;
                                                                         this.srcCountryIso=res.response.srcCountryIso;
                                                                         this.destCountryIso=res.response.destCountryIso;
                                                                         this.benifPayTypeName=res.response.benifPayTypeName;
                                                                          this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                                         this.srcCountryId=res.response.srcCountryId;
                                                                                this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                                          this.destCountryName=res.response.destCountryName;
                                                                          this.destCountryName=res.response.destCountryName;
                                                                         console.log('exchRate', this.recAmount)
                                                                   }

                                                                    }
                                                                  },err =>{
                                                                    console.log(err);
                                                                  }
                                                                );//CLOSE EXCHANGE RATE



if(this.apiName!='FLIQPAY'){
                               //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                               this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                 this.processIdGeneraterService.getPid(),
                                 this.selection,
                                 this.ragent,
                               this.selectpaytype,
                                 this.transactionForm.value.sendingAmount,
                                 this.sendingCurrencyCode,
                                 this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                                 res => {
                                    if (res){

                                      this.serviceFee = res.response;
                                      this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                       this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                      this.minval=res.response.minVal;
                                      this.maxval=res.response.maxVal;
                                      this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                      if(this.apiName=='FLIQPAY'){
                                  this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                      }
                                      else{
                                             if(this.serviceFeeTypeName=='FLATFEE'){
                                               this.sfee=this.serviceFeeTypeVal;
                                             }else{
                                               this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                             }}

                                      console.log('serviceFee', this.sfee)
                                      this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                      this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                         this.totalPay=(this.totalPay).toFixed(2)
                                          this.isloader=true;
                                      this.utilityService.getRevenuePercentageRates(
                                        this.processIdGeneraterService.getPid(),
                                        this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                        this.transactionForm.value.receivingAgentId.split("-")[0]
                                      ).subscribe(
                                        res1 => {
                                          this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                          this.adminPercentage=res1.response.adminPercentage;
                                          this.recAgentPercentage=res1.response.destAgentPercentage;

                                        }
                                      );

                                    }
                                  },err =>{
                                    console.log(err);
                                  }
                                );//CLOSE SERVICE FEE
                            }
                  //CLOSE SERVICE FEE
//}/////CLOSING FLIQPAY IF
});

/////////CLOSED API NAME/


                      }
                    },err =>{
                      console.log(err);
                    }
                  );


                 }
               );

             });//get coltn fee closed

             }//if closed
                                            },err =>{
                                              console.log(err);
                                            }
                                          );//get coltn types closed




//this.transactionForm.value.sendingAgentId=agentId;
}///////IF USER DETAILS BY USER ID

})//////////user details by user id closed
});//error closed




}//NG ON INIT CLOSED



//////////////////////////////////////////////////////////////////////////////////////////////
          //ON CHANGE RECEIVING AGENT ID
              onChangeRecAgentId(agentId:any) {
                 this.promoButtonFlag=true;
                  this.referralButtonFlag=true
                this.isloader=false;

                let val=agentId.split("-");
              //  let agentId=val[0];
                this.ragent=val[0];
                this.dCurrency=val[1];
                      if (this.ragent) {
                      //GET AGENT BENIF PAYMENT TYPES
                      this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
                        this.ragent,
                        this.dCurrency,
                         this.processIdGeneraterService.getPid()).subscribe(
                        res => {
                            this.paymentTypeList = res.response;
                            console.log('paymentTypeList onchangeRec', this.paymentTypeList)
                             this.selectpaytype=this.paymentTypeList[0].agentBenifPayTypeId
                             this.paymentDestination=this.paymentTypeList[0].benifPayTypeName
this.accountNumberMaxSize=this.paymentTypeList[0].numberMaxSize

          this._transactionService.getCountryNameByRecAgentId(
            this.ragent, this.processIdGeneraterService.getPid()).subscribe(
            res => {
              if(res){
                this.benifCountry=res.response.destCountryName;
                this.destDailingCode=res.response.dailingCode

          ////////////////api name
          this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
          this.sendingCountry,
          this.benifCountry,
          this.paymentDestination,this.dCurrency).subscribe(
           res => {
          this.apiResponse=res.response
          this.benifApiName=this.apiResponse.apiName
          //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
          this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
            this.processIdGeneraterService.getPid(),
            this.sagent,
            this.ragent,
            this.selectpaytype,
            this.sCurrency,
        this.dCurrency,'sell',localStorage.getItem("registerAs")).subscribe(
            res => {
                                         if (res){
                                              this.exchangerateId=res.response.exchRateId;
                                            this.apiName=res.response.apiName;
                                            if(res.response.apiName==='GSWIFT'){
                                                this.exchRate = this.geoRate;
                                              if(this.gswiftMarkupType==='FLATFEE'){
                                                  this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                    this.exchRate=(this.exchRate).toFixed(2)
                                              }
                                              else if(this.gswiftMarkupType==='PERCENTAGE'){

                                           let rate=this.exchRate*(this.gswiftMarkup/100);
                                           this.exchRate=this.exchRate-Number(rate);
                                             this.exchRate=(this.exchRate).toFixed(2)
                                         }

                                         }

                                         else if(res.response.apiName==='FLIQPAY'){
                                           const obj={
                                           'sourceCurrency':res.response.sourceCurrencyCode,
                                           'destinationCurrency':res.response.destCurrencyCode,
                                           'amount':this.transactionForm.value.sendingAmount,
                                           'paymentDestination':this.paymentDestination,
                                           'action':'send',

                                           }
                                           this._transactionService.getDefaultFliqPayRates(
                                             this.processIdGeneraterService.getPid(),
                                           obj).subscribe(ress => {
                                               if(ress){
                                                 this.fliqpayRates=ress.response
                                                 this.exchRate = this.fliqpayRates.rate;
                                               if(this.fliqpayMarkupType==='PERCENTAGE'){
                     let rate=this.exchRate*(this.fliqpayMarkup/100);
                     this.exchRate=this.exchRate-Number(rate);

                   }
                   else {
                       this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                   }
                   this.destinationCountryId = res.response.destCountryId;
                     this.srcCurrencyName=res.response.srcCurrencyName;
                     this.destCurrencyName=res.response.destCurrencyName;
                     this.srcCountryIso=res.response.srcCountryIso;
                     this.destCountryIso=res.response.destCountryIso;
                     this.benifPayTypeName=res.response.benifPayTypeName;
                      this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                     this.srcCountryId=res.response.srcCountryId;
                            this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                      this.destCountryName=res.response.destCountryName;
                      this.destCountryName=res.response.destCountryName;
                     console.log('exchRate', this.recAmount)

                     //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                     this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                       this.processIdGeneraterService.getPid(),
                       this.transactionForm.value.sendingAgentUserId.split("-")[0],
                       this.ragent,
                       this.selectpaytype,
                       this.transactionForm.value.sendingAmount,
                     this.transactionForm.value.sendingAgentUserId.split("-")[1],
                   this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                       res => {
                          if (res){


                            this.serviceFee = res.response;
                            this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                             this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                            this.minval=res.response.minVal;
                            this.maxval=res.response.maxVal;
                            this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                            if(this.apiName=='FLIQPAY'){
                        this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                            }
                            else{
                                   if(this.serviceFeeTypeName=='FLATFEE'){
                                     this.sfee=this.serviceFeeTypeVal;
                                   }else{
                                     this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                   }}

                            console.log('serviceFee', this.sfee)
                            this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                            this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                               this.totalPay=(this.totalPay).toFixed(2)
                                this.isloader=true;
                            this.utilityService.getRevenuePercentageRates(
                              this.processIdGeneraterService.getPid(),
                              this.transactionForm.value.sendingAgentUserId.split("-")[0],
                              this.transactionForm.value.receivingAgentId.split("-")[0]
                            ).subscribe(
                              res1 => {
                                this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                this.adminPercentage=res1.response.adminPercentage;
                                this.recAgentPercentage=res1.response.destAgentPercentage;

                              }
                            );

                          }
                        },err =>{
                          console.log(err);
                        }
                      );//CLOSE SERVICE FEE


                                               }  },
                                                   error => {
         console.log(error);

                                         });
                                            }

                                            else{
                                              this.exchRate=res.response.sellRate.toFixed(2);

                                            }
                                            if(this.apiName!='FLIQPAY'){
                                            this.destinationCountryId = res.response.destCountryId;
                                              this.srcCurrencyName=res.response.srcCurrencyName;
                                              this.destCurrencyName=res.response.destCurrencyName;
                                              this.srcCountryIso=res.response.srcCountryIso;
                                              this.destCountryIso=res.response.destCountryIso;
                                              this.benifPayTypeName=res.response.benifPayTypeName;
                                               this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                              this.srcCountryId=res.response.srcCountryId;
                                                     this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                               this.destCountryName=res.response.destCountryName;
                                               this.destCountryName=res.response.destCountryName;
                                              console.log('exchRate', this.recAmount)
                                        }

                                         }
                                       },err =>{
                                         console.log(err);
                                       }
                                     );//CLOSE EXCHANGE RATE



if(this.apiName!='FLIQPAY'){
    //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
    this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
      this.processIdGeneraterService.getPid(),
      this.sagent,
      this.ragent,
      this.selectpaytype,
      this.transactionForm.value.sendingAmount,
    this.sCurrency,
  this.dCurrency,localStorage.getItem("registerAs")).subscribe(
      res => {
         if (res){

           this.serviceFee = res.response;
           this.serviceFeeTypeId=res.response.serviceFeeTypeId;
            this.serviceFeeTypeName=res.response.serviceFeeTypeName;
           this.minval=res.response.minVal;
           this.maxval=res.response.maxVal;
           this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

           if(this.apiName=='FLIQPAY'){
       this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
           }
           else{
                  if(this.serviceFeeTypeName=='FLATFEE'){
                    this.sfee=this.serviceFeeTypeVal;
                  }else{
                    this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                  }}

           console.log('serviceFee', this.sfee)
           this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
           this.totalFee=(this.totalFee).toFixed(2)
           this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
           this.totalPay=(this.totalPay).toFixed(2)
               this.isloader=true;
           this.utilityService.getRevenuePercentageRates(
             this.processIdGeneraterService.getPid(),
             this.sagent,
             this.ragent
           ).subscribe(
             res1 => {
               this.sendAgentPercentage=res1.response.srcAgentPercentage;
               this.adminPercentage=res1.response.adminPercentage;
               this.recAgentPercentage=res1.response.destAgentPercentage;

             }
           );

         }
       },err =>{
         console.log(err);
       }
     );//CLOSE SERVICE FEE
 }

                        });
                          }
                        });

            });////////////////payment types closed
          }
          }///////////////////////////////////////////

          onChangeReferral(){
            this.utilityService.checkTheStatusOfAvailableReferralCode(this.processIdGeneraterService.getPid(),
          this.transactionForm.value.referralCode,this.userId).subscribe(res=>{
            if(res.response){
              if(this.transactionForm.value.sendingAmount>=this.referralMinSendingAmount){
this.utilityService.getAllReferralCodeRatesBySrcAgentIdAndDestAgentId(this.sagent,this.ragent,
this.processIdGeneraterService.getPid()).subscribe(ress=>{
  if(ress){
    console.log("referralcode raetewe...",ress.response);

              this.referralButtonFlag=false;
                  console.log("promotion",res.response);
                  // this.exchRate=(Number(this.exchRate)+Number(ress.response.benifAmount)).toFixed(2)
                  //   this.recAmount=Number(this.transactionForm.value.sendingAmount)*Number(this.exchRate);
                  //          this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                    const o={
                      'referralCodeRateId':ress.response.referralCodeRateId
                    }
                    this.transactionForm.patchValue(o);

              this.referralFlag=true;
              this.referralFlag1=false;
              setTimeout(function () {
                this.referralFlag=false;
              }.bind(this),2000);

            }/////ress
          })

              }
              else{
                this.referralFlag=false;
                this.referralFlag1=true;
alert('Minimum sending amount should be '+this.referralMinSendingAmount)
            this.transactionForm.get('referralCode').reset();
            this.transactionForm.get('referralCodeRateId').reset();
                setTimeout(function () {
                  this.referralFlag1=false;
                }.bind(this),2000);
              }
            }
            else{
              this.referralFlag=false;
              this.referralFlag1=true;

          //  this.transactionForm.value.promoCode.reset();
          this.transactionForm.get('referralCode').reset();
          this.transactionForm.get('referralCodeRateId').reset();

              setTimeout(function () {
                this.referralFlag1=false;
              }.bind(this),2000);
            }
          })

          }



          onChangePayment(val:any){

            this._transactionService.getCollectionFee(
            this.processIdGeneraterService.getPid(),
            val,
            this.transactionForm.value.sendingAmount,localStorage.getItem("registerAs")).subscribe(res => {
                       this.ColtnFee =(res.response.markupVal).toFixed(2);
                       this.collectionTypeName=res.response.collectionTypeName;
                    this.   paymentMethodType=res.response.collectionTypeName;
                       this.markUpTypeName=res.response.markUpTypeName;
                      this.cminval=res.response.minVal;
                      this.cmaxval=res.response.maxVal;

                                              this.markUpTypeId=res.response.markUpTypeId;
                      this.paymentMethodType=this.collectionTypeName;
                      this.markUpTypeId=res.response.markUpTypeId;
                                 if(this.markUpTypeName=='FLATFEE'){
                                   this.ColtnFee=(res.response.markupVal).toFixed(2);
                                 }else{
                                   this.ColtnFee=((this.transactionForm.value.sendingAmount*res.response.markupVal)/100).toFixed(2);
                                 }

                       console.log('onChangePayment ColtnFee======',this.ColtnFee)
                       this.totalFee=(Number(this.sfee)+Number(this.ColtnFee)).toFixed(2);
                       console.log('onChangePayment totalFee', this.totalFee)
                       this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                       console.log('onChangePayment totalPay', this.totalPay)

            },
          err=>{
            this.isloader=false;
          }


          );//getCollectionFee closed
          }


          //ON CHANGE PAYMENTTYPE
          onChangeAgentBenifPayTypeId(agentBenifPayTypeId:any) {
             this.promoButtonFlag=true;
              this.referralButtonFlag=true
            this.isloader=false;
     if (agentBenifPayTypeId) {
              this._transactionService.getBenifPayTypeName(this.processIdGeneraterService.getPid(),
              agentBenifPayTypeId).subscribe(res=>{
                if(res){
              this.paymentDestination=res.response.benifPayTypeName

          //GET EXCHANGE RATES
                  this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
                  this.sendingCountry,
                  this.benifCountry,
                  this.paymentDestination,this.dCurrency).subscribe(
                   res => {
                  this.apiResponse=res.response
                  this.benifApiName=this.apiResponse.apiName

                  //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                  this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                    this.processIdGeneraterService.getPid(),
                    this.sagent,
                    this.ragent,
                    agentBenifPayTypeId,
                    this.sCurrency,
                    this.dCurrency,
                  'sell',localStorage.getItem("registerAs")).subscribe(
                    res => {
                                                 if (res){
                                                      this.exchangerateId=res.response.exchRateId;
                                                    this.apiName=res.response.apiName;
                                                    if(res.response.apiName==='GSWIFT'){
                                                        this.exchRate = this.geoRate;
                                                      if(this.gswiftMarkupType==='FLATFEE'){
                                                          this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                            this.exchRate=(this.exchRate).toFixed(2)
                                                      }
                                                      else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                   let rate=this.exchRate*(this.gswiftMarkup/100);
                                                   this.exchRate=this.exchRate-Number(rate);
                                                     this.exchRate=(this.exchRate).toFixed(2)
                                                 }

                                                 }

                                                 else if(res.response.apiName==='FLIQPAY'){
                                                   const obj={
                                                   'sourceCurrency':res.response.sourceCurrencyCode,
                                                   'destinationCurrency':res.response.destCurrencyCode,
                                                   'amount':this.transactionForm.value.sendingAmount,
                                                   'paymentDestination':this.paymentDestination,
                                                   'action':'send',

                                                   }
                                                   this._transactionService.getDefaultFliqPayRates(
                                                     this.processIdGeneraterService.getPid(),
                                                   obj).subscribe(ress => {
                                                       if(ress){
                                                         this.fliqpayRates=ress.response
                                                         this.exchRate = this.fliqpayRates.rate;
                                                       if(this.fliqpayMarkupType==='PERCENTAGE'){
                             let rate=this.exchRate*(this.fliqpayMarkup/100);
                             this.exchRate=this.exchRate-Number(rate);

                           }
                           else {
                               this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                           }
                           this.destinationCountryId = res.response.destCountryId;
                             this.srcCurrencyName=res.response.srcCurrencyName;
                             this.destCurrencyName=res.response.destCurrencyName;
                             this.srcCountryIso=res.response.srcCountryIso;
                             this.destCountryIso=res.response.destCountryIso;
                             this.benifPayTypeName=res.response.benifPayTypeName;
                              this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                             this.srcCountryId=res.response.srcCountryId;
                                    this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                              this.destCountryName=res.response.destCountryName;
                              this.destCountryName=res.response.destCountryName;
                             console.log('exchRate', this.recAmount)
                             this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                               this.processIdGeneraterService.getPid(),
                               this.sagent,
                               this.ragent,
                               agentBenifPayTypeId,
                               this.transactionForm.value.sendingAmount,
                               this.sCurrency,
                             this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                               res => {
                                  if (res){


                                    this.serviceFee = res.response;
                                    this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                     this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                    this.minval=res.response.minVal;
                                    this.maxval=res.response.maxVal;
                                    this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                    if(this.apiName=='FLIQPAY'){
                                this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                    }
                                    else{
                                           if(this.serviceFeeTypeName=='FLATFEE'){
                                             this.sfee=this.serviceFeeTypeVal;
                                           }else{
                                             this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                           }}

                                    console.log('serviceFee', this.sfee)
                                    this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                    this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                    this.totalPay=(this.totalPay).toFixed(2)
                                        this.isloader=true;
                                    this.utilityService.getRevenuePercentageRates(
                                      this.processIdGeneraterService.getPid(),
                                      this.sagent,
                                      this.ragent
                                    ).subscribe(
                                      res1 => {
                                        this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                        this.adminPercentage=res1.response.adminPercentage;
                                        this.recAgentPercentage=res1.response.destAgentPercentage;

                                      }
                                    );

                                  }
                                },err =>{
                                  console.log(err);
                                }
                              );//CLOSE SERVICE FEE


                                                       }  },
                                                           error => {
                 console.log(error);

                                                 });
                                                    }

                                                    else{
                                                      this.exchRate=res.response.sellRate.toFixed(2);

                                                    }
                                                    if(this.apiName!='FLIQPAY'){
                                                    this.destinationCountryId = res.response.destCountryId;
                                                      this.srcCurrencyName=res.response.srcCurrencyName;
                                                      this.destCurrencyName=res.response.destCurrencyName;
                                                      this.srcCountryIso=res.response.srcCountryIso;
                                                      this.destCountryIso=res.response.destCountryIso;
                                                      this.benifPayTypeName=res.response.benifPayTypeName;
                                                       this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                      this.srcCountryId=res.response.srcCountryId;
                                                             this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                       this.destCountryName=res.response.destCountryName;
                                                       this.destCountryName=res.response.destCountryName;

                                                      console.log('exchRate', this.recAmount)
                                                }

                                                 }
                                               },err =>{
                                                 console.log(err);
                                               }
                                             );//CLOSE EXCHANGE RATE



    if(this.apiName!='FLIQPAY'){
            //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
            this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
              this.processIdGeneraterService.getPid(),
              this.sagent,
              this.ragent,
              agentBenifPayTypeId,
              this.transactionForm.value.sendingAmount,
              this.sCurrency,
            this.dCurrency,localStorage.getItem("registerAs")).subscribe(
              res => {
                 if (res){

                   this.serviceFee = res.response;
                   this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                    this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                   this.minval=res.response.minVal;
                   this.maxval=res.response.maxVal;
                   this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                   if(this.apiName=='FLIQPAY'){
               this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                   }
                   else{
                          if(this.serviceFeeTypeName=='FLATFEE'){
                            this.sfee=this.serviceFeeTypeVal;
                          }else{
                            this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                          }}

                   console.log('serviceFee', this.sfee)
                   this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                   this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                      this.totalPay=(this.totalPay).toFixed(2)
                       this.isloader=true;
                   this.utilityService.getRevenuePercentageRates(
                     this.processIdGeneraterService.getPid(),
                     this.sagent,
                     this.ragent
                   ).subscribe(
                     res1 => {
                       this.sendAgentPercentage=res1.response.srcAgentPercentage;
                       this.adminPercentage=res1.response.adminPercentage;
                       this.recAgentPercentage=res1.response.destAgentPercentage;

                     }
                   );

                 }
               },err =>{
                 console.log(err);
               }
             );//CLOSE SERVICE FEE
         }
        });
      }
    });
          }
          }////////////////////////ON CHANGE

          onKeyupR(AMount){
            AMount=  parseFloat(AMount).toFixed(2);
this.promoButtonFlag=true;
this.referralButtonFlag=true;
this.isloaderr = false;
            AMount=(AMount)/this.exchRate;
AMount=  parseFloat(AMount).toFixed(2);

          const o={
            'sendingAmount':AMount
          }
          this.transactionForm.patchValue(o);

         this.onKeyupRR(AMount);
          }

          onKeyup(AMount){
            
        AMount=    parseFloat(AMount).toFixed(2);

            this.isloader=false;
            this.isloader1=false;
            if(this.maxval<AMount){
        
              this.sendingAmountFlag=true;

            }
            else{
              this.sendingAmountFlag=false;
            }
            if(this.minval>AMount){
              this.minAmountFlag=true;

                  }
            else{
              this.minAmountFlag=false;
            }
            this._transactionService.getCollectionFee(
            this.processIdGeneraterService.getPid(),
            this.transactionForm.value.paymentMethodType,
          AMount,localStorage.getItem("registerAs")).subscribe(res => {
                       this.ColtnFee =res.response.markupVal;
                       this.collectionTypeName=res.response.collectionTypeName;
                       this.markUpTypeName=res.response.markUpTypeName;
                      this.cminval=res.response.minVal;
                      this.cmaxval=res.response.maxVal;
                      this.markUpTypeId=res.response.markUpTypeId;
                                 if(this.markUpTypeName=='FLATFEE'){
                                   this.ColtnFee=res.response.markupVal;
                                 }else{
                                   this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                                 }
                                 this.ColtnFee=(this.ColtnFee).toFixed(2)

                       console.log('onkeyup ColtnFee======',this.ColtnFee)

                       //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                       this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                         this.processIdGeneraterService.getPid(),
                         this.transactionForm.value.sendingAgentUserId.split("-")[0],
                         this.transactionForm.value.receivingAgentId.split("-")[0],
                         this.transactionForm.value.agentBenifPayTypeId,
                       this.transactionForm.value.sendingAgentUserId.split("-")[1],
                       this.transactionForm.value.receivingAgentId.split("-")[1],
                       'sell',localStorage.getItem("registerAs")).subscribe(
                         res => {
                                                      if (res){
                                                           this.exchangerateId=res.response.exchRateId;
                                                         this.apiName=res.response.apiName;
                                                         if(res.response.apiName==='GSWIFT'){
                                                             this.exchRate = this.geoRate;
                                                           if(this.gswiftMarkupType==='FLATFEE'){
                                                               this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                                 this.exchRate=(this.exchRate).toFixed(2)
                                                           }
                                                           else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                        let rate=this.exchRate*(this.gswiftMarkup/100);
                                                        this.exchRate=this.exchRate-Number(rate);
                                                          this.exchRate=(this.exchRate).toFixed(2)
                                                      }

                                                      }

                                                      else if(res.response.apiName==='FLIQPAY'){
                                                        const obj={
                                                        'sourceCurrency':res.response.sourceCurrencyCode,
                                                        'destinationCurrency':res.response.destCurrencyCode,
                                                        'amount':this.transactionForm.value.sendingAmount,
                                                        'paymentDestination':this.paymentDestination,
                                                        'action':'send',

                                                        }
                                                        this._transactionService.getDefaultFliqPayRates(
                                                          this.processIdGeneraterService.getPid(),
                                                        obj).subscribe(ress => {
                                                            if(ress){
                                                              this.fliqpayRates=ress.response
                                                              this.exchRate = this.fliqpayRates.rate;
                                                            if(this.fliqpayMarkupType==='PERCENTAGE'){
                                  let rate=this.exchRate*(this.fliqpayMarkup/100);
                                  this.exchRate=this.exchRate-Number(rate);

                                }
                                else {
                                    this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                                }
                                this.destinationCountryId = res.response.destCountryId;
                                  this.srcCurrencyName=res.response.srcCurrencyName;
                                  this.destCurrencyName=res.response.destCurrencyName;
                                  this.srcCountryIso=res.response.srcCountryIso;
                                  this.destCountryIso=res.response.destCountryIso;
                                  this.benifPayTypeName=res.response.benifPayTypeName;
                                   this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                   this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                  this.srcCountryId=res.response.srcCountryId;
                                   this.destCountryName=res.response.destCountryName;
                                   this.destCountryName=res.response.destCountryName;
                                  console.log('exchRate', this.recAmount)
                                  this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                    this.processIdGeneraterService.getPid(),
                                    this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                    this.transactionForm.value.receivingAgentId.split("-")[0],
                                    this.transactionForm.value.agentBenifPayTypeId,
                                    this.transactionForm.value.sendingAmount,
                                  this.transactionForm.value.sendingAgentUserId.split("-")[1],
                                  this.transactionForm.value.receivingAgentId.split("-")[1],localStorage.getItem("registerAs")).subscribe(
                                    res => {
                                       if (res){

                                        this.isloader1=true;
                                         this.serviceFee = res.response;
                                         this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                          this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                         this.minval=res.response.minVal;
                                         this.maxval=res.response.maxVal;
                                         this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                         if(this.apiName=='FLIQPAY'){
                                     this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                         }
                                         else{
                                                if(this.serviceFeeTypeName=='FLATFEE'){
                                                  this.sfee=this.serviceFeeTypeVal;
                                                }else{
                                                  this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                                }}

                                         console.log('serviceFee', this.sfee)
                                         this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                         this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                            this.totalPay=(this.totalPay).toFixed(2)
                                             this.isloader=true;
                                         this.utilityService.getRevenuePercentageRates(
                                           this.processIdGeneraterService.getPid(),
                                           this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                           this.transactionForm.value.receivingAgentId.split("-")[0]
                                         ).subscribe(
                                           res1 => {
                                             this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                             this.adminPercentage=res1.response.adminPercentage;
                                             this.recAgentPercentage=res1.response.destAgentPercentage;

                                           }
                                         );

                                       }
                                     },err =>{
                                       console.log(err);
                                     }
                                   );//CLOSE SERVICE FEE


                                                            }  },
                                                                error => {
                       console.log(error);

                                                      });
                                                         }

                                                         else{
                                                           this.exchRate=res.response.sellRate.toFixed(2);

                                                         }
                                                         if(this.apiName!='FLIQPAY'){
                                                         this.destinationCountryId = res.response.destCountryId;
                                                           this.srcCurrencyName=res.response.srcCurrencyName;
                                                           this.destCurrencyName=res.response.destCurrencyName;
                                                           this.srcCountryIso=res.response.srcCountryIso;
                                                           this.destCountryIso=res.response.destCountryIso;
                                                           this.benifPayTypeName=res.response.benifPayTypeName;

                                                            this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
this.transactionForm.get('receivingAmount').setValue(this.recAmount);

                                                           this.srcCountryId=res.response.srcCountryId;
                                                            this.destCountryName=res.response.destCountryName;
                                                            this.destCountryName=res.response.destCountryName;

                                                           console.log('exchRate', this.recAmount)
                                                     }

                                                      }
                                                    },err =>{
                                                      console.log(err);
                                                    }
                                                  );//CLOSE EXCHANGE RATE



                       if(this.apiName!='FLIQPAY'){
                       //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                       this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                         this.processIdGeneraterService.getPid(),
                         this.transactionForm.value.sendingAgentUserId.split("-")[0],
                         this.transactionForm.value.receivingAgentId.split("-")[0],
                         this.transactionForm.value.agentBenifPayTypeId,
                         this.transactionForm.value.sendingAmount,
                       this.transactionForm.value.sendingAgentUserId.split("-")[1],
                       this.transactionForm.value.receivingAgentId.split("-")[1],localStorage.getItem("registerAs")).subscribe(
                         res => {
                       if (res){
                        this.isloaderr = true;
                        this.serviceFee = res.response;
                        this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                         this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                        this.minval=res.response.minVal;
                        this.maxval=res.response.maxVal;
                        this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                        if(this.apiName=='FLIQPAY'){
                       this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                        }
                        else{
                               if(this.serviceFeeTypeName=='FLATFEE'){
                                 this.sfee=this.serviceFeeTypeVal;
                               }else{
                                 this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                               }}

                        console.log('serviceFee', this.sfee)
                        this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                        this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                           this.totalPay=(this.totalPay).toFixed(2)
                            this.isloader=true;
                        this.utilityService.getRevenuePercentageRates(
                          this.processIdGeneraterService.getPid(),
                          this.transactionForm.value.sendingAgentUserId.split("-")[0],
                          this.transactionForm.value.receivingAgentId.split("-")[0]
                        ).subscribe(
                          res1 => {
                            this.sendAgentPercentage=res1.response.srcAgentPercentage;
                            this.adminPercentage=res1.response.adminPercentage;
                            this.recAgentPercentage=res1.response.destAgentPercentage;

                          }
                        );

                       }
                       },err =>{
                       console.log(err);
                       }
                       );//CLOSE SERVICE FEE
                       }
        });//get coltn fee  closed
      }//onkeyup CLOSED




      onKeyupRR(AMount){
    AMount=    parseFloat(AMount).toFixed(2);

        this.isloader=false;
        if(this.maxval<AMount){
    
          this.sendingAmountFlag=true;

        }
        else{
          this.sendingAmountFlag=false;
        }
        if(this.minval>AMount){
          this.minAmountFlag=true;

              }
        else{
          this.minAmountFlag=false;
        }
        this._transactionService.getCollectionFee(
        this.processIdGeneraterService.getPid(),
        this.transactionForm.value.paymentMethodType,
      AMount,localStorage.getItem("registerAs")).subscribe(res => {
                   this.ColtnFee =res.response.markupVal;
                   this.collectionTypeName=res.response.collectionTypeName;
                   this.markUpTypeName=res.response.markUpTypeName;
                  this.cminval=res.response.minVal;
                  this.cmaxval=res.response.maxVal;
                  this.markUpTypeId=res.response.markUpTypeId;
                             if(this.markUpTypeName=='FLATFEE'){
                               this.ColtnFee=res.response.markupVal;
                             }else{
                               this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                             }
                             this.ColtnFee=(this.ColtnFee).toFixed(2)

                   console.log('onkeyup ColtnFee======',this.ColtnFee)

                   //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                   this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                     this.processIdGeneraterService.getPid(),
                     this.transactionForm.value.sendingAgentUserId.split("-")[0],
                     this.transactionForm.value.receivingAgentId.split("-")[0],
                     this.transactionForm.value.agentBenifPayTypeId,
                   this.transactionForm.value.sendingAgentUserId.split("-")[1],
                   this.transactionForm.value.receivingAgentId.split("-")[1],
                   'sell',localStorage.getItem("registerAs")).subscribe(
                     res => {
                                                  if (res){
                                                       this.exchangerateId=res.response.exchRateId;
                                                     this.apiName=res.response.apiName;
                                                     if(res.response.apiName==='GSWIFT'){
                                                         this.exchRate = this.geoRate;
                                                       if(this.gswiftMarkupType==='FLATFEE'){
                                                           this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                             this.exchRate=(this.exchRate).toFixed(2)
                                                       }
                                                       else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                    let rate=this.exchRate*(this.gswiftMarkup/100);
                                                    this.exchRate=this.exchRate-Number(rate);
                                                      this.exchRate=(this.exchRate).toFixed(2)
                                                  }

                                                  }

                                                  else if(res.response.apiName==='FLIQPAY'){
                                                    const obj={
                                                    'sourceCurrency':res.response.sourceCurrencyCode,
                                                    'destinationCurrency':res.response.destCurrencyCode,
                                                    'amount':this.transactionForm.value.sendingAmount,
                                                    'paymentDestination':this.paymentDestination,
                                                    'action':'send',

                                                    }
                                                    this._transactionService.getDefaultFliqPayRates(
                                                      this.processIdGeneraterService.getPid(),
                                                    obj).subscribe(ress => {
                                                        if(ress){
                                                          this.fliqpayRates=ress.response
                                                          this.exchRate = this.fliqpayRates.rate;
                                                        if(this.fliqpayMarkupType==='PERCENTAGE'){
                              let rate=this.exchRate*(this.fliqpayMarkup/100);
                              this.exchRate=this.exchRate-Number(rate);

                            }
                            else {
                                this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                            }
                            this.destinationCountryId = res.response.destCountryId;
                              this.srcCurrencyName=res.response.srcCurrencyName;
                              this.destCurrencyName=res.response.destCurrencyName;
                              this.srcCountryIso=res.response.srcCountryIso;
                              this.destCountryIso=res.response.destCountryIso;
                              this.benifPayTypeName=res.response.benifPayTypeName;
                               this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                               this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                              this.srcCountryId=res.response.srcCountryId;
                               this.destCountryName=res.response.destCountryName;
                               this.destCountryName=res.response.destCountryName;
                              console.log('exchRate', this.recAmount)
                              this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                this.processIdGeneraterService.getPid(),
                                this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                this.transactionForm.value.receivingAgentId.split("-")[0],
                                this.transactionForm.value.agentBenifPayTypeId,
                                this.transactionForm.value.sendingAmount,
                              this.transactionForm.value.sendingAgentUserId.split("-")[1],
                              this.transactionForm.value.receivingAgentId.split("-")[1],localStorage.getItem("registerAs")).subscribe(
                                res => {
                                   if (res){


                                     this.serviceFee = res.response;
                                     this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                      this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                     this.minval=res.response.minVal;
                                     this.maxval=res.response.maxVal;
                                     this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                     if(this.apiName=='FLIQPAY'){
                                 this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                     }
                                     else{
                                            if(this.serviceFeeTypeName=='FLATFEE'){
                                              this.sfee=this.serviceFeeTypeVal;
                                            }else{
                                              this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                            }}

                                     console.log('serviceFee', this.sfee)
                                     this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                     this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                        this.totalPay=(this.totalPay).toFixed(2)
                                         this.isloader=true;
                                     this.utilityService.getRevenuePercentageRates(
                                       this.processIdGeneraterService.getPid(),
                                       this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                       this.transactionForm.value.receivingAgentId.split("-")[0]
                                     ).subscribe(
                                       res1 => {
                                         this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                         this.adminPercentage=res1.response.adminPercentage;
                                         this.recAgentPercentage=res1.response.destAgentPercentage;

                                       }
                                     );

                                   }
                                 },err =>{
                                   console.log(err);
                                 }
                               );//CLOSE SERVICE FEE


                                                        }  },
                                                            error => {
                   console.log(error);

                                                  });
                                                     }

                                                     else{
                                                       this.exchRate=res.response.sellRate.toFixed(2);

                                                     }
                                                     if(this.apiName!='FLIQPAY'){
                                                     this.destinationCountryId = res.response.destCountryId;
                                                       this.srcCurrencyName=res.response.srcCurrencyName;
                                                       this.destCurrencyName=res.response.destCurrencyName;
                                                       this.srcCountryIso=res.response.srcCountryIso;
                                                       this.destCountryIso=res.response.destCountryIso;
                                                       this.benifPayTypeName=res.response.benifPayTypeName;

                                                       this.srcCountryId=res.response.srcCountryId;
                                                        this.destCountryName=res.response.destCountryName;
                                                        this.destCountryName=res.response.destCountryName;

                                                       console.log('exchRate', this.recAmount)
                                                 }

                                                  }
                                                },err =>{
                                                  console.log(err);
                                                }
                                              );//CLOSE EXCHANGE RATE



                   if(this.apiName!='FLIQPAY'){
                   //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                   this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                     this.processIdGeneraterService.getPid(),
                     this.transactionForm.value.sendingAgentUserId.split("-")[0],
                     this.transactionForm.value.receivingAgentId.split("-")[0],
                     this.transactionForm.value.agentBenifPayTypeId,
                     this.transactionForm.value.sendingAmount,
                   this.transactionForm.value.sendingAgentUserId.split("-")[1],
                   this.transactionForm.value.receivingAgentId.split("-")[1],localStorage.getItem("registerAs")).subscribe(
                     res => {
                   if (res){

                    this.serviceFee = res.response;
                    this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                     this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                    this.minval=res.response.minVal;
                    this.maxval=res.response.maxVal;
                    this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                    if(this.apiName=='FLIQPAY'){
                   this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                    }
                    else{
                           if(this.serviceFeeTypeName=='FLATFEE'){
                             this.sfee=this.serviceFeeTypeVal;
                           }else{
                             this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                           }}

                    console.log('serviceFee', this.sfee)
                    this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                    this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                       this.totalPay=(this.totalPay).toFixed(2)
                        this.isloader=true;
                    this.utilityService.getRevenuePercentageRates(
                      this.processIdGeneraterService.getPid(),
                      this.transactionForm.value.sendingAgentUserId.split("-")[0],
                      this.transactionForm.value.receivingAgentId.split("-")[0]
                    ).subscribe(
                      res1 => {
                        this.sendAgentPercentage=res1.response.srcAgentPercentage;
                        this.adminPercentage=res1.response.adminPercentage;
                        this.recAgentPercentage=res1.response.destAgentPercentage;

                      }
                    );

                   }
                   },err =>{
                   console.log(err);
                   }
                   );//CLOSE SERVICE FEE
                   }
    });//get coltn fee  closed
    }//onkeyup CLOSED


      onChangePromo(){
        this.utilityService.getPromotionByCriteria(this.processIdGeneraterService.getPid(),
        this.sendingCountry,this.destCountryName,this.benifPayTypeName,
      this.transactionForm.value.sendingAmount,this.transactionForm.value.receivingAmount,this.transactionForm.value.promoCode,'enabled').subscribe(res=>{
        if(res.response){
          this.promotionList=res.response;
          this.promoButtonFlag=false;
          console.log("promotion",res.response);
          if(this.promotionList.promotionType==="FlatFee"){
            this.exchRate=Number(this.exchRate)+Number(this.promotionList.promtionValue)
            this.recAmount=Number(this.transactionForm.value.sendingAmount)*Number(this.exchRate);
                   this.transactionForm.get('receivingAmount').setValue(this.recAmount);
          }
          if(this.promotionList.promotionType==='percentage'){
            this.exchRate=Number(this.exchRate)+Number((this.exchRate/100)*this.promotionList.promtionValue)

            this.recAmount=Number(this.transactionForm.value.sendingAmount)*Number(this.exchRate);
                   this.transactionForm.get('receivingAmount').setValue(this.recAmount);
            }
      this.promoFlag=true;
      this.promoFlag1=false;
      setTimeout(function () {
        this.promoFlag=false;
      }.bind(this),2000);
        }
        else{
          this.promoFlag=false;
          this.promoFlag1=true;

  //  this.transactionForm.value.promoCode.reset();
      this.transactionForm.get('promoCode').reset();
          setTimeout(function () {
            this.promoFlag1=false;
          }.bind(this),2000);
        }
      })

      }

onSubmitt(){
 
  if(this.documentsValidDate){
    alert(' Your id proof is expired, Please update to make a transaction')
return;
}
  if(localStorage.getItem('idVerifiedStatusFlag')==='true'){
    if(localStorage.getItem('senderFlag')==='full'){
      this._router.navigate(['/senderdetails'])
    }
    else{
      alert('Sorry, Your id proof is under review and once approved you will be able to make a transaction')
    }

}
else{
  alert('Sorry, Your id proof is under review and once approved you will be able to make a transaction')


}
}
          onSubmit(){
            this.ngxService.start();

                if(this.apiName==='FLIQPAY'||this.apiName==='RECEIVING_FLIQPAY'){
                  localStorage.setItem("quoteRef",this.fliqpayRates.reference);
                  localStorage.setItem("fliqpayRates",JSON.stringify(this.fliqpayRates));
                  this.serviceFeeTypeVal=this.fliqpayRates.fee;
                }
                if(this.referralButtonFlag){
                  this.transactionForm.get('referralCode').reset();
                  this.transactionForm.get('referralCodeRateId').reset();

                }
                if(this.promoButtonFlag){
                  this.transactionForm.get('promoCode').reset();

                }
                console.log('rec  amount in onsubmit', this.recAmount)
                const newObj = {
                  'receivingAmount':this.transactionForm.value.receivingAmount,
                  'sendingAgentUserId':this.sagent,
                  'receivingAgentId':this.ragent,
                  'sendingAmount' :this.transactionForm.value.sendingAmount,
                  'sendingCurrency':this.sCurrency,
                  'destinationCountryId':this.destinationCountryId,
                  'receivingCurrency':this.destCurrencyName,
                  'agentBenifPayTypeId':this.transactionForm.value.agentBenifPayTypeId,
                  'serviceFeeTypeName':this.serviceFeeTypeName,
                  'serviceFeeId':this.serviceFeeTypeId,
                  'feeCurrency':this.srcCurrencyName,
                  'serviceFeeMinVal':this.minval,
                  'serviceFeeMaxVal':this.maxval,
                  'serviceFeeTypeVal':this.serviceFeeTypeVal,
                  'sellRate':this.exchRate,
                  'destCountryIso':this.destCountryIso,
                  'srcCountryIso':this.srcCountryIso,
                  'benifPayTypeName':this.benifPayTypeName,
                  'totalPay':this.totalPay,
                  'destCurrencyName':this.dCurrency,
                  'srcCountryId':this.srcCountryId,
                  'agentCollectionId':this.cselection,
                  'accountNumberMaxSize':this.accountNumberMaxSize,
                  'ColtnFee':this.ColtnFee,
                  'cminval':this.cminval,
                  'cmaxval':this.cmaxval,
                  'collectionTypeName':this.collectionTypeName,
                  'sendAgentPercentage':this.sendAgentPercentage,
                  'adminPercentage':this.adminPercentage,
                  'recAgentPercentage':this.recAgentPercentage,
                  'markUpTypeId':this.markUpTypeId,
                  'destCountryName':this.destCountryName,
                  'sourceCountryName':this.sendingCountry,
                  'apiName':this.benifApiName,
                  'transactionFee':this.sfee,
                  'sourceDailingCode':this.senderDailingCode,
                  'destDailingCode':this.destDailingCode,
                  'paymentMethodType':this.paymentMethodType,
                  'promoCode':this.transactionForm.value.promoCode,
                  'referralCode':this.transactionForm.value.referralCode,
                  'referralCodeRateId':this.transactionForm.value.referralCodeRateId,
                  'exchangerateId':this.exchangerateId
                }
                localStorage.setItem("apiName",this.benifApiName);
              if(this.destCountryName=='NIGERIA'){
                localStorage.setItem("benifCountry",'NGN');
              }else if(this.destCountryName=='GHANA'){
                localStorage.setItem("benifCountry",'GHS')
              }else if(this.destCountryName=='UNITED STATES'){
                localStorage.setItem("benifCountry",'USD')
              }else if(this.destCountryName=='UNITED KINGDOM'){
                localStorage.setItem("benifCountry",'GBP')
              }else if(this.destCountryName=='KENYA'){
                localStorage.setItem("benifCountry",'KES')
              }else if(this.destCountryName=='SOUTH AFRICA'){
                localStorage.setItem("benifCountry",'ZAR')
              }else{
                  localStorage.setItem("benifCountry",'NA')
                }
          this.flowflag='calculator';
          localStorage.setItem('flowflag',this.flowflag)
              console.log("newObj=",newObj);

              localStorage.setItem('newObj', JSON.stringify(newObj));
              let retrievedObject = localStorage.getItem('newObj');
              console.log('retrievedObject: ',JSON.parse(retrievedObject));
              //localStorage.setItem('benifCountry',"GHS")
              console.log('retrievedObject: ', JSON.parse(retrievedObject));
              let customerId=localStorage.getItem('customerId');
              if(customerId==null){
                this.ngxService.stop();
                this._router.navigate(['/signup'])
              }
              if(localStorage.getItem('senderFlag')==='full'){
                     
                this.ngxService.stop();
              this._router.navigate(['addrecipirent'])
              }
              else{
         
                this.ngxService.stop();
                  this._router.navigate(['/addpersonaldetailsl'])
              }







          }






















          onShow(){
            this.mobileShow=!this.mobileShow;
          }



          //ON CHANGE SENDING AGENT ID
                 onChangeSendingAgentId(value:any) {
                    this.promoButtonFlag=true;
                     this.referralButtonFlag=true
                   this.isloader=false;
                   let val=value.split("-");
        this.sagent=val[0];
        this.sCurrency=val[1];
          //this.transactionForm.value.sendingAgentId=agentId;
                  if (value) {
                    //GET AGENT COLLECTION TYPES BSED ON SENDING AGENT ID
                    this._transactionService.getColtnTypesByAgentIdAndSendAmount(
                      this.sagent,
                      this.sCurrency,
                      'FRONTEND',
                  this.transactionForm.value.sendingAmount.replace(/[^0-9.-]+/g,""),
                                         this.processIdGeneraterService.getPid()).subscribe(
                      res => {
                                                 if (res){
                                                       this.collectionTypeList =res.response;
                                                       console.log('collectionTypeList',this.collectionTypeList)
                                                       this.cselection=this.collectionTypeList[0].agentCollectionId

                  //COLLECTION Fee
                  this._transactionService.getCollectionFee(
                  this.processIdGeneraterService.getPid(),
                  this.cselection,
                  this.transactionForm.value.sendingAmount,localStorage.getItem("registerAs")).subscribe(res => {
                             this.ColtnFee =res.response.markupVal;
                             this.collectionTypeName=res.response.collectionTypeName;
                             this.markUpTypeName=res.response.markUpTypeName;
                            this.cminval=res.response.minVal;
                            this.cmaxval=res.response.maxVal;
                            this.markUpTypeId=res.response.markUpTypeId;
                                       if(this.markUpTypeName=='FLATFEE'){
                                         this.ColtnFee=res.response.markupVal;
                                       }else{
                                         this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                                       }
                                       this.ColtnFee=(this.ColtnFee).toFixed(2)

                             console.log('ColtnFee======',this.ColtnFee)

          //GET RECEIVE AGENTS
          this._transactionService.getRecAgents(
            this.processIdGeneraterService.getPid(),this.sagent).subscribe(
            res => {
              this.recAgentsList = res.response;
              console.log('recAgentsList', this.recAgentsList)
              this.ragent=this.recAgentsList[0].destAgentId
              this.reselection = this.recAgentsList[0].destAgentId+"-"+this.recAgentsList[0].destCurrencyName
              this.destinationCountryId = this.recAgentsList[0].destCountryId;
              this.sourceCountryName=this.recAgentsList[0].srcCountryName
              this.dCurrency=this.recAgentsList[0].destCurrencyName
                            this.benifCountry=this.recAgentsList[0].destCountryName
                            this.sendingCountry=this.recAgentsList[0].srcCountryName
                            this.destDailingCode=this.recAgentsList[0].dailingCode
                            this.senderDailingCode=this.recAgentsList[0].sourceDailingCode
                            //GET AGENT BENIF PAYMENT Types
                            this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
                              this.ragent,
                              this.dCurrency,
                               this.processIdGeneraterService.getPid()).subscribe(
                              res => {
                                this.paymentTypeList = res.response;
                                console.log('paymentTypeList', this.paymentTypeList)
                                this.selectpaytype=this.paymentTypeList[0].agentBenifPayTypeId
                                  this.paymentDestination=this.paymentTypeList[0].benifPayTypeName
                                   this.accountNumberMaxSize=this.paymentTypeList[0].numberMaxSize
                                ///////////////////////GET API NAME BY SOURCE AND DEST COUNTRY AND PAYMENT TYPE///////////////////////
                                this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
                                this.sendingCountry,
                                this.benifCountry,
                                this.paymentDestination,this.dCurrency).subscribe(
                                 result => {
                                   if(result){

                                this.benifApiName=result.response.apiName;
                                this.apiResponse=result.response

                                //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                                this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                                  this.processIdGeneraterService.getPid(),
                                  this.sagent,
                                  this.ragent,
                                  this.selectpaytype,
                                this.sCurrency,
                              this.dCurrency,'sell',localStorage.getItem("registerAs")
                              ).subscribe(
                                  res => {
                                                               if (res){
                                                                    this.exchangerateId=res.response.exchRateId;
                                                                  this.apiName=res.response.apiName;
                                                                  if(res.response.apiName==='GSWIFT'){
                                                                      this.exchRate = this.geoRate;
                                                                    if(this.gswiftMarkupType==='FLATFEE'){
                                                                        this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                                          this.exchRate=(this.exchRate).toFixed(2)
                                                                    }
                                                                    else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                                 let rate=this.exchRate*(this.gswiftMarkup/100);
                                                                 this.exchRate=this.exchRate-Number(rate);
                                                                   this.exchRate=(this.exchRate).toFixed(2)
                                                               }

                                                               }

                                                               else if(res.response.apiName==='FLIQPAY'){
                                                                 const obj={
                                                                 'sourceCurrency':res.response.sourceCurrencyCode,
                                                                 'destinationCurrency':res.response.destCurrencyCode,
                                                                 'amount':this.transactionForm.value.sendingAmount,
                                                                 'paymentDestination':this.paymentDestination,
                                                                 'action':'send',

                                                                 }
                                                                 this._transactionService.getDefaultFliqPayRates(
                                                                   this.processIdGeneraterService.getPid(),
                                                                 obj).subscribe(ress => {
                                                                     if(ress){
                                                                       this.fliqpayRates=ress.response
                                                                       this.exchRate = this.fliqpayRates.rate;
                                                                     if(this.fliqpayMarkupType==='PERCENTAGE'){
                                           let rate=this.exchRate*(this.fliqpayMarkup/100);
                                           this.exchRate=this.exchRate-Number(rate);

                                         }
                                         else {
                                             this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                                         }
                                         this.destinationCountryId = res.response.destCountryId;
                                           this.srcCurrencyName=res.response.srcCurrencyName;
                                           this.destCurrencyName=res.response.destCurrencyName;
                                           this.srcCountryIso=res.response.srcCountryIso;
                                           this.destCountryIso=res.response.destCountryIso;
                                           this.benifPayTypeName=res.response.benifPayTypeName;
                                            this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                           this.srcCountryId=res.response.srcCountryId;
                                                  this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                            this.destCountryName=res.response.destCountryName;
                                            this.destCountryName=res.response.destCountryName;
                                           console.log('exchRate', this.recAmount)
                                           this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                             this.processIdGeneraterService.getPid(),
                                             this.sagent,
                                             this.ragent,
                                           this.selectpaytype,
                                             this.transactionForm.value.sendingAmount,
                                             this.sCurrency,
                                             this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                                             res => {
                                                if (res){


                                                  this.serviceFee = res.response;
                                                  this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                                   this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                                  this.minval=res.response.minVal;
                                                  this.maxval=res.response.maxVal;
                                                  this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                                  if(this.apiName=='FLIQPAY'){
                                              this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                                  }
                                                  else{
                                                         if(this.serviceFeeTypeName=='FLATFEE'){
                                                           this.sfee=this.serviceFeeTypeVal;
                                                         }else{
                                                           this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                                         }}

                                                  console.log('serviceFee', this.sfee)
                                                  this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                                  this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                                     this.totalPay=(this.totalPay).toFixed(2)
                                                      this.isloader=true;
                                                  this.utilityService.getRevenuePercentageRates(
                                                    this.processIdGeneraterService.getPid(),
                                                    this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                                    this.transactionForm.value.receivingAgentId.split("-")[0]
                                                  ).subscribe(
                                                    res1 => {
                                                      this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                                      this.adminPercentage=res1.response.adminPercentage;
                                                      this.recAgentPercentage=res1.response.destAgentPercentage;

                                                    }
                                                  );

                                                }
                                              },err =>{
                                                console.log(err);
                                              }
                                            );//CLOSE SERVICE FEE


                                                                     }  },
                                                                         error => {
                                console.log(error);

                                                               });
                                                                  }

                                                                  else{
                                                                    this.exchRate=res.response.sellRate.toFixed(2);

                                                                  }
                                                                  if(this.apiName!='FLIQPAY'){
                                                                  this.destinationCountryId = res.response.destCountryId;
                                                                    this.srcCurrencyName=res.response.srcCurrencyName;
                                                                    this.destCurrencyName=res.response.destCurrencyName;
                                                                    this.srcCountryIso=res.response.srcCountryIso;
                                                                    this.destCountryIso=res.response.destCountryIso;
                                                                    this.benifPayTypeName=res.response.benifPayTypeName;
                                                                     this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                                    this.srcCountryId=res.response.srcCountryId;
                                                                           this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                                                     this.destCountryName=res.response.destCountryName;
                                                                     this.destCountryName=res.response.destCountryName;

                                                                    console.log('exchRate', this.recAmount)
                                                              }

                                                               }
                                                             },err =>{
                                                               console.log(err);
                                                             }
                                                           );//CLOSE EXCHANGE RATE

                                if(this.apiName!='FLIQPAY'){
                                //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                                this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                  this.processIdGeneraterService.getPid(),
                                  this.sagent,
                                  this.ragent,
                                this.selectpaytype,
                                  this.transactionForm.value.sendingAmount,
                                  this.sCurrency,
                                  this.dCurrency,localStorage.getItem("registerAs")).subscribe(
                                  res => {
                                if (res){

                                 this.serviceFee = res.response;
                                 this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                  this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                 this.minval=res.response.minVal;
                                 this.maxval=res.response.maxVal;
                                 this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                 if(this.apiName=='FLIQPAY'){
                                this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                 }
                                 else{
                                        if(this.serviceFeeTypeName=='FLATFEE'){
                                          this.sfee=this.serviceFeeTypeVal;
                                        }else{
                                          this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                        }}

                                 console.log('serviceFee', this.sfee)
                                 this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                 this.totalPay=Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee);
                                    this.totalPay=(this.totalPay).toFixed(2)
                                     this.isloader=true;
                                 this.utilityService.getRevenuePercentageRates(
                                   this.processIdGeneraterService.getPid(),
                                   this.transactionForm.value.sendingAgentUserId.split("-")[0],
                                   this.transactionForm.value.receivingAgentId.split("-")[0]
                                 ).subscribe(
                                   res1 => {
                                     this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                     this.adminPercentage=res1.response.adminPercentage;
                                     this.recAgentPercentage=res1.response.destAgentPercentage;

                                   }
                                 );

                                }
                                },err =>{
                                console.log(err);
                                }
                                );//CLOSE SERVICE FEE
                                }
                                          }});//////////////////API NAME END

          //}////////////////////////////IF API NAME EMPTY CLOSED//////////////////////////
                              }
                            );

                          });//get rec agents list closed
                        });//getCollectionFee closed

                      }//if closed
                      },err =>{
                      console.log(err);
              });//getAgentCollectionTypes closed
                      }//IF CLOSE OF ONCHANGE SENDING AGENT

                    }//CLOSE ONCHANGE SENDING AGENT


                    openTransactionModal(transaction: any) {
                      // alert(transaction.benificiaryName)
                      // if(this.receipt){
                      this.selectedTransaction = transaction;
                      // }
                    }
                    
                 
                    // Added by Martins
  getTransactionListStatusClass(status: any) {
    if (status === 'processing') return 'inprogress';
    if (status === 'pending' || status === 'refund') return 'pending';
    if (status === 'Aborted') return 'cancelled';
    return 'completed';
  }
    
  closeTransactionModal() {
    this.selectedTransaction = null
  }

  toggleShowCalculations() {
    this.showCalculations = !this.showCalculations;
  }
  onClick2(val: any) {
    localStorage.setItem('txnId', val);
    this._router.navigate(['/customerreciptt']);
  }

  downloadPdf(val) {
    this.ngxService.start();
    this.receipt = false;
    this.txId = val;
   
    // Make asynchronous calls to fetch data needed for PDF generation
     // GET Cash Collection Fee
     this._transactionService.cashColtnByTxnId(
      this.txId, this.processIdGeneraterService.getPid()
    ).subscribe(
      res => {
        this.cashColtn = res.response;
        console.log('cashColtn==', this.cashColtn);
      }
    );

    // GET Bank Deposit
    this._transactionService.bankDepositByTxnId(
      this.txId, this.processIdGeneraterService.getPid()
    ).subscribe(
      res => {
        this.bankDeposit = res.response;
        console.log('bankDeposit==', this.bankDeposit);
      }
    );

    // GET TXN BY ID
    this._transactionService.getTxnById(
      this.txId, this.processIdGeneraterService.getPid()
    ).subscribe(
      res => {
        this.txn = res.response;
        this.timeStamp = res.timeStamp;
        this.custId = res.response.sendingCustomerUserId;
        this.beneficiaryPaymentType = res.response.beneficiaryPaymentType;
        if (this.beneficiaryPaymentType == 'Cash Collection') {
          this.isShow = true;
        }
        if (this.beneficiaryPaymentType == 'Bank Deposit') {
          this.isShow1 = true;
        }
       
        console.log('txn==', this.txn);
        console.log('custId-=', this.custId);
        this._transactionService.getCustomerById(
          this.custId, this.processIdGeneraterService.getPid()
        ).subscribe(
          res => {
            this.cust = res.response;
            console.log('cust==', this.cust);
    
            // this.generatePdf();
this.pdf='yes'

            setTimeout(() => 
{
this.generatePdf();
},
100);
           
          
          }
        );

  
      // Once all data is fetched, generate PDF
     
    },
    error => {
      console.error('Error fetching data:', error);
    }
  );
}
fetchDataForPdfGeneration() {
 
  
     
  }
 
  generatePdf() {
    const doc = new jsPDF();
   
    
    // Set font size and color
    doc.setFontSize(14);
    doc.setTextColor(0);

    // Function to add HTML content to PDF
    const addHtmlToPdf = (element) => {
        html2canvas(element).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const imgWidth = 190;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            doc.addImage(imgData, 'PNG', 15, 15, imgWidth, imgHeight);

            // Draw lines or rectangles to separate sections if needed
            // doc.rect(10, 10, 190, 280); // Main box to contain all content

            // // Add lines to separate rows
            // doc.line(10, 60, 200, 60); // Line below additional boxes
            // doc.line(10, 170, 200, 170); // Line below remitter details
            // doc.line(10, 280, 200, 280); // Line below payment details

            // // Add horizontal lines
            // doc.line(10, 100, 200, 100); // Line after phone number
            // doc.line(10, 230, 200, 230); // Line after beneficiary details

            // Add vertical line
            // doc.line(100, 10, 100, 290); // Vertical line at the center
            this.ngxService.stop();
            doc.save('receipt.pdf');
            // 
        });
    };

    const container = document.querySelector('.container');
    if (container) {
        // Add remitter details
        addHtmlToPdf(container);
    }
    this.pdf = 'no';
}

}
