<style media="screen">
  input[type=text] {
      padding: 1px
  ;
</style>
<div class="row">
  <div class="col-md-12">
    <form [formGroup]="promotionForm">
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Name</label>
        </div>
        <div class="col-md-3">
          <input type="text" formControlName="promotionName" name="" value="" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Status1</label>
        </div>
        <div class="col-md-3">
          <select class="form-control" name="" formControlName="status">
            <option value="">--select--</option>
            <option value="enabled">enabled</option>
            <option value="disabled">disabled</option>
          </select>
        </div>
        <div class="col-md-3">
          <label for="">From date</label>
          <input
            type="date"
            formControlName="fromDate"
            class="form-control"
            name=""
            value=""
          />
        </div>
        <div class="col-md-3">
          <label for=""> to date</label>
          <input
            type="date"
            class="form-control"
            formControlName="toDate"
            name=""
            value=""
          />
        </div>
      </div>
      <div class="form-group">
        <h6>CRITERIA FOR RULE TO APPLY</h6>
        <p>
          Conditions under which the rule will apply. Leave entries blank to not
          filter by that item.
        </p>
      </div>

      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Source Country</label>
        </div>
        <div class="col-md-3">
          <select
            class="form-control"
            name=""
            (change)="onChangeUserId($event.target.value)"
            formControlName="sourceCountry"
          >
            <option value="">--select--</option>
            <option
              *ngFor="let slist of sList"
              value="{{ slist.countryName }}&{{ slist.userId }}"
            >
              {{ slist.countryName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Destination Country</label>
        </div>
        <div class="col-md-3">
          <select
            class="form-control"
            name=""
            value=""
            formControlName="destinationCountry"
            (change)="onChangeUserId1($event.target.value)"
          >
            <option value="">--select--</option>
            <option
              *ngFor="let list of dList"
              value="{{ list.destCountryName }}"
            >
              {{ list.destCountryName }}
            </option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Min Source Amount </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="sendMin" value="" />
        </div>
      </div>

      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Max Source Amount </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="sendMax" value="" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Min Destination Amount </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="recMin" value="" />
        </div>
      </div>

      <div class="row form-group"></div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Max Destination Amount </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="recMax" value="" />
        </div>
      </div>

      <div class="row form-group">
        <p>RULE PERIOD AND WEIGHT SETTINGS</p>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Transfer Type </label>
        </div>
        <div class="col-md-3">
          <select class="form-control" name="" formControlName="transferType">
            <option value="All">--All--</option>
            <option value="period">Period</option>
            <option value="calender day">calender day</option>
            <option value="calender week">calender week</option>
            <option value="calender month">calender month</option>
            <option value="calender year">calender year</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Promotion Type </label>
        </div>
        <div class="col-md-3">
          <select class="form-control" name="" formControlName="promotionType">
            <option value="All">--All--</option>
            <option value="percentage">percentage</option>
            <option value="FlatFee">Flat Fee</option>
          </select>
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Promotion Code </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="promotionCode" value="" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for="">Promotion Value </label>
        </div>
        <div class="col-md-3">
          <input type="text" name="" formControlName="promtionValue" value="" />
        </div>
      </div>
      <div class="row form-group">
        <div class="col-md-3">
          <label for=""> Description </label>
        </div>
        <div class="col-md-3">
          <textarea
            name="name"
            class="form-control"
            formControlName="description"
            rows="5"
            cols="6"
          ></textarea>
        </div>
      </div>

      <div class="col-md-4">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="onSubmit()"
          name="button"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
