import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http';
import { FrontloginComponent } from './frontlogin/frontlogin.component';
import { FrontsignupComponent } from './frontsignup/frontsignup.component';
import { FronthomeComponent } from './fronthome/fronthome.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FooterComponent } from './footer/footer.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { NewFooterComponent } from './footernew/footer.component';
import { MatSelectModule } from '@angular/material/select';
import { TestimonialNewComponent } from './newtestimonial/testimonial.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
@NgModule({
  declarations: [
    LoginComponent,
    FrontloginComponent,
    FrontsignupComponent,
    FronthomeComponent,
    FooterComponent,
    TestimonialComponent,
    NewFooterComponent,
    TestimonialNewComponent,
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    NgbModule,
    AutocompleteLibModule,
    NgSelectModule,
    NgxCaptchaModule,
    MatSelectModule,
  ],
  exports: [
    FooterComponent,
    TestimonialComponent,
    NewFooterComponent,
    TestimonialNewComponent,
  ],
})
export class AuthModule {}
