import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class TransactionService {
  apiUrl = environment.baseUrl;
  public updateAbortTrnx: string = `${this.apiUrl}/transaction/getTransactionStatusnByTransactionId`;
  public sentTransactionsToComplianceUrl: string = `${this.apiUrl}/transaction/sentTransactionsToCompliance`;
  public sentTransactionsToAbortedUrl: string = `${this.apiUrl}/transaction/sentTransactionsToAborted`;
  public sentTransactionsToUnComplianceUrl: string = `${this.apiUrl}/transaction/sentTransactionsToUnCompliance`;
  public changeSelectedTransactionsToProcessingUrl: string = `${this.apiUrl}/transaction/changeSelectedTransactionsToProcessing`;
  public getTransactionListByStatusUrl: string = `${this.apiUrl}/transaction/getTransactionListBasedOnStatus`;
  public getAllCancelledTransactionListUrl: string = `${this.apiUrl}/transaction/getAllCancelledTransactions`;
  public createVirtualAccountUrl: string = `${this.apiUrl}/fincra/generateVirtualAccount`;
  public getTransactionByAgentAndCreateDateAndStatusUrl: string = `${this.apiUrl}/transaction/getTransactionsListByStatusAndAgentIdAndCreatedDate`;
  public sendEmailToUploadDocumentsUrl: string = `${this.apiUrl}/transaction/sendMailToUploadDocuments`;
  public uploadAdditionalDocumentsUrl: string = `${this.apiUrl}/transaction/addAdditionalDocuments`;
  public getAdditionalDocumentsUrl: string = `${this.apiUrl}/transaction/getComplianceDocumentsByTransactionId`;
  public getuploadAdditionalDocumentsUrl: string = `${this.apiUrl}/transaction/complainceUploadDocuments`;
  public exchangerateUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesByDestAndSrcAgentIdAndPType`;
  public serviceFeeUrl: string = `${this.apiUrl}/transaction/getServiceFeeBySrcAndDestAgentAndPtype`;
  public collectionFeeUrl: string = `${this.apiUrl}/transaction/getColtnFeeByAgentColtnIdAndSendAmount`;
  public sendingAgentsUrl: string = `${this.apiUrl}/user/getFrontendSrcAgentsByFunctionality`;
  public recAgentsUrl: string = `${this.apiUrl}/user/getFrontendDestAgentsByFunctionality`;
  public makeTransactionsUnArchiveUrl: string = `${this.apiUrl}/transaction/makeTransactionsUnArchive`;
  public collectiontypeUrl: string = `${this.apiUrl}/agent/getAgentCollectionList`;
  public paymentTypeUrl: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeList`;
  public createTxnUrl: string = `${this.apiUrl}/transaction/createTransactionWithNewCustomer`;
  public addressProofTypesUrl: string = `${this.apiUrl}/utility/getAddressProofTypes`;
  public identityTypesUrl: string = `${this.apiUrl}/utility/getIdentityTypes`;
  public txnListUrl: string = `${this.apiUrl}/transaction/getTransactionsList`;
  public createTxnUrl1: string = `${this.apiUrl}/transaction/createTransactionWithExistCustomer`;
  public createTxnWithIdProofUrl: string = `${this.apiUrl}/transaction/createTransactionWithNewCustomerWithIdProofs`;
  public poliPaymentReqUrl: string = `${this.apiUrl}/polipay/polyPaymentRequest`;
  public poliPaymentReqForReloadlyUrl: string = `${this.apiUrl}/polipay/polyPaymentRequestforReloadly`;

  public poliBillPaymentReqForReloadlyUrl: string = `${this.apiUrl}/polipay/poliBillPaymentReqForReloadly`;

  public airTxnUrl: string = `${this.apiUrl}/reloadly/getairTxnById`;
  public airTxnForPoliUrl: string = `${this.apiUrl}/polipay/getairTxnByIdPoliPay`;
  public billTxnForPoliUrl: string = `${this.apiUrl}/polipay/getbillTxnByIdPoliPay`;

  public billTxnUrl: string = `${this.apiUrl}/reloadly/getbillTxnById`;
  public getAllTransactionsForBillURL: string = `${this.apiUrl}/reloadly/getAllbillTxn`;

  public txnUrl: string = `${this.apiUrl}/transaction/getTransactionById`;
  public saveBankDetails: string = `${this.apiUrl}/transaction/saveBankInfo`;
  public getBankList: string = `${this.apiUrl}/transaction/getBanksList`;
  public getCashCollPointList: string = `${this.apiUrl}/transaction/getCashCollPointList`;
  public saveCshCollDetail: string = `${this.apiUrl}/transaction/saveCashCollInfo`;
  public cashColtnByTxnIdUrl: string = `${this.apiUrl}/transaction/getCashCollectionByTransactionId`;
  public bankDepositByTxnIdUrl: string = `${this.apiUrl}/transaction/getBankDepositTransactionId`;
  public cashColtnByRecAgentIdUrl: string = `${this.apiUrl}/transaction/getCashCollPointList`;
  public bankDepositByRecCountryIdUrl: string = `${this.apiUrl}/transaction/getBanksListByCountryId`;
  public getBenifPaytypeNameUrl: string = `${this.apiUrl}/transaction/getBenifPayTypeNameById`;
  public customerByIdUrl: string = `${this.apiUrl}/customer/getCustomerByUserId`;
  public txnListByStatusUrl: string = `${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDate`;
  public txnListBySrcAgentIdAndStatusUrl: string = `${this.apiUrl}/transaction/getTransactionsListBySrcAgentAndStatus`;
  public cancelTxnListUrl: string = `${this.apiUrl}/transaction/getCancelTransactionList`;
  public refundTxnUrl: string = `${this.apiUrl}/transaction/getRefundTransactionList`;
  public cancelTransactionUrl: string = `${this.apiUrl}/transaction/cancelTransactionByTransactionId`;
  public cancelAirTransactionUrl: string = `${this.apiUrl}/reloadly/cancelAirTransactionByTransactionId`;
  public cancelBillTransactionUrl: string = `${this.apiUrl}/reloadly/cancelBillTransactionByTransactionId`;

  public rejectTransactionByComplainceUrl: string = `${this.apiUrl}/transaction/rejectTransactionByComplaince`;

  public getcustomerlistApi: string = `${this.apiUrl}/customer/getCustomersListByAgentId`;
  public getCustomerListbyFieldsApi: string = `${this.apiUrl}/customer/getCustomerListBySearchFileds`;
  public getBeneficiaryListUrl: string = `${this.apiUrl}/customer/getBeneficiaryListByCustomerIdAndPaymentType`;
  public updateTransactionStatusUrl: string = `${this.apiUrl}/transaction/updateTransactionStatus`;
  public updateStatusByTxnIdUrl: string = `${this.apiUrl}/transaction/updatePaymentStatusByTxnId`;
  public refundTransactionUrl: string = `${this.apiUrl}/transaction/refundTransactionByTransactionId`;

  public generateCsvUrl: string = `${this.apiUrl}/transaction/generateCsvFile`;
  public txnListByStatusAndKycUrl: string = `${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDateAndKycStatus`;
  public txnListByStatusAndKycAndSearchTypeUrl: string = `${this.apiUrl}/transaction/getTransactionsListByStatusAndCreatedDateAndKycStatusAndSearchType`;
  public geoSwiftExRateUrl: string = `${this.apiUrl}/geoSwift/getRates`;
  public pendingtxnListUrl: string = `${this.apiUrl}/transaction/getpendingTransactionsByCreatedDate`;
  public pendingtxnListWithTimeZoneUrl: string = `${this.apiUrl}/transaction/getpendingTransactionsByCreatedDateWithTimeZone`;
  public getAllApiNamesUrl: string = `${this.apiUrl}/transaction/getAllApiNamesAndCountries`;
  public pendingtxnListBySendingAgentUrl: string = `${this.apiUrl}/transaction/getpendingTransactionsByCreatedDateAndSendingAgent`;

  public cancelTxnListBySendingAgentUrl: string = `${this.apiUrl}/transaction/getCancelTxnListBySendingAgentANdCreatedDate`;

  public refundTxnsBySendingAgentUrl: string = `${this.apiUrl}/transaction/getRefundTransactionListBySendingAgentIdAndCreatedDate`;
  public getTransactionStatusUrl: string = `${this.apiUrl}/polipay/updatePolyPaymentRequest`;
  public getTransactionStatusReloadlyUrl: string = `${this.apiUrl}/polipay/updatePolyPaymentRequestForReloadly`;

  public getTransactionStatusReloadlyBillUrl: string = `${this.apiUrl}/polipay/updatePolyPaymentRequestForReloadlyBill`;

  public txnListByStatusAndKycANdSendingAgentUrl: string = `${this.apiUrl}/transaction/getTransactionListByStatusAndKycStatusAndSendingAgent`;
  public trustPaymentUrl: string = `${this.apiUrl}/trustPayment/savePaymentRequest`;
  public updateTrustPaymentUrl: string = `${this.apiUrl}/trustPayment/updatePaymentRequest`;
  public getApiNameUrl: string = `${this.apiUrl}/transaction/getApiName`;
  public getColtnFeeByAgentIdAndSendAmountUrl: string = `${this.apiUrl}/transaction/getColtnFeeByAgentIdAndSendAmount`;

  public txnListByStatusAndKycANdRecAgentUrl: string = `${this.apiUrl}/transaction/getTransactionListByStatusAndKycStatusAndRecAgent`;
  public getDefaultFliqPayRatesUrl: string = `${this.apiUrl}/fliqpay/getQuote`;
  public duplicateCustomerUrl: string = `${this.apiUrl}/customer/findDuplicateCustomer`;
  public duplicateCustomerWithAddressUrl: string = `${this.apiUrl}/customer/findDuplicateCustomerWithAddress`;
  public firePaymentUrl: string = `${this.apiUrl}/fire/paymentRequest`;
  public fireGenerateAccessTokenUrl: string = `${this.apiUrl}/fire/generateAccessToken`;
  public benifBanksListUrl: string = `${this.apiUrl}/fliqpay/bankCode`;
  public duplicateBeneficiaryUrl = `${this.apiUrl}/customer/findDuplicateBenificiary`;
  public duplicateCustomerUrl1: string = `${this.apiUrl}/customer/findDuplicateCustomerByEmailId`;
  public getCountryNameByRecAgentIdUrl: string = `${this.apiUrl}/user/findCountryNameBydestAgentId`;
  public identityTypesUrlBasedOnCountry: string = `${this.apiUrl}/utility/identityTypesBasedOnCountry`;
  public sentTxnListUrl: string = `${this.apiUrl}/transaction/getSentTransactionListByCreatedDate`;
  public sentTxnListByRecAgentUrl: string = `${this.apiUrl}/transaction/getSentTransactionListByRecAgentIdCreatedDate`;
  public getAllTransactionsByArchiveStatusUrl: string = `${this.apiUrl}/transaction/getAllTransactionsByArchiveStatus`;
  public makeTransactionsArchiveUrl: string = `${this.apiUrl}/transaction/makeTransactionsArchive`;
  public getInboundAustracReportUrl: string = `${this.apiUrl}/transaction/inboundAustracReport`;
  public getVelocitytByPeriodTypeUrl: string = `${this.apiUrl}/utility/getVelocitytByPeriodType`;
  public getSendingAmountByCustomerIdUrl: string = `${this.apiUrl}/transaction/getSendingAmountByCustomerId`;
  public getVelocitytByPeriodTypeAndSrcCountryUrl: string = `${this.apiUrl}/utility/getVelocitytByPeriodTypeAndSrcCountry`;
  public getVelocitytByPeriodTypeAndDestCountryUrl: string = `${this.apiUrl}/utility/getVelocitytByPeriodTypeAndDestCountry`;
  public getTrxnListByCompilanceStatusAndCreatedDateUrl: string = `${this.apiUrl}/transaction/getTrxnListByCompilanceStatusAndCreatedDate`;
  public saveComplainceUrl: string = `${this.apiUrl}/utility/saveComplaince`;
  public getComplainceUrl: string = `${this.apiUrl}/utility/getComplainceList`;
  public getComplainceListByApprovedDateUrl: string = `${this.apiUrl}/utility/getComplainceListByApprovedDate`;
  public getRejectedListByRejectedDateUrl: string = `${this.apiUrl}/utility/getRejectedListByRejectedDate`;
  public mobileWalletsByRecCountryIdUrl: string = `${this.apiUrl}/transaction/getMobileListBasedOnCountryId`;
  public getAllTransactionProfitListUrl: string = `${this.apiUrl}/transaction/getTransactionProfitList`;
  public getCountryDetailsByIdUrl: string = `${this.apiUrl}/transaction/getCountryDetailsByCountryId`;
  public getTransactionReceivingBranchDetailsByBeneficiaryIdUrl: string = `${this.apiUrl}/transaction/getTransactionReceivingBranchDetailsByBeneficiaryId`;
  public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl: string = `${this.apiUrl}/exchRate/getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType`;
  //getBeneficiaryByCustomerId
  public getBeneficiaryByCustomerIdUrl: string = `${this.apiUrl}/transaction/getBeneficiaryByCustomerId`;
  public nameCheckForAlianzaBankUrl: string = `${this.apiUrl}/allianza/getAccountName`;
  public getAlianzaBanks: string = `${this.apiUrl}/allianza/getAlianzaBanksList`;
  public getAllianzaStatusUrl: string = `${this.apiUrl}/allianza/getAllianzaStatus`;

  public getAgentCollectionListByAgentIdAndCurrencyUrl: string = `${this.apiUrl}/agent/getAgentCollectionListByAgentIdAndCurrency`;
  public cashCollsByBenifId: string = `${this.apiUrl}/transaction/getCashCollPointByBenficiaryId`;
  public bankDepositsByBenifId = `${this.apiUrl}/transaction/getBankDepositByBenficiaryId`;

  public getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeListByAgentIdAndCurrency`;
  public getNotesListByTransactionIdUrl: string = `${this.apiUrl}/transaction/getNotesListOfTransaction`;
  public getNotesListByUserIdUrl:string = `${this.apiUrl}/transaction/getNotesListOfUser`;
  public saveNotesToTransactionUrl: string = `${this.apiUrl}/transaction/addNotesToTransaction`;
  public saveUserNoteUrl: string = `${this.apiUrl}/transaction/addUserNotes`;
  

  public getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl: string = `${this.apiUrl}/transaction/getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency`;
  public getServiceFeeBySrcAndDestAgentAndPtypeWithOutAmountUrl: string = `${this.apiUrl}/transaction/getServiceFeeBySrcAndDestAgentAndPtypeWithOutAmount`;

  public getSendAgentShowListUrl: string = `${this.apiUrl}/user/getSendAgentShowList`;
  public getExchangeRateById: string = `${this.apiUrl}/utility/getApiInfoByExchRateId`;
  public getBenifPayTypes: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeListByAgentId`;
  public getAllOccupationsUrl: string = `${this.apiUrl}/utility/getAllOccupations`;

  public checkOutUrl: string = `${this.apiUrl}/checkout/getCheckoutLink`;
  public checkOutStatusUrl: string = `${this.apiUrl}/checkout/getCheckOutLinkStatus`;

  public getBankDetailsByBenifIdUrl: string = `${this.apiUrl}/transaction/getBankDetailsByBeneficairyId`;
  //getCashCollectionByBenificiaryId
  public getCashCollectionByBenificiaryIdUrl: string = `${this.apiUrl}/transaction/getCashCollectionByBenificiaryId`;
  public getMobileDetailsByBenificiaryIdUrl: string = `${this.apiUrl}/transaction/getMobileWalletDetailsByBeneficiaryId`;
  public txnListByStatusAndUserIdAndDateUrl: string = `${this.apiUrl}/transaction/getTransactionListDetailsBySendingCustomerUserIdAndDateAndStatus`;
  public lastWeekTransactionsOfUserUrl: string = `${this.apiUrl}/transaction/getLastWeekTransactionsOfUserId`;
  public getCountriesFromReloadlyUrl: string = `${this.apiUrl}/reloadly/getCountries`;
  public getAllOperatorsByPhoneAndCountryIsoUrl: string = `${this.apiUrl}/reloadly/getAllOperatorsByPhoneAndCountryIso`;
  public createTopUpUrl: string = `${this.apiUrl}/reloadly/createTopUp`;

  // Added by Martins start
  public getAllPageableTransactionStatusTimeUrl: string = `${this.apiUrl}/transaction/getAllTransactionStatusTimeReport`;
  public getAllPageableTransactionsExchangeHistoryUrl: string = `${this.apiUrl}/transaction/getAllTransactionExchangeRate`;
  public exportTransactionUrl: string = `${this.apiUrl}/transaction/getTransactionStatusTimeReportRange`;
  public exportTransactionExchangeRateUrl: string = `${this.apiUrl}/transaction/getTransactionExchangeRateReportRange`;
  // Added by Martin End

  public getBillersByCountryIsoUrl: string = `${this.apiUrl}/reloadly/getBillers`;
  public createBillUrl: string = `${this.apiUrl}/reloadly/createBillPayment`;
  public getAllAirTransactionsURL: string = `${this.apiUrl}/reloadly/getAllAirTxn`;
  public getAllAirTransactionsByUserIdd: string = `${this.apiUrl}/reloadly/getAirTransactionListByUserId`;
  public getAllBillTransactionsByUserIdd: string = `${this.apiUrl}/reloadly/getBillTransactionListByUserId`;
  public getAllBIllTransactionsURL: string = `${this.apiUrl}/reloadly/getAllBillTxn`;

  public updateStatusByAirTxnIdUrl: string = `${this.apiUrl}/reloadly/updateStatusByAirTxnId`;
  public updateStatusByBillTxnIdUrl: string = `${this.apiUrl}/reloadly/updateStatusByBillTxnId`;
  public reloadlyTransactionDetailsByCustomerIdUrl: string = `${this.apiUrl}/reloadly/getAllReloadlyTransactionByPaymentType`;
  public getRegisterAsUrl:string=`${this.apiUrl}/user/getRegisterAs`;
  public getTrxnListByCompilanceStatusUrl: string = `${this.apiUrl}/utility/getTrxnListByCompilanceList`;
  public getReferralTransactionsActivities: string = `${this.apiUrl}/utility/getReferralTransactionsActivities`;
  public getAgentBenifPaymentTypeListByAgentIdAndCurrencyAndMobileUrl: string = `${this.apiUrl}/reloadly/getAgentBenifPaymentTypeListByAgentIdAndCurrency`;
  public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeForReloadlyUrl: string = `${this.apiUrl}/reloadly/getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType`;
  public getColtnFeeByAgentIdAndSendAmountForReloadlyUrl: string = `${this.apiUrl}/reloadly/getColtnFeeByAgentIdAndSendAmountForReloadly`;
    public volumePaymentUrl:string=`${this.apiUrl}/volumepay/saveVolumePaymentRequest`
     public getMtbsTransactionListByStatusUrl:string=`${this.apiUrl}/mtbs/getMtbsTransactionsListByStatusAndCreatedDate`
    
    
  // httpOptions = {
  //   headers : new HttpHeaders ({}).set('Authorization', localStorage.getItem("datas"))
  //
  // }
  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }),
  };

  constructor(
    private _http: HttpClient,
    private _httpp: HttpClient,
    handler: HttpBackend
  ) {
    this._httpp = new HttpClient(handler);
  }
  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res', res);
    return body || {};
  }
  public updateTransactionStatusnByTransactionId(
    transactionId: any,
    pId: string
  ): Observable<any> {
    return this._http
      .get(
        `${this.updateAbortTrnx}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId
      )
      .pipe(map(this.extractData));
  }
  public agentenifPayTypesByRecAgentId(Id: any, pId: string): Observable<any> {
    console.log('cashColtnByRecAgentIdUrl....', Id);
    return this._http
      .get(`${this.getBenifPayTypes}` + '?pId=' + pId + '&agentId=' + Id)
      .pipe(map(this.extractData));
  }
  public cashColtnByBenifId(Id: any, pId: string): Observable<any> {
    return this._http
      .get(`${this.cashCollsByBenifId}` + '?pId=' + pId + '&benficiaryId=' + Id)
      .pipe(map(this.extractData));
  }

  public getCheckoutLink(
    Id: any,
    ipAddress: any,
    pId: string
  ): Observable<any> {
    return this._http
      .get(
        `${this.checkOutUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          Id +
          '&ipAddress=' +
          ipAddress
      )
      .pipe(map(this.extractData));
  }

  public getCheckOutLinkStatus(Id: any, pId: string): Observable<any> {
    return this._http
      .get(`${this.checkOutStatusUrl}` + '?pId=' + pId + '&transactionId=' + Id)
      .pipe(map(this.extractData));
  }

  //getBeneficiaryByCustomerIdUrl
  public getBeneficiaryByCustomerId(Id: any, pId: string): Observable<any> {
    return this._http
      .get(
        `${this.getBeneficiaryByCustomerIdUrl}` +
          '?pId=' +
          pId +
          '&customerId=' +
          Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public bankDepositByTxnId(Id: any, pId: string): Observable<any> {
    console.log('bankDepositByTxnIdUrl....', pId);
    return this._http
      .get(
        `${this.bankDepositByTxnIdUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public bankDepositByBenifId(Id: any, pId: string): Observable<any> {
    return this._http
      .get(
        `${this.bankDepositsByBenifId}` + '?pId=' + pId + '&benficiaryId=' + Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public cashColtnByRecAgentId(Id: any, pId: string): Observable<any> {
    console.log('cashColtnByRecAgentIdUrl....', pId);
    return this._http
      .get(
        `${this.cashColtnByRecAgentIdUrl}` + '?pId=' + pId + '&userId=' + Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getDefaultFliqPayRates(pId: string, record: any): Observable<any> {
    console.log('getDefaultFliqPayRatesUrl....', pId);
    return this._http
      .post(
        `${this.getDefaultFliqPayRatesUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getApiName(
    pId: string,
    sending: any,
    receive: any,
    paymentType: any,
    destCurrency: any
  ): Observable<any> {
    console.log('getApiName....', pId);
    return this._httpp
      .get(
        `${this.getApiNameUrl}` +
          '?pId=' +
          pId +
          '&sendingCountry=' +
          sending +
          '&receivingCountry=' +
          receive +
          '&paymentType=' +
          paymentType +
          '&destCurrencyCode=' +
          destCurrency,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public bankDepositByRecCountryId(Id: any, pId: string): Observable<any> {
    console.log('bankDepositByRecCountryIdUrl....', pId);
    return this._http
      .get(
        `${this.bankDepositByRecCountryIdUrl}` +
          '?pId=' +
          pId +
          '&countryId=' +
          Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getGeoSwiftExcRate(pId: string): Observable<any> {
    console.log('geoSwiftExRateUrl....', pId);
    return this._httpp
      .get(`${this.geoSwiftExRateUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }
  public poliPaymentReq(pId: string, record: any): Observable<any> {
    console.log('poliPaymentReqUrl....', pId);
    return this._http
      .post(
        `${this.poliPaymentReqUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public cashColtnByTxnId(Id: any, pId: string): Observable<any> {
    console.log('cashColtnByTxnIdUrl....', pId);
    return this._http
      .get(
        `${this.cashColtnByTxnIdUrl}` + '?pId=' + pId + '&transactionId=' + Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getBanks(pId: string, countryId: any, payment: any): Observable<any> {
    console.log('benifBanksList....', pId);
    return this._http
      .get(
        `${this.benifBanksListUrl}` +
          '?pId=' +
          pId +
          '&countryId=' +
          countryId +
          '&paymentType=' +
          payment,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getBeneficiaryListByCustomerId(
    Id: any,
    paymentType: any,
    pId: string
  ): Observable<any> {
    console.log('getBeneficiaryListByCustomerId....', pId);
    return this._http
      .get(
        `${this.getBeneficiaryListUrl}` +
          '?pId=' +
          pId +
          '&customerId=' +
          Id +
          '&paymentType=' +
          paymentType,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getCustomerById(userId: any, pId: string): Observable<any> {
    console.log('getCustomerById....', pId);
    return this._http
      .get(
        `${this.customerByIdUrl}` + '?pId=' + pId + '&userId=' + userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public generateAccessToken(pId: string): Observable<any> {
    console.log('accesstoken....', pId);
    return this._http
      .post(
        `${this.fireGenerateAccessTokenUrl}` + '?pId=' + pId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public firePaymentRequest(record: any, pId: string): Observable<any> {
    console.log('firePayment....', pId);
    return this._http
      .post(`${this.firePaymentUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(map(this.extractData));
  }
  public saveTrustPayment(record: any, pId: string): Observable<any> {
    console.log('trustPayment....', pId);
    return this._http
      .post(`${this.trustPaymentUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getTxnById(transactionId: any, pId: string): Observable<any> {
    console.log('getTxnById....', pId);
    return this._http
      .get(
        `${this.txnUrl}` + '?pId=' + pId + '&transactionId=' + transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTxnList(pId: string): Observable<any> {
    console.log('getTxnList....', pId);
    return this._http
      .get(`${this.txnListUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe();
    map(this.extractData);
  }

  public getAddressProofTypes(pId: string): Observable<any> {
    console.log('getAddressProofTypes....', pId);
    return this._http
      .get(`${this.addressProofTypesUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getIdentityTypes(pId: string): Observable<any> {
    console.log('getIdentityTypes....', pId);
    return this._http
      .get(`${this.identityTypesUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getIdentityTypesBasedOnCountry(
    sendingCountry: any,
    pId: string
  ): Observable<any> {
    console.log('getIdentityTypes....', pId);
    return this._http
      .get(
        `${this.identityTypesUrlBasedOnCountry}` +
          '?pId=' +
          pId +
          '&sendingCountry=' +
          sendingCountry,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAgentBenifPaymentTypes(agentId: any, pId: string): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._http
      .get(
        `${this.paymentTypeUrl}` + '?pId=' + pId + '&agentId=' + agentId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAgentCollectionTypes(agentId: any, pId: string): Observable<any> {
    console.log('working Agent Collection Types..' + agentId);
    return this._http
      .get(`${this.collectiontypeUrl}` + '?pId=' + pId + '&agentId=' + agentId)
      .pipe(map(this.extractData));
  }
  public getSendingAgents(pId: string, functionality: any): Observable<any> {
    console.log('working getSendingAgents=' + pId);
    return this._httpp
      .get(
        `${this.sendingAgentsUrl}` +
          '?pId=' +
          pId +
          '&functionality=' +
          functionality,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getRecAgents(pId: string, srcAgentId: any): Observable<any> {
    console.log('working getRecAgents=' + pId);
    return this._httpp
      .get(
        `${this.recAgentsUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&status=' +
          'active',
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getExchangeRate(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any,
    rateFlag = 'sell'
  ): Observable<any> {
    //console.log("working getExchangeRate="+pId);
    return this._http
      .get(
        `${this.exchangerateUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId +
          '&rateFlag=' +
          rateFlag,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getCountryNameByRecAgentId(id: any, pId: string): Observable<any> {
    console.log('working getCountryNameByRecAgentId=' + pId);
    return this._httpp
      .get(
        `${this.getCountryNameByRecAgentIdUrl}` +
          '?pId=' +
          pId +
          '&destAgentId=' +
          id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getServiceFee(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any,
    Amount: any
  ): Observable<any> {
    console.log('working getServiceFee=' + pId);
    return this._http
      .get(
        `${this.serviceFeeUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId +
          '&Amount=' +
          Amount,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getCollectionFee(
    pId: string,
    agentCollectionId: any,
    Amount: any,
    registerName:any
  ): Observable<any> {
    console.log('working getCollectionFee=' + pId);
    return this._httpp
      .get(
        `${this.collectionFeeUrl}` +
          '?pId=' +
          pId +
          '&agentCollectionId=' +
          agentCollectionId +
          '&amount=' +
          Amount +
          '&registerName=' +
          registerName,
        this.httpOptions
      )
      .pipe(debounceTime(500),map(this.extractData));
  }

  //createTxnWithIdProofUrl
  public createTxnWithIdProoF(txnRecord: any, pId: string): Observable<any> {
    //console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http
      .post(
        this.createTxnWithIdProofUrl + '?pId=' + pId,
        txnRecord,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //CREATE TXN
  public createTxn(txnRecord: any, pId: string): Observable<any> {
    //console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http
      .post(this.createTxnUrl + '?pId=' + pId, txnRecord, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public createTxnWithExistCustomer(
    txnRecord: any,
    pId: string
  ): Observable<any> {
    console.log('createTransactionWithExistCustomer ..!' + txnRecord);
    return this._http
      .post(this.createTxnUrl1 + '?pId=' + pId, txnRecord, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getCustomerlist(agentId: any, pId: string): Observable<any> {
    return this._http
      .get(
        `${this.getcustomerlistApi}` + '?pId=' + pId + '&agentId=' + agentId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getCollectionByFields(
    pId: string,
    address: string,
    firstName: string,
    lastName: string,
    mobileNumber: string
  ): Observable<any> {
    return this._http
      .get(
        '${this.getCustomerListbyFieldsApi}' +
          '?address=' +
          address +
          '&firstName=' +
          firstName +
          '&lastName=' +
          lastName +
          '&mobileNumber=' +
          mobileNumber,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public saveBnkDetails(bnkRecord: any, pId: string): Observable<any> {
    //console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http
      .post(this.saveBankDetails + '?pId=' + pId, bnkRecord, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getClientBanksList(pId: string): Observable<any> {
    return this._http
      .get(`${this.getBankList}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getAgentCollPointList(pId: string, userId: string): Observable<any> {
    return this._http
      .get(
        `${this.getCashCollPointList}` + '?pId=' + pId + '&userId=' + userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public saveCshCollDetails(cshRecord: any, pId: string): Observable<any> {
    //console.log("json ..!"+JSON.Stringify(txnRecord));
    return this._http
      .post(this.saveCshCollDetail + '?pId=' + pId, cshRecord, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public getTxnListByStatus(
    fromDate: any,
    toDate: any,
    status: any,
    pId: string
  ): Observable<any> {
    console.log('txnListByStatusUrl....', pId);
    return this._http
      .get(
        `${this.txnListByStatusUrl}` +
          '?pId=' +
          pId +
          '&status=' +
          status +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public txnListByStatusAndKycStatus(
    fromDate: any,
    toDate: any,
    status: any,
    kycStatus: any,
    pId: string
  ): Observable<any> {
    console.log('txnListByStatusAndKycUrl....', pId);
    console.log('txnListByStatusAndKycUrl....1', this.txnListByStatusAndKycUrl);
    return this._http
      .get(
        `${this.txnListByStatusAndKycUrl}` +
          '?pId=' +
          pId +
          '&status=' +
          status +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&kycStatus=' +
          kycStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public txnListByStatusAndKycStatusAndSearchType(
    fromDate: any,
    toDate: any,
    status: any,
    searchType: any,
    kycStatus: any,
    pId: string
  ): Observable<any> {
    console.log('txnListByStatusAndKycStatusAndSearchType....', pId);
    console.log(
      'txnListByStatusAndKycStatusAndSearchType....1',
      this.txnListByStatusAndKycUrl
    );
    return this._http
      .get(
        `${this.txnListByStatusAndKycAndSearchTypeUrl}` +
          '?pId=' +
          pId +
          '&status=' +
          status +
          '&searchType=' +
          searchType +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&kycStatus=' +
          kycStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //txnListByStatusAndKycANdSendingAgentUrl
  public txnListByStatusAndKycANdSendingAgent(
    agentId: any,
    fromDate: any,
    toDate: any,
    status: any,
    kycStatus: any,
    pId: string
  ): Observable<any> {
    console.log('txnListByStatusAndKycANdSendingAgentUrl....', pId);
    return this._http
      .get(
        `${this.txnListByStatusAndKycANdSendingAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&status=' +
          status +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&kycStatus=' +
          kycStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public txnListByStatusAndKycANdRecAgent(
    agentId: any,
    fromDate: any,
    toDate: any,
    status: any,
    kycStatus: any,
    pId: string
  ): Observable<any> {
    console.log('txnListByStatusAndKycANdRecAgent....', pId);
    return this._http
      .get(
        `${this.txnListByStatusAndKycANdRecAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&status=' +
          status +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&kycStatus=' +
          kycStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTxnListBySrcAgentIdAndStatus(
    agentId: any,
    status: any,
    pId: string
  ): Observable<any> {
    console.log('getTxnListBySrcAgentsAndStatus....', pId);
    return this._http
      .get(
        `${this.txnListBySrcAgentIdAndStatusUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&status=' +
          status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getCancelTxns(
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getCancelTxns....', pId);
    return this._http
      .get(
        `${this.cancelTxnListUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getRefundTxns(
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getRefundTxns....', pId);
    return this._http
      .get(
        `${this.refundTxnUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public updateStatusByTxnId(
    transactionId: any,
    status: any,
    pId: string
  ): Observable<any> {
    console.log('updateStatusByTxnId....', pId);
    return this._http
      .get(
        `${this.updateStatusByTxnIdUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&status=' +
          status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public cancelTransaction(
    transactionId: any,
    cancelReason: any,
    cancelBy: any,
    pId: string
  ): Observable<any> {
    console.log('cancelTransaction....', pId);
    return this._http
      .get(
        `${this.cancelTransactionUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&cancelReason=' +
          cancelReason +
          '&cancelBy=' +
          cancelBy,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public cancelAirTimeTransaction(
    transactionId: any,
    cancelReason: any,
    cancelBy: any,
    pId: string
  ): Observable<any> {
    console.log('cancelTransaction....', pId);
    return this._http
      .get(
        `${this.cancelAirTransactionUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&cancelReason=' +
          cancelReason +
          '&cancelBy=' +
          cancelBy,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public cancelBillTransaction(
    transactionId: any,
    cancelReason: any,
    cancelBy: any,
    pId: string
  ): Observable<any> {
    console.log('cancelTransaction....', pId);
    return this._http
      .get(
        `${this.cancelBillTransactionUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&cancelReason=' +
          cancelReason +
          '&cancelBy=' +
          cancelBy,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public cancelTransactionByComplaince(
    transactionId: any,
    cancelReason: any,
    cancelBy: any,
    pId: string
  ): Observable<any> {
    console.log('cancelTransaction....', pId);
    return this._http
      .get(
        `${this.rejectTransactionByComplainceUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&cancelReason=' +
          cancelReason +
          '&cancelBy=' +
          cancelBy,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public refundTransaction(
    transactionId: any,
    refundReason: any,
    refundBy: any,
    pId: string
  ): Observable<any> {
    console.log('refundTransaction....', pId);
    return this._http
      .get(
        `${this.refundTransactionUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId +
          '&refundReason=' +
          refundReason +
          '&refundBy=' +
          refundBy,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //client pendingtxnListUrl
  public getPendingtxnList(
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('pendingtxnListUrl....', pId);
    return this._http
      .get(
        `${this.pendingtxnListUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //client pendingtxnListUrl
  public getPendingtxnListt(record: any, pId: string): Observable<any> {
    console.log('pendingtxnListUrl....', record);
    return this._httpp
      .post(
        `${this.pendingtxnListUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //client pendingtxnListUrl
  public getPendingtxnListWithTimZone(
    fromDate: any,
    toDate: any,
    pId: string,
    timeZone: any
  ): Observable<any> {
    console.log('pendingtxnListUrl....', pId);
    return this._http
      .get(
        `${this.pendingtxnListWithTimeZoneUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&timeZone=' +
          timeZone,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //Agent pendingtxnListBySendingAgentUrl
  public getPendingtxnListBySendingAgent(
    agentId: any,
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('pendingtxnListBySendingAgentUrl....', pId);
    return this._http
      .get(
        `${this.pendingtxnListBySendingAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //Agent cancelTxnListBySendingAgentUrl
  public getCancelTxnListBySendingAgent(
    agentId: any,
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('cancelTxnListBySendingAgentUrl....', pId);
    return this._http
      .get(
        `${this.cancelTxnListBySendingAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //refundTxnsBySendingAgentUrl
  public getrefundTxnListBySendingAgent(
    agentId: any,
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getrefundTxnListBySendingAgent....', pId);
    return this._http
      .get(
        `${this.refundTxnsBySendingAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //duplicateCustomerUrl
  public getDuplicateCustomer(
    firstName: any,
    lastName: any,
    emailId: any,
    phoneNumber: any,
    pId: string
  ): Observable<any> {
    console.log('getDuplicateCustomer....', pId);
    return this._http
      .get(
        `${this.duplicateCustomerUrl}` +
          '?pId=' +
          pId +
          '&firstName=' +
          firstName +
          '&lastName=' +
          lastName +
          '&emailId=' +
          emailId +
          '&phoneNumber=' +
          phoneNumber,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getDuplicateCustomerWithAddress(
    firstName: any,
    lastName: any,
    dob: any,
    address: any,
    pId: string
  ): Observable<any> {
    console.log('getDuplicateCustomer....', pId);
    return this._http
      .get(
        `${this.duplicateCustomerWithAddressUrl}` +
          '?pId=' +
          pId +
          '&firstName=' +
          firstName +
          '&lastName=' +
          lastName +
          '&dob=' +
          dob +
          '&address=' +
          address,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //duplicateBeneficiaryUrl
  public getDuplicateBeneficiary(
    firstName: any,
    lastName: any,
    phoneNumber: any,
    pId: string
  ): Observable<any> {
    console.log('duplicateBeneficiaryUrl....', pId);
    return this._http
      .get(
        `${this.duplicateBeneficiaryUrl}` +
          '?pId=' +
          pId +
          '&firstName=' +
          firstName +
          '&lastName=' +
          lastName +
          '&phoneNumber=' +
          phoneNumber,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public findDuplicateCustomerByEmailId(
    emailId: any,
    pId: string
  ): Observable<any> {
    console.log('findDuplicateCustomerByEmailId====', pId);
    return this._http
      .get(
        `${this.duplicateCustomerUrl1}` + '?pId=' + pId + '&emailId=' + emailId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTransactionStatus(
    pId: string,
    token: any,
    txId: any
  ): Observable<any> {
    console.log('getTransactionStatus....', pId);
    return this._http
      .get(
        `${this.getTransactionStatusUrl}` +
          '?pId=' +
          pId +
          '&token=' +
          token +
          '&txId=' +
          txId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getTransactionStatusForReloadly(
    pId: string,
    token: any,
    txId: any
  ): Observable<any> {
    console.log('getTransactionStatus....', pId);
    return this._http
      .get(
        `${this.getTransactionStatusReloadlyUrl}` +
          '?pId=' +
          pId +
          '&token=' +
          token +
          '&txId=' +
          txId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getTransactionStatusForReloadlybill(
    pId: string,
    token: any,
    txId: any
  ): Observable<any> {
    console.log('getTransactionStatus....', pId);
    return this._http
      .get(
        `${this.getTransactionStatusReloadlyBillUrl}` +
          '?pId=' +
          pId +
          '&token=' +
          token +
          '&txId=' +
          txId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getBenifPayTypeName(pId: string, bId: any): Observable<any> {
    console.log('getBenifPayTypeName....', pId);
    return this._http
      .get(
        `${this.getBenifPaytypeNameUrl}` + '?pId=' + pId + '&bId=' + bId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //sentTxnListUrl
  public sentTxnList(
    pId: string,
    apistatus: string,
    fromDate: any,
    toDate: any
  ): Observable<any> {
    console.log('sentTxnListUrl====', apistatus);
    return this._http
      .get(
        `${this.sentTxnListUrl}` +
          '?pId=' +
          pId +
          '&apiStatus=' +
          apistatus +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //sentTxnListByRecAgentUrl
  public sentTxnListByRecAgent(
    agentId: any,
    pId: string,
    apistatus: string,
    fromDate: any,
    toDate: any
  ): Observable<any> {
    console.log('sentTxnListByRecAgentUrl====', apistatus);
    return this._http
      .get(
        `${this.sentTxnListByRecAgentUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&apiStatus=' +
          apistatus +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAllTransactionsByArchiveStatus(
    pId: string,
    status: string
  ): Observable<any> {
    console.log('getAllTransactionsByArchiveStatusUrl====', status);
    return this._http
      .get(
        `${this.getAllTransactionsByArchiveStatusUrl}` +
          '?pId=' +
          pId +
          '&status=' +
          status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public makeTransactionsArchive(pId: string): Observable<any> {
    console.log('makeTransactionsArchive====', status);
    return this._http
      .get(
        `${this.makeTransactionsArchiveUrl}` +
          '?pId=' +
          pId +
          '&status=' +
          status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public makeTransactionUnArchive(
    pId: string,
    transactionId: any
  ): Observable<any> {
    console.log('makeTransactionsUnArchiveUrl====', status);
    return this._http
      .get(
        `${this.makeTransactionsUnArchiveUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getInboundAustracReport(
    pId: string,
    fromDate: string,
    toDate: string,
    status: string
  ): Observable<any> {
    console.log('getInboundAustracReport====', status);
    return this._http
      .get(
        `${this.getInboundAustracReportUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&status=' +
          status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAllApiNames(pId: string): Observable<any> {
    console.log('getAllApiNames====', pId);
    return this._http
      .get(`${this.getAllApiNamesUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }
  //getVelocitytByPeriodTypeUrl
  public getVelocitytByPeriodType(
    periodType: any,
    pId: string
  ): Observable<any> {
    console.log('getVelocitytByPeriodTypeUrl====', pId);
    return this._http
      .get(
        `${this.getVelocitytByPeriodTypeUrl}` +
          '?pId=' +
          pId +
          '&periodType=' +
          periodType,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getSendingAmountByCustomerIdUrl
  public getSendingAmountByCustomerId(
    sendingCustomerUserId: any,
    bid: any,
    days: any,
    pId: string
  ): Observable<any> {
    console.log('getSendingAmountByCustomerIdUrl====', pId);
    return this._http
      .get(
        `${this.getSendingAmountByCustomerIdUrl}` +
          '?pId=' +
          pId +
          '&sendingCustomerUserId=' +
          sendingCustomerUserId +
          '&beneficiaryId=' +
          bid +
          '&days=' +
          days,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getVelocitytByPeriodTypeAndSrcCountryUrl
  public getVelocitytByPeriodTypeAndSrcCountry(
    periodType: any,
    srcCountry: any,
    pId: string
  ): Observable<any> {
    console.log('getVelocitytByPeriodTypeAndSrcCountryUrl====', pId);
    return this._http
      .get(
        `${this.getVelocitytByPeriodTypeAndSrcCountryUrl}` +
          '?pId=' +
          pId +
          '&periodType=' +
          periodType +
          '&srcCountry=' +
          srcCountry,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getVelocitytByPeriodTypeAndDestCountryUrl
  public getVelocitytByPeriodTypeAndDestCountry(
    periodType: any,
    destCountry: any,
    pId: string
  ): Observable<any> {
    console.log('getVelocitytByPeriodTypeAndDestCountryUrl====', pId);
    return this._http
      .get(
        `${this.getVelocitytByPeriodTypeAndDestCountryUrl}` +
          '?pId=' +
          pId +
          '&periodType=' +
          periodType +
          '&destCountry=' +
          destCountry,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getTrxnListByCompilanceStatusAndCreatedDateUrl
  public getTrxnListByCompilanceStatusAndCreatedDate(
    complainceRequired: any,
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getTrxnListByCompilanceStatusAndCreatedDate====', pId);
    return this._http
      .get(
        `${this.getTrxnListByCompilanceStatusAndCreatedDateUrl}` +
          '?pId=' +
          pId +
          '&complainceRequired=' +
          complainceRequired +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //saveComplainceUrl
  public saveComplaince(pId: string, record: any): Observable<any> {
    console.log('saveComplainceUrl....', record);
    return this._http
      .post(
        `${this.saveComplainceUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getComplainceUrl

  //getComplainceListByApprovedDate
  public getComplainceListByApprovedDate(
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getComplainceListByApprovedDate====', pId);
    return this._http
      .get(
        `${this.getComplainceListByApprovedDateUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getRejectedListByRejectedDate(
    fromDate: any,
    toDate: any,
    pId: string
  ): Observable<any> {
    console.log('getRejectedListByRejectedDate====', pId);
    return this._http
      .get(
        `${this.getRejectedListByRejectedDateUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public mobileWalletsByRecCountryId(Id: any, pId: string): Observable<any> {
    console.log('mobileWalletsByRecCountryIdUrl....', pId);
    return this._http
      .get(
        `${this.mobileWalletsByRecCountryIdUrl}` +
          '?pId=' +
          pId +
          '&countryId=' +
          Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public createVirtualAccount(Id: any, pId: string): Observable<any> {
    console.log('createVirtualAccountUrl....', pId);
    return this._http
      .get(
        `${this.createVirtualAccountUrl}` + '?pId=' + pId + '&userId=' + Id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAllTransactionProfitList(pId: string): Observable<any> {
    console.log('getAllTransactionProfitListUrl....', pId);
    return this._http
      .get(
        `${this.getAllTransactionProfitListUrl}` + '?pId=' + pId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTransactionReceivingBranchDetailsByBeneficiaryId(
    pId: string,
    bId: any
  ): Observable<any> {
    console.log(
      'getTransactionReceivingBranchDetailsByBeneficiaryIdUrl....',
      pId
    );
    return this._http
      .get(
        `${this.getTransactionReceivingBranchDetailsByBeneficiaryIdUrl}` +
          '?pId=' +
          pId +
          '&beneficiaryId=' +
          bId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getCountryDetailsById(countryId: any, pId: string): Observable<any> {
    console.log('getCountryDetailsByIdUrl....', pId);
    return this._http
      .get(
        `${this.getCountryDetailsByIdUrl}` +
          '?pId=' +
          pId +
          '&countryId=' +
          countryId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl
  public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any,
    srcCurrency: any,
    destCurrency: any,
    rateFlag: string,
    registerName:String
  ): Observable<any> {
    console.log('working getExchangeRate=' + pId);
    return this._httpp
      .get(
        `${this.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId +
          '&srcCurrency=' +
          srcCurrency +
          '&destCurrency=' +
          destCurrency +
          '&rateFlag=' +
          rateFlag +
          '&registerName=' +
          registerName
      )
      .pipe(map(this.extractData));
  }
  //getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl
  public getAgentBenifPaymentTypeListByAgentIdAndCurrency(
    agentId: any,
    currency: any,
    pId: string
  ): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._httpp
      .get(
        `${this.getAgentBenifPaymentTypeListByAgentIdAndCurrencyUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&currency=' +
          currency,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getSendAgentShowListUrl
  public getSendAgentShowList(pId: string): Observable<any> {
    console.log('getSendAgentShowListUrl....', pId);
    return this._httpp
      .get(`${this.getSendAgentShowListUrl}` + '?pId=' + pId)
      .pipe(map(this.extractData));
  }
  //getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl
  public getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any,
    Amount: any,
    srcCurrency: any,
    destCurrency: any,
    registerName:any
  ): Observable<any> {
    console.log('working getServiceFee=' + pId);
    return this._httpp
      .get(
        `${this.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId +
          '&amount=' +
          Amount +
          '&srcCurrency=' +
          srcCurrency +
          '&destCurrency=' +
          destCurrency +
          '&registerName=' +
          registerName,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getServiceFeeBySrcAndDestAgentAndPtypeAndCurrencyUrl
  public getServiceFeeBySrcAndDestAgentAndPtype(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any
  ): Observable<any> {
    console.log('working getServiceFee=' + pId);
    return this._httpp
      .get(
        `${this.getServiceFeeBySrcAndDestAgentAndPtypeWithOutAmountUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getAgentCollectionListByAgentIdAndCurrencyUrl
  public getAgentCollectionListByAgentIdAndCurrency(
    agentId: any,
    currency: any,
    pId: string
  ): Observable<any> {
    console.log('working Agent Collection Types..' + agentId);
    return this._http
      .get(
        `${this.getAgentCollectionListByAgentIdAndCurrencyUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&currency=' +
          currency
      )
      .pipe(map(this.extractData));
  }

  public getApiInfoByExchRateId(exchId: any, pId: string): Observable<any> {
    console.log('getApiInfoByExchRateId' + exchId);
    return this._http
      .get(
        `${this.getExchangeRateById}` +
          '?pId=' +
          pId +
          '&exchangeRateId=' +
          exchId
      )
      .pipe(map(this.extractData));
  }

  public updateTransactionStatus(
    txnId: any,
    status: any,
    reason: any,
    pId: string
  ): Observable<any> {
    console.log('working txnId..' + txnId);
    return this._http
      .get(
        `${this.updateTransactionStatusUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          txnId +
          '&status=' +
          status +
          '&reason=' +
          reason
      )
      .pipe(map(this.extractData));
  }

  public generateCsv(record: any, pId: string): Observable<any> {
    console.log('working pId..' + pId);
    return this._http.get(`${this.generateCsvUrl}` + '?pId=' + pId, record);
  }
  public getNotesListOfTransaction(txnId: any, pId: string): Observable<any> {
    console.log('working txnId..' + txnId);
    return this._http
      .get(
        `${this.getNotesListByTransactionIdUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          txnId
      )
      .pipe(map(this.extractData));
  }

  public getNotesListOfUser(userId: any, pId: string): Observable<any> {
    console.log('working txnId..' + userId);
    return this._http
      .get(
        `${this.getNotesListByUserIdUrl}` +
          '?pId=' +
          pId +
          '&userId=' +
          userId
      )
      .pipe(map(this.extractData));
  }
  //getColtnFeeByAgentIdAndSendAmountUrl
  public getColtnTypesByAgentIdAndSendAmount(
    agentId: any,
    currency: any,
    display: any,
    amount: any,
    pId: string
  ): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._httpp
      .get(
        `${this.getColtnFeeByAgentIdAndSendAmountUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&currency=' +
          currency +
          '&display=' +
          display +
          '&amount=' +
          amount,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public saveNotesToTransaction(
    txnId: any,
    notes: any,
    pId: string
  ): Observable<any> {
    console.log('working pId..' + pId);
    return this._http.get(
      `${this.saveNotesToTransactionUrl}` +
        '?pId=' +
        pId +
        '&transactionId=' +
        txnId +
        '&notes=' +
        notes
    );
  }

  public saveUserNote(
    userId: any,
    notes: any,
    pId: string
  ): Observable<any> {
    console.log('working pId..' + pId);
    return this._http.get(
      `${this.saveUserNoteUrl}` +
        '?pId=' +
        pId +
        '&userId=' +
        userId +
        '&notes=' +
        notes
    );
  }

  public getBankDetailsByBenifId(id: any, pId: any): Observable<any> {
    console.log('getBankDetailsByBenifIdUrl....', pId);
    return this._http
      .get(
        `${this.getBankDetailsByBenifIdUrl}` +
          '?pId=' +
          pId +
          '&beneficiaryId=' +
          id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  //getCashCollectionByBenificiaryIdUrl
  public getCashCollectionByBenificiaryId(id: any, pId: any): Observable<any> {
    console.log('getCashCollectionByBenificiaryIdUrl....', pId);
    return this._http
      .get(
        `${this.getCashCollectionByBenificiaryIdUrl}` +
          '?pId=' +
          pId +
          '&beneficiaryId=' +
          id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //getmobliebyBenificiaryIdUrl
  public getMobileDetailsByBenificiaryId(id: any, pId: any): Observable<any> {
    console.log('getMobileDetailsByBenificiaryIdUrl....', pId);
    return this._http
      .get(
        `${this.getMobileDetailsByBenificiaryIdUrl}` +
          '?pId=' +
          pId +
          '&beneficiaryId=' +
          id,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //get all occupation list
  public getAllOccupationList(pId: any): Observable<any> {
    console.log('getAllOccupationList....', pId);
    return this._http
      .get(`${this.getAllOccupationsUrl}` + '?pId=' + pId, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public changeSelectedTransactionsToProcessing(
    pId: any,
    record: any
  ): Observable<any> {
    console.log('changeSelectedTransactionsToProcessing....', pId);
    return this._http
      .post(
        `${this.changeSelectedTransactionsToProcessingUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public sentTransactionsToCompliance(pId: any, record: any): Observable<any> {
    console.log('sentTransactionsToCompliance....', pId);
    return this._http
      .post(
        `${this.sentTransactionsToComplianceUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public sentTransactionsToAborted(pId: any, record: any): Observable<any> {
    console.log('sentTransactionsToAborted....', pId);
    return this._http
      .post(
        `${this.sentTransactionsToAbortedUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }


  

  public sentTransactionsToUnCompliance(
    pId: any,
    record: any
  ): Observable<any> {
    console.log('sentTransactionsToUnCompliance....', pId);
    return this._http
      .post(
        `${this.sentTransactionsToUnComplianceUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAlianzaBanksList(id: any): Observable<any> {
    return this._http
      .get(`${this.getAlianzaBanks}` + '?pId=' + id, this.httpOptions)
      .pipe(map(this.extractData));
  }
  public getAllianzaServerStatus(id: any): Observable<any> {
    return this._http
      .get(`${this.getAllianzaStatusUrl}` + '?pId=' + id, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public alianzaNameCheck(
    bankCode: any,
    accountNumber: any,
    pId: any
  ): Observable<any> {
    return this._http
      .get(
        `${this.nameCheckForAlianzaBankUrl}` +
          '?pId=' +
          pId +
          '&bankCode=' +
          bankCode +
          '&accountNumber=' +
          accountNumber
      )
      .pipe(map(this.extractData));
  }

  public getTransactionListByStatus(pId: any, status: any): Observable<any> {
    console.log('getTransactionListByStatusUrl....', status);
    return this._httpp
      .post(
        `${this.getTransactionListByStatusUrl}` + '?pId=' + pId,status,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  // Added by Martins =======================
  public getTransactionsListByStatusAndTime(
    page: any,
    pageSize: any
  ): Observable<any> {
    return this._http
      .get(
        `${this.getAllPageableTransactionStatusTimeUrl}` +
          '?page=' +
          page +
          '&pageSize=' +
          pageSize,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTransactionsExchangeRateHistory(
    page: any,
  ): Observable<any> {
    return this._http
      .get(
        `${this.getAllPageableTransactionsExchangeHistoryUrl}` +
          '?page=' +
          page,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public exportTransactionToCsv(fromDate: any, toDate: any): Observable<any> {
    return this._http
      .get(
        `${this.exportTransactionUrl}` +
          '?fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public exportTransactionExchangeRateToCsv(
    fromDate: any,
    toDate: any
  ): Observable<any> {
    return this._http
      .get(
        `${this.exportTransactionExchangeRateUrl}` +
          '?fromDate=' +
          fromDate +
          '&toDate=' +
          toDate,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public exportTransactionExchangeRate(
    fromDate: any,
    toDate: any,
    sendingCurrency: any
  ): Observable<any> {
    return this._http
      .get(
        `${this.exportTransactionExchangeRateUrl}` +
          '?fromDate=' +
          fromDate +
          '&toDate=' +
          toDate + '&sendingCurrency=' + sendingCurrency,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  //End here

  public getCancelTransactionList(pId: any): Observable<any> {
    console.log('getCancelTransactionList....', pId);
    return this._http
      .get(
        `${this.getAllCancelledTransactionListUrl}` + '?pId=' + pId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public txnListByStatusAndUserIdAndDate(
    fromDate: any,
    toDate: any,
    status: any,
    userId: any,
    pId: string
  ): Observable<any> {
    return this._http
      .get(
        `${this.txnListByStatusAndUserIdAndDateUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&status=' +
          status +
          '&userId=' +
          userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getTransactionsListByStatusAndAgentIdAndCreatedDate(
    pId: string,
    fromDate: any,
    toDate: any,
    status: any,
    userId: any
  ): Observable<any> {
    return this._http
      .get(
        `${this.getTransactionByAgentAndCreateDateAndStatusUrl}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&status=' +
          status +
          '&agentId=' +
          userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public sendEmailToUploadDocuments(
    transactionId: any,
    pId: string
  ): Observable<any> {
    return this._http
      .get(
        `${this.sendEmailToUploadDocumentsUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public uploadAdditionalDocuments(
    transactionId: any,
    record: any,
    pId: string
  ): Observable<any> {
    return this._httpp
      .post(
        `${this.uploadAdditionalDocumentsUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        record
      )
      .pipe(map(this.extractData));
  }

  public getAdditionalDocuments(
    transactionId: any,
    pId: string
  ): Observable<any> {
    return this._httpp
      .get(
        `${this.getAdditionalDocumentsUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId
      )
      .pipe(map(this.extractData));
  }
  
  public getuploadAdditionalDocuments(
    transactionId: any,
    pId: string
  ): Observable<any> {
    return this._httpp
      .get(
        `${this.getuploadAdditionalDocumentsUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId
      )
      .pipe(map(this.extractData));
  }
  
  public getLastWeekTransactionsOfUser(
    userId: any,
    pId: string
  ): Observable<any> {
    return this._httpp
      .get(
        `${this.lastWeekTransactionsOfUserUrl}` +
          '?pId=' +
          pId +
          '&userId=' +
          userId
      )
      .pipe(map(this.extractData));
  }

  public getCountriesFromReloadly(pId: string): Observable<any> {
    return this._httpp
      .get(
        `${this.getCountriesFromReloadlyUrl}` + '?pId=' + pId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAllOperatorsByPhoneAndCountryIso(
    pId: string,
    phoneNumber: any,
    countryIso: any
  ): Observable<any> {
    return this._httpp
      .get(
        `${this.getAllOperatorsByPhoneAndCountryIsoUrl}` +
          '?pId=' +
          pId +
          '&phoneNumber=' +
          phoneNumber +
          '&countryIso=' +
          countryIso,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public createNewTopUp(pId: string, record: any): Observable<any> {
    console.log('Record,,,,', record);
    return this._httpp
      .post(`${this.createTopUpUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(map(this.extractData));
  }
  public createBillForReladly(pId: string, record: any): Observable<any> {
    console.log('Record,,,,', record);
    return this._httpp
      .post(`${this.createBillUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(map(this.extractData));
  }

  public poliPaymentReqForReloadly(pId: string, record: any): Observable<any> {
    console.log('poliPaymentReqUrl....', pId);
    return this._http
      .post(
        `${this.poliPaymentReqForReloadlyUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public poliBillPaymentReqForReloadly(
    pId: string,
    record: any
  ): Observable<any> {
    console.log('poliPaymentReqUrl....', pId);
    return this._http
      .post(
        `${this.poliBillPaymentReqForReloadlyUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAirTxnById(transactionId: any, pId: string): Observable<any> {
    console.log('poliPaymentReqUrl....', pId);
    return this._http
      .get(
        `${this.airTxnUrl}` + '?pId=' + pId + '&transactionId=' + transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getBillTxnById(transactionId: any, pId: string): Observable<any> {
    console.log('poliPaymentReqUrl....', pId);
    return this._http
      .get(
        `${this.billTxnUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAllTransactionsForBill(pId: any, record: any): Observable<any> {
    return this._http
      .post(
        `${this.getAllTransactionsForBillURL}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAirTxnByIdForPoli(
    transactionId: any,
    pId: string
  ): Observable<any> {
    // console.log('poliPaymentReqUrl....', pId);
    return this._http
      .get(
        `${this.airTxnForPoliUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getBillTxnByIdForPoli(
    transactionId: any,
    pId: string
  ): Observable<any> {
    // console.log('poliPaymentReqUrl....', pId);
    return this._http
      .get(
        `${this.billTxnForPoliUrl}` +
          '?pId=' +
          pId +
          '&transactionId=' +
          transactionId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getBillersByCountryIso(pId: string, record: any): Observable<any> {
    // alert(record)
    return this._httpp
      .post(
        `${this.getBillersByCountryIsoUrl}` + '?pId=' + pId,
        record,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  

  public getAllAirTransactions(
    pId: string,
    fromDate: any,
    toDate: any,
    paymentStatus: any
  ): Observable<any> {
    // alert(record)
    fromDate = fromDate + ' 00:00:00';
    toDate = toDate + ' 23:59:59';
    return this._http
      .get(
        `${this.getAllAirTransactionsURL}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&paymentStatus=' +
          paymentStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAllAirTransactionsByUserId(
    pId: string,
    userId: any
  ): Observable<any> {
    // alert(record)
    return this._http
      .get(
        `${this.getAllAirTransactionsByUserIdd}` +
          '?pId=' +
          pId +
          '&userId=' +
          userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAllBillTransactionsByUserId(
    pId: string,
    userId: any
  ): Observable<any> {
    // alert(record)
    return this._http
      .get(
        `${this.getAllBillTransactionsByUserIdd}` +
          '?pId=' +
          pId +
          '&userId=' +
          userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public getAllBillTransactions(
    pId: string,
    fromDate: any,
    toDate: any,
    paymentStatus: any
  ): Observable<any> {
    // alert(record)
    fromDate = fromDate + ' 00:00:00';
    toDate = toDate + ' 23:59:59';
    return this._http
      .get(
        `${this.getAllBIllTransactionsURL}` +
          '?pId=' +
          pId +
          '&fromDate=' +
          fromDate +
          '&toDate=' +
          toDate +
          '&paymentStatus=' +
          paymentStatus,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public updateStatusByAirTxnId(
    transactionId: any,
    status: any,
    pId: string
  ): Observable<any> {
    console.log('updateStatusByAirTxnId....', pId);
    return this._http.get(
      `${this.updateStatusByAirTxnIdUrl}` +
        '?pId=' +
        pId +
        '&transactionId=' +
        transactionId +
        '&status=' +
        status,
      this.httpOptions
    );
  }

  public updateStatusByBillTxnId(
    transactionId: any,
    status: any,
    pId: string
  ): Observable<any> {
    console.log('updateStatusByAirTxnId....', pId);
    return this._http.get(
      `${this.updateStatusByBillTxnIdUrl}` +
        '?pId=' +
        pId +
        '&transactionId=' +
        transactionId +
        '&status=' +
        status,
      this.httpOptions
    );
  }

  public reloadlyTransactionDetailsByCustomerId(
    pId: string,
    type: any
  ): Observable<any> {
    console.log('reloadlyTransactionDetailsByCustomerId....', pId);
    return this._http
      .get(
        `${this.reloadlyTransactionDetailsByCustomerIdUrl}` +
          '?pId=' +
          pId +
          '&type=' +
          type,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getRegisterAs(pId : string) : Observable <any> {
    console.log("working getSendingAgents="+pId);
    return this._httpp.get(`${this.getRegisterAsUrl}`+'?pId='+pId,this.httpOptions).pipe(
    map(this.extractData)
    );
  }

  public getTrxnListByCompilanceStatus(
    complainceRequired: any,
    pId: string
  ): Observable<any> {
    console.log('getTrxnListByCompilanceStatusAndCreatedDate====', pId);
    return this._http
      .get(
        `${this.getTrxnListByCompilanceStatusUrl}` +
          '?pId=' +
          pId +
          '&complainceRequired=' +
          complainceRequired,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getReferralTransactions(
    userId: any,
    pId: string
  ): Observable<any> {
    console.log('getReferralTransactionsActivities====', pId);
    return this._http
      .get(
        `${this.getReferralTransactionsActivities}` +
          '?pId=' +
          pId +
          '&userId=' +
          userId,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getAgentBenifPaymentTypeListByAgentIdAndCurrencyAndMobile(
    agentId: any,
    currency: any,
    pId: string
  ): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._httpp
      .get(
        `${this.getAgentBenifPaymentTypeListByAgentIdAndCurrencyAndMobileUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&currency=' +
          currency,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }

  public getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeForReloadly(
    pId: string,
    srcAgentId: any,
    destAgentId: any,
    agentBenifPayTypeId: any,
    srcCurrency: any,
    destCurrency: any,
    rateFlag: string
  ): Observable<any> {
    console.log('working getExchangeRate=' + pId);
    return this._httpp
      .get(
        `${this.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPTypeForReloadlyUrl}` +
          '?pId=' +
          pId +
          '&srcAgentId=' +
          srcAgentId +
          '&destAgentId=' +
          destAgentId +
          '&agentBenifPayTypeId=' +
          agentBenifPayTypeId +
          '&srcCurrency=' +
          srcCurrency +
          '&destCurrency=' +
          destCurrency +
          '&rateFlag=' +
          rateFlag
      )
      .pipe(map(this.extractData));
  }
  public getColtnFeeByAgentIdAndSendAmountForReloadly(
    agentId: any,
    currency: any,
    display: any,
    amount: any,
    pId: string
  ): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._httpp
      .get(
        `${this.getColtnFeeByAgentIdAndSendAmountForReloadlyUrl}` +
          '?pId=' +
          pId +
          '&agentId=' +
          agentId +
          '&currency=' +
          currency +
          '&display=' +
          display +
          '&amount=' +
          amount,
        this.httpOptions
      )
      .pipe(map(this.extractData));
  }
  public saveVolumePayment(record:any,pId: string): Observable<any> {
    console.log('volumepayment....', pId);
    return this._http.post(`${this.volumePaymentUrl}`+'?pId=' + pId,record, this.httpOptions)
  }
  GenerateInvoicePDF(txnId: any) {
    return this._httpp.get(`http://localhost:4200/#/customerreciptt`, { observe: 'response', responseType: 'blob' });
  }
  public getMtbsTransactionListByStatus( pId: string,
    fromDate: any,
    toDate: any,
    status: any): Observable<any> {
      fromDate = fromDate + ' 00:00:00';
      toDate = toDate + ' 23:59:59';
    console.log('getTransactionListByStatusUrl....', status);
    return this._httpp
      .get(
        `${this.getMtbsTransactionListByStatusUrl}`  +
        '?pId=' +
        pId +
        '&fromDate=' +
        fromDate +
        '&toDate=' +
        toDate +
        '&status=' +
        status,
      this.httpOptions
    )
    .pipe(map(this.extractData));
}


 

}
