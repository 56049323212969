import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators,FormArray,FormBuilder} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import {ExchangeRatesService,ProcessIdGeneraterService} from '../../service';
import {AuditTrialService } from '../../service';
import { HttpClient  } from '@angular/common/http';


@Component({
  selector: 'app-dest-agent-buy-rates',
  templateUrl: './dest-agent-buy-rates.component.html',
  styleUrls: ['./dest-agent-buy-rates.component.css']
})
export class DestAgentBuyRatesComponent implements OnInit {

  ipAddress:any;
  auditTrialForm:FormGroup;
  isShow:boolean
  public List:any;
  sv:any;
  dv:any;
  mv:any;
  adr:any;
  agentId:any;
  arate:any;
  srate:any;
  destExchRateForm:FormGroup;
  constructor(private fb :FormBuilder ,
    private _router :Router,
    private _route:ActivatedRoute,
    private _exchangeratesservice:ExchangeRatesService,
    private _auditTrialService: AuditTrialService,
    private http:HttpClient,
    private processIdGeneraterService : ProcessIdGeneraterService) { }


  ngOnInit(): void {
    this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
      this.ipAddress = res.ip;
     // alert(this.ipAddress)

    });
      if(localStorage.getItem("userId")==null){
          this._router.navigateByUrl('remitz');
      }
    this.destExchRateForm=new FormGroup({
    sourceAgentId:new FormControl(''),
    destinationAgentId:new FormControl(''),
    markUpValue:new FormControl(''),
    sellRate:new FormControl(''),
    adminRate:new FormControl(''),
    srcCurrencyValue:new FormControl(''),
    destCurrencyValue:new FormControl(''),
    agentBenifPayTypeId:new FormControl(''),
    srcMarkUpValue:new FormControl('')
    });
    this.auditTrialForm=new FormGroup({
    category:new FormControl(''),
    userName:new FormControl(''),
    userId:new FormControl(''),
    userType:new FormControl(''),
    ipAddress:new FormControl(''),
    sourceCountry:new FormControl(''),
    agent:new FormControl(''),
    transaction:new FormControl(''),
    message:new FormControl(''),
    originalData:new FormControl(''),
    changedData:new FormControl('')
    });

  this.agentId=localStorage.getItem('userId')
  console.log('this.agentId===',this.agentId);
    this._exchangeratesservice.getExchangeRatesListByDestAgentIdAndRateFlag(
    this.agentId,'buy',this.processIdGeneraterService.getPid()).subscribe(res => {
             if (res){
                   this.List =res.response;
                   console.log('List',this.List)

             }
           },err =>{
             alert("fail");
             console.log(err);
           }
         );

  }

  onRowClick(event,id){
  this.dv=event.target.outerText;
  console.log("this.dv...!"+this.dv);
  }

  public onUpdate(selectedItem: any) :void{
  //alert("onUpdate working..!",selectedItem.agentBenifPayTypeId);
  let originalData="destination value="+selectedItem.destCurrencyValue;
    if(this.dv==null){
      this.dv=selectedItem.destCurrencyValue;
  }
  console.log('arate=',selectedItem.adminRate);
  if(selectedItem.markUpValue!=0){
    console.log('markUpValue111=',selectedItem.adminRate);
    this.arate=selectedItem.adminRate;
  }else{
    console.log('markUpValue222=',selectedItem.adminRate);
    this.arate=this.dv;
  }
  if(selectedItem.srcMarkUpValue!=0){
    console.log('srcMarkUpValue1111=',selectedItem.srcMarkUpValue);
    this.srate=selectedItem.sellRate;
  }else{
    console.log('srcMarkUpValue222=',selectedItem.srcMarkUpValue);
    this.srate=this.arate;
  }
 
  //alert("this.dv..."+this.dv);
  //alert("this.srate..."+this.srate);
    const newObj = {
      'sourceAgentId':selectedItem.sourceAgentId,
      'destinationAgentId':selectedItem.destinationAgentId,
      'srcCurrencyValue':selectedItem.srcCurrencyValue,
      'destCurrencyValue':this.dv,
      'adminRate' :this.arate,
      'markUpValue':selectedItem.markUpValue,
      'sellRate':this.srate,
      'srcMarkUpValue':selectedItem.srcMarkUpValue,
      'agentBenifPayTypeId':selectedItem.agentBenifPayTypeId
    }

    if(this.destExchRateForm.invalid){
        return;
    }
    else{
      this.destExchRateForm.patchValue(newObj);
      console.log('destExchRateForm==',this.destExchRateForm.value);
      console.log('exchRateId..',selectedItem.exchRateId);
      this._exchangeratesservice.updateExchangeRateById(this.destExchRateForm.value, this.processIdGeneraterService.getPid(),selectedItem.exchRateId).subscribe(res => {
        console.log("update exchRateForm....!.!" + res);

        if (res) {
          let changedData="destination value="+this.dv;

          const trObj={
          ipAddress:this.ipAddress,
          category:'Update Exchange Rate By DestinationAgent',
          userType:localStorage.getItem("userTypeName"),
          userName:localStorage.getItem("uname"),
          originalData:originalData,
          changedData:changedData,
          userId:localStorage.getItem("userId")
          }
          this.auditTrialForm.patchValue(trObj);
        
          this._auditTrialService.saveAuditTrailDetails(
            this.auditTrialForm.value,
             this.processIdGeneraterService.getPid()).subscribe(res => {
            if (res) {
              //this.List=res.response;
            }
          });
          this._router.navigate(['/destAgentExchRates']);
          this.destExchRateForm.reset();
         window.location.reload();
        }
      },
        error => {

          this.destExchRateForm.reset();
          throw error;
        }
      );


  }
  }




  }
