import { Component, OnInit } from '@angular/core';
import {Router,ActivatedRoute} from '@angular/router';
import {TransactionService,LogOutService,LoginserviceService} from '../../../service';
import { ProcessIdGeneraterService} from '../../../service';
import {AccessLogService } from '../../../service';
import { FormGroup,FormControl} from '@angular/forms';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isnaviagationbar:boolean;
  isindexnavbar:boolean;
  isShow:boolean;
  userLastName:any;
  userFirstName:any;
  senderdetailsnavbar:boolean;
  userId:any;
  beneficiaryFlag:boolean;
  senderFlag:any;
  idVerifiedStatusFlag:any;
mobileShow:boolean;
AccessLogForm:FormGroup;
  constructor(

    public router:Router,


  private _transactionService:TransactionService,
  private processIdGeneraterService:ProcessIdGeneraterService ,
  private _route:ActivatedRoute,
  private _accessLogService: AccessLogService,
  private _loginService:LoginserviceService,
  private logoutService:LogOutService
  ) { }


  ngOnInit(): void {
    this.senderFlag=localStorage.getItem('senderFlag')
    this.idVerifiedStatusFlag=localStorage.getItem('idVerifiedStatusFlag')
    this.AccessLogForm=new FormGroup({
     ipAddress:new FormControl(''),
     userType:new FormControl(''),
     userName:new FormControl(''),
     acessResult:new FormControl(''),
     userid:new FormControl('')
    })

this.isindexnavbar=false;
this.senderdetailsnavbar=true
this.userId=  localStorage.getItem('userId');
this._transactionService.getCustomerById(this.userId,this.processIdGeneraterService.getPid()).subscribe(res=>{
  if(res){
this.senderFlag='full';
if(res.response.identityVerifiedStatus==='Verified'){
  this.idVerifiedStatusFlag='true';
}
else{
  this.idVerifiedStatusFlag='false';
}
console.log("user",res.response)
this.userFirstName=res.response.firstName;

this.userLastName=res.response.lastName;
localStorage.setItem("fullName",this.userFirstName+" "+this.userLastName);
}
},err=>{
this._loginService.getUserDetailsById(this.userId,this.processIdGeneraterService.getPid()).subscribe(res=>{

if(res){
this.idVerifiedStatusFlag='false';
this.senderFlag='partial';
this.userFirstName=res.response.firstName;

this.userLastName=res.response.lastName;

}
});
}
)//
// if(this._route.routeConfig.path=='/login'  ){
//
// this.isShow=false;
// this.senderdetailsnavbar=true;
// }
// if(this._route.routeConfig.path== '/signup'  ){
//
// this.isShow=false;
// this.senderdetailsnavbar=true;
// }
// else{
//   this.isShow=true;
// }

  }
onShow(){
  this.mobileShow=!this.mobileShow;
}

onSend(){
  if(this.idVerifiedStatusFlag==='true'){
    if(this.senderFlag==='full'){
      this.router.navigate(['/senderdetails'])
    }
    else{
      alert('Sorry, Your id proof is under review and once approved you will be able to make a transaction')
    }

}
else{
  alert('Sorry, Your id proof is under review and once approved you will be able to make a transaction')
}

}
onTransaction(){
  if(this.idVerifiedStatusFlag==='true'){
    if(this.senderFlag==='full'){
      this.router.navigate(['/trlist'])
    }
    else{
      alert('Sorry, Unable to list Transactions as there are no transactions available to list')
    }

}
else{
  alert('Sorry, Unable to list Transactions as there are no transactions available to list')
}

}
onBenif(){
  if(this.idVerifiedStatusFlag==='true'){
    if(this.senderFlag==='full'){
      this.router.navigate(['/recipient'])
    }
    else{
      alert('Sorry, Unable to list recipient')
    }

}
else{
  alert('Sorry, Unable to list recipient')
}

}

  logout(){
    if(localStorage.getItem('accessId')!=null){
    this._accessLogService.getAccessLogByAccessIdAndUserId(
    localStorage.getItem('accessId'),
    localStorage.getItem('userId'),
     this.processIdGeneraterService.getPid()).subscribe(res => {
    if (res) {
      console.log('accesslog....',res.response)
this._accessLogService.updateAccessLogByAccessId(
localStorage.getItem('accessId'),
this.processIdGeneraterService.getPid()).subscribe(res => {
if (res) {
console.log('accesslog.....',res.response);
localStorage.setItem("accessId", res.response.accessId);
this.logoutService.logOut().subscribe(res=>{
  if(res){

    localStorage.clear()
      this.router.navigate(['login'])
  }
})//logout closed

}//if closed
});//update accesslog closed

}//if closed
});//get accesslog closed
}//if access id closed
else{
  localStorage.clear()
    this.router.navigate(['login'])
}
}
onindex(){
  this.router.navigate(['index'])
}
omhome(){
  this.router.navigate(['/'])
}
onforget(){
  this.router.navigate(['/forget'])
}
}
