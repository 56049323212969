import { Injectable } from '@angular/core';
import {HttpClient ,HttpHeaders} from "@angular/common/http";
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddrecipientService {
  apiUrl=environment.baseUrl;
  public addReciepentApi:string = `${this.apiUrl}/transaction/addReciepent`;
  public beneficiaryListByCustomerIdUrl:string =`${this.apiUrl}/customer/getBeneficiaryListByCustomerId`;
  public beneficiaryListDetailsByCustomerIdUrl:string =`${this.apiUrl}/customer/getBeneficiaryDetailsByCustomerId`;
  public relationShipList:string =`${this.apiUrl}/utility/getRelationShipList`;
  public updateBenificiaryById:string=`${this.apiUrl}/transaction/updateBeneficiaryDetailsByBeneficiaryId`;
  public beneficiaryListByCustomerIdAndPTypeUrl=`${this.apiUrl}/customer/getBeneficiaryListByCustomerIdAndPaymentType`;
public getCountryByCountryIsoUrl:string=`${this.apiUrl}/utility/getCountryByCountryIso`;
  public accountValidateUrl:string = `${this.apiUrl}/nsano/findNameBasedOnAccountAndBank`;
  public accountValidateWalletUrl:string = `${this.apiUrl}/nsano/findNameBasedOnServiceName`;
public deleteBeneficiaryByIdUrl:string=`${this.apiUrl}/transaction/deleteBeneficiaryByBeneficiaryId`;
public getBankDetailsByBenifIdUrl:string=`${this.apiUrl}/transaction/getBankDetailsByBeneficairyId`;
public updateBankDepositByIdUrl:string=`${this.apiUrl}/customer/updateBeneficiaryAccountById`;
public updateCashCollectionByUrl:string=`${this.apiUrl}/customer/updateCashCollectionDetailsById`;
public updateMobileMoneyByIdUrl:string=`${this.apiUrl}/customer/updateMobileWalletDetailsById`;
public saveBankDepositUrl:string=`${this.apiUrl}/transaction/saveBankDeposit`;
public getBankDepositByBankDepositIdUrl:string=`${this.apiUrl}/customer/getBankDepositByBankDepositId`;
public getMobileDetailsByMobileIdUrl:string=`${this.apiUrl}/customer/getMobileMoneyDetailsByMobileId`;
public getCashCollectionByCashCollectionIdUrl:string=`${this.apiUrl}/customer/getCashCollectionByCashCollectionId`;
public checkBankDetailsByBeneficairyIdAndAccNumUrl:string=`${this.apiUrl}/transaction/checkBankDetailsByBeneficairyIdAndAccNum`;
//getBeneficiaryByCustomerIdAndCountryId
public getBeneficiaryByCustomerIdAndCountryIdUrl=`${this.apiUrl}/customer/getBeneficiaryByCustomerIdAndCountryId`;
//getCashCollectionByBenificiaryId
public getCashCollectionByBenificiaryIdUrl:string=`${this.apiUrl}/transaction/getCashCollectionByBenificiaryId`;
//saveBenifCashCollectionInfo
public saveBenifCashCollectionInfoUrl:string=`${this.apiUrl}/transaction/saveBenifCashCollectionInfo`;
public getMobileDetailsByBenificiaryIdUrl:string=`${this.apiUrl}/transaction/getMobileWalletDetailsByBeneficiaryId`
public saveBenifMobileWalletInfoUrl:string=`${this.apiUrl}/transaction/saveBenifMobileWalletInfo`
public updateBankAndBeneficiaryDetailsUrl:string=`${this.apiUrl}/utility/updateBankAndBeneficiaryDetails`

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
    //.set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http:HttpClient) { }


  public extractData(res:Response):Array<any> | {} {
    const  body=res;
    console.log('res',res)
    return body || {};
  }
  public beneficiaryListByCustomerId(Id:any, pId: string): Observable<any> {
    console.log('beneficiaryListByCustomerIdUrl....', pId);
    return this._http.get(`${this.beneficiaryListByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public beneficiaryListDetailsByCustomerId( pId: string): Observable<any> {
    console.log('beneficiaryListByCustomerIdUrl....', pId);
    return this._http.get(`${this.beneficiaryListDetailsByCustomerIdUrl}`+'?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public beneficiaryListByCustomerIdAndPaymentType(Id:any,paymentType:any, pId: string,country:any): Observable<any> {
    console.log('beneficiaryListByCustomerIdAndPaymentType....', pId);
    return this._http.get(`${this.beneficiaryListByCustomerIdAndPTypeUrl}`+'?pId=' + pId+'&customerId='+Id+'&paymentType='+paymentType+'&countryId='+country, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //getBeneficiaryByCustomerIdAndCountryIdUrl
  public getBeneficiaryByCustomerIdAndCountryId(Id:any, pId: string,country:any): Observable<any> {
    console.log('getBeneficiaryByCustomerIdAndCountryIdUrl....', pId);
    return this._http.get(`${this.getBeneficiaryByCustomerIdAndCountryIdUrl}`+'?pId=' + pId+'&customerId='+Id+'&countryId='+country, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getCountryByCountryIso(iso:any,pId:any): Observable<any> {
    console.log('getCountryByCountryIsoUrl....', pId);
    return this._http.get(`${this.getCountryByCountryIsoUrl}`+'?pId=' + pId+'&countryIso='+iso, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getRelationShipIdList(pId:string): Observable<any> {
    console.log('relationShipId....', pId);
    return this._http.get(`${this.relationShipList}`+'?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public postUpdaterecipient(pId: string,Id:any ,record:any): Observable<any> {
    console.log('beneficiaryUpdateDetails....', pId);
    return this._http.put(`${this.updateBenificiaryById}`+'?pId=' + pId+'&beneficiaryId='+Id,record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public postAddrecipient(pId:string,record:any):Observable<any>{
    console.log('record',record)
    return this._http.post(`${this.addReciepentApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //saveBankDepositUrl
  public saveBankDeposit(pId:string,record:any):Observable<any>{
    console.log('record',record)
    return this._http.post(`${this.saveBankDepositUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public updateBankAndBeneficiaryDetails(pId:string,record:any):Observable<any>{
    console.log('record',record)
    return this._http.post(`${this.updateBankAndBeneficiaryDetailsUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

//saveBenifCashCollectionInfoUrl
public saveBenifCashCollectionInfo(pId:string,record:any):Observable<any>{
  console.log('record',record)
  return this._http.post(`${this.saveBenifCashCollectionInfoUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
    map(this.extractData)
  )
}
//saveBenifMobileWalletInfoUrl
public saveBenifMobileWalletInfo(pId:string,record:any):Observable<any>{
  console.log('record',record)
  return this._http.post(`${this.saveBenifMobileWalletInfoUrl}`+'?pId='+pId,record,this.httpOptions).pipe(
    map(this.extractData)
  )
}

  public deleteBeneficiaryById(id:any,pId:any): Observable<any> {
    console.log('deleteBeneficiaryByIdUrl....', pId);
    return this._http.delete(`${this.deleteBeneficiaryByIdUrl}`+'?pId=' + pId+'&beneficiaryId='+id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getBankDetailsByBenifId(id:any,pId:any): Observable<any> {
    console.log('getBankDetailsByBenifIdUrl....', pId);
    return this._http.get(`${this.getBankDetailsByBenifIdUrl}`+'?pId=' + pId+'&beneficiaryId='+id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //getCashCollectionByBenificiaryIdUrl
  public getCashCollectionByBenificiaryId(id:any,pId:any): Observable<any> {
    console.log('getCashCollectionByBenificiaryIdUrl....', pId);
    return this._http.get(`${this.getCashCollectionByBenificiaryIdUrl}`+'?pId=' + pId+'&beneficiaryId='+id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

//checkBankDetailsByBeneficairyIdAndAccNum
public checkBankDetailsByBeneficairyIdAndAccNum(id:any,accNum:any,pId:any): Observable<any> {
  console.log('getBankDetailsByBenifIdUrl....', pId);
  return this._http.get(`${this.checkBankDetailsByBeneficairyIdAndAccNumUrl}`+'?pId=' + pId+'&beneficiaryId='+id+'&accNum='+accNum, this.httpOptions).pipe(
    map(this.extractData)
  )
}


//getmobliebyBenificiaryIdUrl
public getMobileDetailsByBenificiaryId(id:any,pId:any): Observable<any> {
  console.log('getMobileDetailsByBenificiaryIdUrl....', pId);
  return this._http.get(`${this.getMobileDetailsByBenificiaryIdUrl}`+'?pId=' + pId+'&beneficiaryId='+id, this.httpOptions).pipe(
    map(this.extractData)
  )
}
  public accountValidate(accountNumber:any,accountBank:any,pId:string): Observable<any> {
  console.log('accountValidateUr....', accountNumber);
  return this._http.get(`${this.accountValidateUrl}`+'?pId=' + pId+'&accountNumber='+accountNumber+'&accountBank='+accountBank).pipe(
    map(this.extractData)
  )
}
public accountValidateWallet(mobileNumber:any,serviceName:any,pId:string): Observable<any> {
  console.log('accountValidateUr....', mobileNumber);
  return this._http.get(`${this.accountValidateWalletUrl}`+'?pId=' + pId+'&mobileNumber='+mobileNumber+'&mobileService='+serviceName).pipe(
    map(this.extractData)
  )
}
public getBankDepositByBankDepositId(id:any,pId:string): Observable<any> {
console.log('getBankDepositByBankDepositIdUrl....', id);
return this._http.get(`${this.getBankDepositByBankDepositIdUrl}`+'?pId=' + pId+'&bankDepositId='+id).pipe(
  map(this.extractData)
)
}

public getMobileDetailsByMobileId(id:any,pId:string): Observable<any> {
console.log('getMobileDetailsByMobileId....', id);
return this._http.get(`${this.getMobileDetailsByMobileIdUrl}`+'?pId=' + pId+'&mobileMoneyId='+id).pipe(
  map(this.extractData)
)
}

public getCashCollectionByCashCollectionId(id:any,pId:string): Observable<any> {
console.log('getCashCollectionByCashCollectionId....', id);
return this._http.get(`${this.getCashCollectionByCashCollectionIdUrl}`+'?pId=' + pId+'&cashCollectionId='+id).pipe(
  map(this.extractData)
)
}

public updateBankDepositById(id:any,pId:string,record:any): Observable<any> {
console.log('updateBankDepositById....', id);
return this._http.post(`${this.updateBankDepositByIdUrl}`+'?pId=' + pId+'&accountId='+id,record).pipe(
  map(this.extractData)
)
}

public updateMobileMoneyById(id:any,pId:string,record:any): Observable<any> {
console.log('updateMobileMoneyById....', id);
return this._http.post(`${this.updateMobileMoneyByIdUrl}`+'?pId=' + pId+'&mobileMoneyId='+id,record).pipe(
  map(this.extractData)
)
}

public updateCashCollectionById(id:any,pId:string,record:any): Observable<any> {
console.log('updateCashCollection....', id);
return this._http.post(`${this.updateCashCollectionByUrl}`+'?pId=' + pId+'&cashCollectionId='+id,record).pipe(
  map(this.extractData)
)
}

}
