<div class="container">
  <form [formGroup]="exchRateForm">
    <div style="margin-top: 2%"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="mtp"><h4 class="">exchage rates</h4></div>

            <div class="row">
              <div class="col-md-12 form-group">
                <div>
                  <table class="table table-bordered table-responsive-xxl">
                    <thead>
                      <tr>
                        <th>Source Agent</th>
                        <th>Dest Agent</th>
                        <th>Payment Type</th>
                        <th>Source Value</th>
                        <th>Dest Value</th>
                        <th>Admin Markup</th>
                        <!-- <th>Admin Rate</th>
                    <th>Sorce Markup</th> -->
                        <th>Sell Rate</th>
                        <th>Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of List">
                        <td>{{ list.srcUserName }}</td>
                        <td>{{ list.destUserName }}</td>
                        <td>{{ list.benifPayTypeName }}</td>
                        <td>
                          {{ list.srcCurrencyValue }} {{ list.srcCurrencyName }}
                        </td>
                        <td>
                          <div
                            contenteditable="true"
                            class="div"
                            (input)="
                              onRowClick1($event, list.destCurrencyValue)
                            "
                          >
                            {{ list.destCurrencyValue }}
                          </div>
                          {{ list.destCurrencyName }}
                        </td>
                        <td>
                          <div
                            contenteditable="true"
                            class="div"
                            (input)="onRowClick3($event, list.markUpValue)"
                          >
                            {{ list.markUpValue }}
                          </div>
                        </td>
                        <!-- <td>{{list.adminRate}} {{list.destCurrencyName}}</td> -->
                        <!-- <td>  <div class="div" contenteditable='true'  (input)="onRowClick($event, list.srcMarkUpValue)">{{list.srcMarkUpValue}}</div>
                    </td> -->
                        <td>{{ list.sellRate }} {{ list.destCurrencyName }}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="onUpdate(this.list)"
                            name="button"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
