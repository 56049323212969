
<style media="screen">
	.bg-darkk{
		opacity:0.8;
	        background: linear-gradient(to bottom, #3b64bf 0%,#142854 100%);

	}
	.owl-carousel .owl-item {
    min-height: 1px;
    float: left;
    padding: 30px;}
		@media screen and (max-width: 768px){
		  .cimg{
		    width:50px;
		  }
		}
			@media screen and (min-width: 1221px){
		.countryimagse .cimg{
		  	width:100%;
		}
	}
		.navbar-brand img {
    
    /* max-height: 42px; */
    max-height: 70px;
}
</style>
<div class="section-8">
	<div class="container" style="">
		<div class="row justify-content-center">
			<div class="col-md-10 offset-1">
				<div class="row">
					<div class="col-md-6">
					<div class="title5">QUICK LINKS</div><br>

				<p class="p"><a href="javascript:void(0)" (click)="opensendmoney()" style="color:white;">SEND MONEY</a> </p>
				<p class="p"><a href="javascript:void(0)"  style="color:white;" (click)="openrefund()"> REFUND POLICY </a>  </p>
				<p class="p"><a  href="javascript:void(0)" (click)="openuseraggrement()"  style="color:white;">TERMS AND CONDITIONS</a>  </p>
				<p class="p"><a href="javascript:void(0)" (click)="openprivacypolicy()"  style="color:white;">PRIVACY POLICY</a>  </p>
				<p class="p"><a href=""  style="color:white;">REFER AND WIN</a>  </p>
				<!-- <p class="p"><a href="javascript:void(0)" (click)="openuseraggrement()" style="color:white;">USER AGGREMENT</a>  </p> -->



					</div>
					<div class="col-md-6">
					<div class="title5">COMPANY</div><br>
					<p class="p">
						<a  href="javascript:void(0)" style="color:white;" (click)="openaboutus()">
							ABOUT US
						</a>
						</p>
					<p class="p">
						<a href="javascript:void(0)" style="color:white;" (click)="opencontact()">
							CONTACT US
						</a>
					</p>
					<p class="p">
						<a href="javascript:void(0)" style="color:white;" (click)="openfaqs()">
							FAQS
						</a>
						</p>
					<p class="p">
						<a href="javascript:void(0)" style="color:white;" (click)="openhelp()">
							HOW IT WORKS
						</a>
						</p>

					<p class="p">
						<a href="https://tayrexchange.com/#/signup" style="color:white;">
							GET STARTED
						</a>
						</p>
					</div>


				</div>

			</div>

		</div>
		<div class="row">
			<div class="col-md-1"></div>
			<div class="col-md-10">
				<div class="row">

					<div class="col-md-6">
						<img src="/assets/images/logo-png.png" width="100%" alt="">

					</div>
					<div class="col-md-4">
						<label class="" style="margin-top:30px" for="">&nbsp;</label>

						<a href="https://api.whatsapp.com/send?phone=61408392696&text=&source=&data=&app_absent=" class="btn btn-danger btn-block">CHAT WITH US NOW</a>
					</div>
				</div>

			</div>


		</div>

	</div>


</div>
