<!-- Login Reg In start -->
<section class="log-reg login">
  <div class="overlay">
    <div class="container">
      <div class="top-head-area mb-2">
        <div class="row d-flex align-items-center">
         
          <div class="col-sm-6 col">
            <a href="javascript:void(0)" (click)="openHome()">
              <img src="assetsnew/img/logo-png.png" class="w-100px"  alt="logo"  width="55%"/>
            </a>
          </div>

          <div class="col-sm-6 col text-end">
            <!-- <a
              class="back-home text-light"
              href="javascript:void(0)"
              (click)="openHome()"
            >
              <img src="assetsnew/img/left-icon.png" alt="back to home" />
              Back To Home
            </a> -->
          </div>

        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-5">
          <div class="img-area">
            <img src="assetsnew/img/login-bg.png" alt="image" />
          </div>
        </div>
        <div
          class="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120"
        >
          <div class="form-box">
            <h4>Log in to Tayrex</h4>
            <p class="dont-acc">
              Don't have an account?
              <a href="javascript:void(0)" routerLink="/signup">Register</a>
            </p>

            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="personal"
                role="tabpanel"
                aria-labelledby="personal-tab"
              >
                <div class="login-with d-flex align-items-center"></div>

                <form [formGroup]="userForm">
                  <input
                    type="hidden"
                    name="_token"
                    value="Q9CkLyOP4M0uGKjLx7liPFBMIaX5vuIJjAWFenAB"
                  />
                  <div class="row">
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="email"
                          placeholder="Email"
                          formControlName="userName"
                          name="Email"
                          id="Email"
                          aria-describedby="emailHelp"
                          value=""
                          placeholder="Username"
                          [ngClass]="{
                            'is-invalid': submitted && f.userName.errors
                          }"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="password"
                          class="passInput"
                          placeholder="Password"
                          [type]="fieldTextType ? 'text' : 'password'"
                          formControlName="password"
                          name="Password"
                          id="Password"
                          [ngClass]="{
                            'is-invalid': submitted && f.password.errors
                          }"
                          value=""
                        />
                        <img
                          class="showPass"
                          src="assetsnew/img/show-hide.png"
                          alt="toggle password"
                          (click)="toggleFieldTextType()"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                    <div
                      *ngIf="invalidResponseFlag"
                      class="col-md-12 text-center"
                    >
                      <span style="color: red; text-align: center">{{
                        invalidResponse
                      }}</span>
                    </div>
                  </div>

                  <div class="remember-forgot d-flex justify-content-between">
                    <div class="form-group d-flex">
                      <div class="checkbox_wrapper">
                        <input class="check-box" id="check1" type="checkbox" />
                        <label></label>
                      </div>
                      <label for="check1"
                        ><span class="check_span">Remember Me</span></label
                      >
                    </div>
                    <div class="forget-pw">
                      <a href="javascript:void(0)" (click)="onforget()"
                        >Forgot your password?</a
                      >
                    </div>
                  </div>
                  <div class="btn-area">
                    <button
                      class="cmn-btn"
                      *ngIf="isloader"
                      (click)="onSubmit()"
                    >
                      Log in
                    </button>

                    <div class="row" *ngIf="!isloader">
                      <div class="col-md-12 text-center">
                        <img class="mb-mode" src="assets/images/dots-loader.gif" width="20%" alt="" />
                      </div>
                    </div>
                  </div>
                </form>

                <form class="form-auth-small" [formGroup]="AccessLogForm">
                  <input
                    type="hidden"
                    formControlName="ipAddress"
                    name="ipAddress"
                    id="ipAddress"
                  />
                </form>
              </div>
              <div
                class="tab-pane fade"
                id="business"
                role="tabpanel"
                aria-labelledby="business-tab"
              >
                <div class="login-with d-flex align-items-center">
                  <a href="javascript:void(0)"
                    ><img src="assetsnew/img/google.png" alt="image"
                  /></a>
                  <a href="javascript:void(0)"
                    ><img src="assetsnew/img/fb.png" alt="image"
                  /></a>
                </div>
                <div class="continue">
                  <p>Or continue with</p>
                </div>
                <form action="#">
                  <div class="row">
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input type="email" placeholder="Business email" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="single-input d-flex align-items-center">
                        <input
                          type="password"
                          class="passInput"
                          placeholder="Password"
                        />
                        <img
                          class="showPass"
                          src="assetsnew/img/show-hide.png"
                          alt="image"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="remember-forgot d-flex justify-content-between">
                    <div class="form-group d-flex">
                      <div class="checkbox_wrapper">
                        <input class="check-box" id="check2" type="checkbox" />
                        <label></label>
                      </div>
                      <label for="check2"
                        ><span class="check_span">Remember Me</span></label
                      >
                    </div>
                    <div class="forget-pw">
                      <a routerLink="/forgot-password">Forgot your password?</a>
                    </div>
                  </div>
                  <div class="btn-area">
                    <button class="cmn-btn">Log in</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
