<header
class="header-section fixed"
>
<div class="overlay">
  <div class="container">
    <div class="row d-flex header-area">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="navbar-brand" routerLink="/home">
          <img
            src="assetsnew/images/logo-light.png"
            class="logo"
            alt="logo"
            loading="lazy"
          />
        </a>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-content"
        >
          <i class="fas fa-bars"></i>
        </button>
        <div
          class="collapse navbar-collapse justify-content-end"
          id="navbar-content"
        >
          <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown main-navbar">
              <a
                class="nav-link"
                href="javascript:void(0)"
                (click)="openaboutus()"
                >About Us</a
              >
            </li>
            <li class="nav-item dropdown main-navbar">
              <a
                class="nav-link"
                href="javascript:void(0)"
                (click)="opencontact()"
                >Contact Us</a
              >
            </li>
            <li class="nav-item dropdown main-navbar">
              <a
                class="nav-link"
                href="javascript:void(0)"
                (click)="openfaqs()"
                >Faqs</a
              >
            </li>
            <li class="nav-item dropdown main-navbar">
              <a
                class="nav-link"
                href="javascript:void(0)"
                (click)="openhelp()"
                >How it works</a
              >
            </li>
            <li class="nav-item dropdown main-navbar">
              <a
                href="javascript:void(0)"
                (click)="login()"
                class="nav-link"
                >Login</a
              >
            </li>
          </ul>
          <div
            class="right-area header-action d-flex align-items-center max-un"
          >
            <a
              href="javascript:void(0)"
              (click)="opensignup()"
              class="cmn-btn command_btn "
              >Sign Up
              <i class="icon-d-right-arrow-2"></i>
            </a>
          </div>
        </div>
      </nav>
    </div>
  </div>
</div>
</header> 
 
 
 <!-- The Modal -->
 <!-- <div *ngIf="deletepopup" class="modal" id="myModal">
 <div  >
    <div class="modal-dialog">
      <div class="modal-content" style="padding: 10px">
        
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <p>
                You are now going to delete this account , you will no longer
                be able to login again and all your data will be deleted.
              </p>
  
              <br />
              <div class="row">
                <div class="col-md-6">
                  <button
                    class="btn btn-success btn-block"
                    data-dismiss="modal"
                    (click)="deleteAccount()"
                  >
                    Yes
                  </button>
                </div>
                <div class="col-md-6">
                  <button
                    class="btn btn-danger btn-block"
                    data-dismiss="modal"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div> -->
  
  
  <div *ngIf="deletepopup">
    <div  class="container mt-5 position-absolute col-12" style="z-index: 6;">
    <div class="col-md-12 d-flex justify-content-center">
      <div class="col-md-6">
        <div class="white-box">
          <h3> You are now going to delete this account , you will no longer
            be able to login again and all your data will be deleted.</h3>
          <div class="row pt-2">
            <div class="col-md-6">
              <div
                class="btn btn-success btn-block"
                (click)="deleteAccount()"
              >
                Yes
              </div>
            </div>
            <div class="col-md-6">
              <div
                class="btn btn-danger btn-block"
                (click)="no()"
              >
                No
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

<div  *ngIf="!deletepopup" class="container pt-5 mt-5">

    <div class="col-md-12 d-flex justify-content-center">
    <div  class="col-md-6 send-transfer-base">

            <form class="form-auth-small" [formGroup]="userForm">
                <input
                  type="hidden"
                  name="_token"
                  value="Q9CkLyOP4M0uGKjLx7liPFBMIaX5vuIJjAWFenAB"
                />
                <div class="form tab-content log-tabb">
                  <div class="form-group snd-log-lable">
                    <label for="Email" class="text-left">Username/Email</label>
                    <input
                      type="text"
                      formControlName="userName"
                      name="Email"
                      id="Email"
                      class="form-control log-form-custom"
                      aria-describedby="emailHelp"
                      value=""
                      placeholder="Username"
                      [ngClass]="{ 'is-invalid': submitted && f.userName.errors }"
                      required=""
                    />
                  </div>
                  <label for="Password" class="text-left">Password</label>
                  <div class="form-group snd-log-lable input-group">
                    <input
                      type="password"
                      [type]="fieldTextType ? 'text' : 'password'"
                      formControlName="password"
                      name="Password"
                      id="Password"
                      class="form-control log-form-custom"
                      [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                      value=""
                      placeholder="Password"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">
                        <i
                          class="fa"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div *ngIf="invalidResponseFlag" class="col-md-12 text-center">
                    <span style="color: red; text-align: center">{{
                      invalidResponse
                    }}</span>
                  </div>
                  <div class="form-group mt-4">
                    <div class="col-md-12 pad-zero">
                      <!-- <div class="pull-left">
                        <label class="checkbox-inline">
                          <input
                            type="checkbox"
                            name="remember"
                            formControlName="rememberme"
                            value="rememberme"
                            id="remember"
                          />
                          Remember me
                        </label>
                      </div> -->
                      <!-- <div class="pull-right for-got">
                        <a href="javascript:void(0)" (click)="onforget()"
                          >Reset password?</a
                        >
                      </div> -->
                    </div>
                  </div>
                  <div class="col-12 pad-zero" *ngIf="isloader">
                    <div>
                      <div data-toggle="modal" data-target="#myModal">
                        <button type="submit" id="loginbutton" (click)="onSubmit()" class="btn tay-btn btn-block">
                                <span class="text-white">Delete account</span>
                              </button>
                            </div>
                    </div>
                </div>
                <div class="col-md-12 d-flex justify-content-end mt-4">
                  <div class="back-btn" (click)="login()">
                    <div class="col-4">
                      <div class="btn btn-danger" style="border-radius: 8px !important;">
                        Back
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="row" *ngIf="!isloader">
                    <div class="col-md-12 text-center">
                      <img class="mb-mode" src="assets/images/dots-loader.gif" width="40%" alt="" />
                    </div>
                  </div>
                </div>
              </form>
        </div>
    
    </div>
</div>


<!-- <app-new-footer></app-new-footer> -->