<style media="screen">
  th {
    background: #4285f4;
    color: white;
  }
  .table th {
    padding: 3px;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
  }
  th,
  td {
    text-align: center;
  }
</style>

<div class="container">
  <form [formGroup]="srcExchRateForm">
    <div style="margin-top: 2%"></div>

    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="mtp"><h4 class="">source agent exchange rate</h4></div>

            <div class="row">
              <div class="col-md-12 form-group">
                <div>
                  <table class="table table-bordered table-responsive-xxl">
                    <thead>
                      <tr>
                        <th>SOURCE AGENT</th>
                        <th>DEST AGENT</th>
                        <th>PAYMENT TYPE</th>
                        <th>SOURCE VALUE</th>
                        <th>ADMIN RATE</th>
                        <th>SOURCE MARKUP</th>
                        <th>SELL RATE</th>
                        <th>UPDATE</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let list of List">
                        <td>{{ list.srcUserName }}</td>
                        <td>{{ list.destUserName }}</td>
                        <td>{{ list.benifPayTypeName }}</td>
                        <td>
                          {{ list.srcCurrencyValue }} {{ list.srcCurrencyName }}
                        </td>
                        <td>
                          {{ list.adminRate }} {{ list.destCurrencyName }}
                        </td>
                        <td>
                          <div
                            class="div"
                            contenteditable="true"
                            (input)="onRowClick($event, list.srcMarkUpValue)"
                          >
                            {{ list.srcMarkUpValue }}
                          </div>
                        </td>

                        <td>{{ list.sellRate }}{{ list.destCurrencyName }}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-primary"
                            (click)="onUpdate(this.list)"
                            name="button"
                          >
                            Update
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
