import { Injectable } from '@angular/core';

import {HttpHeaders,HttpClient,HttpErrorResponse} from '@angular/common/http';
import {Observable,throwError} from 'rxjs';
import { catchError, map} from 'rxjs/operators';

import { Router,ActivatedRoute} from '@angular/router';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AlertLognService {
  apiUrl=environment.baseUrl
  public loginUrl : string = `${this.apiUrl}/user/login`;



    httpOptions = {
          headers : new HttpHeaders ({'content-type' : 'application/json'})
      };
      constructor(  private _http:HttpClient,private router :Router,private _route:ActivatedRoute) { }


        private  extractData( res: Response ) : Array <any> | {} {
          const body = res;
          console.log('res ',res)
          return body || {} ;
         }



         public getAlert(): Observable <any>{
           return this._http.post(this.loginUrl,this.httpOptions)
         }
}
