import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {
  TransactionService,
  LogOutService,
  LoginserviceService,
} from '../../service';
import { ProcessIdGeneraterService } from '../../service';
import { AccessLogService } from '../../service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-frontdashboard',
  templateUrl: './frontdashboard.component.html',
  styleUrls: ['./frontdashboard.component.css'],
})
export class FrontdashboardComponent implements OnInit {
  isnaviagationbar: boolean;
  isindexnavbar: boolean;
  isShow: boolean;
  userLastName: any;
  userFirstName: any;
  senderdetailsnavbar: boolean;
  userId: any;
  beneficiaryFlag: boolean;
  senderFlag: any;
  documentsValidDate: boolean;
  idVerifiedStatusFlag: any;
  mobileShow: boolean;
  AccessLogForm: FormGroup;
  constructor(
    public router: Router,

    private _transactionService: TransactionService,
    private processIdGeneraterService: ProcessIdGeneraterService,
    private _route: ActivatedRoute,
    private _accessLogService: AccessLogService,
    private _loginService: LoginserviceService,
    private logoutService: LogOutService
  ) {}

  ngOnInit(): void {
    this.senderFlag = localStorage.getItem('senderFlag');
    this.idVerifiedStatusFlag = localStorage.getItem('idVerifiedStatusFlag');
    this.AccessLogForm = new FormGroup({
      ipAddress: new FormControl(''),
      userType: new FormControl(''),
      userName: new FormControl(''),
      acessResult: new FormControl(''),
      userid: new FormControl(''),
    });

    this.isindexnavbar = false;
    this.senderdetailsnavbar = true;
    this.userId = localStorage.getItem('userId');
    this._transactionService
      .getCustomerById(this.userId, this.processIdGeneraterService.getPid())
      .subscribe(
        (res) => {
          if (res) {
            var d = new Date();
            var e = new Date(res.response.validTo);
            var diff = e.getTime() - d.getTime();
            var diffDays = Math.floor(diff / (1000 * 3600));

            if (diffDays < 7) {
              this.documentsValidDate = true;
            } else {
              this.documentsValidDate = false;
            }

            this.senderFlag = 'full';
            if (res.response.identityVerifiedStatus === 'Verified') {
              this.idVerifiedStatusFlag = 'true';
            } else {
              this.idVerifiedStatusFlag = 'false';
            }
            console.log('user', res.response);
            this.userFirstName = res.response.firstName;

            this.userLastName = res.response.lastName;
            localStorage.setItem(
              'fullName',
              this.userFirstName + ' ' + this.userLastName
            );
          }
        },
        (err) => {
          this._loginService
            .getUserDetailsById(
              this.userId,
              this.processIdGeneraterService.getPid()
            )
            .subscribe((res) => {
              if (res) {
                this.idVerifiedStatusFlag = 'false';
                this.senderFlag = 'partial';
                this.userFirstName = res.response.firstName;

                this.userLastName = res.response.lastName;
              }
            });
        }
      ); //
    // if(this._route.routeConfig.path=='/login'  ){
    //
    // this.isShow=false;
    // this.senderdetailsnavbar=true;
    // }
    // if(this._route.routeConfig.path== '/signup'  ){
    //
    // this.isShow=false;
    // this.senderdetailsnavbar=true;
    // }
    // else{
    //   this.isShow=true;
    // }
  }
  onShow() {
    this.mobileShow = !this.mobileShow;
  }

  onSend() {
    alert('s');
    if (this.documentsValidDate) {
      alert(
        'Sorry, Your id proof is under review and once approved you will be able to make a transaction'
      );
    }
    if (this.idVerifiedStatusFlag === 'true') {
      if (this.senderFlag === 'full') {
        this.router.navigate(['/senderdetails']);
      } else {
        alert(
          'Sorry, Your id proof is under review and once approved you will be able to make a transaction'
        );
      }
    } else {
      alert(
        'Sorry, Your id proof is under review and once approved you will be able to make a transaction'
      );
    }
  }
  onTransaction() {
    if (this.idVerifiedStatusFlag === 'true') {
      if (this.senderFlag === 'full') {
        this.router.navigate(['/trlist']);
      } else {
        alert(
          'Sorry, Unable to list Transactions as there are no transactions available to list'
        );
      }
    } else {
      alert(
        'Sorry, Unable to list Transactions as there are no transactions available to list'
      );
    }
  }
  onBenif() {
    this.router.navigate(['/recipient']);
    //   if(this.idVerifiedStatusFlag==='true'){
    //     if(this.senderFlag==='full'){
    //       this.router.navigate(['/recipient'])
    //     }
    //     else{
    //       alert('Sorry, Unable to list recipient')
    //     }
    //
    // }
    // else{
    //   alert('Sorry, Unable to list recipient')
    // }
  }

  logout() {
    if (
      localStorage.getItem('accessId') != null &&
      localStorage.getItem('accessId') != 'undefined'
    ) {
      this._accessLogService
        .getAccessLogByAccessIdAndUserId(
          localStorage.getItem('accessId'),
          localStorage.getItem('userId'),
          this.processIdGeneraterService.getPid()
        )
        .subscribe(
          (res) => {
            if (res) {
              console.log('accesslog....', res.response);
              this._accessLogService
                .updateAccessLogByAccessId(
                  localStorage.getItem('accessId'),
                  this.processIdGeneraterService.getPid()
                )
                .subscribe(
                  (res) => {
                    if (res) {
                      console.log('accesslog.....', res.response);
                      localStorage.setItem('accessId', res.response.accessId);
                      localStorage.clear();
                      this.router.navigate(['/']);
                      this.logoutService.logOut().subscribe(
                        (res) => {
                          if (res) {
                          }
                        },
                        (error) => {
                          console.log(error);
                          localStorage.clear();
                          this.router.navigate(['/']);
                        }
                      ); //logout closed
                    } //if closed
                  },
                  (error) => {
                    console.log(error);
                    localStorage.clear();
                    this.router.navigate(['/']);
                  }
                ); //update accesslog closed
            } //if closed
          },
          (error) => {
            console.log(error);
            localStorage.clear();
            this.router.navigate(['/']);
          }
        ); //get accesslog closed
    } //if access id closed
    else {
      localStorage.clear();
      this.router.navigate(['/']);
    }
  }
  onindex() {
    this.router.navigate(['index']);
  }
  omhome() {
    this.router.navigate(['/']);
  }
  onforget() {
    this.router.navigate(['/forget']);
  }
}
