import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';



@Injectable({
  providedIn: 'root'
})
export class TokenServiceService {

  apiUrl=environment.baseUrl;
  public baseUrl :string =`${this.apiUrl}/branch`;
  public apiUrl7: string = `${this.apiUrl}/branch/getBranchInfo`;
  public insertUrl2: string = `${this.apiUrl}/user/agentCreationWithNewBranch`;
  public insertUrl1: string = `${this.apiUrl}/user/createAgentWithExistingBranch`;
  public saveAgentColtnMrkpUrl:string=`${this.apiUrl}/agent/createAgentCollectionMarkup`;
  public updateApi:string = `${this.apiUrl}/utility/updateEmailTemplateDetails`;
  public updatesorceofFoundApi:string =`${this.apiUrl}/utility/updateSourceOfFundDetails`;
  public postsorceofFoundApi:string =`${this.apiUrl}/utility/setSourceOfFundDetails`
  public postRelationAhipApi:string = `${this.apiUrl}/utility/setRelationShipDetails`;
  public updateRelationApi:string = `${this.apiUrl}/utility/updateRelationShipDetails`;
  public saveMaxAmountDetails: string=`${this.apiUrl}/utility/saveMaxAmountDetails`;
  public updateMaxAmountDetails: string=`${this.apiUrl}/utility/updateMaxAmountDetails`;
  public saveMappingUrl: string = `${this.apiUrl}/agent/saveAgentMapping`;
  public updateRevenuePercentageDetails:string=`${this.apiUrl}/utility/updateRevenuePercentageDetails`;
  public updateUrl:string=`${this.apiUrl}/exchRate/updateExchangeRateById`;
  public createTxnUrl1: string=`${this.apiUrl}/transaction/createTransactionWithExistCustomer`;
  public createTxnWithIdProofUrl: string=`${this.apiUrl}/transaction/createTransactionWithNewCustomerWithIdProofs`;
  public saveAgentDepositData:string=`${this.apiUrl}/agent/createAgentDeposits`;
  public customerApi:string = `${this.apiUrl}/customer/createBackendCustomer`;
  public saveCshCollDetail: string=`${this.apiUrl}/transaction/saveCashCollInfo`;
  public apiUrl5:string=`${this.apiUrl}/exchRate/updateMasterExchangeRates`;
  public agentListUr: string = `${this.apiUrl}/user/getAgentsList`;
  public apiUrl2: string = `${this.apiUrl}/keyclock/getClientCountrys`;
  public apiUrl6: string = `${this.apiUrl}/branch/getBranchesList`;
  public baseUrl12 :string =`${this.apiUrl}/branch/updateBranchDetails`;
    constructor(private _http:HttpClient) { }
    private  extractData( res: Response ) : Array <any> | {} {
      const body = res;
      console.log('res ',res)
      return body || {} ;
     }

     httpOptions = {
       headers : new HttpHeaders ({'content-type' : 'application/json'})
       //.set('Authorization', 'Bearer ' +localStorage.getItem("datas"))
     };

     //this method is used for create new branch
      public createBranch(branchRecord:any,pId : string): Observable <any>{
           return this._http.post(`${this.baseUrl}/createBranch`+'?pId='+pId,branchRecord,this.httpOptions)
           .pipe(
           map(this.extractData)
           );
     }


     public editUser(userId:number,branchRecord:any,pId : string){
       return this._http.put(`${this.baseUrl12}`+'?pId='+pId+'&branchId='+userId,branchRecord,this.httpOptions).pipe(
           map(this.extractData)
       )
     }


     public getBranchIn(branchId: any, pId: string): Observable<any> {
       return this._http.get(`${this.apiUrl7}/${branchId}` + '?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public getBranch(pId: string): Observable<any> {
       return this._http.get(`${this.apiUrl6}` + '?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       );
     }

     public saveAgentWithExistingBranch(Record: any, pId: string): Observable<any> {
       return this._http.post(this.insertUrl1 + '?pId=' + pId, Record, this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public saveAgentWithNewBranch(recordList: any, pId: string): Observable<any> {
       return this._http.post(this.insertUrl2 + '?pId=' + pId, recordList, this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public getClientCountrys(token: any, pId: string): Observable<any> {

       return this._http.get(`${this.apiUrl2}` + '?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       );
     }

     public getAgentList(pId: string): Observable<any> {

       return this._http.get(`${this.agentListUr}` + '?pId=' + pId, this.httpOptions).pipe(
         map(this.extractData)
       );
     }


     public updateMasterExchangeRate(branchRecord:any,pId:string,masterExchangeRateId:number){

           return this._http.put(`${this.apiUrl5}`+'?pId='+pId+'&id='+masterExchangeRateId,branchRecord,this.httpOptions).pipe(
           map(this.extractData)
       )
     }

     public saveCshCollDetails(cshRecord: any,pId:string): Observable<any> {
   //console.log("json ..!"+JSON.Stringify(txnRecord));
       return this._http.post(this.saveCshCollDetail + '?pId=' + pId, cshRecord, this.httpOptions).pipe(
         map(this.extractData)
       );
     }



       public postCustomer(pId:string,record:any):Observable<any>{
         return this._http.post(`${this.customerApi}`+'?pId='+pId,record,this.httpOptions).pipe(
           map(this.extractData)
         )
       }

     public createAgentDepositDetails(agentDepositRecord: any,pId:string): Observable<any> {
      console.log("agent deposits.."+JSON.stringify(agentDepositRecord))
      console.log("agent.! "+this.saveAgentDepositData)
      return this._http.post(this.saveAgentDepositData + '?pId=' + pId, agentDepositRecord, this.httpOptions).pipe(
          map(this.extractData)
              );
              }

     //createTxnWithIdProofUrl
     public createTxnWithIdProoF(txnRecord: any,pId:string): Observable<any> {
       return this._http.post(this.createTxnWithIdProofUrl + '?pId=' + pId, txnRecord).pipe(
         map(this.extractData)
       );
     }
     public createTxnWithExistCustomer(txnRecord: any,pId:string): Observable<any> {
       return this._http.post(this.createTxnUrl1 + '?pId=' + pId, txnRecord, this.httpOptions).pipe(
         map(this.extractData)
       );
     }

     public updateExchangeRateById(exchangeRecord:any,pId:string,exchRateId:number){
       return this._http.post(`${this.updateUrl}`+'?pId='+pId+'&exchRateId='+exchRateId,exchangeRecord,this.httpOptions).pipe(
           map(this.extractData)
       )
     }

     //this method is used for create new branch
      public updateRevPercDetails(pId : string,revRecord:any,revenuePercentageId:any): Observable <any>{
         return this._http.post(`${this.updateRevenuePercentageDetails}`+'?pId='+pId+'&revenuePercentageId='+revenuePercentageId,revRecord,this.httpOptions).pipe(
           map(this.extractData)
           );
     }
     public createAgentMapping(mappingRecord: any,pId:string): Observable<any> {

       return this._http.post(this.saveMappingUrl + '?pId=' + pId, mappingRecord, this.httpOptions).pipe(
         map(this.extractData)
       );
     }

     public updateMaxAmount(maxAmountRecord:any,pId : string,maxAmountId:string){

       return this._http.post(`${this.updateMaxAmountDetails}`+'?pId='+pId+'&maxAmountId='+maxAmountId,maxAmountRecord,this.httpOptions).pipe(
           map(this.extractData)
       )
     }

     public saveMaxAmount(maxAmountRecord:any,pId : string){
       return this._http.post(`${this.saveMaxAmountDetails}`+'?pId='+pId,maxAmountRecord,this.httpOptions).pipe(
           map(this.extractData)
       )
     }

     public createAgentCollectionMarkup(agentColtnMrkpRecord: any,pId:string): Observable<any> {

       return this._http.post(this.saveAgentColtnMrkpUrl + '?pId=' + pId, agentColtnMrkpRecord, this.httpOptions).pipe(
         map(this.extractData)
       );
     }
     public updateEmailList(pId:string,emailTemplateId:any,record:any):Observable<any>{
       return this._http.put(`${this.updateApi}`+'?pId='+pId+'&emailTemplateId='+emailTemplateId,record,this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public updateSourceofFound(pId:string,sourceOfFundId:any,record:any):Observable<any>{
       return this._http.put(`${this.updatesorceofFoundApi}`+'?pId='+pId+'&sourceOfFundId='+sourceOfFundId,record,this.httpOptions).pipe(
         map(this.extractData)
       )
     }

     public postSourceofFound(record:any,pId:string):Observable<any>{
       return this._http.post(`${this.postsorceofFoundApi}`+'?pId='+pId,record,this.httpOptions).pipe(
         map(this.extractData)
       )
     }
     public postrelationList(pId:string,record:any):Observable<any>{
       return this._http.post(`${this.postRelationAhipApi}`+'?pId='+pId,record,this.httpOptions).pipe(
         map(this.extractData)
       )
     }

     public updateRelationList(pId:string,relationShipId:any,record:any):Observable<any>{
       return this._http.put(`${this.updateRelationApi}`+'?pId='+pId+'&relationShipId='+relationShipId,record,this.httpOptions).pipe(
         map(this.extractData)
       )
     }
}
