import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class InternetbanktransferService {
  apiUrl=environment.baseUrl;
  public internetGetListApi:string =`${this.apiUrl}/utility/getInternetBankTransferList`;
  public internetGetListingByApi :string = `${this.apiUrl}/utility/getInternetBankTransferById`;
  public postInternetBankdetailApi :string = `${this.apiUrl}/utility/saveInternetBankTransferDetails`;
  public updateInternetBankdetails : string = `${this.apiUrl}/utility/updateInternetBankTransferDetails`;
  httpOptions = {
        headers : new HttpHeaders ({'content-type' : 'application/json'}).set('Authorization', localStorage.getItem("datas"))
  };


  constructor(private _http:HttpClient) { }

  public extractData(res:Response):Array<any> | {}{
    const body=res;
    console.log('res',res)
    return body || {};
  }

  public getInternetBankTransferList(pId:string):Observable<any>{
    return this._http.get(`${this.internetGetListApi}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getInternetBanklistbyId(pId:string,internetBankTransferId:any):Observable<any>{
    return this._http.get(`${this.internetGetListingByApi}`+'?pId='+pId+'&internetBankTransferId='+internetBankTransferId).pipe(
      map(this.extractData)
    )
  }

  public postInternetBanklist(pId:string,record:any):Observable<any>{
    return this._http.post(`${this.postInternetBankdetailApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateInternetBanklist(pId:string,internetBankTransferId:any,record:any):Observable <any>{
    return this._http.put(`${this.updateInternetBankdetails}`+'?pId='+pId+'&internetBankTransferId='+internetBankTransferId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
}
