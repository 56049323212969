
<style media="screen">
	.bg-dark{
			opacity:1;
		        background: linear-gradient(to bottom, #3b64bf 0%,#142854 100%);

		}
</style>
<div class=" be-fixed-sidebar " >
		<nav class="navbar navbar-expand fixed-top be-top-header bg-dark">
			<div class="container-fluid">
				<div class="be-navbar-header">
					<a class="navbar-brand" href="javascript:void(0)"  routerLink="index"><img class="img" src="/assets/images/logo-png.png"></a>
				</div>
				<!-- <div class="page-title text-white"><span> Activities</span></div> -->
				<div class="be-right-navbar">
					<div class="mobile-brand mr-auto"> <a href="javascript:void(0)" routerLink="index" ><img src="/assets/images/logo-png.png" class="img"> </a></div>
					<ul class="nav navbar-nav float-right be-user-nav">
						<!--<li class="nav-item show-min-768">
                        <a class="nav-link" href="#"> About ABC money </a>
                         </li>-->
						<li class="nav-item show-min-768 text-white">
							 <a class="nav-link mr-15 text-white" href="javascript:void(0)"  routerLink="customersupport">Customer Support?
                        </a> </li>
						<li class="nav-item dropdown mob-mr-20">
							<a class="nav-link dropdown-toggle" href="" data-toggle="dropdown" role="button" aria-expanded="false">
								 <img src="/assets/images/avatar.svg" alt="Avatar">
								 <span class="user-name pro-down-action text-white">{{userFirstName | titlecase}}</span> </a>
							<div class="dropdown-menu" role="menu">
								<div class="user-info">
									<div class="user-name">{{userFirstName}} {{userLastName}}</div>
									<div class="user-position online">Available</div>
								</div>
								<a class="dropdown-item" href="javascript:void(0)" routerLink="myprofile" > <i class="fas fa-user-circle fa-dr-user-pro"></i> Profile</a>
								<a class="dropdown-item" href="javascript:void(0)" routerLink="changepassword"> <i class="fas fa-lock fa-dr-user-pro"></i> Change Password</a>
								<a class="dropdown-item" href="javascript:void(0)" (click)="logout()" > <i class="fas fa-sign-out-alt fa-dr-user-pro"></i> Logout</a>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</nav>
        <br><br><br><br>

<div class="be-left-sidebar" >
			<div class="left-sidebar-wrapper"> <a class="left-sidebar-toggle"  (click)="onShow()"> Dashboard</a>

				<div class="" >
					<div class="left-sidebar-scroll ps " *ngIf="mobileShow" >
						<div class="left-sidebar-content">
							<div class="user-profile-base"> <img src="/assets/images/avatar.svg">
								<div>
											<div class="pro-user-name"> {{userFirstName}} {{userLastName}}
												<br>
											</div> <span>Customer ID: {{userId}}</span> </div>
							</div>
							<ul class="sidebar-elements c-color">
								<li class="divider"> Dashboard Menu</li>
								<li class="active" >
									<a class="gonext" href="javascript:void(0)" routerLink="index"  (click)="onShow()">
										 <i class="fas fa-sliders-h side-bar-font-icon"></i> <span> Dashboard </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onSend()" >
										 <i class="far fa-paper-plane side-bar-font-icon"></i> <span> Send Money </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onTransaction()" (click)="onShow()">
										<i class="fas fa-list side-bar-font-icon"></i> <span> My Transactions </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onBenif()" (click)="onShow()">
										<i class="fas fa-users side-bar-font-icon"></i> <span> Beneficiary </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="documents" (click)="onShow()">
										<i class="fas fa-passport side-bar-font-icon"></i> <span> My Documents </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="myprofile" (click)="onShow()">
										<i class="fas fa-user-circle side-bar-font-icon"></i>
										<span> My Profile </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="customersupport" (click)="onShow()"> <i class="fas fa-user-circle side-bar-font-icon">
									</i> <span> Customer Support </span></a>
								</li>
							</ul>
						</div>
						<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
							<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
						</div>
						<div class="ps__rail-y" style="top: 0px; right: 0px; height: auto;">
							<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
						</div>
					</div>
				</div>
				<div class="left-sidebar-spacer"  >
					<div class="left-sidebar-scroll ps" >
						<div class="left-sidebar-content">
							<div class="user-profile-base"> <img src="/assets/images/avatar.svg">
								<div>
											<div class="pro-user-name"> {{userFirstName}} {{userLastName}}
												<br>
											</div> <span>Customer ID: {{userId}}</span> </div>
							</div>
							<ul class="sidebar-elements c-color">
								<li class="divider"> Dashboard Menu</li>
								<li >
									<a class="gonext" href="javascript:void(0)" routerLink="index">
										 <i class="fas fa-sliders-h side-bar-font-icon"></i> <span> Dashboard </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onSend()" >
										 <i class="far fa-paper-plane side-bar-font-icon"></i> <span> Send Money </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onTransaction()">
										<i class="fas fa-list side-bar-font-icon"></i> <span> My Transactions </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" (click)="onBenif()">
										<i class="fas fa-users side-bar-font-icon"></i> <span> Beneficiary </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="documents">
										<i class="fas fa-passport side-bar-font-icon"></i> <span> My Documents </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="myprofile"> <i class="fas fa-user-circle side-bar-font-icon"></i>
										<span> My Profile </span></a>
								</li>
								<li>
									<a class="gonext" href="javascript:void(0)" routerLink="customersupport"> <i class="fas fa-user-circle side-bar-font-icon">
									</i> <span> Customer Support </span></a>
								</li>
							</ul>
						</div>
						<div class="ps__rail-x" style="left: 0px; bottom: 0px;">
							<div class="ps__thumb-x" tabindex="0" style="left: 0px; width: 0px;"></div>
						</div>
						<div class="ps__rail-y" style="top: 0px; right: 0px; height: auto;">
							<div class="ps__thumb-y" tabindex="0" style="top: 0px; height: 0px;"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>
	
