import { Component, OnInit,Input } from '@angular/core';
import { SignupService } from '../../frontservice';
import { FormGroup,FormControl,Validators} from '@angular/forms';
import { ProcessIdGeneraterService} from '../../service';
import { BranchService } from '../../service';
import { Router,ActivatedRoute} from '@angular/router';
import {TransactionService} from '../../service';
import {AccessLogService } from '../../service';
import { HttpClient  } from '@angular/common/http';
import { AddsenderdetailsService } from '../../frontservice';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

@Component({
  selector: 'app-frontsignup',
  templateUrl: './frontsignupnew.component.html',
  styleUrls: ['./frontsignup.component.css']
})
export class FrontsignupComponent implements OnInit {

  @Input()
maxlength: string | number | null
  emsg2:any;
  customer:any;
  ipAddress:any;
  regiterList: any;
  registerId: any;
  selectedValue: any;
  userId:any;
  emsg:any;
  tryDuplicate:any;
  Array1: any[];
  keyword1='address';
  keyword='full_address';
  signupForm:FormGroup;
  msg:any;
  isValid=null;
  Array: [];
  otpFlag:any;
  signup:any;
  country:any;
  fieldTextType:boolean;
  agent:any;
popUpFlag=false;
  selection: number;
  timeLeft: number;
    interval:any;
sendingAgentId:any;
  dailingCode:any;
  countryName:any;
isloader:boolean;
countryIdd:any;
  countryId:any;
  countryIso:any;
  isshowm:boolean;
  showPassword: boolean = false;
  vemail:string ='[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}';
  submitted=false;
  spassword:string='(?=.*[0-9a-z])(?=.*[a-z])(?=.*[A-Z])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}';
  //spassword:string='^(?=.*[A-Z])(?=.*[%^!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$';
isDisabled:boolean=false;
 public siteKey: string;
  isShowss: boolean;
  confirmPasswordFlag: boolean;
  address: any;
  fullAddress: any;
  tryDuplicates: string;
  isShoww: boolean;
  isShowws: boolean;
  isShowwss: boolean;



  constructor(private _router :Router,private _route:ActivatedRoute,
    private signupService:SignupService,
    private processIdGeneraterService:ProcessIdGeneraterService ,
    private _transactionService:TransactionService,
    private _accessLogService: AccessLogService,
    private http:HttpClient,
    
      public bnIdle: BnNgIdleService,
      private addSenderdetails:AddsenderdetailsService,
    private _branchService:BranchService
  ) {this.siteKey="6Le58CAkAAAAAE73LkrRg-u4yuhe7tsiyqYH2urp" }
srcCountryId:any;
  editable:any;
public ViewList:Array<any>;
sendingAgentsList:{};
public AccessLogForm:FormGroup;

onback(){
  this._router.navigate(['/login'])
}
  ngOnInit(): void {

 
    this.isShowws= true;

    this.getIPAddress();
    this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
      this.ipAddress = res.ip;


    });


    this.AccessLogForm=new FormGroup({
     ipAddress:new FormControl(''),
     userType:new FormControl(''),
     userName:new FormControl(''),
     acessResult:new FormControl(''),
     userid:new FormControl('')

    })

this.popUpFlag=true;
             this.isshowm=false;
        this.msg='';
        let tObj = JSON.parse(localStorage.getItem('newObj'));
        console.log('tObj: ',tObj);
    this.signupForm = new FormGroup({
  countryId:new FormControl(''),
  emailId:new FormControl(''),
  password:new FormControl(''),
  firstName:new FormControl(''),
  userSource:new FormControl(''),
  captcha:new FormControl(''),
  lastName:new FormControl(''),
  sendingAgentId:new FormControl(''),
  nationality:new FormControl(''),
  confirmPassword:new FormControl(''),
  userName:new FormControl('h'),
  placeOfBirth:new FormControl(''),
  phoneNumber:new FormControl('',[Validators.required,Validators.maxLength(13)]),
  countryIdd:new FormControl(''),
  registerId:new FormControl(this.registerId),
  businessName:new FormControl(''),
  businessEmail:new FormControl(''),
  businessPhoneNumber:new FormControl(''),
  businessPassword:new FormControl(''),
  businessAddress:new FormControl(''),
  businessStructure:new FormControl(''),
  businessCategory:new FormControl(''),
    })
this.editable=false;
    var obj1='SEND';
  //  this.countryIdd='aifaif'
//SENDING AGENT LIST BASED ON FUNCTIONALITY
this._transactionService.getRegisterAs(
  this.processIdGeneraterService.getPid()).subscribe(res => {
  
    if (res){
    this.regiterList = res.response;
    this.registerId=this.regiterList[0].registerId;
    this.selectedValue=this.regiterList[0].registerId;
    }
})



this._transactionService.getSendingAgents(
  this.processIdGeneraterService.getPid(),obj1).subscribe(res => {
         if (res){
               this.sendingAgentsList =res.response;
             if(localStorage.getItem("newObj")==null){
                 this.countryId=this.sendingAgentsList[0].countryId
                 this.countryName=this.sendingAgentsList[0].countryName;
                 this.sendingAgentId=this.sendingAgentsList[0].userId;
                 this.dailingCode=this.sendingAgentsList[0].dailingCode;
                 this.countryIso=this.sendingAgentsList[0].countryIso;
                 this.agent=this.sendingAgentId;
                 this.country=this.countryName;
this.countryIdd=this.countryId+'&'+this.sendingAgentId;
             }
             else{

             this.countryId=tObj.srcCountryId;
             this.countryName=tObj.sourceCountryName;
             this.sendingAgentId=tObj.sendingAgentUserId;
             this.dailingCode=tObj.sourceDailingCode;

             this.agent=this.sendingAgentId;
             this.country=this.countryName;
             this.countryIso=tObj.srcCountryIso;
             this.countryIdd=this.countryId+'&'+this.sendingAgentId;
               }

               console.log('sendingAgentsList',this.sendingAgentsList)

}
})
var obj11 = localStorage.getItem('datas');
this._branchService.getClientCountrys(this.processIdGeneraterService.getPid(),obj11).subscribe(res => {
if (res) {
  this.ViewList = res.response;
  console.log('viewlist', this.ViewList)


}
}, err => {
console.log(err);
}

);

}
 onHome(){
     this._router.navigate(['/'])
 }
onChangeCountry(val:any){
  var str=val;
  var splitted = str.split("&",2);
this.countryId=splitted[0];
this.agent=splitted[1];
  this._branchService.getCountryDetailsBasedOnCountryId(
    this.processIdGeneraterService.getPid(),val).subscribe(res => {
  if (res) {
    console.log("onchange country",res.response)
    this.dailingCode=res.response.dailingCode;
    this.countryName=res.response.countryName;
    this.countryIso=res.response.countryIso;
 
    if(res.response.countryName == "UNITED KINGDOM"){
     this.isShoww = true;
     this.isShowws= false;
     this.isShowwss= false;
    } else if(res.response.countryName == "AUSTRALIA") {
      this.isShowws= true;
      this.isShoww = false;
      this.isShowwss= false;
    }else{
      this.isShowwss= true;
      this.isShoww = false;
     this.isShowws= false;
    }

  }



});
}


public get f(){
return this.signupForm.controls;
}
onOtpSubmit(val:any){
  this.isloader=true;
////////////////////////////////////////////
this.userId=  localStorage.getItem('userId');
this.customer = JSON.parse(localStorage.getItem('signupform'));
console.log('retrievedObject: ',this.customer);
  this.signupService.otpVerify(
    this.processIdGeneraterService.getPid(),
    this.signupForm.value.firstName,
    this.signupForm.value.emailId,
    val)
  .subscribe(res=>{
if(res.response=='verified'){
  const uObj={
    'userName':this.signupForm.value.emailId
  }
  const signup={
      'countryId':this.countryId,
      'sendingAgentId':this.agent,
      'placeOfBirth':this.countryName,
      'nationality':this.countryName,
      'phoneNumber':"+"+""+this.dailingCode+""+this.signupForm.value.phoneNumber,
      'businessPhoneNumber':"+"+""+this.dailingCode+""+this.signupForm.value.businessPhoneNumber,
  }

this.signupForm.patchValue(uObj);
this.signupForm.patchValue(signup);
console.log('phoneNumber.....',this.signupForm.value.phoneNumber)
//  this.submitted=true;

    const newObj = {
      'countryId':this.signupForm.value.countryId,
      'emailId':this.signupForm.value.emailId,
      'password':this.signupForm.value.password,
      'userSource':this.signupForm.value.userSource,
      'firstName':this.signupForm.value.firstName,
      'lastName':this.signupForm.value.lastName,
      'phoneNumber':this.signupForm.value.phoneNumber,
      'sendingAgentId':this.signupForm.value.sendingAgentId,
      'countryIso':this.countryIso,
      'countryName':this.countryName,
      'registerId':this.signupForm.value.registerId,
      'businessName':this.signupForm.value.businessName,
      'businessEmail':this.signupForm.value.businessEmail,
      'businessPhoneNumber':"+"+""+this.dailingCode+""+this.signupForm.value.businessPhoneNumber,
      'businessPassword':this.signupForm.value.businessPassword,
      'businessStructure':this.signupForm.value.businessStructure,
      'businessAddress':this.signupForm.value.businessAddress,
      'businessCategory':this.signupForm.value.businessCategory,
      }
    localStorage.setItem('signupform', JSON.stringify(newObj));
    let retrievedObject = localStorage.getItem('signupform');
    console.log('retrievedObject: ',JSON.parse(retrievedObject));

    this.signupService.postSignup(
      this.processIdGeneraterService.getPid(),
      this.signupForm.value).subscribe(res=>{
        console.log("form   ",this.signupForm.value)
      if(res){
      //  this.isloader=true;
      //  this.signupForm.reset()

          localStorage.setItem("userId", res.response.userId);
          localStorage.setItem("datas", res.response.accessToken);
          localStorage.setItem("customerId", res.response.userId);
          const trObj={
ipAddress:this.ipAddress,
userType:'remitz_role_customer',
userName:this.signupForm.value.emailId,
acessResult:'Customer Signup Success',
userid:res.response.userId
}
this.AccessLogForm.patchValue(trObj);
console.log('this.AccessLogForm.value====',this.AccessLogForm.value);

this._accessLogService.saveAccessLog(
this.AccessLogForm.value,
this.processIdGeneraterService.getPid()).subscribe(res => {
if (res) {
console.log('accesslog.....',res.response);
localStorage.setItem("accessId", res.response.acessId);

}
});

            this._router.navigate(['/addpersonaldetailsl'])


}//if closed
});//postSignup closed
}//if verified closed
else{
  alert('OTP Not Verified..!,Please...Try Again...')
}

})//otpVerify closed
}//on otp submit cloesd

getIPAddress()
{
  this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
    this.ipAddress = res.ip;

  });
}



startTimer() {
    clearInterval(this.interval);
  this.timeLeft=120;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        this.emsg='';
      } else {
        this.timeLeft = 0;
        this.emsg='Click On Resend..!';
      }
    },1000)
  }




reSend(){
  this.emsg='';
  this.customer = JSON.parse(localStorage.getItem('signupform'));
  console.log('retrievedObject: ',this.customer);
  this.userId=  localStorage.getItem('userId');
this.signupService.sendingOtptoEmailId(
  this.processIdGeneraterService.getPid(),
  this.userId,
this.signupForm.value.emailId).subscribe(ress=>{
if(ress){
  console.log("otppppppppp",ress.response)
  clearInterval(this.interval);

  this.timeLeft=120;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        this.emsg='';
      } else {
        this.timeLeft = 0;
        this.emsg='Try Again..!';
      }
    },1000)

}

})

}

onChange(){
this.popUpFlag=true;
}

toggleFieldTextType(){
  this.fieldTextType=!this.fieldTextType
}
  onSubmit(){
    console.log('values....,'+ this.signupForm.value)
  if(this.signupForm.value.registerId == 1 && this.signupForm.value.password!=this.signupForm.value.confirmPassword) {
  
    alert("password and confirmPassword are not equal")
     
    }else if(this.signupForm.value.registerId == 2 && this.signupForm.value.businessPassword!=this.signupForm.value.confirmPassword){

      alert("password and confirmPassword are not equal")
    }else {

    this.isValid=null;
    if(this.isDisabled){
    this.signupForm.get('emailId').reset();
  }

    this.submitted=true;
  clearInterval(this.interval);
  //    this.countryId=(Number)this.countryId;
    const signup={
      'countryId':this.countryId,
        'sendingAgentId':this.agent,
        'placeOfBirth':this.countryName,
        'nationality':this.countryName,

    }


    if(this.signupForm.value.registerId == 2) {
      const obj ={
      'emailId':this.signupForm.value.businessEmail,
      'password':this.signupForm.value.businessPassword,
      'phoneNumber':this.signupForm.value.businessPhoneNumber, 
      }
      this.signupForm.patchValue(obj);
    }


    const newObj = {
    'countryId':this.countryId,
      'emailId':this.signupForm.value.emailId,
      'password':this.signupForm.value.password,
      'userSource':this.signupForm.value.userSource,
      'firstName':this.signupForm.value.firstName,
      'lastName':this.signupForm.value.lastName,

      'countryIso':this.countryIso,
      'countryName':this.countryName,

        'sendingAgentId':this.agent,
        'placeOfBirth':this.countryName,
        'nationality':this.countryName,
        'dailingCode':this.dailingCode,
      'phoneNumber':this.signupForm.value.phoneNumber,
      'registerId':this.signupForm.value.registerId,
      'businessName':this.signupForm.value.businessName,
      'businessEmail':this.signupForm.value.businessEmail,
      'businessPhoneNumber':"+"+""+this.dailingCode+""+this.signupForm.value.businessPhoneNumber,
      'businessPassword':this.signupForm.value.businessPassword,
      'businessStructure':this.signupForm.value.businessStructure,
      'businessAddress':this.signupForm.value.businessAddress,
      'businessCategory':this.signupForm.value.businessCategory,

      }

  const obj = {

      'registerId':this.signupForm.value.registerId,
      'businessName':this.signupForm.value.businessName,
      'businessEmail':this.signupForm.value.businessEmail,
      'businessPhoneNumber':this.signupForm.value.businessPhoneNumber,
      'businessPassword':this.signupForm.value.businessPassword,
      'businessStructure':this.signupForm.value.businessStructure,
      'businessAddress':this.signupForm.value.businessAddress,
      'businessCategory':this.signupForm.value.businessCategory,

  }


      localStorage.setItem("businessform",JSON.stringify(obj))
      localStorage.setItem("remitterInfo",JSON.stringify(newObj))
      localStorage.setItem("signupform",JSON.stringify(newObj))
    this.signupForm.patchValue(signup);
    console.log('values....', JSON.stringify(this.signupForm.value, null, 2));
    if(this.signupForm.invalid){
    this.popUpFlag=true;
    alert("invalid form")
      return ;
     
    }
    else{

      this.signupService.sendingOtptoEmailId(
          this.processIdGeneraterService.getPid(),
          this.signupForm.value.firstName,
        this.signupForm.value.emailId).subscribe(res=>{
        if(res){
    clearInterval(this.interval);
    console.log("otp.......",res.response);

  this._router.navigate(['/emailverification'])


  }

  })







}
  }
}//onsubmit closed






  onclick(){
    this._router.navigate(['/index'])
  }
  onclick1(){
    this._router.navigate(['/login'])
  }

  checkUserName(val:any){
    this.signupService.findDuplicateUsers(
      val).subscribe(res => {
      console.log("findDuplicateUserByUserName....!.!" + res);
      if(res.response=='duplicate'){
        this.tryDuplicate='This Username already exist!';
        this.isDisabled=true;

      }else{
        this.tryDuplicate="";
        this.isDisabled=false;

      }
  })
  }


  checkBussinessUserName(val:any){
    this.signupService.findDuplicateUsers(
      val).subscribe(res => {
      console.log("findDuplicateUserByUserName....!.!" + res);
      if(res.response=='duplicate'){
        this.tryDuplicates='This Username already exist!';
        this.isDisabled=true;
    
      }else{
        this.tryDuplicates="";
        this.isDisabled=false;

      }
  })
  }


  onChangeRegister(val:any){
    if(val == 2){
           this.isShowss = true;
           this.signupForm.controls["businessName"].setValidators(Validators.required);
           this.signupForm.controls["businessEmail"].setValidators([Validators.required,Validators.pattern(this.vemail)]);
           this.signupForm.controls["businessPhoneNumber"].setValidators([Validators.required,Validators.maxLength(13)]);
           this.signupForm.controls["businessPassword"].setValidators(Validators.required);
           this.signupForm.controls["businessAddress"].setValidators(Validators.required);
           this.signupForm.controls["businessStructure"].setValidators(Validators.required);
           this.signupForm.controls["businessCategory"].setValidators(Validators.required);
           this.signupForm.controls["emailId"].clearValidators();
           this.signupForm.controls["password"].clearValidators();
           this.signupForm.controls["phoneNumber"].clearValidators();
        }else{
          
         this.isShowss = false;
         this.signupForm.controls["businessName"].clearValidators();
         this.signupForm.controls["businessEmail"].clearValidators();
         this.signupForm.controls["businessPhoneNumber"].clearValidators();
         this.signupForm.controls["businessPassword"].clearValidators();
         this.signupForm.controls["businessAddress"].clearValidators();
         this.signupForm.controls["businessStructure"].clearValidators();
         this.signupForm.controls["businessCategory"].clearValidators();
         this.signupForm.controls["emailId"].setValidators(Validators.required);
         this.signupForm.controls["password"].setValidators(Validators.required);
         this.signupForm.controls["phoneNumber"].setValidators(Validators.required);
        }
        this.signupForm.get("businessName").updateValueAndValidity();
        this.signupForm.get("businessEmail").updateValueAndValidity();
        this.signupForm.get("businessPhoneNumber").updateValueAndValidity();
        this.signupForm.get("businessPassword").updateValueAndValidity();
        this.signupForm.get("businessAddress").updateValueAndValidity();
        this.signupForm.get("businessStructure").updateValueAndValidity();
        this.signupForm.get("businessCategory").updateValueAndValidity();
        this.signupForm.get("emailId").updateValueAndValidity();
        this.signupForm.get("password").updateValueAndValidity();
        this.signupForm.get("phoneNumber").updateValueAndValidity();
    }


    onChangeAddress(val:any){

      this.addSenderdetails.findFullAddress(val,this.processIdGeneraterService.getPid()).subscribe(resp=>{
        if(resp){
          this.address=JSON.parse(resp.response);
          this.Array=this.address.completions;
         
  
        }
      })
    
      }
      selectEvent(val1:any){
      var splitted=val1.id;
      this.addSenderdetails.addressMetaData(splitted,this.processIdGeneraterService.getPid()).subscribe(res=>{
        if(res){
    
          this.fullAddress=res.response.address;
         
    const o={
          
      'businessAddress' : this.fullAddress
    }
    this.signupForm.patchValue(o);
        }
      })
      }

      selectEvent1(val1:any){
        var splitted=val1.id;
        
        this.addSenderdetails.ukAddressMetaData(splitted,this.processIdGeneraterService.getPid()).subscribe(res=>{
          if(res){
                   
              const o={
                'businessAddress':res.response.streetName+", "+res.response.userCity+", "+res.response.userState+", "+res.response.userPostalCode,
              }
              this.signupForm.patchValue(o);
         
        }
        })
      }

      passworKeyup(val){
      if(this.signupForm.value.registerId == 1) {
        if(this.signupForm.value.password==val){
        
         this.confirmPasswordFlag=false;
         return;
       }
       else{
         this.confirmPasswordFlag=true;
       }
      }else {
        if(this.signupForm.value.businessPassword==val){
        
          this.confirmPasswordFlag=false;
          return;
        }
        else{
          this.confirmPasswordFlag=true;
        }
      }
     }


      onChangeGetAddress(val:any){

        this.addSenderdetails.findFullUkAddress(val,this.processIdGeneraterService.getPid()).subscribe(resp=>{
          if(resp){
              this.address=JSON.parse(resp.response);
                this.Array1=[];
                this.Array=this.address.suggestions;

                this.Array.forEach(element => {
                  let addresss= element['address']+" "+val;
                  const o={
                    'address':addresss,
                    'id':element['id']
                  }
                  this.Array1.push(o)
            
                    });
             
          }
        })
        
        }

        filterName(items: any[], query: string) {
          var results:any[] = [];
          items.forEach(
            (item) => {
              if( (item['address']+' '+this.keyword1).toLowerCase().indexOf(query.toLowerCase()) > -1 ) {
                results.push(item);
              }
            }
          );
          return results;
        }

        showHidePassword() {
          this.showPassword = !this.showPassword;
        }
        
}
