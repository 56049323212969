<!-- Navbar start -->
<header class="header-section fixed" (scroll)="onScroll()">
  <div class="overlay">
    <div class="container">
      <div class="row d-flex header-area">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" routerLink="/">
            <img
              src="assetsnew/images/logo-light.png"
              class="logo"
              alt="logo"
              loading="lazy"
            />
          </a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbar-content"
          >
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/aboutus">About Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/contactus">Contact Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <!-- <a class="nav-link" routerLink="/faqs">Faqs</a> -->
                <a class="nav-link" (click)="openFaqs()">Faqs</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/help">How it works</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a routerLink="/login" class="nav-link">Login</a>
              </li>
            </ul>
            <div
              class="right-area header-action d-flex align-items-center max-un"
            >
              <a routerLink="/signup" class="cmn-btn command_btn"
                >Sign Up
                <i class="icon-d-right-arrow-2"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Navbar end -->

<!-- banner-section start -->
<section class="banner-section inner-banner privacy-content">
  <div class="overlay">
    <div class="banner-content d-flex align-items-center">
      <div class="container">
        <div class="row justify-content-start">
          <div class="col-lg-7 col-md-10">
            <div class="main-content">
              <h1 class="title3">About Us</h1>
              <div class="breadcrumb-area">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb d-flex align-items-center">
                    <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
                    <li class="breadcrumb-item active" aria-current="page">
                      About Us
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Our Values start -->
<section class="our-values">
  <div class="overlay pt-120 pb-120">
    <div class="container wow fadeInUp">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-6">
          <div class="section-header text-justify">
            <h2 class="sub-title">Who we are</h2>
            <p class="text-4"><br /></p>
            <p>
              Tayrex Exchange Pty Limited (TEX) is an Australian registered
              Remittance Company (ACN 621601698) and it is regulated by the
              Australian Transaction Reports and Analysis Centre (AUSTRAC).
            </p>
            <p>
              TEX is set up to provide seamless cross-border transactions on a
              value for money basis at no hidden cost. Since the inception of
              TEX, our vision is to make sure all remittances feel like a normal
              day to day transaction thereby cutting off the traditional high
              transfer fees that banks charge. TEX also understands the usual
              emotions behind every remittance, especially to loved ones. How
              our customers feel after each transaction is very important to us.
              Therefore, we not only take the quantitative factors into
              consideration but also the qualitative factors. It is time to make
              every immigrant feel as if they’re back on home soil, even though
              they’re far away from home. For businesses also, cross-border
              payments/settlements would seem like intra-country transactions.
            </p>
          </div>
        </div>
        <div class="col-md-6">
          <img
            src="assetsnew/images/who.png"
            alt=""
            class="img-fluid shadow-lg rounded-lg"
          />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Values end -->

<!-- Our Team start -->
<section>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-xl-6">
        <div class="section-header text-justify">
          <h2 class="sub-title">Value & Aims</h2>
          <p class="text-4">
            <br />
            TEX would continually look for ways to provide remittance services
            to clients at the best optimum rate using the available FinTech. •
            Provide high speed remittance as fast as possible because time is
            crucial to us at TEX. • Leaving a smile on both the remitter and
            beneficiary of funds.
          </p>
          <div></div>
        </div>
      </div>
      <div class="col-md-6">
        <img
          src="assetsnew/images/vision.png"
          alt=""
          class="img-fluid shadow-lg rounded-lg"
        />
      </div>
    </div>
  </div>
</section>
<!-- Our Team end -->

<!-- <section class="testimonials">
  <div class="overlay pt-120 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-header text-center">
            <h5 class="sub-title">Testimonials</h5>
            <h2 class="title">What Our Customers Say</h2>
            <p>
              50,119 happy clients all around the world. Don’t just take our
              word for it
            </p>
            <img src="assetsnew/images/yellows.jpg" class="review_img" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="testimonials-carousel">
        <app-testimonial></app-testimonial>
      </div>
    </div>
  </div>
</section> -->

<!-- <app-testimonial></app-testimonial> -->

<div class="swiper">
  <!-- Additional required wrapper -->
  <div class="swiper-wrapper">
    <!-- Slides -->
    <div class="swiper-slide">Slide 1</div>
    <div class="swiper-slide">Slide 2</div>
    <div class="swiper-slide">Slide 3</div>
    ...
  </div>
  <!-- If we need pagination -->
  <div class="swiper-pagination"></div>

  <!-- If we need navigation buttons -->
  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>

  <!-- If we need scrollbar -->
  <div class="swiper-scrollbar"></div>
</div>

<app-new-footer></app-new-footer>
