<style>
  .new-btn {
  background: transparent;
   /* padding: 15px;  */
  border-radius: 12px;
  cursor: pointer;
  padding:4px 0px;
}
.new-btn2{
  background: #f8f8ff;
  padding: 15px;
  /* display: none; */
}
</style>

 <!-- The Modal -->
 <div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content" style="padding: 10px">
      <!-- Modal body -->
      <div class="modal-body">
        <div class="row">
          <div class="col-md-12">
            <p>
              You are now going to delete this account , you will no longer
              be able to login again and all your data will be deleted.
            </p>

            <br />
            <div class="row">
              <div class="col-md-6">
                <button
                  class="btn btn-success btn-block"
                  data-dismiss="modal"
                  (click)="deleteAccount()"
                >
                  Yes
                </button>
              </div>
              <div class="col-md-6">
                <button
                  class="btn btn-danger btn-block"
                  data-dismiss="modal"
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="user_dashboard_body">
  <nav class="dashboard_navbar">
    <!-- Icon -->
    <div class="dashboard_menu_icon"  data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
      <img src="assetsnew/dashboard/images/icon/menu.png" alt="icon" />
    </div>


    <div class="router-link d-flex d-lg-none"  routerLink="/index"
        ><img src="assetsnew/dashboard/images/logo.png" alt="logo" width="105px"/>
    </div>
    <!-- <div class="dashboard_menu_icon" (click)="toggleSideNavbar()">
      <img src="assetsnew/dashboard/images/icon/menu.png" alt="icon" />
    </div> -->

    <!-- Search form -->
    <form class="dashboard_search_form " >
      <div class="d-none">
        <img src="assetsnew/dashboard/images/icon/search.png" alt="icon" />
        <input type="text" placeholder="Type to search..." />
      </div>
    </form>

    <div class="dashboard_nav_group">
        <!-- language -->
      <div class="single-item language-area d-none">
        <div class="language-btn" (click)="toggleShowLanguage()">
          <img src="assetsnew/dashboard/images/icon/lang.png" alt="icon" />
        </div>
        <ul class="language_dropdown_content">
          <li>English</li>
          <li>Hindi</li>
          <li class="active_language">English (US)</li>
          <li>Japanese</li>
          <li>Canada</li>
          <li>Lithuanian</li>
        </ul>
      </div>

      <!-- Notification -->
      <div class="dashboard_notification d-none">
        <div class="dashboard_notification_btn" (click)="toggleNotification()">
          <img
            src="assetsnew/dashboard/images/icon/bell.png"
            class="bell-icon"
            alt="icon"
          />
        </div>

        <div class="notification_dropdown_content">
          <div class="notification_title">
            <h5>Notifications</h5>
            <span>4</span>
          </div>
          <ul>
            <li>
              <a href="javascript:void(0)" class="d-flex">
                <div class="img-area">
                  <img
                    src="assetsnew/dashboard/images/user-1.png"
                    class="max-un"
                    alt="image"
                  />
                </div>
                <div class="text-area">
                  <p class="mdr">
                    You received a payment of <b>$134.00</b> from
                    <b>Anna Green</b>
                  </p>
                  <p class="mdr time-area">09.39AM</p>
                </div>
              </a>
              <i class="fa-solid fa-caret-right"></i>
            </li>

            <li>
              <a href="javascript:void(0)" class="d-flex">
                <div class="img-area">
                  <img
                    src="assetsnew/dashboard/images/user-2.png"
                    class="max-un"
                    alt="image"
                  />
                </div>
                <div class="text-area">
                  <p class="mdr">
                    <b>James Deed</b> requested a payment of
                    <b>£890.00</b>
                  </p>
                  <p class="mdr time-area">08.09AM</p>
                </div>
              </a>
              <i class="fa-solid fa-caret-right"></i>
            </li>
            <li>
              <a href="javascript:void(0)" class="d-flex">
                <div class="img-area">
                  <img
                    src="assetsnew/dashboard/images/master-card.png"
                    class="max-un"
                    alt="image"
                  />
                </div>
                <div class="text-area">
                  <p class="mdr">
                    Your new payment method has beed added successfully
                  </p>
                  <p class="mdr time-area">09.39AM</p>
                </div>
              </a>
              <i class="fa-solid fa-caret-right"></i>
            </li>
            <li>
              <a href="javascript:void(0)" class="d-flex">
                <div class="img-area">
                  <img
                    src="assetsnew/dashboard/images/user-3.png"
                    class="max-un"
                    alt="image"
                  />
                </div>
                <div class="text-area">
                  <p class="mdr">
                    You received a payment of <b>$250.00</b> from Goerge Michael
                  </p>
                  <p class="mdr time-area">Yesterday</p>
                </div>
              </a>
              <i class="fa-solid fa-caret-right"></i>
            </li>
          </ul>
        </div>
      </div>

      <!-- User profile -->
      <div class="dashboard_nav_user_profile d-flex">
        <a href="https://support.tayrexchange.com.au/" class="px-4 d-lg-flex align-items-center d-none" title="Customer Support">
          <!-- <img src="assetsnew/dashboard/images/icon/crypto.png" alt="Crypto" /> -->
          <span>Customer Support?</span>
        </a>
        <a href="javascript:void(0)" class="d-lg-flex align-items-center mt-1 d-none">
          <h5 class="mb-0" style="
          font-weight: 500;font-size: 15px;">
          {{ userFirstName }} {{ userLastName }}</h5>
        </a>
        <div class="user_profile_area" (click)="toggleUserProfile()">
          <span class="user-profile mx-2">
            <img src="/assets/images/avatar.svg" width="35" alt="User" />
          </span>
          <i class="fa-solid fa-sort-down"></i>
        </div>
        
        <div class="user_profile_dropdown_content">
          <div class=" d-flex align-items-center flex-wrap flex-row">
          <div class="col-md-6">
          <div class="user_profile_top">
            <div class="profile_head">
              <a href="javascript:void(0)">
                <h5>{{ userFirstName }} {{ userLastName }}</h5>
              </a>
              <p>Customer ID: {{ userId }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 d-none d-lg-block">
          <ul>
            <li class="border-area my-3 my-lg-0">
              <div class="router-link" [routerLinkActive]="'active'" routerLink="/myprofile"><i class="fas fa-cog"></i>Settings</div>
            </li>
            <li>
              <a href="javascript:void(0)" (click)="logout()"
                ><i class="fas fa-sign-out"></i>Logout</a
              >
            </li>
          </ul>
        </div>
        <div class="col-md-6 d-lg-none d-block">
          <div>
            <div class="border-area my-3 my-lg-0 d-flex justify-content-between">
              <div class="router-link d-inline text-dark" [routerLinkActive]="'active'" routerLink="/myprofile"><i class="fas fa-cog"></i>Settings</div>
              <a href="javascript:void(0)" class="d-inline text-dark" (click)="logout()"
                ><i class="fas fa-sign-out"></i>Logout</a
              >
            </div>
            
          </div>
        </div>
      </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- <a class="btn btn-primary mt-5 " data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
    =
  </a> -->

  <main>

 

  <div class="collapse " id="collapseExample">
  
  <aside class="user_dashboard_aside">
    <div class="sidebar_logo d-none d-lg-flex">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/index"
        ><img src="assetsnew/dashboard/images/logo.png" alt="logo"
      /></div>
    </div>

    <ul class="aside_menu">
      <!-- Dashboard -->
      <li class="active new-btn nnew-btn2"  id="btn10" >
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/index"  title="Dashboard">
          <img
            src="assetsnew/dashboard/images/icon/dashboard.png"
            alt="Dashboard"
          />
          <span>Dashboard</span>
        </div>
      </li>

      <!-- Market place -->
      <li class="new-btn"  id="btn1">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/marketrate" title="Market Place">
          <img
            src="assetsnew/dashboard/images/icon/exchange.png"
            alt="Exchange"
          />
          <span>Marketplace</span>
        </div>
      </li>

      <!-- My Transactions -->
      <li class="new-btn"  id="btn2">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/trlist"
        
        title="Transactions"
        >
          <img
            src="assetsnew/dashboard/images/icon/transactions.png"
            alt="Transactions"
          />
          <span>My Transactions</span>
        </div>
      </li>

      <!-- Beneficiary -->
      <li class="new-btn"  id="btn3">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/recipient"  title="Beneficiary">
          <img
            src="assetsnew/dashboard/images/icon/recipients.png"
            alt="Recipients"
          />
          <span> Beneficiary</span>
        </div>
      </li>

      <!-- My Profile -->
      <li class="new-btn"  id="btn4">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/myprofile"  title="My Profile">
          <img
            src="assetsnew/dashboard/images/icon/account.png"
            alt="Account"
          />
          <span>My Profile</span>
        </div>
      </li>
      <li *ngIf="registerName=='Business'" >
        <a class="gonext" href="javascript:void(0)" (click)="openmybusinessprofile()"> <i class="fas fa-user-circle side-bar-font-icon"></i>
          <span>Business Profile</span></a>
      </li>

      <!-- Wallet -->
      <li class="new-btn"  id="btn5">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/wallet"  title="My Wallet">
          <img
            src="assetsnew/dashboard/images/icon/deposit.png"
            alt="Deposit"
          />
          <span>My Wallet</span>
        </div>
      </li>

      <!-- Referral -->
      <li class="new-btn"  id="btn6">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/myreferal" title="My Referral">
          <img
            src="assetsnew/dashboard/images/icon/withdraw.png"
            alt="Withdraw"
          />
          <span> My Referral</span>
        </div>
      </li>

      <li class="new-btn"  id="btn7">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/airtimetopup" title="Airtime Top up">
          <img src="assetsnew/dashboard/images/icon/pay.png" alt="Pay" />
          <span>Airtime Top Up</span>
        </div>
      </li>
      <li class="new-btn"  id="btn8">
        <div class="router-link" [routerLinkActive]="'active'" routerLink="/billpayment" title="billpayment">
          <img src="assetsnew/dashboard/images/icon/pay.png" alt="Pay" />
          <span>billpayment</span>
        </div>
      </li>
      <!-- <li class="new-btn"  id="btn8">
        <div class="router-link" [routerLinkActive]="'active'"  title="deleteaccount">
          <a
          class="dropdown-item tr px-0"
          href="javascript:void(0)"
          data-toggle="modal"
          data-target="#myModal"
          ><i class="fa fa-trash" style="font-size: 14px; color: #92a9ff;"></i><span>
          Delete account </span></a
        >
        </div>
      </li> -->

      <li class="new-btn"  id="btn9">
        <a href="https://support.tayrexchange.com.au/" title="Customer Support">
          <img src="assetsnew/dashboard/images/icon/crypto.png" alt="Crypto" />
          <span>Customer Support</span>
        </a>
      </li>
    </ul>
  </aside>


</div>





<aside class="user_dashboard_aside d-none d-lg-block">
  <div class="sidebar_logo">
    <div class="router-link"  routerLink="/index"
      ><img src="assetsnew/dashboard/images/logo.png" alt="logo"
    /></div>
  </div>

  <ul class="aside_menu">
    <!-- Dashboard -->
    <li class="active new-btn nnew-btn2"  id="btn10" >
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/index"  title="Dashboard">
        <img
          src="assetsnew/dashboard/images/icon/dashboard.png"
          alt="Dashboard"
        />
        <span>Dashboard</span>
      </div>
    </li>

    <!-- Market place -->
    <li class="new-btn nav-btn-2"   id="btn1">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/marketrate" title="Market Place">
        <img
          src="assetsnew/dashboard/images/icon/exchange.png"
          alt="Exchange"
        />
        <span>Marketplace</span>
      </div>
    </li>

    <!-- My Transactions -->
    <li class="new-btn"  id="btn2">
      <div  class="router-link" [routerLinkActive]="'active'" routerLink="/trlist"
        
        title="Transactions"
      >
        <img
          src="assetsnew/dashboard/images/icon/transactions.png"
          alt="Transactions"
        />
        <span>My Transactions</span>
      </div>
    </li>

    <!-- Beneficiary -->
    <li class="new-btn"  id="btn3">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/recipient"  title="Beneficiary">
        <img
          src="assetsnew/dashboard/images/icon/recipients.png"
          alt="Recipients"
        />
        <span> Beneficiary</span>
      </div>
    </li>

    <!-- My Profile -->
    <li class="new-btn"  id="btn4">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/myprofile"  title="My Profile">
        <img
          src="assetsnew/dashboard/images/icon/account.png"
          alt="Account"
        />
        <span>My Profile</span>
      </div>
    </li>
    <li *ngIf="registerName=='Business'" >
      <a class="gonext" href="javascript:void(0)" (click)="openmybusinessprofile()"> <i class="fas fa-user-circle side-bar-font-icon"></i>
        <span>Business Profile</span></a>
    </li>

    <!-- Wallet -->
    <li class="new-btn"  id="btn5">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/wallet"  title="My Wallet">
        <img
          src="assetsnew/dashboard/images/icon/deposit.png"
          alt="Deposit"
        />
        <span>My Wallet</span>
      </div>
    </li>

    <!-- Referral -->
    <li class="new-btn"  id="btn6">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/myreferal" title="My Referral">
        <img
          src="assetsnew/dashboard/images/icon/withdraw.png"
          alt="Withdraw"
        />
        <span> My Referral</span>
      </div>
    </li>

    <li class="new-btn"  id="btn7">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/airtimetopup" title="Airtime Top up">
        <img src="assetsnew/dashboard/images/icon/pay.png" alt="Pay" />
        <span>Airtime Top Up</span>
      </div>
    </li>
    <li class="new-btn"  id="btn8">
      <div class="router-link" [routerLinkActive]="'active'" routerLink="/billpayment" title="billpayment">
        <img src="assetsnew/dashboard/images/icon/pay.png" alt="Pay" />
        <span>billpayment</span>
      </div>
    </li>
    <!-- <li class="new-btn"  id="btn8">
      <div class="router-link" [routerLinkActive]="'active'"  title="deleteaccount">
        <a
        class="dropdown-item tr px-0"
        href="javascript:void(0)"
        data-toggle="modal"
        data-target="#myModal"
        ><i class="fa fa-trash" style="font-size: 14px; color: #92a9ff;"></i><span>
        Delete account </span></a
      >
      </div>
    </li> -->


    <li class="new-btn"  id="btn9">
      <a href="https://support.tayrexchange.com.au/" title="Customer Support">
        <img src="assetsnew/dashboard/images/icon/crypto.png" alt="Crypto" />
        <span>Customer Support</span>
      </a>
    </li>
  </ul>
</aside>
<router-outlet></router-outlet>
</main>
</div>
