import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders,HttpBackend} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators'
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
    apiUrl=environment.baseUrl;
  public customerApi:string = `${this.apiUrl}/customer/createBackendCustomer`;
  public customerListApi:string = `${this.apiUrl}/customer/getCustomersList`;
  public searchRemitterByCriteriaUrl:string = `${this.apiUrl}/transaction/searchRemitterByCriteria`;
  public searchRemitterByCriteriaAndUserIdUrl:string = `${this.apiUrl}/transaction/searchRemitterByCriteriaAndUserId`;
  public searchRemitterByCriteriaAndUserIdGroupByBeneficiaryUrl:string = `${this.apiUrl}/transaction/searchRemitterByCriteriaAndUserIdGroupByBeneficiary`;
  public customerSearchBasedOnCriteriaForBenifUrl:string = `${this.apiUrl}/transaction/customerSearchBasedOnCriteriaForBenif`;
  public beneficiaryListByCustomerIdUrl:string =`${this.apiUrl}/customer/getBeneficiaryListByCustomerId`;
    public updateManualIdVerification: string=`${this.apiUrl}/customer/manualIdVerification`;
   public updateRiscScoreByUserIdUrl: string=`${this.apiUrl}/customer/updateRiscScoreByIUserd`;
    public updateManualIdVerificationWithNote: string=`${this.apiUrl}/customer/manualIdVerificationWithNote`;
  public updateSanctionListStatus: string=`${this.apiUrl}/customer/sanctionFlagVerification`;
  public updateCashCollectionDetails: string=`${this.apiUrl}/transaction/updateCashCollInfo`;
  public saveMaxAmountDetails: string=`${this.apiUrl}/utility/saveMaxAmountDetails`;
  public getClientSendingCountryList: string=`${this.apiUrl}/keyclock/getClientSendingCountrys`;
  public getMaximumList: string=`${this.apiUrl}/utility/getMaxAmountList`;
  public getMaximumListByCountryId: string=`${this.apiUrl}/utility/getMaxAmountListByCountryId`;
  public updateMaxAmountDetails: string=`${this.apiUrl}/utility/updateMaxAmountDetails`;
  public customerListBySancFlag:string = `${this.apiUrl}/customer/getCustomersListBySanctionFlag`;
  public trnlisturl:string = `${this.apiUrl}/transaction/getListByTxndays`;
  public customerByIdUrl:string=`${this.apiUrl}/customer/getCustomerByUserId`;
  public customerByIddUrl:string=`${this.apiUrl}/customer/getImage`;
  public nameSearchByCustomerIdUrl:string=`${this.apiUrl}/rapid/nameSearchByCustomerId`;
  public validatePassportByCustomerIdUrl:string=`${this.apiUrl}/rapid/validatePassportByCustomerId`;
  public validateDrivingLicenseByCustomerIdUrl:string=`${this.apiUrl}/rapid/validateDrivingLicenseByCustomerId`;
  public updateNameSearchStatusUrl:string=`${this.apiUrl}/rapid/updateNameSearchStatus`;
  public updatePassPortStatusUrl:string=`${this.apiUrl}/rapid/updatePassPortStatus`;
  public updateLicenseStatusUrl:string=`${this.apiUrl}/rapid/updateLicenseStatus`;
public getCustomerDocumentsByuserIdUrl=`${this.apiUrl}/customer/getCustomerDocumentsByUserId`;
public changeBlockedStatusUrl=`${this.apiUrl}/customer/changeCustomerBlockUnblockStatus`;
public documentsExpiredCustomerListUrl=`${this.apiUrl}/customer/documentsExpiredCustomerList`;
public getBlockedCustomerListUrl=`${this.apiUrl}/customer/getBlockedCustomerList`;
public getValidDocumentCustomerListUrl=`${this.apiUrl}/customer/validDocumentsCustomerList`;
public sendComplainsSmsUrl=`${this.apiUrl}/customer/sendComplainsSmstoMobileNumber`;
public updatesBusinessDetailsUrl:string=`${this.apiUrl}/customer/updateBusinessDocument`;
public getBusinesDocumentByUserIdUrl:string=`${this.apiUrl}/customer/getBusinessImage`;
public searchBenificieryByCriteriaUrl=`${this.apiUrl}/transaction/searchBenificieryByCriteria`;

public getRiscScoreByuserIdUrl=`${this.apiUrl}/customer/getRiscScoreByUserId`;
 

httpOptions = {
    headers: new HttpHeaders({  })//.set('Authorization', localStorage.getItem("datas"))

  };
  httpOptions1 = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })//.set('Authorization', localStorage.getItem("datas"))

  };


  constructor(private _http:HttpClient,private _httpp:HttpClient,handler: HttpBackend) { this._httpp = new HttpClient(handler); }

  public extractData(res:Response):Array<any>| {} {
    const body=res;
    console.log('res',res);
     return body || {} ;
  }

  public postCustomer(pId:string,record:any):Observable<any>{
    return this._http.post(`${this.customerApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.customerListApi}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public changeBlockedStatus(pId:string,userId:string,status:any,reason:any):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.post(`${this.changeBlockedStatusUrl}`+'?pId='+pId+'&userId='+userId+'&status='+status+'&reason='+reason,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getBlockedCustomerList(pId:string,status:any):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.post(`${this.getBlockedCustomerListUrl}`+'?pId='+pId+'&status='+status,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public documentsExpiredCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.documentsExpiredCustomerListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getValidDocumentCustomerList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getValidDocumentCustomerListUrl}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateManualIdVerificationData(pId:any,userId:any,idVerifiedStatus:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+idVerifiedStatus);
    return this._http.post(`${this.updateManualIdVerification}`+'?pId='+pId+'&userId='+userId+'&idVerifiedStatus='+idVerifiedStatus,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateRiscScoreByUserId(pId:any,userId:any,riscScore:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+riscScore);
    return this._http.post(`${this.updateRiscScoreByUserIdUrl}`+'?pId='+pId+'&userId='+userId+'&riscScore='+riscScore,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  
  public updateManualIdVerificationDataWithNote(pId:any,userId:any,idVerifiedStatus:any,note:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+idVerifiedStatus);
    return this._http.post(`${this.updateManualIdVerificationWithNote}`+'?pId='+pId+'&userId='+userId+'&idVerifiedStatus='+idVerifiedStatus+'&note='+note,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateSanctionListData(pId:any,userId:any,sanctionFlagStatus:any):Observable<any>{
    console.log("processId..!"+pId+","+userId+","+sanctionFlagStatus);
    return this._http.post(`${this.updateSanctionListStatus}`+'?pId='+pId+'&userId='+userId+'&sanctionFlagStatus='+sanctionFlagStatus,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateCashPickUpDetail(cashCollectionPointId:number,updateRecord:any,pId : string){
    return this._http.post(`${this.updateCashCollectionDetails}`+'?pId='+pId+'&cashCollectionPointId='+cashCollectionPointId,updateRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public saveMaxAmount(maxAmountRecord:any,pId : string){
    return this._http.post(`${this.saveMaxAmountDetails}`+'?pId='+pId,maxAmountRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public getClientSendCountryList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getClientSendingCountryList}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getMaxAmntList(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getMaximumList}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getMaxAmntListByCountryId(pId:string,countryId:any):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.getMaximumListByCountryId}`+'?pId='+pId+'&countryId='+countryId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public updateMaxAmount(maxAmountRecord:any,pId : string,maxAmountId:string){
    return this._http.post(`${this.updateMaxAmountDetails}`+'?pId='+pId+'&maxAmountId='+maxAmountId,maxAmountRecord,this.httpOptions).pipe(
        map(this.extractData)
    )
  }
  public getCustomerListBySanctionFlag(pId:string):Observable<any>{
    console.log("pId..!"+pId);
    return this._http.get(`${this.customerListBySancFlag}`+'?pId='+pId+'&sanctionSearch='+'yes',this.httpOptions).pipe(
      map(this.extractData)
    )
  }
//trnlisturl
public getTransactionLIstByCustId(Id:any,days:any,pId:string):Observable<any>{
  console.log("pI d..!"+pId);
  return this._http.get(`${this.trnlisturl}`+'?pId='+pId+'&sendingCustomerUserId='+Id+'&days='+days,this.httpOptions).pipe(
    map(this.extractData)
  )
}
public beneficiaryListByCustomerId(Id:any, pId: string): Observable<any> {
  console.log('beneficiaryListByCustomerIdUrl....', pId);
  return this._http.get(`${this.beneficiaryListByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+Id, this.httpOptions).pipe(
    map(this.extractData)
  )
}


public getCustomerById(userId:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.customerByIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getCustomerByIdd(userId:any,imageType:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.customerByIddUrl}`+'?pId=' + pId+'&userId='+userId+'&imageType='+imageType, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//nameSearchByCustomerIdUrl
public nameSearchByCustomerId(userId:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.nameSearchByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//validatePassportByCustomerIdUrl
public validatePassportByCustomerId(userId:any,pId: string): Observable<any> {
  console.log('validatePassportByCustomerIdUrl....', pId);
  return this._http.get(`${this.validatePassportByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

//validateDrivingLicenseByCustomerIdUrl
public validateDrivingLicenseByCustomerId(userId:any,pId: string): Observable<any> {
  console.log('validateDrivingLicenseByCustomerIdUrl....', pId);
  return this._http.get(`${this.validateDrivingLicenseByCustomerIdUrl}`+'?pId=' + pId+'&customerId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//updateNameSearchStatusUrl
public updateNameSearchStatus(userId:any,status:any,pId: string): Observable<any> {
  console.log('updateNameSearchStatusUrl....', pId);
  return this._http.get(`${this.updateNameSearchStatusUrl}`+'?pId=' + pId+'&customerId='+userId+'&status='+status, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//updatePassPortStatusUrl
public updatePassPortStatus(userId:any,status:any,pId: string): Observable<any> {
  console.log('updatePassPortStatusUrl....', pId);
  return this._http.get(`${this.updatePassPortStatusUrl}`+'?pId=' + pId+'&customerId='+userId+'&status='+status, this.httpOptions).pipe(
    map(this.extractData)
  )
}
//updateLicenseStatusUrl
public updateLicenseStatus(userId:any,status:any,pId: string): Observable<any> {
  console.log('updateLicenseStatusUrl....', pId);
  return this._http.get(`${this.updateLicenseStatusUrl}`+'?pId=' + pId+'&customerId='+userId+'&status='+status, this.httpOptions).pipe(
    map(this.extractData)
  )
}
public getCustomerDocumentsByuserId(userId:any,pId: string): Observable<any> {
  console.log('getCustomerDocumentsByuserIdUrl....', pId);
  return this._http.get(`${this.getCustomerDocumentsByuserIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getRiscScoreByuserId(userId:any,pId: string): Observable<any> {
  console.log('ggetRiscScoreByuserIdUrl....', pId);
  return this._http.get(`${this.getRiscScoreByuserIdUrl}`+'?pId=' + pId+'&userId='+userId, this.httpOptions).pipe(
    map(this.extractData)
  )
}



public searchRemitterByCriteria(record:any,pId: string): Observable<any> {
  console.log('searchRemitterByCriteriaUrl....', pId);
  return this._http.post(`${this.searchRemitterByCriteriaUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public searchRemitterByCriteriaAndUserId(record:any,pId: string): Observable<any> {
  console.log('searchRemitterByCriteriaAndUserIdUrl....', pId);
  return this._http.post(`${this.searchRemitterByCriteriaAndUserIdUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public searchRemitterByCriteriaAndUserIdGroupByBeneficiary(record:any,pId: string): Observable<any> {
  console.log('searchRemitterByCriteriaAndUserIdGroupByBeneficiaryUrl....', pId);
  return this._http.post(`${this.searchRemitterByCriteriaAndUserIdGroupByBeneficiaryUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public customerSearchBasedOnCriteriaForBenif(record:any,pId: string): Observable<any> {
  console.log('customerSearchBasedOnCriteriaForBenif....', pId);
  return this._http.post(`${this.customerSearchBasedOnCriteriaForBenifUrl}`+'?pId=' + pId,record, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public sendComplainsSmstoCustomerMobileNumber(number:any,pId: string): Observable<any> {
  return this._httpp.post(`${this.sendComplainsSmsUrl}`+'?pId=' + pId+'&number='+number, this.httpOptions1).pipe(
    map(this.extractData)
  )
}

public updateBusinessDetails(pId:string,formData:any):Observable<any>{
  console.log(formData);
  return this._http.post(`${this.updatesBusinessDetailsUrl}`+'?pId='+pId,formData,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getBusinesDocumentByUserId(userId:any,imageType:any,pId: string): Observable<any> {
  console.log('getCustomerById....', pId);
  return this._http.get(`${this.getBusinesDocumentByUserIdUrl}`+'?pId=' + pId+'&userId='+userId+'&imageType='+imageType, this.httpOptions).pipe(
    map(this.extractData)
  )
}

public searchBenificieryByCriteria(beneficiaryId:any,destCountryName:any,pId: string): Observable<any> {
  console.log('searchRemitterByCriteriaUrl....', pId);
  return this._http.post(`${this.searchBenificieryByCriteriaUrl}`+'?pId=' + pId+'&beneficiaryId='+beneficiaryId+'&destCountryName='+destCountryName, this.httpOptions).pipe(
    map(this.extractData)
  )
}
}
