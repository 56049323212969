import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators,FormArray,FormBuilder} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import {ExchangeRatesService,ProcessIdGeneraterService,AgentServiceFeeService,UtilityService} from '../../service';
import {AuditTrialService } from '../../service';
import { HttpClient  } from '@angular/common/http';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
promotionForm:FormGroup;
agentId:any;
countryId1:any;
countryId:any;
ipAddress:any;
auditTrialForm:FormGroup;
sList:any;
dList:any;

  constructor(private fb :FormBuilder ,
    private _router :Router,
    private _auditTrialService: AuditTrialService,
    private _route:ActivatedRoute,
    private http:HttpClient,
    private _exchangeratesservice:ExchangeRatesService,
       private _utilityService: UtilityService,
    private serviceFee:AgentServiceFeeService,
    private processIdGeneraterService : ProcessIdGeneraterService) { }

  ngOnInit(): void {
    this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
      this.ipAddress = res.ip;
     // alert(this.ipAddress)

    });
    this.auditTrialForm=new FormGroup({
    category:new FormControl(''),
    userName:new FormControl(''),
    userId:new FormControl(''),
    userType:new FormControl(''),
    ipAddress:new FormControl(''),
    sourceCountry:new FormControl(''),
    agent:new FormControl(''),
    transaction:new FormControl(''),
    message:new FormControl(''),
    originalData:new FormControl(''),
    changedData:new FormControl('')
    });

    this.promotionForm=new FormGroup({
    promotionName:new FormControl(''),
    status:new FormControl(''),
    fromDate:new FormControl(''),
    toDate:new FormControl(''),
    sourceCountry:new FormControl(''),
    destinationCountry:new FormControl(''),
    transferType:new FormControl(''),
    promotionCode:new FormControl(''),
    promotionType:new FormControl(''),
    promtionValue:new FormControl(''),
    description:new FormControl(''),
    sendMin:new FormControl(''),
    sendMax:new FormControl(''),
    recMin:new FormControl(''),
    recMax:new FormControl('')
    });

    this.serviceFee.getSendAgentsByFunctionality(
      'SEND',
      this.processIdGeneraterService.getPid()).subscribe(res=>{
      if(res){
        this.sList=res.response;
        console.log('this.Listing',this.sList)
        this.serviceFee.getRecAgentsByFunctionalityAndAgentId(
          'RECEIVE',
          this.sList[0].userId,
          this.processIdGeneraterService.getPid()).subscribe(res=>{
          if(res){
            this.dList=res.response
            console.log('this.Listing',this.dList)
  }
  });
  }
  });


  }
  onSubmit(){
    this._utilityService.savePromotions(
      this.promotionForm.value,
      this.processIdGeneraterService.getPid()).subscribe(res=>{
  console.log('response.....',res.response);
  this.promotionForm.reset();
  window.location.reload();
  const trObj={
  ipAddress:this.ipAddress,
  category:'promotion  rule added',
  userType:localStorage.getItem("userTypeName"),
  userName:localStorage.getItem("uname"),
  originalData:"-",
  changedData:"-",
  userId:localStorage.getItem("userId")
  }
  this.auditTrialForm.patchValue(trObj);

  this._auditTrialService.saveAuditTrailDetails(
    this.auditTrialForm.value,
     this.processIdGeneraterService.getPid()).subscribe(res => {
    if (res) {
    //  this.List=res.response;
    console.log('response...',res.response);
    }
  });

      });

}



onChangeUserId(val:any){
  var splitted = val.split("&",2);
//  alert(splitted)
this.countryId=splitted[0];
this.agentId=splitted[1];
//alert(this.agentId);
//alert(this.countryId);
  this.serviceFee.getRecAgentsByFunctionalityAndAgentId(
    'RECEIVE',
    this.agentId,
    this.processIdGeneraterService.getPid()).subscribe(res=>{
    if(res){
      this.dList=res.response
      console.log('this.Listing',this.dList)
}
});

}
onChangeUserId1(val:any){
  this.countryId1=val;

}


}
