import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl,Validators,FormArray,FormBuilder} from '@angular/forms';
import { Router,ActivatedRoute} from '@angular/router';
import {ExchangeRatesService,ProcessIdGeneraterService} from '../../service';
import {AuditTrialService } from '../../service';
import { HttpClient  } from '@angular/common/http';



@Component({
  selector: 'app-src-agent-buy-rates',
  templateUrl: './src-agent-buy-rates.component.html',
  styleUrls: ['./src-agent-buy-rates.component.css']
})
export class SrcAgentBuyRatesComponent implements OnInit {

  ipAddress:any;
  auditTrialForm:FormGroup;

    public List:any;
     smv:any;
     agentId:any;
     isShow:boolean;
    srcExchRateForm:FormGroup;
      constructor(private fb :FormBuilder ,
        private _router :Router,
        private _route:ActivatedRoute,
        private _exchangeratesservice:ExchangeRatesService,
        private _auditTrialService: AuditTrialService,
        private http:HttpClient,
        private processIdGeneraterService : ProcessIdGeneraterService) { }

    ngOnInit(): void {
      if(localStorage.getItem("adminId")==null){
          this._router.navigateByUrl('admin');
      }


        this.http.get(this.processIdGeneraterService.getIpAdress()).subscribe((res:any)=>{
          this.ipAddress = res.ip;
         // alert(this.ipAddress)

        });
        this.auditTrialForm=new FormGroup({
        category:new FormControl(''),
        userName:new FormControl(''),
        userId:new FormControl(''),
        userType:new FormControl(''),
        ipAddress:new FormControl(''),
        sourceCountry:new FormControl(''),
        agent:new FormControl(''),
        transaction:new FormControl(''),
        message:new FormControl(''),
        originalData:new FormControl(''),
        changedData:new FormControl('')
        });

      this.srcExchRateForm=new FormGroup({
      sourceAgentId:new FormControl(''),
      destinationAgentId:new FormControl(''),
      markUpValue:new FormControl(''),
      srcMarkUpValue:new FormControl(''),
      sellRate:new FormControl(''),
      adminRate:new FormControl(''),
      srcCurrencyValue:new FormControl(''),
      destCurrencyValue:new FormControl(''),
      agentBenifPayTypeId:new FormControl('')
      });

      this.agentId=localStorage.getItem('adminId')
      console.log('this.agentId===',this.agentId);

      this._exchangeratesservice.getExchangeRatesListBySrcAgentIdAndRateFlag(
        this.agentId,'buy',this.processIdGeneraterService.getPid()).subscribe(res => {
               if (res){
                     this.List =res.response;
                     console.log('List',this.List)

               }
             },err =>{
               alert("fail");
               console.log(err);
             }
           );

    }

    onRowClick(event,id){
  //  var ar=event.target.outerText;
    this.smv=event.target.outerText;
  console.log("this.mv...!"+this.smv);
    }

    public onUpdate(selectedItem: any) :void{
    //alert("onUpdate working..!",selectedItem.agentBenifPayTypeId);
    let originalData="Source Markup value="+selectedItem.srcMarkUpValue;

      if(this.smv==null){
        this.smv=selectedItem.srcMarkUpValue;
      }
   

  let ar=selectedItem.adminRate*(this.smv/100);
  console.log('ar..,',ar);
  let sr=selectedItem.adminRate-ar;
      const newObj = {
        'sourceAgentId':selectedItem.sourceAgentId,
        'destinationAgentId':selectedItem.destinationAgentId,
        'srcCurrencyValue':selectedItem.srcCurrencyValue,
        'destCurrencyValue':selectedItem.destCurrencyValue,
        'adminRate' :selectedItem.adminRate,
        'markUpValue':selectedItem.markUpValue,
        'sellRate':sr,
        'agentBenifPayTypeId':selectedItem.agentBenifPayTypeId,
        'srcMarkUpValue':this.smv
      }

      if(this.srcExchRateForm.invalid){
          return;
      }
      else{
        this.srcExchRateForm.patchValue(newObj);
        console.log('srcExchRateForm==',this.srcExchRateForm.value);
        console.log('exchRateId..',selectedItem.exchRateId);
        this._exchangeratesservice.updateExchangeRateById(this.srcExchRateForm.value, this.processIdGeneraterService.getPid(),selectedItem.exchRateId).subscribe(res => {
          console.log("update srcExchRateForm....!.!" + res);

          if (res) {
            let changedData="Source Markup value="+this.smv;

            const trObj={
            ipAddress:this.ipAddress,
            category:'Update Exchange Rate By Source Agent',
            userType:localStorage.getItem("userTypeName"),
            userName:localStorage.getItem("uname"),
            originalData:originalData,
            changedData:changedData,
            userId:localStorage.getItem("adminId")
            }
            this.auditTrialForm.patchValue(trObj);
      
            this._auditTrialService.saveAuditTrailDetails(
              this.auditTrialForm.value,
               this.processIdGeneraterService.getPid()).subscribe(res => {
              if (res) {
                //this.List=res.response;
              }
            });
            this._router.navigate(['/exchage']);
           this.srcExchRateForm.reset();
            window.location.reload();
          }
        },
          error => {

            this.srcExchRateForm.reset();
            throw error;
          }
        );


  }
  }

    open_form(){

      this.isShow=true;
    }

}
