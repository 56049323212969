<app-indexheader></app-indexheader>
<ngx-ui-loader fgsColor="red" text="please wait"></ngx-ui-loader>
<main class="user_dashboard_main_container pt-0">
  <form class="dashboard_home_form pt-0" [formGroup]="transactionForm">
    <div class="mt-3">
      <div id="couponPage" #couponPage *ngIf="idVerifiedStatusFlag == 'false'">
        <h3>Thank you for registering.....</h3>
        <h4 class="text-danger">
          Your documents are being verified, Once verified we will notify you
          and you may start sending money
        </h4>
      </div>
      <div *ngIf="documentsValidDate && idVerifiedStatusFlag == 'true'">
        <h3>Documents Expired.....</h3>
        <h4>Please update to start sending money</h4>
      </div>
    </div>
    <div class="sending_group">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-6 px-0 px-lg-2">
            <div class="sender_form mt-lg-4 mt-0 py-3 py-lg-4">
              <span class="mdr">You Send</span>
              <div class="input_area">
                <input
                  class="xxlr"
                  type="number"
                  formControlName="sendingAmount"
                  (keyup)="onKeyup($event.target.value)"
                  placeholder="Sending Amount"
                  value="100"
                />
                <ng-select
                  class="get"
                  style="z-index: 1; width: 134px"
                  [(ngModel)]="selection"
                  formControlName="sendingAgentUserId"
                  (change)="onChangeSendingAgentId($event)"
                  placeholder="Sending County"
                >
                  <ng-option default value="{{ sagent }}-{{ currency1 }}">
                    <img
                      src="/assets/images/{{ sendingCountry }}.png"
                      width="25px"
                      alt=""
                    />&nbsp;&nbsp;
                    <span>{{ currency1 }}</span>
                  </ng-option>
                  <ng-option
                    *ngIf="currency2 != ''"
                    value="{{ sagent }}-{{ currency2 }}"
                  >
                    <img
                      src="/assets/images/{{ sendingCountry }}.png"
                      width="25px"
                      alt=""
                    />&nbsp;&nbsp; <span>{{ currency2 }}></span>&nbsp;&nbsp;
                  </ng-option>
                  <ng-option
                    *ngIf="currency3 != ''"
                    value="{{ sagent }}-{{ currency3 }}"
                  >
                    <img
                      src="/assets/images/{{ sendingCountry }}.png"
                      width="25px"
                      alt=""
                    />&nbsp;&nbsp;
                    <span>{{ currency2 }}></span>
                  </ng-option>
                </ng-select>
              </div>

              <span class="mdr d-lg-none d-flex">Recipient gets</span>
              <div class="input_area d-lg-none d-flex">
                <!-- <img *ngIf="!isloader" class="mb-mode" src="assets/images/dots-loader.gif" width="80px"
          style="text-align:center" alt=""> -->
                <input
                  class="xxlr"
                  placeholder="0.00"
                  type="number"
                  formControlName="receivingAmount"
                  name="PayOutAmount"
                  (keyup)="onKeyupR($event.target.value)"
                  id="PayOutAmount"
                  value="{{ recAmount }}"
                />
                <ng-select
                  class="get"
                  style="z-index: 1; width: 134px"
                  formControlName="receivingAgentId"
                  placeholder="test"
                  [(ngModel)]="reselection"
                  placeholder="Receiving Country"
                  (change)="onChangeRecAgentId($event)"
                >
                  <ng-option
                    *ngFor="let list of recAgentsList"
                    value="{{ list.destAgentId }}-{{ list.destCurrencyName }}"
                  >
                    <img
                      src="/assets/images/{{ list.destCountryName }}.jpg"
                      width="25px"
                      alt=""
                    />
                    &nbsp;
                    {{ list.destCurrencyName }}&nbsp;&nbsp;
                  </ng-option>
                </ng-select>
              </div>

              <div class="form-group" *ngIf="sendingAmountFlag">
                <span style="color: red"
                  >Maximum sending amount is {{ this.maxval }}</span
                >
              </div>

              <div class="form-group" *ngIf="minAmountFlag">
                <span style="color: red"
                  >Minimum sending amount is {{ this.minval }}</span
                >
              </div>

              <input
                class="form_control d-lg-flex d-none"
                placeholder="Promo Code"
                id="payerid"
                name="payerid"
                formControlName="promoCode"
              />
              <div class="d-none d-lg-flex" *ngIf="promoButtonFlag">
                <button
                  type="button"
                  (click)="onChangePromo()"
                  class="w-100 active text-center apply_btn"
                  name="button"
                >
                  Apply
                </button>
              </div>
              <div *ngIf="!promoButtonFlag">
                <button
                  type="button"
                  class="w-100 active text-center apply_btn"
                  name="button"
                >
                  Applied
                </button>
              </div>

              <div *ngIf="this.promoFlag">
                <span style="color: green">Your promo code is applied</span>
              </div>
              <div *ngIf="this.promoFlag1">
                <span style="color: red"
                  >Your promo code is invalid or expired</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-6 px-0 px-lg-2">
            <div class="receiver_form mmt-lg-4 mt-3 mt-lg-0 py-3 py-lg-4">
              <span class="mdr d-lg-flex d-none">Recipient gets</span>

              <div class="input_area d-lg-flex d-none">
                <!-- <img *ngIf="!isloader" class="mb-mode" src="assets/images/dots-loader.gif" width="80px"
          style="text-align:center" alt=""> -->
                <input
                  class="xxlr"
                  placeholder="0.00"
                  type="number"
                  formControlName="receivingAmount"
                  name="PayOutAmount"
                  (keyup)="onKeyupR($event.target.value)"
                  id="PayOutAmount"
                  value="{{ recAmount }}"
                />
                <ng-select
                  class="get"
                  style="z-index: 1; width: 134px"
                  formControlName="receivingAgentId"
                  placeholder="test"
                  [(ngModel)]="reselection"
                  placeholder="Receiving Country"
                  (change)="onChangeRecAgentId($event)"
                >
                  <ng-option
                    *ngFor="let list of recAgentsList"
                    value="{{ list.destAgentId }}-{{ list.destCurrencyName }}"
                  >
                    <img
                      src="/assets/images/{{ list.destCountryName }}.jpg"
                      width="25px"
                      alt=""
                    />
                    &nbsp;
                    {{ list.destCurrencyName }}&nbsp;&nbsp;
                  </ng-option>
                </ng-select>
              </div>

              <div class="d-flex align-items-center">
                <input
                  class="form_control col-6 col-lg-12 h-35"
                  placeholder="Referral Code"
                  formControlName="referralCode"
                />
                <div
                  class="d-block d-lg-none my-2 col-6 col-lg-12"
                  *ngIf="referralButtonFlag"
                >
                  <button
                    type="button"
                    (click)="onChangeReferral()"
                    class="w-100 active text-center apply_btn"
                    name="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <input
                  class="form_control d-flex d-lg-none h-35 col-6 col-lg-12"
                  placeholder="Promo Code"
                  id="payerid"
                  name="payerid"
                  formControlName="promoCode"
                />
                <div
                  class="col-6 col-lg-12 px-3 px-lg-0"
                  *ngIf="referralButtonFlag"
                >
                  <button
                    type="button"
                    (click)="onChangeReferral()"
                    class="w-100 active text-center apply_btn"
                    name="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div *ngIf="!referralButtonFlag">
                <button
                  type="button"
                  class="w-100 active text-center apply_btn"
                  name="button"
                >
                  Applied
                </button>
              </div>

              <div *ngIf="this.referralFlag">
                <span style="color: green">Your referral code is applied</span>
              </div>
              <div *ngIf="this.referralFlag1">
                <span style="color: red">Your referral code is invalid </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="payment_method_group my-3 my-lg-0">
      <div class="payemnt_method">
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-6 px-0 px-lg-3 d-lg-flex d-block justify-content-center align-items-center"
            >
              <h6 class="mb-0">Payout method</h6>
            </div>

            <div class="col-md-6 px-0">
              <select
                class="form-control pay-method"
                id="payerid"
                name="payerid"
                [(ngModel)]="selectpaytype"
                formControlName="agentBenifPayTypeId"
                (change)="onChangeAgentBenifPayTypeId($event.target.value)"
              >
                <option
                  *ngFor="let list of paymentTypeList"
                  value="{{ list.agentBenifPayTypeId }}"
                >
                  {{ list.benifPayTypeName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="payemnt_method">
        <div class="col-md-12">
          <div class="row">
            <div
              class="col-md-6 px-0 px-lg-3 d-lg-flex d-block justify-content-center align-items-center"
            >
              <h6 class="mb-0">Payment Method</h6>
            </div>

            <div class="col-md-6 px-0">
              <select
                class="form-control pay-method"
                (change)="onChangePayment($event.target.value)"
                id="payerid"
                name="payerid"
                [(ngModel)]="cselection"
                formControlName="paymentMethodType"
              >
                <option
                  *ngFor="let list of collectionTypeList"
                  value="{{ list.agentCollectionId }}"
                >
                  {{ list.collectionTypeName }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="calculator_group">
      <ul>
        <li>
          <p>Today Rate</p>
          <p>
            1 <b>{{ srcCurrencyName }}</b> = {{ exchRate }}
            <b>{{ destCurrencyName }} </b>
          </p>
        </li>
        <li>
          <p>Fee</p>
          <p>
            {{ srcCurrencyName }} <b>{{ sfee }}</b>
          </p>
        </li>
        <li>
          <p>Collection Fee</p>
          <p>
            {{ srcCurrencyName }} <b> {{ ColtnFee }}</b>
          </p>
        </li>
      </ul>

      <div class="total_transaction">
        <h5 style="color: #0c266c !important">Payable Amount</h5>
        <h5>
          {{ srcCurrencyName }} <b> {{ totalPay }} </b>
        </h5>
      </div>
    </div> -->

    <div class="calculation">
      <div
        class="head-area my-4"
        style="cursor: pointer"
        (click)="toggleShowCalculations()"
      >
        <img src="assetsnew/images/icon/conversion.png" alt="image" />
        <span class="mdr highlight"
          ><span class="show" *ngIf="!showCalculations">Show</span>
          <span class="show" *ngIf="showCalculations">Hide</span>
          calculation</span
        >
      </div>
      <!-- <div class="calculation-details" *ngIf="showCalculations">
        <div class="calculator_group">
          <ul>
            <li>
              <p>Today Rate</p>
              <p class="mdr" style="font-size: 13px;">
                1 <b>{{ srcCurrencyName }}</b> = {{ exchRate }}
                <b>{{ destCurrencyName }} </b>
              </p>
            </li>
            <li>
              <p>Fee</p>
              <p>
                {{ srcCurrencyName }} <b>{{ sfee }}</b>
              </p>
            </li>
            <li>
              <p>Collection Fee</p>
              <p>
                {{ srcCurrencyName }} <b> {{ ColtnFee }}</b>
              </p>
            </li>
          </ul>
    
          <div class="total_transaction">
            <h5 style="color: #0c266c !important">Payable Amount</h5>
            <h5>
              {{ srcCurrencyName }} <b> {{ totalPay }} </b>
            </h5>
          </div>
        </div>
      </div> -->

      <div class="calculation-details" *ngIf="showCalculations">
        <div class="single-area d-flex align-items-center pb-2">
          <div class="left-area">
            <!-- <span class="mdr" style="font-size: 12px">
              1 <b>{{ srcCurrencyName }}</b> = {{ exchRate }}
              <b>{{ destCurrencyName }} </b></span
            > -->
          </div>
          <!-- <div
            class="right-area text-right w-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">Today Rate</span>
          </div> -->
        </div>
        <div
          class="single-area d-flex align-items-center justify-content-between py-2"
        >
          <div class="left-area">
            <!-- <i class="fas fa-minus"></i> -->
            <span class="mdr">
              1 <b>{{ srcCurrencyName }}</b> = {{ exchRate }}
              <b>{{ destCurrencyName }}</b>
            </span>
          </div>
          <div
            class="right-area text-right w-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">Today Rate</span>
          </div>
          <!-- <div
            class="right-area text-right ww-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">fee</span>
          </div> -->
        </div>
        <div
          class="single-area d-flex align-items-center justify-content-between py-2"
        >
          <div class="left-area">
            <!-- <i class="fas fa-minus"></i> -->
            <span class="mdr"
              >{{ sfee }} <b>{{ srcCurrencyName }}</b></span
            >
          </div>
          <div
            class="right-area text-right ww-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">fee</span>
          </div>
          <!-- <div
            class="right-area text-right ww-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">Collection Fee</span>
          </div> -->
        </div>

        <div
          class="single-area d-flex align-items-center justify-content-between py-2"
        >
          <div class="left-area">
            <!-- <i class="fas fa-minus"></i> -->
            <span class="mdr"
              >{{ ColtnFee }} <b> {{ srcCurrencyName }}</b></span
            >
          </div>

          <div
            class="right-area text-right ww-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">Collection Fee</span>
          </div>
        </div>

        <div
          class="single-area d-flex align-items-center justify-content-between py-2"
        >
          <div class="left-area">
            <!-- <i class="fas fa-minus"></i> -->
            <span class="mdr"
              >{{ totalPay }} <b> {{ srcCurrencyName }}</b></span
            >
          </div>
          <!-- <span >Payable Amount</span> -->

          <div
            class="right-area text-right ww-100 d-flex align-items-center justify-content-end"
          >
            <span class="mdr">Payable Amount</span>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_area mmt-40 mt-4">
      <div class="row" *ngIf="!isloader">
        <div class="text-center">
          <img
            class="mb-mode"
            src="assets/images/dots-loader.gif"
            width="30%"
            height="30px"
            alt=""
          />
        </div>
      </div>
      <div *ngIf="isloader" class="w-100">
        <div
          *ngIf="idVerifiedStatusFlag == 'true' && !documentsValidDate"
          class="w-100"
        >
          <a
            class="w-100 active text-center"
            href="javascript:void(0)"
            (click)="onSubmit()"
            >Proceed With Sending Payment</a
          >
        </div>
      </div>
    </div>
  </form>

  <div class="transactions_container d-flex flex-lg-row flex-column">
    <div class="transaction_number_card">
      <div class="img_area">
        <img src="assetsnew/dashboard/images/icon/spend-get.png" alt="" />
      </div>
      <div class="text-area">
        <p>Total Transactions</p>
        <span data-toggle="counter" class="d-block text-end" data-end="113">{{
          numberOfTransactions
        }}</span>
      </div>
    </div>
    <div class="transaction_number_card">
      <div class="img_area">
        <img src="assetsnew/dashboard/images/icon/spend-get.png" alt="" />
      </div>
      <div class="text-area">
        <p>Completed Transactions</p>
        <span data-toggle="counter" class="d-block text-end" data-end="113">{{
          completedTransactions
        }}</span>
      </div>
    </div>
    <div class="transaction_number_card">
      <div class="img_area">
        <img src="assetsnew/dashboard/images/icon/spend-get.png" alt="" />
      </div>
      <div class="text-area">
        <p>Pending Transactions</p>
        <span data-toggle="counter" class="d-block text-end" data-end="113">{{
          pendingTransactions
        }}</span>
      </div>
    </div>
  </div>

  <section class="d-block">
    <div class="transactions-area mt-40">
      <div class="section-text">
        <h5 style="color: #0c266c !important">Transactions</h5>
        <p>Updated every several minutes</p>
      </div>
      <div
        class="top-area d-flexx d-none align-items-center justify-content-between"
      >
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="latest-tab"
              data-bs-toggle="tab"
              data-bs-target="#latest"
              type="button"
              role="tab"
              aria-controls="latest"
              aria-selected="true"
            >
              Latest
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="upcoming-tab"
              data-bs-toggle="tab"
              data-bs-target="#upcoming"
              type="button"
              role="tab"
              aria-controls="upcoming"
              aria-selected="false"
            >
              Upcoming
            </button>
          </li>
        </ul>
        <div class="view-all d-flex align-items-center">
          <a href="javascript:void(0)">View All</a>
          <img
            src="assetsnew/dashboard/images/icon/right-arrow.png"
            alt="icon"
          />
        </div>
      </div>
      <div class="tab-content mt-40">
        <div
          class="tab-pane fade show active"
          id="latest"
          role="tabpanel"
          aria-labelledby="latest-tab"
        >
          <div class="table-responsive-xxl">
            <table class="table" *ngIf="!isTransactionListTableEmpty">
              <thead>
                <tr>
                  <th scope="col">Name / Business</th>
                  <th scope="col">Date</th>
                  <th scope="col">Status</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Receipt</th>
                  <th scope="col">Download Receipt</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let list of transactionList
                      ? transactionList.slice(0, 10)
                      : []
                  "
                >
                  <th
                    scope="row"
                    data-bs-toggle="modal"
                    data-bs-target="#transactionsMod"
                    (click)="openTransactionModal(list)"
                    class="transaction_row"
                  >
                    <p>{{ list.cusName }} {{ list.custLastName }}</p>
                    <p class="mdr">
                      <span class="text-normal">To</span>
                      {{ list.benificiaryName | titlecase }}
                      {{ list.beneficiaryLastName }}
                    </p>
                  </th>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#transactionsMod"
                    (click)="openTransactionModal(list)"
                    class="transaction_row"
                  >
                    <p></p>
                    <p class="mdr">{{ list.createdOn }}</p>
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#transactionsMod"
                    (click)="openTransactionModal(list)"
                    class="transaction_row"
                  >
                    <div
                      class="mb-10"
                      *ngIf="
                        list.status ==
                        'Possible duplicate transaction.\nPlease check transaction history'
                      "
                    >
                      <p [ngClass]="getTransactionListStatusClass(list.status)">
                        Paid
                      </p>
                    </div>
                    <div
                      class="mb-10"
                      *ngIf="list.status == 'Duplicate request ID'"
                    >
                      <p [ngClass]="getTransactionListStatusClass(list.status)">
                        Paid
                      </p>
                    </div>
                    <div
                      class="mb-10"
                      *ngIf="
                        list.status !==
                          'Possible duplicate transaction.\nPlease check transaction history' &&
                        list.status !== 'Duplicate request ID'
                      "
                    >
                      <p [ngClass]="getTransactionListStatusClass(list.status)">
                        {{ list.status }}
                      </p>
                    </div>
                    <div class="mb-10" *ngIf="list.complainceRequired == 'yes'">
                      <h3 style="color: red">awaiting compliance review</h3>
                    </div>
                  </td>
                  <td
                    data-bs-toggle="modal"
                    data-bs-target="#transactionsMod"
                    (click)="openTransactionModal(list)"
                    class="transaction_row"
                  >
                    <p>
                      {{ list.receivingAmount }} {{ list.receivingCurrency }}
                    </p>
                    <p class="mdr">
                      {{ list.sendingAmount }} {{ list.sendingCurrency }}
                    </p>
                  </td>
                  <td>
                    <button class="mdr" (click)="onClick2(list.transactionId)">
                      Receipt
                    </button>
                  </td>
                  <td>
                    <button
                      class="mdr"
                      (click)="downloadPdf(list.transactionId)"
                    >
                      Download Receipt
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<div *ngIf="pdf == 'yes'" class="container">
  <div class="row" id="transactionDetails">
    <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
    <div class="new col-md-6">
      <div class="row">
        <div class="col-md-3">
          <img src="assetsnew/images/logo-light.png" width="100px;" />
        </div>
        <div class="col-md-9">
          <p>RECEIPT-Customer Copy</p>
          <p>Print Date: {{ timeStamp }}</p>
        </div>
        <div class="col-md-12">
          <br />
          <p>
            <b>
              Company Name: TAYREX EXCHANGE PTY LTD<br />
              Address: Australia
            </b>
          </p>
          <p>
            Email Id: {{ txn.senderEmail }}<br />
            Phone Number: {{ txn.senderPhone }}
          </p>
        </div>
      </div>
    </div>
    <div class="new col-md-6">
      <table id="transactionDetailsTable">
        <tr>
          <td>Trans Ref No. :</td>
          <th style="padding-left: 20px">{{ txn.transactionId }}</th>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="new col-md-6" id="remittersTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <p>REMITTER'S DETAILS:</p>
      <table>
        <tr>
          <td>First Name. :</td>
          <th style="padding-left: 20px">
            {{ cust.firstName | titlecase }}
          </th>
        </tr>
        <tr>
          <td>Last Name. :</td>
          <th style="padding-left: 20px">
            {{ cust.lastName | titlecase }}
          </th>
        </tr>
        <tr>
          <td>Email Id. :</td>
          <th style="padding-left: 20px">
            {{ cust.userEmailId }}
          </th>
        </tr>
        <tr>
          <td>phone Number. :</td>
          <th style="padding-left: 20px">
            {{ cust.mobileNumber }}
          </th>
        </tr>
        <tr>
          <td>Identity Type Name. :</td>
          <th style="padding-left: 20px">
            {{ cust.identityTypeName }}
          </th>
        </tr>
        <tr>
          <td>Identity Number. :</td>
          <th style="padding-left: 20px">
            {{ cust.identityDocumentNumber }}
          </th>
        </tr>
      </table>
    </div>
    <div class="new col-md-6" id="beneficiariesTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <p>BENEFICIARY'S DETAILS</p>
      <table>
        <tr>
          <td>First Name. :</td>
          <th style="padding-left: 20px">
            {{ txn.benificiaryName | titlecase }}
          </th>
        </tr>
        <tr>
          <td>Last Name. :</td>
          <th style="padding-left: 20px">
            {{ txn.beneficiaryLastName | titlecase }}
          </th>
        </tr>
        <tr>
          <td>Phone Number. :</td>
          <th style="padding-left: 20px">
            {{ txn.beneficiaryPhone }}
          </th>
        </tr>
        <tr>
          <td>Account Number. :</td>
          <th
            *ngIf="
              txn.accountNumber != null && txn.accountNumber != '';
              else elseBlock
            "
            style="padding-left: 20px"
          >
            {{ txn.accountNumber }}
          </th>
          <ng-template #elseBlock>
            <th style="padding-left: 20px">NA</th>
          </ng-template>
        </tr>

        <tr>
          <td>Bank Name. :</td>
          <th
            *ngIf="txn.bankName != '' && txn.bankName != null; else elseBlock"
            style="padding-left: 20px"
          >
            {{ txn.bankName }}
          </th>
        </tr>

        <tr>
          <td>Branch Code. :</td>
          <th
            *ngIf="
              txn.branchCode != '' && txn.branchCode != null;
              else elseBlock
            "
            style="padding-left: 20px"
          >
            {{ txn.branchCode }}
          </th>
        </tr>

        <tr>
          <td *ngIf="txn.destCountryName != 'UNITED KINGDOM'">
            Routing Number. :
          </td>
          <td *ngIf="txn.destCountryName == 'UNITED KINGDOM'">Sort Code. :</td>
          <th
            *ngIf="txn.swiftCode != '' && txn.swiftCode != null; else elseBlock"
            style="padding-left: 20px"
          >
            {{ txn.swiftCode }}
          </th>
        </tr>
      </table>
    </div>
  </div>
  <div class="row">
    <div class="new col-md-6" id="paymentDetailsTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <p>PAYMENT DETAILS:</p>
      <table>
        <tr>
          <td>Send Amount. :</td>
          <th style="padding-left: 20px">
            {{
              txn.sendingAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            }}
            {{ txn.sendingCurrency }}
          </th>
        </tr>
        <tr>
          <td>Transaction Amount. :</td>
          <th style="padding-left: 20px">
            {{ txn.transactionFee }} {{ txn.sendingCurrency }}
          </th>
        </tr>
        <tr>
          <td>Payment Charges. :</td>
          <th style="padding-left: 20px">
            {{ txn.markupVal }} {{ txn.sendingCurrency }}
          </th>
        </tr>

        <tr>
          <td>Total Amount. :</td>
          <th style="padding-left: 20px">
            {{
              txn.totalAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            }}
            {{ txn.sendingCurrency }}
          </th>
        </tr>

        <tr>
          <td>Payment Type. :</td>
          <th style="padding-left: 20px">
            {{ txn.collectionType }}
          </th>
        </tr>
      </table>
    </div>
    <div class="new col-md-6" id="transferDetailsTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <p>TRANSFER AMOUNT DETAILS :</p>
      <table>
        <tr>
          <td>Exchange Rate. :</td>
          <th style="padding-left: 20px">
            {{ txn.sellRate }} {{ txn.receivingCurrency }}
          </th>
        </tr>
        <tr>
          <td>Dest Amount. :</td>
          <th style="padding-left: 20px">
            {{
              txn.receivingAmount.toLocaleString(undefined, {
                minimumFractionDigits: 2
              })
            }}
            {{ txn.receivingCurrency }}
          </th>
        </tr>
        <tr>
          <td>Payment Method. :</td>
          <th style="padding-left: 20px">
            {{ txn.beneficiaryPaymentType }}
          </th>
        </tr>

        <!-- Add more rows for transfer details -->
      </table>
    </div>
  </div>
  <div class="row">
    <div class="new col-md-6" id="CustomersignaturesTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->

      <div class="new col-md-6">
        <p>
          <b
            >Customer Signature<br />{{ cust.firstName }} {{ cust.lastName }}</b
          >
        </p>
      </div>
    </div>
    <div class="new col-md-6" id="AgentsignaturesTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <div class="new col-md-6">
        <p>
          <b>Agent Signature<br />{{ txn.sendAgentName }}</b>
        </p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="new col-md-12" id="termsConditionsTable">
      <!-- <hr style="margin-top: 20px; margin-bottom: 20px;"> -->
      <p>
        Terms and conditions apply. Disclosures will only be made to the
        relevant authorities. Should you wish to complain, you must contact us
        within 180 days from the date the order was placed, producing this
        receipt. This receipt is not valid unless signed by an
        txnDetail.admin.companyName agent or employee, or sent by Tayrex email.
        FRAUD WARNING: Do not dispatch money to obtain a loan or a credit card.
        Do not send money to claim lottery and/or contest winnings, or in the
        certainty of giving brides or obtaining monetary benefits. Do not
        forward money to an anonymous or unfamiliar person. Do not disclose any
        transaction details to a third party. www.tayrexchange.com.com
      </p>
    </div>
  </div>
</div>

<app-dashboardmodal
  *ngIf="selectedTransaction"
  [transaction]="selectedTransaction"
  (closeModal)="closeTransactionModal()"
></app-dashboardmodal>
