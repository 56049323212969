import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndexRoutingModule } from './index-routing.module';
import { IndexComponent } from './index.component';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { HeaderfrontModule } from 'src/app/headerfront/headerfront.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
@NgModule({
  declarations: [IndexComponent, HeaderComponent],
  imports: [
    CommonModule,HeaderfrontModule,NgSelectModule ,
    IndexRoutingModule,FormsModule,ReactiveFormsModule,NgxUiLoaderModule
  ],
  exports:[IndexComponent]
})
export class IndexModule { }
