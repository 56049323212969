import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
@Component({
  selector: 'app-beneficiarymodal',
  templateUrl: './beneficiarymodal.component.html',
  styleUrls: ['./beneficiarymodal.component.css'],
})
export class BeneficiaryModalComponent implements OnInit {
  @Input() beneficiary: any;
  // @Input() selectedBeneficiaryId: any;
  @Output() closeModal = new EventEmitter<void>();
  @Output() updateBeneficiary = new EventEmitter<void>();
  // @Output() getTransactions = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();

  closeBackDrop(e: MouseEvent) {
    const target = e.target as HTMLDivElement;
    const modal = document.querySelectorAll('.my_modal');
    if (!target.classList.contains('modal-dialog')) {
      modal.forEach((el: HTMLDivElement) => (el.style.display = 'none'));
    }
  }

  constructor(  
      private _router:Router
  ) { }

  ngOnInit(
    
  ): void {}

  // deleteBeneficiary() {
  //   alert(this.selectedBeneficiaryId)
  //   return
  //   this.delete.emit(this.selectedBeneficiaryId);
  // }
  getTransactions(list:any){
    console.log('sdsdfsdfsdf....', list)
    const obj={
      'beneficiaryId':list.beneficiaryId,
      'destCountry':list.countryName,
      'beneficiaryName':list.firstName,
      'beneficiaryLastName':list.lastName,
      'beneficiaryAddress':list.address,
      'beneficiaryNumber':list.phoneNumber,
      'beneficiaryNationality':list.countryName,
    }
    localStorage.setItem("reportingObj",JSON.stringify(obj))
  
    this._router.navigate(['/beneficiaryreport'])
  
  }
}
