<!-- start preloader -->
<!-- <div *ngIf="showContent">
  <div class="preloader" id="preloader"></div>
</div> -->



<!-- popup  -->

<div class="popup">
  <div class="popup-content text-center">
      <!-- <h1>X</h1> -->
      <!-- <h2>Disclaimer</h2> -->
      <p>Discover the All-New Experience! Our latest website Unveiled - Seemlessly designed for you. Do more on Tayrex new website. Find out the array of exciting packages 🎁 you stand to enjoy in Fund Remittance, FX Marketplace, Airtime, Bill payment, Data top-ups and many more amazing offers. Login to learn more ....</p>
<div class="btn pop-btn  ">
 Ok
</div>
  </div>
</div>

<!-- Navbar start -->
<header class="header-section fixed" (scroll)="onScroll()">
  <div class="overlay">
    <div class="container">
      <div class="row d-flex header-area">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" routerLink="/">
            <img
              src="assetsnew/images/logo-light.png"
              class="logo"
              alt="logo"
              loading="lazy"
            />
          </a>
          <!-- <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <i class="fas fa-bars navbar-toggler-icon"></i>
          </button> -->
           <button class="navbar-toggler" type="button" data-toggle="collapse" 
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" 
            aria-expanded="false" aria-label="Toggle navigation">
   <i class="fas fa-bars "></i>
  </button>
          <div
          class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent"
          >
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown main-navbar">
                <a
                  class="nav-link"
                  routerLink="/aboutus"
                  >About Us</a
                >
              </li>
              <li class="nav-item dropdown main-navbar">
                <a
                  class="nav-link"
                  routerLink="/contactus"
                  >Contact Us</a
                >
              </li>
              <li class="nav-item dropdown main-navbar">
                <a
                  class="nav-link"
                  (click)="openfaqs()"
                  >Faqs</a
                >
              </li>
              <!-- <li class="nav-item dropdown main-navbar">
                <a
                  class="nav-link"
                  routerLink="/faqs"
                  >Faqs</a
                >
              </li> -->
              <li class="nav-item dropdown main-navbar">
                <a
                  class="nav-link"
                  routerLink="/help"
                  >How it works</a
                >
              </li>
              <li class="nav-item dropdown main-navbar">
                <a
                routerLink="/login"
                  class="nav-link"
                  >Login</a
                >
              </li>
            </ul>
            <div
              class="right-area header-action d-flex align-items-center max-un"
            >
              <a
              routerLink="/signup"
                class="cmn-btn command_btn py-1"
                >Sign Up
                <i class="icon-d-right-arrow-2"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Navbar end -->



<!-- Banner Section start -->
<section class="banner-section index">
  <div class="overlay">
    <div class="shape-area">
      <img src="assetsnew/images/banner-box.png" class="obj-1" alt="image" />
      <img src="assetsnew/images/banner-human.png" class="obj-2" alt="image" />
      <img src="assetsnew/images/banner-rocket.png" class="obj-3" alt="image" />
      <img src="assetsnew/images/banner-clock.png" class="obj-4" alt="image" />
    </div>

    <div class="banner-content">
      <div class="container wow fadeInUp">
        <div class="content-shape">
          <img
            src="assetsnew/images/banner-wallet.png"
            class="obj-1"
            alt="image"
          />
        </div>

        <div class="row justify-content-between align-items-center">
          <div class="col-lg-5 col-md-6">
            <div class="main-content">
              <div class="top-area section-text">
                <h5 class="sub-title">Trusted by over 3M customers</h5>
                <h1 class="title">A Seamless Transfer</h1>
                <p class="xlr">With No Hidden Fees</p>
              </div>
              <div class="bottom-area d-flex justify-content-center justify-content-lg-start">
                <a
                  href="javascript:void(0)"
                  (click)="opensignup()"
                  class="cmn-btn py-1"
                  >Open a Free Account</a
                >
                <a
                  href="javascript:void(0)"
                  (click)="openhelp()"
                  class="cmn-btn py-1"
                  >See How it <br />
                  works</a
                >
              </div>
            </div>
          </div>
          <div class="col-xxl-4 col-xl-5 col-md-6">
            <div class="send-money">
              <form form [formGroup]="transactionForm" class="form_container">
                <div class="form_upper">
                  <div class="upper_left">
                    <span>You send</span>
                    <input
                      type="text"
                      class="left_side_input"
                      value=""
                      placeholder="Enter Amount"
                      formControlName="sendingAmount"
                      (keyup)="onKeyup($event.target.value)"
                      style="font-size: 20px; font-weight: bold"
                    />
                  </div>
                  <ng-select
                    [(ngModel)]="selection"
                    class="upper_right"
                    formControlName="sendingAgentUserId"
                    (change)="onChangeSendingAgentId($event)"
                  >
                    <ng-option
                      *ngFor="let list of sendingAgentsList"
                      value="{{ list.userId }}-{{ list.currency1 }}"
                    >
                      <img
                        src="/assets/images/{{ list.countryName }}.png"
                        width="25px"
                        alt=""
                      />
                      &nbsp;
                      <span>{{ list.currency1 }}</span>
                    </ng-option>
                  </ng-select>
                </div>

                <div class="form-group" *ngIf="sendingAmountFlag">
                  <span style="color: red; font-size: 13px"
                    >Maximum sending amount is {{ this.maxval }}</span
                  >
                </div>

                <div class="form-group" *ngIf="minAmountFlag">
                  <span style="color: red; font-size: 13px"
                    >Minimum sending amount is {{ this.minval }}</span
                  >
                </div>

                <div class="form_upper px-0" style="background-color: #fff">
                  <div class="w-100">
                    <div class="pay-option-single w-100">
                      <h6 class="m-2 payment_option_text">Payout method</h6>
                      <select
                        class="middle_select w-100 px-3"
                        [(ngModel)]="selectpaytype"
                        id="payerid"
                        name="payerid"
                        formControlName="agentBenifPayTypeId"
                        (change)="
                        onChangeAgentBenifPayTypeId($event.target.value)
                        "
                        style="display: block !important;"
                      >
                        <option
                          *ngFor="let list of paymentTypeList"
                          value="{{ list.agentBenifPayTypeId }}"
                        >
                          {{ list.benifPayTypeName }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="w-100">
                    <div class="pay-option-single border-area w-100">
                      <h6 class="m-2 payment_option_text">Payment Method</h6>
                      <select
                        class="middle_select w-100 px-3"
                        [(ngModel)]="cselection"
                        id="payerid"
                        name="payerid"
                        formControlName="paymentMethodType"
                        (change)="onChangePayment($event.target.value)"
                      >
                        <option
                          *ngFor="let list of collectionTypeList"
                          value="{{ list.agentCollectionId }}"
                        >
                          {{ list.collectionTypeName }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="pay-option promo-option">
                  <div class="pay-option-single">
                    <div class="row mt-3">
                      <div class="col-6" *ngIf="promoButtonFlag">
                        <input
                          style="border-radius: 0"
                          class="form_control mb-2"
                          placeholder="Promo Code"
                        />
                        <a
                          href="javascript:void(0)"
                          class="cmn-btn w-100"
                          (click)="onChangePromo()"
                          >Apply Now</a
                        >
                      </div>
                      <!-- <div class="col-6" *ngIf="!promoButtonFlag"> -->
                      <div class="col-6" >
                        <input
                          class="form_control mb-2"
                          placeholder="Referral Code"
                        />
                        <div>
                          <a href="javascript:void(0)" class="cmn-btn w-100"
                            >Apply Now</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="calculation">
                  <div
                    class="head-area"
                    style="cursor: pointer"
                    (click)="toggleShowCalculations()"
                  >
                    <img
                      src="assetsnew/images/icon/conversion.png"
                      alt="image"
                    />
                    <span class="mdr highlight"
                      ><span class="show" *ngIf="!showCalculations">Show</span>
                      <span class="show" *ngIf="showCalculations">Hide</span>
                      calculation</span
                    >
                  </div>
                  <div class="calculation-details" *ngIf="showCalculations">
                    <div class="single-area d-flex align-items-center">
                      <div class="left-area">
                        <span class="mdr" style="font-size: 12px">
                          1 <b>{{ srcCurrencyName }}</b> = {{ exchRate }}
                          <b>{{ destCurrencyName }} </b></span
                        >
                      </div>
                      <div
                        class="right-area text-right w-100 d-flex align-items-center justify-content-end"
                      >
                        <span class="mdr">Today Rate</span>
                      </div>
                    </div>
                    <div class="single-area d-flex align-items-center">
                      <div class="left-area">
                        <i class="fas fa-minus"></i>
                        <span class="mdr"
                          >{{ srcCurrencyName }} <b>{{ sfee }}</b></span
                        >
                      </div>
                      <div
                        class="right-area text-right w-100 d-flex align-items-center justify-content-end"
                      >
                        <span class="mdr">Our fee</span>
                      </div>
                    </div>
                    <div class="single-area d-flex align-items-center">
                      <div class="left-area">
                        <i class="fas fa-minus"></i>
                        <span class="mdr"
                          >{{ srcCurrencyName }} <b>{{ ColtnFee }}</b></span
                        >
                      </div>
                      <div
                        class="right-area text-right w-100 d-flex align-items-center justify-content-end"
                      >
                        <span class="mdr">Collection Fee</span>
                      </div>
                    </div>
                    <div class="single-area d-flex align-items-center">
                      <div class="left-area">
                        <i class="fas fa-equals"></i>
                        <span class="mdr"
                          >{{ srcCurrencyName }} <b> {{ totalPay }} </b></span
                        >
                      </div>
                      <div
                        class="right-area text-right w-100 d-flex align-items-center justify-content-end"
                      >
                        <span class="mdr highlight">Payable Amount</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form_upper">
                  <div class="upper_left">
                    <span>Recipient gets</span>
                    <!-- <img *ngIf="!isloader" class="mb-mode" src="assets/images/dots-loader.gif" width="80px"
                          style="text-align:center" alt=""> -->
                    <input
                        class="left_side_input"
                        type="number"
                        formControlName="receivingAmount"
                        name="PayOutAmount"
                        [value]="recAmount"
                        style="
                            background: white;
                            font-size: 22px;
                            font-weight: bold;
                        "
                        (keyup)="onKeyupR($event.target.value)"
                        id="PayOutAmount"
                        placeholder="Receiving Amount"
                    />
                </div>
                
                  <ng-select
                    class="upper_right"
                    formControlName="receivingAgentId"
                    [(ngModel)]="reselection"
                    placeholder="County"
                    (change)="onChangeRecAgentId($event)"
                  >
                    <ng-option
                      *ngFor="let list of recAgentsList"
                      value="{{ list.destAgentId }}-{{ list.destCurrencyName }}"
                    >
                      <img
                        src="/assets/images/{{ list.destCountryName }}.jpg"
                        width="25px"
                        height="25px"
                        alt="{{ list.destCountryName }}"
                      />
                      &nbsp;
                      <span>{{ list.destCurrencyName }}</span>
                    </ng-option>
                  </ng-select>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="counter-section">
    <div class="container wow fadeInUp itterate">
      <div class="row cus-mar">
        <div class="col-xl-4 col-md-4 col-sm-6">
          <div
            class="single-area d-flex align-items-center justify-content-center"
          >
            <div class="text-area text-center">
              <p class="h3">Super Rates</p>
              <p>
                Our super rates and low fees are tough to beat. No bank charges
                with any transfer you make.
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-6">
          <div
            class="single-area d-flex align-items-center justify-content-center"
          >
            <div class="text-area text-center">
              <p class="h3">Super Fast</p>
              <p>
                All our transactions are made in a timely manner. You can be
                sure of an instant transfers
              </p>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-md-4 col-sm-6">
          <div
            class="single-area d-flex align-items-center justify-content-center"
          >
            <div class="text-area text-center">
              <p class="h3">100% Security</p>
              <p>
                We pride ourselves in the area of security as it is our duty to
                ensure all transactions are secured
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Banner Section end -->

<!-- Features start -->
<section class="global-payment features">
  <div class="overlay pt-120 pb-120">
    <div class="container wow fadeInUp">
      <div class="row align-items-center justify-content-between">
        <div class="col-xl-5 order-xl-0 order-1">
          <div class="image-area d-rtl left-side">
            <img
              src="assetsnew/images/global-payment-2.png"
              alt="images"
              class="max-un"
            />
          </div>
        </div>
        <div class="col-xxl-6 col-xl-7">
          <div class="row cus-mar">
            <div class="col-lg-12">
              <div class="section-header">
                <h5 class="sub-title">
                  Everything You Need to Accept Online Payments
                </h5>
                <h2 class="title">One Platform. Every Payment Possibility</h2>
                <p>
                  Making international payments and receivables, easy and
                  efficient.
                </p>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <div class="single-item">
                <img
                  src="assetsnew/images/icon/features-icon-1.png"
                  alt="icon"
                />
                <h5>Cheaper than banks</h5>
                <p>Significant savings on your global payments</p>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <div class="single-item">
                <img
                  src="assetsnew/images/icon/features-icon-2.png"
                  alt="icon"
                />
                <h5>Faster than banks</h5>
                <p>Have payments received in as soon as one..</p>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <div class="single-item">
                <img
                  src="assetsnew/images/icon/features-icon-3.png"
                  alt="icon"
                />
                <h5>Global reach</h5>
                <p>Make payments across the globe to more than..</p>
              </div>
            </div>
            <div class="col-sm-6 col-6">
              <div class="single-item">
                <img
                  src="assetsnew/images/icon/features-icon-4.png"
                  alt="icon"
                />
                <h5>Get local support</h5>
                <p>Receive full support from our local success..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Features end -->

<!-- Business Model start -->
<section class="our-solutions business-model">
  <div class="overlay pt-120 pb-120">
    <div class="container wow fadeInUp">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-header dark-sec text-center">
            <h5 class="sub-title">
              Solutions built for your seamless transactions
            </h5>
            <h2 class="title">All-in-one solutions for your money transfer</h2>
            <p>
              Save time and money with borderless payments, bill payments,
              currency exchange, and more. All in one account.
            </p>
          </div>
        </div>
      </div>
      <div class="row cus-mar pt-5">
        <div class="col-xl-4 col-6">
          <div class="single-item pb-4">
            <img
              src="assetsnew/images/icon/business-model-icon-1.png"
              alt="icon"
            />
            <h5>Open an Account</h5>
            <p>
              Get an account ID and referral bonus instantly upon approval.
              Manage it all from your account.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-6">
          <div class="single-item pb-4">
            <img
              src="assetsnew/images/icon/business-model-icon-2.png"
              alt="icon"
            />
            <h5>No hidden fees</h5>
            <p>
              Enjoy the lowest transfer fees in the market with transparent
              pricing and no monthly fees.
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-6">
          <div class="single-item pt-5">
            <img
              src="assetsnew/images/icon/business-model-icon-3.png"
              alt="icon"
            />
            <h5>All in one place</h5>
            <p>
              No need for separate accounts. Scale faster with a fully
              integrated solutions
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Business Model end -->

<!-- How it Works start -->
<section class="how-it-works">
  <div class="overlay pb-120">
    <!-- <div class="container wow fadeInUp">
            <div class="row justify-content-center">
                <div class="col-lg-8">
                    <div class="section-header text-center">
                    </div>
                </div>
            </div>
            <div class="row cus-mar">
                <div class="col-xl-3 col-sm-6 col-6">
                    <div class="single-item first text-center">
                        <img src="assets/images/icon/how-works-icon-1.png" alt="icon">
                        <h5>Register for free</h5>
                        <p>Simply sign up online for free and verify your identity</p>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-6">
                    <div class="single-item second text-center">
                        <img src="assets/images/icon/how-works-icon-2.png" alt="icon">
                        <h5>Set up your transfer</h5>
                        <p>Add a recipient's details and choose which currency ...</p>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-6">
                    <div class="single-item first text-center">
                        <img src="assets/images/icon/how-works-icon-3.png" alt="icon">
                        <h5>Make your payment</h5>
                        <p>Send us your funds with a bank transfer and we'll notify..</p>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-6 col-6">
                    <div class="single-item text-center">
                        <img src="assets/images/icon/how-works-icon-4.png" alt="icon">
                        <h5>You're all done!</h5>
                        <p>We inform you when the money has been sent and can also ...</p>
                    </div>
                </div>
            </div>
        </div> -->
  </div>
</section>
<!-- How it Works end -->

<!-- App Download start -->
<section class="app-download">
  <div class="overlay pb-120">
    <div class="container wow fadeInUp">
      <div class="row justify-content-between align-items-center">
        <div class="col-lg-6 order-lg-0 order-1">
          <div class="image-area d-rtl left-side">
            <img
              src="assetsnew/images/app-download-img.png"
              alt="images"
              class="max-un"
            />
          </div>
        </div>
        <div class="col-lg-5">
          <div class="section-area">
            <h5 class="sub-title">App Download</h5>
            <h2 class="title">
              A fast and secure way to send money on the go from Australia
            </h2>
            <p>
              Download our app free to send money online in minutes to our 130
              other countries.Track your payments and view your transfer history
              from anywhere.
            </p>
          </div>

          <div class="brand-area mt-40">
            <a
              href="https://play.google.com/store/apps/details?id=com.tayrexchange.ap"
              target="_blank"
            >
              <img src="assetsnew/images/GooglePlay-btn.png" alt="icon" />
            </a>
            <a
              href="https://apps.apple.com/us/app/id1590231468"
              target="_blank"
            >
              <img src="assetsnew/images/apple-btn.png" alt="icon" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- App Download end -->

<!-- Testimonials start -->
<section class="testimonials">
  <div class="overlay pt-120 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div class="section-header text-center">
            <h5 class="sub-title">Testimonials</h5>
            <h2 class="title">What Our Customers Say</h2>
            <p>
              50,119 happy clients all around the world. Don’t just take our
              word for it
            </p>
            <img src="assetsnew/images/yellows.jpg" class="review_img" />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid wow fadeInUp">
      <div class="testimonials-carousel pb-0">
        <app-testimonial></app-testimonial>
      </div>
    </div>
  </div>
</section>
<!-- App Download end -->

<app-new-footer></app-new-footer>
