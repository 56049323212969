import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promotions-lis',
  templateUrl: './promotions-lis.component.html',
  styleUrls: ['./promotions-lis.component.css']
})
export class PromotionsLisComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
