import { Component, OnInit ,HostListener} from '@angular/core';
import { LogOutService } from '../../service';
import {AccessLogService } from '../../service';
import { FormGroup,FormControl,Validators} from '@angular/forms';
import { ProcessIdGeneraterService} from '../../service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';


@Component({
  selector: 'app-backdashboard',
  templateUrl: './backdashboard.component.html',
  styleUrls: ['./backdashboard.component.css']
})
export class BackdashboardComponent implements OnInit {
  branchUrl:boolean;
  remitzcomplaince:boolean;
  remitzmarketing:boolean;
  AccessLogForm:FormGroup;
  showSlider = false;
  isMobileView = false;
agentUrl:boolean;
sendReceiveName:string;
sendUrl:boolean;
recUrl:boolean;
newrec:boolean;
newsend:boolean;
backnavbar:boolean;
userTypeName:any;
subRoleId:string;
switchUser:boolean;

rolesuser:boolean;


AccessLogg:boolean;
agentcreation:boolean;
Velocity_Checks:boolean;
AuditTrail:boolean;
Branch_Creation:boolean;
TransactionListad:boolean;
Sell_Rates:boolean;
Service_Fee:boolean;
Api_Info:boolean;
Transactionlistr:boolean;
Payment_Type:boolean;
Collection_Fee:boolean;
Profit_Loss:boolean;
Sanction_List:boolean;
Kyc_Approval:boolean;
Customer_List:boolean;
Beneficiary_List:boolean;
Agent_Deposit_List:boolean;
Email_Template:boolean;
Source_Of_Fund:boolean;
RelationShip_List:boolean;
Reason_For_Transfer:boolean;
Internet_Bank_Transfer:boolean;
Aml_List:boolean;
TransactionListLimits:boolean;
Agent_Mappings:boolean;
Revenue_Mapping:boolean;
Agent_Deposit_Lists:boolean;
Banks:boolean;
Buy_Rates:boolean;
Austrac:boolean;
Branch_list:boolean;
ticketIssue:boolean;
Referal_Code:boolean;
Ambassadors:boolean;
Promotions:boolean;
Payment_Types:boolean;
Migrated:boolean;
Blocked:boolean;
Archived:boolean;
Expired:boolean;
accountant:boolean;
bdm:boolean;
newrolepayout:boolean;
Fliq_pay:boolean;
Geo_Swift:boolean;
Nsano:boolean;
Samsara:boolean;
kaara:boolean;
kaarahistory:boolean;
Api_Mapping:boolean;
Arc_Transaction_List:boolean;
Mon_Transaction_List:boolean;
// Added by Martins start
Mon_Transaction_Time:boolean;
Exchange_rate_history:boolean;
airtopupexchangerate:boolean;
// added by Martins end
Market_transactions:boolean;
Market_orders:boolean;
Create_market:boolean;
Market_comission:boolean;
agentlist: boolean;

Source_Exchange_Ratessend:boolean;
BackEnd_Transactionsend:boolean;
Exchange_Ratessend:boolean;
Agent_Depositsend:boolean;
Ledgersend:boolean;
Customersend:boolean;
customerService:boolean;

Destination_Agent_ExchangeRate:boolean;
Cash_Pickup:boolean;
Admin_Deposits:boolean;
Rec_Agent_Ledger:boolean;


usernamess:any;
usernamesr:any;
usersend:any;
userreceive:any;
usercomplance:any;
Complaince_Approval:boolean;
complaince:boolean;
  trans: boolean;
  air_trancation: boolean;
  bill_tranaction: boolean;


  constructor(
    private authService: LogOutService,
    public router:Router,
    private processIdGeneraterService:ProcessIdGeneraterService,
    private _accessLogService: AccessLogService,
         private _route:ActivatedRoute) { }

         ngOnInit(): void {
          this.rolesuser=false;
          if(localStorage.getItem('switchUser')=='yes'){
            this.switchUser=true;
          }

          this.userTypeName=localStorage.getItem('userTypeName');

      this.sendReceiveName=localStorage.getItem('sendReceiveName');
      this.subRoleId=localStorage.getItem('subRoleId');



      if(  this.usersend=JSON.parse(localStorage.getItem('pagenamesend'))){
        this.newsend = true;
      // alert(this.newsend)

                this.usersend.forEach((element)=>{
                  if(element.sendingAgent === 'Source_Exchange_Rates' ){
                    // alert(element.sendingAgent)
                    this.Source_Exchange_Ratessend=true;
                      this.newsend = true;
                      // alert(this.newsend)
                  }

                  else if(element.sendingAgent === 'BackEnd_Transaction')
                  {
                    this.BackEnd_Transactionsend=true;
                      this.newsend = true;
                  }

                  else if(element.sendingAgent === 'Transactions')
                  {
                this.Agent_Depositsend=true;
                  this.newsend = true;
                  }

                  else if(element.sendingAgent === 'Ledger')
                  {
                       this.Ledgersend=true;
                         this.newsend = true;
                  }

                  else if(element.sendingAgent === 'Customer')
                  {
                    this.Customersend=true;
                      this.newsend = true;
                  }
                })


              }
            else if(this.usernamess=JSON.parse(localStorage.getItem('pagenames')))  {


                this.usernamess.forEach((element)=>{
                  if(element.admin === 'ACreate' ){
                    // alert('kjhkj')


        this.agentcreation=true;
            this.branchUrl=true;
                  }
                  if(element.admin === 'Agent list' ){
                    // alert('kjhkj')


        this.agentlist=true;
            this.branchUrl=true;
                  }


                  else if(element.admin === 'Api mapping' ){


        this.Api_Mapping=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Arc_Transaction_List' ){
                    //


        this.Arc_Transaction_List=true;
            this.branchUrl=true;
                  }

                  else if(element.admin === 'Mon Transaction List' ){
                    //


        this.Mon_Transaction_List=true;
        this.Mon_Transaction_Time=true;
        this.Exchange_rate_history=true;
      this.airtopupexchangerate=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Acess logs' ){


        this.AccessLogg=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Fliqpay' ){


        this.Fliq_pay=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Gswift' ){


        this.Geo_Swift=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Nsano' ){


        this.Nsano=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'Samsara' ) {


        this.Samsara=true;
            this.branchUrl=true;
                  }
                  else if(element.admin === 'kaara' ) {


                    this.kaara=true;
                        this.branchUrl=true;
                              }
                              else if(element.admin === 'kaarahistory' ) {


                                this.kaarahistory=true;
                                    this.branchUrl=true;
                                          }

                  else if(element.admin === 'Velocity-check' )   {
                   this.Velocity_Checks=true;
                       this.branchUrl=true;
                  }
                  else if(element.admin === 'Audit trail' )  {
                    this.AuditTrail=true;    this.branchUrl=true;
                  }
                  else if(element.admin === 'TransactionList' )   {
                    this.TransactionListad=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Austrac' )    {
                    this.Austrac=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'BCreate' ){
                     this.Branch_Creation=true;
                     // alert(this.Branch_Creation)
                         this.branchUrl=true;
                  }
                  else if(element.admin === 'Branch list' ){
                     this.Branch_list=true;
                     // alert(this.Branch_Creation)
                         this.branchUrl=true;
                  }
                  else if(element.admin === 'Sell-rates' ){
                this.Sell_Rates=true;    this.branchUrl=true;
                  }
                  else if(element.admin === 'Service fee' ){
                  this.Service_Fee=true;
                      this.branchUrl=true;
                  }
                  else if(element.admin === 'Api info' ){
                  this.Api_Info=true;
                      this.branchUrl=true;
                  }
                  else if(element.admin === 'Transaction list' ){
                    this.Transactionlistr=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Payment_Type' ){
                        this.Payment_Type=true;
                            this.branchUrl=true;
                  }
                  else if(element.admin === 'Collection fee' ){
                       this.Collection_Fee=true;
                           this.branchUrl=true;
                  }
                  else if(element.admin === 'Profit-loss' ){
                     this.Profit_Loss=true;
                         this.branchUrl=true;
                  }
                  else if(element.admin === 'Sanction_List' ){
                    //
                      this.Sanction_List=true;
                          this.branchUrl=true;
                  }
                  else if(element.admin === 'KYC Approval' ){
            this.Kyc_Approval=true;
                this.branchUrl=true;
                  }
                  else if(element.admin === 'Customer List' ){
                        this.Customer_List=true;
                            this.branchUrl=true;
                  }
                  else if(element.admin === 'Beneficiary' ){
                   this.Beneficiary_List=true;
                       this.branchUrl=true;
                  }
                  else if(element.admin === 'Agent_Deposit_List' ){

                    //Revenue percentage
                        this.Agent_Deposit_List=true
                  }
                  else if(element.admin === 'Email Template' ){
                        this.Email_Template=true;
                            this.branchUrl=true;
                  }
                  else if(element.admin === 'Source Of Fund' ){
                    this.Source_Of_Fund=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Relationship' ){
                 this.RelationShip_List=true;
                     this.branchUrl=true;
                  }
                  else if(element.admin === 'Reason For Transfer' ){
                    this.Reason_For_Transfer=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Internet Bank Transfer' ){
                this.Internet_Bank_Transfer=true;
                    this.branchUrl=true;
                  }
                  else if(element.admin === 'AML Limits' ){
                       this.Aml_List=true;
                           this.branchUrl=true;
                  }
                  else if(element.admin === 'TransactionListLimits' ){
                    //
                    this.TransactionListLimits=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Revenue percentage' ){
                     this.Revenue_Mapping=true;
                         this.branchUrl=true;
                  }
                  else if(element.admin === 'Mapping' ){
                    this.Agent_Mappings=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Agent_Deposit_Lists' ){
                    //
                  this.Agent_Deposit_Lists=true;
                      this.branchUrl=true;
                  }
                  else if(element.admin === 'Banks' ){
                    this.Banks=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Buy-rates' ){
                    this.Buy_Rates=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Referal Code' ){
                    this.Referal_Code=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Ambassadors' ){
                    this.Ambassadors=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Promotions' ){
                    this.Promotions=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Payment Types' ){
                    this.Payment_Types=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'Blocked' ){
                    this.Blocked=true;
                        this.branchUrl=true;
                  }

                  else if(element.admin === 'Archived' ){
                    this.Archived=true;
                        this.branchUrl=true;
                  }
                  else if(element.admin === 'air-transaction' )   {
                    // this.TransactionListad=true;
                        this.branchUrl=true;
                        this.air_trancation;
                  }
                  else if(element.admin === 'bill-transactions' )   {
                    // this.TransactionListad=true;
                        this.branchUrl=true;
                        this.bill_tranaction;
                  }



                })



              }


                      else if(  this.userreceive=JSON.parse(localStorage.getItem('pagenamereceive'))){





                                  this.userreceive.forEach((element)=>{
                                    if(element.receivingAgent === 'Dest transaction list' ){
        this.Destination_Agent_ExchangeRate=true;
        // alert(this.Destination_Agent_ExchangeRate)
        this.newrec=true;
                                    }
                                    else if(element.receivingAgent === 'Cash_Pickup' ){
      this.Cash_Pickup=true;
      this.newrec=true;
                                    }
                                    else if(element.receivingAgent === 'Admin_Deposits' ){
      this.Admin_Deposits=true;
      this.newrec=true;
                                    }
                                    else if(element.receivingAgent === 'Rec_Agent_Ledger' ){
        this.Rec_Agent_Ledger=true;
        this.newrec=true;
      }
      }            )



                      }
                      else if(  this.usercomplance=JSON.parse(localStorage.getItem('pagenamecomplaince'))){

                        this.usercomplance.forEach((element)=>{
                          if(element.usercomplance === 'Complaince_Approval' ){
      this.Complaince_Approval=true;
      this.complaince = true;
      }})
                      // this.Complaince_Approval=  this.usercomplance[0].complaince



                      }




      if(this.subRoleId == '1'){
              this.backnavbar=true;
      }else{
              this.backnavbar=false;
      }
       if(this.userTypeName=='remitz_role_payout'){
        // this.newrolepayout=true;

        

        this.newrec = true;

        this.Destination_Agent_ExchangeRate=true;
        this.Cash_Pickup=true;
        // this.Admin_Deposits=true;
        this.Rec_Agent_Ledger=true;

        this.rolesuser=true;

        // this.Austrac=true;
        this.Fliq_pay=true;
        this.Geo_Swift=true;
        this.Nsano=true;
        this.Samsara=true;
       
        this.kaara=true;
        this.kaarahistory=true;
        
      
      }
      if(this.userTypeName=='remitz_role_ticket_issue'){
        this.ticketIssue=true;
      }
      if(this.userTypeName=='remitz_role_accountant'){
        this.accountant=true;
      }
      if(this.userTypeName=='remitz_role_bdm'){
        this.bdm=true;

        this.rolesuser=true;

                    this.branchUrl=true;
                    this.agentcreation=true;
                    this.Velocity_Checks=true;
                   this.AuditTrail=true;
                    this.Branch_Creation=true;
                    this.TransactionListad=true;
                   this.Sell_Rates=true;
                   this.Service_Fee=true;
                    this.Api_Info=true;
                    this.Transactionlistr=true;
                    this.Payment_Type=true;
                   this.Collection_Fee=true;
                   this.Profit_Loss=true;
                    this.Sanction_List=true;
                    this.Kyc_Approval=true;
                    this.Customer_List=true;
                    this.Beneficiary_List=true;
                    this.Agent_Deposit_List=true;
                   this.Email_Template=true;
                   this.Source_Of_Fund=true;
                   this.RelationShip_List=true;
                   this.Reason_For_Transfer=true;
                   this.Internet_Bank_Transfer=true;
                    this.Aml_List=true;
                    this.TransactionListLimits=true;
                    this.Agent_Mappings=true;
                  this.Revenue_Mapping=true;
                    this.Agent_Deposit_Lists=true;
                   this.Banks=true;
                   this.Buy_Rates=true;
this.Branch_list=true;

this.agentlist=true;
this.Referal_Code=true
this.Ambassadors=true
this.Promotions=true
this.Payment_Types=true;
this.Migrated=true
this.Blocked=true;
this.Expired=true;
this.Archived=true;


                   this.AccessLogg=true;
                   this.Austrac=true;
                   this.Fliq_pay=true;
                   this.Geo_Swift=true;
                   this.Nsano=true;
                   this.Samsara=true;
                   this.kaara=true;
                   this.kaarahistory=true;
                   this.Api_Mapping=true;
                    this.Arc_Transaction_List=true;
                    this.Mon_Transaction_List=true;
                    this.Mon_Transaction_Time=true;
                    this.Exchange_rate_history=true;
                    this.airtopupexchangerate=true;
                    this.Market_transactions=true;
                    this.Market_orders=true;
                    this.Create_market=true;
                    this.Market_comission=true;
                    this.air_trancation=true;
                    this.bill_tranaction=true;

      }

      if(this.userTypeName=='remitz_role_customer_service'){
        this.customerService=true;
      }
      if(this.userTypeName =='remitz_role_admin'){

        this.rolesuser=true;

                    this.branchUrl=true;
                    this.agentcreation=true;
                    this.Velocity_Checks=true;
                    this.AuditTrail=true;
                    this.Branch_Creation=true;
                    this.TransactionListad=true;
                    this.Sell_Rates=true;
                    this.Service_Fee=true;
                    this.Api_Info=true;
                    this.Transactionlistr=true;
                    this.Payment_Type=true;
                    this.Collection_Fee=true;
                    this.Profit_Loss=true;
                    this.Sanction_List=true;
                    this.Kyc_Approval=true;
                    this.Customer_List=true;
                    this.Beneficiary_List=true;
                    this.Agent_Deposit_List=true;
                    this.Email_Template=true;
                    this.Source_Of_Fund=true;
                    this.RelationShip_List=true;
                    this.Reason_For_Transfer=true;
                    this.Internet_Bank_Transfer=true;
                    this.Aml_List=true;
                    this.TransactionListLimits=true;
                    this.Agent_Mappings=true;
                  this.Revenue_Mapping=true;
                    this.Agent_Deposit_Lists=true;
                    this.Banks=true;
                    this.Buy_Rates=true;
this.Branch_list=true;

this.agentlist=true;
this.Referal_Code=true
this.Ambassadors=true
this.Promotions=true
this.Payment_Types=true;
this.Migrated=true
this.Blocked=true;
this.Expired=true;
this.Archived=true;


                    this.AccessLogg=true;
                    this.Austrac=true;
                    this.Fliq_pay=true;
                    this.Geo_Swift=true;
                    this.Nsano=true;
                    this.Samsara=true;
                    this.kaara=true;
                    this.kaarahistory=true;
                    this.Api_Mapping=true;
                    this.Arc_Transaction_List=true;
                    this.Mon_Transaction_List=true;
                    this.Mon_Transaction_Time=true;
                    this.Exchange_rate_history=true;
                         this.airtopupexchangerate=true;
                    this.Market_transactions=true;
                    this.Market_orders=true;
                    this.Create_market=true;
                    this.Market_comission=true;
                    this.air_trancation=true;
                    this.bill_tranaction=true;


                  }

                  if(this.userTypeName == 'remitz_role_send_agent'){

                    this.newsend = true;
                    // alert(this.newsend)

                    this.Source_Exchange_Ratessend=true;
                    this.BackEnd_Transactionsend=true;
                    this.Exchange_Ratessend=true;
                    this.Agent_Depositsend=true;
                    this.Ledgersend=true;
                    this.Customersend=true;

                  }
                  if(this.userTypeName == 'remitz_role_rec_agent'){

                    this.newrec = true;

                                this.Destination_Agent_ExchangeRate=true;
                                this.Cash_Pickup=true;
                                this.Admin_Deposits=true;
                                this.Rec_Agent_Ledger=true;
                  }
                  if(this.userTypeName == 'remitz_role_complaince'){
                  //username = remitzc
                  //password = password
                    this.complaince = true;
                    this.remitzcomplaince=true

                  }
                  if(this.userTypeName == 'remitz_role_payment_clearance'){
                    
                    
                    // this.branchUrl=true;
                   this.trans=true;
                  
                            
                    }
                    if(this.userTypeName == 'remitz_role_marketing'){
                    
                    
                      // this.branchUrl=true;
                    //  this.trans=true;
                    this.remitzmarketing=true;
                              
                      }


        }
  public loggout():void{
    if(localStorage.getItem('accessId')!=null&&localStorage.getItem('accessId')!="undefined"){
    this._accessLogService.getAccessLogByAccessIdAndUserId(
    localStorage.getItem('accessId'),
    localStorage.getItem('adminId'),
     this.processIdGeneraterService.getPid()).subscribe(res => {
    if (res) {
this._accessLogService.updateAccessLogByAccessId(
localStorage.getItem('accessId'),
this.processIdGeneraterService.getPid()).subscribe(res => {
if (res) {
console.log('accesslog.....',res.response);
localStorage.setItem("accessId", res.response.accessId);

localStorage.clear()
this.router.navigateByUrl('/login');
}//if
},error => {
console.log(error)
localStorage.clear()
this.router.navigateByUrl('/login');
}
);//update accesslog

}//if
},error => {
console.log(error)
localStorage.clear()
this.router.navigateByUrl('/login');
}
);//get accesslog
}//if access id closed
else{

  localStorage.removeItem('datas');
  localStorage.removeItem('uname');
  localStorage.clear();
  this.router.navigateByUrl('/login');


}

}
SwitcToAdmin(){
//  alert('s')

  localStorage.setItem("switchFlag","true")
  localStorage.setItem("userTypeName",'remitz_role_admin');
  this.router.navigateByUrl('branch');
  localStorage.setItem('adminId',localStorage.getItem('adminId'));
  localStorage.setItem("switchUser", 'no');

}

ChangePassword(){
  this.router.navigateByUrl('/adminchangepassword');
}

// toggleSlider() {
//   this.showSlider = !this.showSlider;
// }

// closeSlider() {
//   this.showSlider = false;
// }





}
