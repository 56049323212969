import { Component,HostListener ,OnInit} from '@angular/core';
import {Router,ActivatedRoute,NavigationStart,NavigationEnd} from "@angular/router"
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component
export let browserRefresh = false;
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
declare let gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  backenddashboard:boolean;
  title = 'nairelem270921';
userTypeNamee:any;
branchUrl:boolean;
sendReceiveName:any;
newrec:boolean;
newsend:boolean;
backnavbar:boolean;
ishide:boolean;
subscription: Subscription;

private webSocket: WebSocket;

@HostListener('window:beforeunload', ['$event'])
beforeunloadHandler(event) {
    alert('By refreshing this page you may lost all data.');
}
constructor(

  public router:Router,
  public bnIdle: BnNgIdleService,
  private _route:ActivatedRoute
){
  
  this.bnIdle.startWatching(1800).subscribe((isTimedOut: boolean) => {
    if (isTimedOut) {
      console.log('session expired');
       localStorage.clear();
      // this.bnIdle.stopTimer();
       this.router.navigate(['/']);
    }
  });

  this.subscription = this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          browserRefresh = !this.router.navigated;
        }
    });
}

ngOnInit(): void {

  if(sessionStorage.getItem('storageFlag')!='true'){
    localStorage.clear();
  }

   this.setUpAnalytics();


  sessionStorage.setItem('storageFlag','true');

    }

    setUpAnalytics() {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {

                gtag('config', 'G-T50E8S3S22',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
                gtag('config', 'G-WMDPCVE6LL',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
                gtag('config', 'G-SX465N4ZKF',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
                gtag('config', 'G-5TBTQELJB1',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
                gtag('config', 'G-PBS4XXBZE8',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
                gtag('config', 'G-P67E4Q82N4',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            });
    }

}
