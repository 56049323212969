import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderfrontRoutingModule } from './headerfront-routing.module';
import { NewheaderComponent } from './newheader/newheader.component';
import { IndexheaderComponent } from './indexheader/indexheader.component';
import { DashboardModalComponent } from './dashboardmodal/dashboardmodal.component';
import { BeneficiaryModalComponent } from './beneficiarymodal/beneficiarymodal.component';

@NgModule({
  declarations: [
    NewheaderComponent,
    IndexheaderComponent,
    DashboardModalComponent,
    BeneficiaryModalComponent,
  ],
  imports: [CommonModule, HeaderfrontRoutingModule],
  exports: [
    NewheaderComponent,
    IndexheaderComponent,
    DashboardModalComponent,
    BeneficiaryModalComponent,
  ],
})
export class HeaderfrontModule {}
