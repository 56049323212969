import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component'
import {FronthomeComponent } from './fronthome/fronthome.component'
import {FrontloginComponent} from './frontlogin/frontlogin.component';
import { FrontsignupComponent } from './frontsignup/frontsignup.component'
import { DeleteaccountComponent } from '../headerpages/deleteaccount/deleteaccount.component';
const routes: Routes = [
  {
    path:'admin',
    component:LoginComponent
  },
  {
    path:'',
    component:FronthomeComponent
  },
  {
    path:'login',
    component:FrontloginComponent
  },{
    path:'signup',
    component: FrontsignupComponent
  },{
    path:'deleteaccount',
    component: DeleteaccountComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
