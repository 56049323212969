import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  apiUrl = environment.baseUrl
  public baseUrl: string = `${this.apiUrl}/agent/getRevenueRates`;
  public saveAgentDepositData: string = `${this.apiUrl}/agent/createAgentDeposits`;
  public getAgentDepositList: string = `${this.apiUrl}/agent/getAgentDepositListBWDates`;

  public getAgentDepositListById: string = `${this.apiUrl}/agent/getAgentDepositList`;
  public getUserListUrl: string = `${this.apiUrl}/utility/migratedCustomerList`;
  public getAgentDepositListByClient: string = `${this.apiUrl}/agent/getAgentDepositListByClient`;
  public updateAgentDepositByDepositId: string = `${this.apiUrl}/agent/updateAgentDepositByDepositId`;
  public velocityByVelocityIdUrl: string = `${this.apiUrl}/utility/getVelocityByVelocityId`;
  public revenuePerListByClient: string = `${this.apiUrl}/utility/getRevenuePercentageList`;
  public findByPromotionIdUrl: string = `${this.apiUrl}/utility/findByPromotionId`;
  public updateRevenuePercentageDetails: string = `${this.apiUrl}/utility/updateRevenuePercentageDetails`;
  public ledgerCl: string = `${this.apiUrl}/transaction/getAllTransactionList`;
  public rolesList: string = `${this.apiUrl}/user/getRoleCreatedList`;
  public savemapping: string = `${this.apiUrl}/user/saveRoleMapping`;
  public referralAmountByCountryIdUrl: string = `${this.apiUrl}/utility/getAllReferralAmountByCountryId`;

  public openBalCl: string = `${this.apiUrl}/transaction/getOpeningalByAgentIdAndFromDate`;
  public saveTxDaysListUrl: string = `${this.apiUrl}/utility/saveTxnDaysLimitDetails`;
  public getTxDaysListUrl: string = `${this.apiUrl}/utility/getTxnDaysLimitList`;
  public updateTxDaysListUrl: string = `${this.apiUrl}/utility/updateTxnDaysLimit`;
  public checkTheGivenPromoCodeUniqueUrl: string = `${this.apiUrl}/utility/checkTheGivenPromoCodeUnique`;
  public updatePrommotionUrl: string = `${this.apiUrl}/utility/updatePromoCode`;
  public getTxDaysListBySrcAgentUrl: string = `${this.apiUrl}/utility/getTxnDaysLimitBySrcAgentId`;
  public listBySendingCustomerId: string = `${this.apiUrl}/transaction/getTransactionListBySendingCustomerUserId`;
  public addComplaintUrl: string = `${this.apiUrl}/utility/addComplaint`;
  public getComplaintByUserIdUrl: string = `${this.apiUrl}/utility/getComplaintByUserId`;
  public getDepositListByRecAgentId: string = `${this.apiUrl}/agent/getRecAgentDepositList`;
  public getRolesAndPagesWithHeaderUrl: string = `${this.apiUrl}/utility/getAllRolesAndPagesWithHeader`;

  public getAllBeneficiariesUrl: string = `${this.apiUrl}/customer/getAllBeneficiaries`;
  public getAllBeneficiariesBasedOnStatusUrl: string = `${this.apiUrl}/customer/getAllBeneficiariesBasedOnStatus`
  public getAllBeneficiariesBasedOnTextUrl: string = `${this.apiUrl}/customer/getAllBeneficiariesBasedOnText`
  public getAllCustListBasedOnTextUrl: string = `${this.apiUrl}/customer/getCustomerListByText`
  public getAllCustListBasedOnTextAndStatusUrl: string = `${this.apiUrl}/customer/getCustomersList`
  public getCustomerListByStatusUrl: string = `${this.apiUrl}/customer/getCustomerListByStatus`
  public getCustomerListByStatusAndCountryUrl: string = `${this.apiUrl}/customer/getCustomerListByStatusAndCountry`
  public getCustomerDocumentsByUserIdUrl: string = `${this.apiUrl}/customer/getCustomerDocumentsByUserId`;
  public getCustomerDocumentsDetailsByUserIdUrl: string = `${this.apiUrl}/customer/getCustomerDocumentsDetailsByUserId`;
  public getCountryDetailsBasedOnCountryIdUrl: string = `${this.apiUrl}/utility/getCountryDetailsBasedOnCountryId`;
  public savePromotionsUrl: string = `${this.apiUrl}/utility/savePromotions`;
  public getAdminList1: string = `${this.apiUrl}/user/getByUserTypeId`;
  public getAgentsListByFunctionaliti: string = `${this.apiUrl}/user/getAgentsListByFunctionality`;
  public userFormActionsByRoleCreatedId: string = `${this.apiUrl}/user/getUserFormActonsByRoleCreatedId`;
  public saveRoleUser: string = `${this.apiUrl}/user/saveRoleUser`;
  public getCountryList: string = `${this.apiUrl}/keyclock/getClientCountrys`;
  public getUserIdBySubUserId: string = `${this.apiUrl}/user/getUserIdByRoleId`;
  public getUserFormActonsByUserId: string = `${this.apiUrl}/user/getUserFormActonsBuUserId`;

  public checkTheStatusOfAvailableReferralCodeUrl: string = `${this.apiUrl}/utility/checkTheStatusOfAvailableReferralCode`;
  public createReferralCodeToReferUrl: string = `${this.apiUrl}/utility/createReferralCodeToRefer`
  public myRefferalCodeUsedByUrl: string = `${this.apiUrl}/customer/getTheRferalCodeUsedBy`;

  public getAllPromotionsListUrl: string = `${this.apiUrl}/utility/getPromotionsList`;
  public getPromotionsByCriteriaUrl: string = `${this.apiUrl}/utility/getPromotionsBasedOnCriteria`;
  public getPromotionsByCriteriaOnFrontUrl: string = `${this.apiUrl}/utility/getPromotionsBasedOnCriteriaOnFront`;
  public updateCustomerUrl: string = `${this.apiUrl}/utility/updateCustomer`;
  public updateVelocityByVelocityIdUrl: string = `${this.apiUrl}/utility/updateVelocityByVelocityId`;
  public getAllReferralCodeRatesUrl: string = `${this.apiUrl}/utility/getAllReferralCodeRatesList`;
  public getAllReferralCodeRatesBySrcAgentIdUrl: string = `${this.apiUrl}/utility/getAllReferralCodeRatesListBySrcAgentId`;
  public getAllReferralCodeRatesByDestAgentIdUrl: string = `${this.apiUrl}/utility/getAllReferralCodeRatesListByDestAgentId`;
  public getAllReferralCodeRatesBySrcAndDestAgentIdUrl: string = `${this.apiUrl}/utility/getAllReferralCodeRatesListBySrcAgentIdAndDestAgentId`;
  public updateReferralCodeRatesByIdUrl: string = `${this.apiUrl}/utility/updateReferralCodeRateBasedOnId`;
  public getUserListByStatusUrl: string = `${this.apiUrl}/user/getUserDetailsBasedOnStatus`;
  public findDuplicateCustomerWithDetailsUrl: string = `${this.apiUrl}/customer/findDuplicateCustomerWithDetails`;
  public saveDepositData: string = `${this.apiUrl}/exchRate/createAdminDeposits`;
  public getAdminDepositList: string = `${this.apiUrl}/exchRate/getAdminDepositListBWDates`;
  public getAdminDepositListtt: string = `${this.apiUrl}/exchRate/getAdminDepositList`;
  public sendNotificationUrl: string = `${this.apiUrl}/fbm/sendnotification`;
  httpOptions = {
    headers: new HttpHeaders({})//.set('Authorization', localStorage.getItem("datas"))
  };
  constructor(private _http: HttpClient, private _httpp: HttpClient,
    handler: HttpBackend) {
    this._httpp = new HttpClient(handler);
  }
  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }
  public getRevenuePercentageRates(pId: string, srcAgentId: any, recAgentId: any): Observable<any> {
    return this._httpp.get(`${this.baseUrl}` + '?pId=' + pId + '&srcAgentId=' + srcAgentId + '&recAgentId=' + recAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public createAgentDepositDetails(pId: string, agentDepositRecord: any): Observable<any> {
    //alert("entered");
    console.log("json ..!" + JSON.stringify(agentDepositRecord));
    return this._http.post(this.saveAgentDepositData + '?pId=' + pId, agentDepositRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }



  public createDeposit(pId: string, depositRecord: any): Observable<any> {
    //alert("entered");
    console.log("json ..!" + JSON.stringify(depositRecord));
    return this._http.post(this.saveDepositData + '?pId=' + pId, depositRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getAgentList(pId: string, srcAgentId: any, fromDate: any, toDate: any): Observable<any> {
    fromDate = fromDate + ' 00:00:00';
    toDate = toDate + ' 23:59:59';
    return this._http.get(`${this.getAgentDepositList}` + '?pId=' + pId + '&userId=' + srcAgentId + '&fromDate=' + fromDate + '&toDate=' + toDate, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAdminDepositListt(pId: string, fromDate: any, toDate: any): Observable<any> {
    fromDate = fromDate + ' 00:00:01';
    toDate = toDate + ' 23:59:59';
    return this._http.get(`${this.getAdminDepositList}` + '?pId=' + pId + '&fromDate=' + fromDate + '&toDate=' + toDate, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public getAdminDepositListttt(pId: string, fromDate: any, toDate: any): Observable<any> {
    fromDate = fromDate + ' 00:00:01';
    toDate = toDate + ' 23:59:59';
    return this._http.get(`${this.getAdminDepositListtt}` + '?pId=' + pId + '&fromDate=' + fromDate + '&toDate=' + toDate, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public getAgentListBySenderId(pId: string, srcAgentId: any): Observable<any> {

    return this._http.get(`${this.getAgentDepositListById}` + '?pId=' + pId + '&userId=' + srcAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getAgentListByClient(pId: string): Observable<any> {
    return this._http.get(`${this.getAgentDepositListByClient}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateAgentDepositDetails(pId: string, depositedId: any, depositedStatus: any): Observable<any> {
    return this._http.put(this.updateAgentDepositByDepositId + '?pId=' + pId + '&agentDepositId=' + depositedId + '&depositedStatus=' + depositedStatus, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getRevPercListByClient(pId: string): Observable<any> {
    return this._http.get(`${this.revenuePerListByClient}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getVelocityByVelocityId(id: any, pId: string): Observable<any> {
    return this._http.get(`${this.velocityByVelocityIdUrl}` + '?pId=' + pId + '&velocityId=' + id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }


  public updateVelocityByVelocityId(id: any, record: any, pId: string): Observable<any> {
    return this._http.put(`${this.updateVelocityByVelocityIdUrl}` + '?pId=' + pId + '&velocityId=' + id, record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //this method is used for create new branch
  public updateRevPercDetails(pId: string, revRecord: any, revenuePercentageId: any): Observable<any> {
    console.log("update record..!" + JSON.stringify(revRecord));
    return this._http.post(`${this.updateRevenuePercentageDetails}` + '?pId=' + pId + '&revenuePercentageId=' + revenuePercentageId, revRecord, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }
  public getOpeningBalanceList(pId: string, agentId: any, fromDate: any): Observable<any> {
    fromDate = fromDate + ' 00:00:00';
    return this._http.get(`${this.openBalCl}` + '?pId=' + pId + '&agentId=' + agentId + '&fromDate=' + fromDate, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getAgentsListByFunctionality(pId: string, functionality: any): Observable<any> {
    return this._http.get(`${this.getAgentsListByFunctionaliti}` + '?pId=' + pId + '&functionality=' + functionality, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getTrnxListBySendAgentIdWithDates(pId: string, agentId: any, fromDate: any, toDate: any): Observable<any> {
    fromDate = fromDate + ' 00:00:00'; toDate = toDate + ' 23:59:59';
    return this._http.get(`${this.ledgerCl}` + '?pId=' + pId + '&agentId=' + agentId + '&fromDate=' + fromDate + '&toDate=' + toDate, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  //saveTxDaysListUrl

  public saveTxDaysList(pId: string, record: any): Observable<any> {
    console.log("saveTxDaysListUrl..!" + JSON.stringify(record));
    return this._http.post(`${this.saveTxDaysListUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }
  //getTxDaysListUrl
  public getTxDaysList(pId: string): Observable<any> {
    return this._http.get(`${this.getTxDaysListUrl}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  //updateTxDaysListUrl
  public updateTxDaysList(pId: string, Id: any, record: any): Observable<any> {
    return this._http.put(this.updateTxDaysListUrl + '?pId=' + pId + '&txnDaysLimitId=' + Id, record, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  //getTxDaysListBySrcAgentUrl
  public getTxDaysListBySrcAgent(pId: string, agentId: any): Observable<any> {
    return this._http.get(`${this.getTxDaysListBySrcAgentUrl}` + '?pId=' + pId + '&agentId=' + agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getTransactionListBySendingCustomerUserId(Id: any, pId: string): Observable<any> {
    console.log('TransactionListBySendingCustomerUserId....', pId);
    return this._http.get(`${this.listBySendingCustomerId}` + '?pId=' + pId + '&sendingCustomerUserId=' + Id, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public addComplaint(pId: string, userId: any, record: any): Observable<any> {
    console.log("addComplaintUrl..!" + JSON.stringify(record));
    return this._http.post(`${this.addComplaintUrl}` + '?pId=' + pId + '&userId=' + userId, record, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }

  public getComplaintByUserId(pId: string, userId: any): Observable<any> {
    return this._http.get(`${this.getComplaintByUserIdUrl}` + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllBeneficiaries(pId: string): Observable<any> {
    return this._http.get(this.getAllBeneficiariesUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getBeneficiariesBasedOnStatus(pId: string, status: any): Observable<any> {
    return this._http.get(this.getAllBeneficiariesBasedOnStatusUrl + '?pId=' + pId + '&status=' + status, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getBeneficiariesBasedOnText(pId: string, text: any): Observable<any> {
    return this._http.get(this.getAllBeneficiariesBasedOnTextUrl + '?pId=' + pId + '&text=' + text, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getCustomerListBasedOnText(pId: string, text: any): Observable<any> {
    return this._http.get(this.getAllCustListBasedOnTextUrl + '?pId=' + pId + '&textSearch=' + text, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getCustomerListBasedOnTextAndStatus(pId: string): Observable<any> {
    return this._http.get(this.getAllCustListBasedOnTextAndStatusUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getCustomerListByStatusAndCountry(pId: string, status: any, country: any): Observable<any> {
    return this._http.get(this.getCustomerListByStatusAndCountryUrl + '?pId=' + pId + '&status=' + status + '&country=' + country, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getCustomerListByStatus(pId: string, status: any): Observable<any> {
    return this._http.get(this.getCustomerListByStatusUrl + '?pId=' + pId + '&status=' + status, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getCustomerDocumentsDetailsByUserId(pId: string): Observable<any> {
    return this._http.get(this.getCustomerDocumentsDetailsByUserIdUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getCustomerDocumentsByUserId(pId: string, userId: any): Observable<any> {
    return this._http.get(this.getCustomerDocumentsByUserIdUrl + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getCountryDetailsBasedOnCountryId(pId: string, userId: any): Observable<any> {
    return this._httpp.get(this.getCountryDetailsBasedOnCountryIdUrl + '?pId=' + pId + '&countryId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  //savePromotionsUrl
  public savePromotions(pId: string, record: any): Observable<any> {
    console.log("savePromotionsU..!" + JSON.stringify(record));
    return this._http.post(`${this.savePromotionsUrl}` + '?pId=' + pId, record, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }

  public getAllPromotionsList(pId: string): Observable<any> {
    return this._http.get(this.getAllPromotionsListUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public updateCustomer(record: any, userId: any, pId: string): Observable<any> {
    return this._http.put(this.updateCustomerUrl + '?pId=' + pId + '&userId=' + userId, record, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getPromotionByCriteria(pId: string, srcCountry: any, destCountry: any, paymentMethodType: any,
    srcAmount: any, destAmount: any, promoCode: any, status: any): Observable<any> {
    return this._http.get(this.getPromotionsByCriteriaUrl + '?pId=' + pId + '&srcCountry=' + srcCountry + '&destCountry=' + destCountry +
      '&paymentType=' + paymentMethodType + '&srcAmount=' + srcAmount + '&destAmount=' + destAmount + '&promoCode=' + promoCode + '&status=' + status, this.httpOptions).pipe(
        map(this.extractData)
      );
  }

  public getPromotionByCriteriaOnFront(pId: string, srcCountry: any, destCountry: any, paymentMethodType: any,
    srcAmount: any, destAmount: any, promoCode: any, status: any): Observable<any> {
    return this._httpp.get(this.getPromotionsByCriteriaOnFrontUrl + '?pId=' + pId + '&srcCountry=' + srcCountry + '&destCountry=' + destCountry +
      '&paymentType=' + paymentMethodType + '&srcAmount=' + srcAmount + '&destAmount=' + destAmount + '&promoCode=' + promoCode + '&status=' + status, this.httpOptions).pipe(
        map(this.extractData)
      );
  }

  public getUserList(pId: string): Observable<any> {
    return this._http.get(this.getUserListUrl + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public checkTheGivenPromoCodeUnique(pId: string, promoCode: any): Observable<any> {
    return this._http.get(this.checkTheGivenPromoCodeUniqueUrl + '?pId=' + pId + '&promoCode=' + promoCode, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public findByPromotionId(pId: string, promotionId: any): Observable<any> {
    return this._http.get(this.findByPromotionIdUrl + '?pId=' + pId + '&promotionId=' + promotionId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public updatePromotion(pId: string, promotionId: any, record: any): Observable<any> {
    return this._http.post(this.updatePrommotionUrl + '?pId=' + pId + '&promotionId=' + promotionId, record, this.httpOptions).pipe(
      map(this.extractData)
    );
  }



  public createReferralCodeToRefer(pId: string, record: any): Observable<any> {
    return this._http.post(this.createReferralCodeToReferUrl + '?pId=' + pId, record, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public myRefferalCodeUsedBy(pId: string, userId: any): Observable<any> {
    return this._http.get(this.myRefferalCodeUsedByUrl + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public checkTheStatusOfAvailableReferralCode(pId: string, code: string, userId: any): Observable<any> {
    return this._http.get(`${this.checkTheStatusOfAvailableReferralCodeUrl}` + '?pId=' + pId + '&referralCode=' + code + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllReferralCodeRates(pId: string): Observable<any> {
    return this._http.get(`${this.getAllReferralCodeRatesUrl}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllReferralCodeRatesBySrcAgentId(srcAgentId: string, pId: string): Observable<any> {
    return this._http.get(`${this.getAllReferralCodeRatesBySrcAgentIdUrl}` + '?pId=' + pId + '&srcAgentId=' + srcAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllReferralCodeRatesListByDestAgentId(destAgentId: string, pId: string): Observable<any> {
    return this._http.get(`${this.getAllReferralCodeRatesByDestAgentIdUrl}` + '?pId=' + pId + '&destAgentId=' + destAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllReferralCodeRatesBySrcAgentIdAndDestAgentId(srcAgentId: string, destAgentId: string, pId: string): Observable<any> {
    return this._http.get(`${this.getAllReferralCodeRatesBySrcAndDestAgentIdUrl}` + '?pId=' + pId + '&srcAgentId=' + srcAgentId + '&destAgentId=' + destAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateReferralCodeRatesById(pId: string, record: any): Observable<any> {
    return this._http.post(`${this.updateReferralCodeRatesByIdUrl}` + '?pId=' + pId, record, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getRoleCreatedList(pId: string): Observable<any> {
    return this._http.get(`${this.rolesList}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public createRole(branchRecord: any, pId: string): Observable<any> {
    return this._http.post(`${this.savemapping}` + '?pId=' + pId, branchRecord, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }
  public getAdminList(pId: string, userTypeId: any): Observable<any> {
    return this._http.get(`${this.getAdminList1}` + '?pId=' + pId + '&userTypeId=' + userTypeId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }
  public getAgentsDepositListByrecAgentId(pId: string, recAgentId: any): Observable<any> {
    return this._http.get(`${this.getDepositListByRecAgentId}` + '?pId=' + pId + '&recAgentId=' + recAgentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getUserFormActonsByRoleCreatedIdList(pId: string, roleCreatedId: any): Observable<any> {
    return this._http.get(`${this.userFormActionsByRoleCreatedId}` + '?pId=' + pId + '&roleCreatedId=' + roleCreatedId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getUserFormActonByUserId(pId: string, userId: any): Observable<any> {
    return this._http.get(`${this.getUserFormActonsByUserId}` + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public createRoleWithUser(roleUserRecord: any, pId: string): Observable<any> {
    return this._http.post(`${this.saveRoleUser}` + '?pId=' + pId, roleUserRecord, this.httpOptions)
      .pipe(
        map(this.extractData)
      );
  }

  public getCountrysList(pId: string): Observable<any> {
    return this._http.get(`${this.getCountryList}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getReferralAmountByCountryId(pId: string, countryId: any): Observable<any> {
    return this._http.get(`${this.referralAmountByCountryIdUrl}` + '?pId=' + pId + '&countryId=' + countryId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAllRolesAndPagesWithHeader(pId: string, role: string): Observable<any> {
    return this._http.get(`${this.getRolesAndPagesWithHeaderUrl}` + '?pId=' + pId + '&role=' + role, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getUserIdbySubUserId(pId: string, userId: any): Observable<any> {
    return this._http.get(`${this.getUserIdBySubUserId}` + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getUserListByStatus(status: any, pId: string): Observable<any> {
    return this._http.get(`${this.getUserListByStatusUrl}` + '?pId=' + pId + '&status=' + status, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public findDuplicateCustomerWithDetails(userId: any, pId: string): Observable<any> {
    return this._http.get(`${this.findDuplicateCustomerWithDetailsUrl}` + '?pId=' + pId + '&userId=' + userId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public sendNotification(form: any): Observable<any> {
    return this._http.post(`${this.sendNotificationUrl}`, form, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getIpAddress(): Observable<any> {
    return this._httpp.get('http://api.ipify.org/?format=json', this.httpOptions).pipe(
      map(this.extractData)
    )
  }
}
