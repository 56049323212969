<!-- Navbar start -->
<header class="header-section fixed" (scroll)="onScroll()">
  <div class="overlay">
    <div class="container">
      <div class="row d-flex header-area">
        <nav class="navbar navbar-expand-lg navbar-light">
          <a class="navbar-brand" routerLink="/">
            <img
              src="assetsnew/images/logo-light.png"
              class="logo"
              alt="logo"
              loading="lazy"
            />
          </a>
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbar-content"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbar-content"
          >
            <ul class="navbar-nav mr-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/aboutus">About Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/contactus">Contact Us</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/faqs">Faqs</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a class="nav-link" routerLink="/help">How it works</a>
              </li>
              <li class="nav-item dropdown main-navbar">
                <a routerLink="/login" class="nav-link">Login</a>
              </li>
            </ul>
            <div
              class="right-area header-action d-flex align-items-center max-un"
            >
              <a routerLink="/signup" class="cmn-btn command_btn"
                >Sign Up
                <i class="icon-d-right-arrow-2"></i>
              </a>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</header>
<!-- Navbar end -->

<!-- Banner Section start -->
<section class="banner-section invoice-management rewards">
  <div class="overlay">
    <div class="shape-area">
      <!-- <img src="assets/images/rewards-banner-1.png" class="obj-1" alt="icon" />
      <img src="assets/images/rewards-banner-2.png" class="obj-2" alt="icon" /> -->
    </div>
    <div class="banner-content">
      <div class="container fadeInUp">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-8">
            <div class="main-content text-center mt-5">
              <div class="top-area section-text dark-sec">
                <h5 class="sub-title">How It Works</h5>
                <h1 class="title">Simple ways to get around TAYREX</h1>
                <p>Open a TAYREX Account to get started</p>
              </div>
              <div class="btn-area">
                <a routerLink="/signup" class="cmn-btn">Get Started</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Banner Section end -->

<!-- How it Works start -->
<section class="how-it-works second">
  <div class="overlay pt-120 pb-120">
    <div class="container fadeInUp">
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="section-header text-center">
            <h5 class="sub-title">How it works?</h5>
            <h2 class="title">Get Started in Just 3 Steps</h2>
            <p>Tayrex Exchange, Your choice exchange!</p>
          </div>
        </div>
      </div>
      <div class="row cus-mar">
        <div class="col-xl-4 col-sm-6 col-6">
          <div class="single-item first text-center">
            <div class="step-box">
              <h3>01</h3>
            </div>
            <h5>Open Account</h5>
            <p>
              Become a Tayrex User by opening your free Account to access all
              seamless transactions and offers
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-6">
          <div class="single-item second text-center">
            <div class="step-box">
              <h3>02</h3>
            </div>
            <h5>Add Beneficiary</h5>
            <p>
              A simple way to send money, select a beneficiary to receive
              payment
            </p>
          </div>
        </div>
        <div class="col-xl-4 col-sm-6 col-6">
          <div class="single-item text-center">
            <div class="step-box">
              <h3>03</h3>
            </div>
            <h5>Send Money</h5>
            <p>
              Enjoy the benefits of being a Tayrex User by sending instant
              money transfers across boarders
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- How it Works end -->

<!-- Partner perks start -->
<section class="partner-perks">
  <div class="overlay pt-120 pb-120">
    <div class="container fadeInUp">
      <div class="row justify-content-center">
        <div class="col-xl-8">
          <div class="section-header text-center">
            <h5 class="sub-title">Learn About Your Referral Code</h5>
            <h2 class="title">
              Get Paid and get discount offer when your referral code is used on
              our system
            </h2>
            <p>
              Tayrex Exchange offers $5 in value when you refer a friend and can
              be redeemed anytime.
            </p>
            <div class="btn_groupp">
              <a
                class="cmn-btn active mfp-iframe popupvideo"
                href="https://uk.tayrexchange.com/assets/images/VID-20221019-WA0003.mp4"
                class="cmn-btn w-25 me-2" style="width: 215px !important;"
                >Learn How It Works</a
              >
              <a
                class="cmn-btn active mfp-iframe popupvideo"
                href="https://tayrexchange.com/assets/Referral-video.mp4"
                class="cmn-btn w-25 ms-2"
                >See Referral</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</section>
<!-- Partner perks end -->

<!-- Call Action start -->
<section class="call-action">
  <div class="overlay pt-120 pb-5 bg-light">
    <div class="container">
      <div class="main-content">
        <div class="row justify-content-center">
          <div class="col-xl-5">
            <div class="section-text text-center">
              <h2 class="title">Get Started Today</h2>
              <p>And to make it Easy, fast, transparent.</p>
            </div>
            <div class="btn-area text-center">
              <a routerLink="/register" class="cmn-btn">Sign up in minutes</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Call Action end -->

<app-new-footer></app-new-footer>
