import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AgentServiceFeeService {
  apiUrl=environment.baseUrl

  public paymentTypeUrl: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeList`;
  public createAgentServiceUrl: string = `${this.apiUrl}/agent/createAgentServiceFee`;
  public updateAgentServiceFee:string = `${this.apiUrl}/agent/updateAgentServiceFeeById`;
  public getServiceFeeTypeList:string =`${this.apiUrl}/utility/getServiceFeeTypeList`;

  public SendAgentListUrl: string = `${this.apiUrl}/user/getAgentsListByFunctionality`;
  public getServiceFeeMaxValUrl: string=`${this.apiUrl}/agent/getAgentServiceFeeMaxValue`;
  public recAgentListUrl: string=`${this.apiUrl}/user/getAgentsListByFunctionalityAndAgentId`;
  public getAgentServiceFeeList:string= `${this.apiUrl}/agent/getAgentServiceFeeListBySrcAgentAndDestAgentAndPType`;
  public deleteAgentServiceFee:string = `${this.apiUrl}/agent/deleteAgentServiceFeeBySrcAgentAndDestAgentAndPType`;
  public getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeUrl:string= `${this.apiUrl}/agent/getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayType`;
//getAgentServiceFeeMaxValueByCurrency
public getAgentServiceFeeMaxValueByCurrencyUrl:string= `${this.apiUrl}/agent/getAgentServiceFeeMaxValueByCurrency`;
public getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeAndRegisterIdUrl:string= `${this.apiUrl}/agent/getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeAndRegisterId`;


  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' }).set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http: HttpClient, private router: Router, private _route: ActivatedRoute) { }

  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};
  }

  public getSendAgentsByFunctionality(functionality: any, pId: string): Observable<any> {
    console.log('get  Agents By Functionality working', functionality);
    return this._http.get(`${this.SendAgentListUrl}` + '?pId=' + pId+'&functionality='+functionality, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getRecAgentsByFunctionalityAndAgentId(functionality: any,agentId: any, pId: string): Observable<any> {
    console.log('get  Agents By Functionality working', functionality,agentId);
    return this._http.get(`${this.recAgentListUrl}` +'?agentId='+agentId+'&pId=' + pId+'&functionality='+functionality).pipe(
      map(this.extractData)
    )
  }

  public createAgentServiceFee(agentServiceFeeRecord: any,pId:string): Observable<any> {
    console.log("agentServiceFeeRecord..." + agentServiceFeeRecord);
    return this._http.post(`${this.createAgentServiceUrl}`+'?pId=' + pId, agentServiceFeeRecord, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getAgentBenifPaymentTypes(agentId: any, pId: string): Observable<any> {
    console.log('get  Agent payment types By agentId working', agentId);
    return this._http.get(`${this.paymentTypeUrl}`+'?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAgentServiceFeeListBySrcAgentAndDestAgentAndPType(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,pId:string):Observable<any>{

      return this._http.get(`${this.getAgentServiceFeeList}` + `?srcAgentId=` +srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&pId=`+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }



  public getServiceFeeMaxVal(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,pId:string):Observable<any>{
  console.log('get collection fee max val working');
    return this._http.get(`${this.getServiceFeeMaxValUrl}` + `?srcAgentId=` +srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&pId=`+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

 public updateAgentService(serviceRecord:any,userId:any,pId:string):Observable<any>{
   return this._http.put(`${this.updateAgentServiceFee}`+`?pId=`+pId+'&agentServiceFeeId='+userId,serviceRecord,this.httpOptions).pipe(
     map(this.extractData)
   )
 }

 public getServiceFeeTypeListing(pId: string): Observable<any> {
   console.log("working markup type list.." + pId);
   return this._http.get(`${this.getServiceFeeTypeList}` + '?pId=' + pId, this.httpOptions).pipe(
     map(this.extractData)
   );
 }

 public deleteAgentService(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,pId:string,registerId:any):Observable<any>{
  return this._http.delete(`${this.deleteAgentServiceFee}`+ `?srcAgentId=` +srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&pId=`+pId+`&registerId=`+registerId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

 //getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeUrl

 public getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayType(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,srcCurrency:any,destCurrency:any,pId:string):Observable<any>{

     return this._http.get(`${this.getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeUrl}` +`?pId=`+pId+`&srcAgentId=`+srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&srcCurrency=`+srcCurrency+'&destCurrency='+destCurrency).pipe(
     map(this.extractData)
   )
 }
 //getAgentServiceFeeMaxValueByCurrencyUrl
 public getAgentServiceFeeMaxValueByCurrency(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,srcCurrencyName:any,destCurrencyName:any,pId:string,registerId:any):Observable<any>{
  console.log('get collection fee max val working');
    return this._http.get(`${this.getAgentServiceFeeMaxValueByCurrencyUrl}` + `?pId=`+pId+`&srcAgentId=`+srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&srcCurrencyName=`+srcCurrencyName+'&destCurrencyName='+destCurrencyName+'&registerId='+registerId).pipe(
      map(this.extractData)
    )
  }

  public getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeAndRegisterId(srcAgentId: any,destAgentId: any,agentBenifPayTypeId: any,srcCurrency:any,destCurrency:any,pId:string,registerId:any):Observable<any>{

    return this._http.get(`${this.getListBySrcAgentAndDestAgentAndSrcDestCurrencyAndPayTypeAndRegisterIdUrl}` +`?pId=`+pId+`&srcAgentId=`+srcAgentId+`&destAgentId=`+destAgentId+`&agentBenifPayTypeId=`+agentBenifPayTypeId+`&srcCurrency=`+srcCurrency+'&destCurrency='+destCurrency+'&registerId='+registerId).pipe(
    map(this.extractData)
  )
}


}
