import { Component, OnInit, Input, HostListener } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  TransactionService,
  ProcessIdGeneraterService,
  ExchangeRatesService,
} from '../../service';
import { UtilityService } from '../../service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BnNgIdleService } from 'bn-ng-idle'; // import it to your component

import { filter } from 'rxjs/operators';
declare const gtag: Function;

interface ICountryList {
  userId: number;
  countryName: string;
}

@Component({
  selector: 'app-fronthome',
  templateUrl: './fronthomenew.component.html',
  styleUrls: ['./fronthome.component.css'],
})
export class FronthomeComponent implements OnInit {
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,

    pullDrag: true,
    dots: true,
    autoplay: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 3,
      },
    },
    nav: true,
  };

  public sendAgentPercentage: any;
  markUpTypeId: any;
  sagent: any;
  ragent: any;
  tempRecAmount: any;
  sCurrency: any;
  dCurrency: any;
  benifApiName: any;
  AMountt: any;
  sendingAmountFlag: boolean;
  minAmountFlag: boolean;
  promoFlag: boolean;
  promoFlag1: boolean;
  promoButtonFlag = true;
  promotionList: any;
  sourceCountryName: any;

  // Added by Martins
  showCalculations: boolean = true;

  @Input() size: string;
  searchText: string;
  gswiftMarkup: number;
  fliqpayMarkup: number;
  public adminPercentage: any;
  public recAgentPercentage: any;
  public bankFilterCtrl: FormControl = new FormControl();

  sendingAgentsList: Array<any>;
  selection: any;
  recAgentsList: {};
  paymentTypeList: {};
  collectionTypeList: {};
  destCountryName: any;
  serviceFee: any;
  exchangerateId: any;
  paymentMethodType: any;
  exchRate: any;
  rate: any;
  selectedCountryCode = 'us';
  receivingFliqpayRate = 0.0;
  countryCodes = ['us', 'lu', 'de', 'bs', 'br', 'pt'];

  changeSelectedCountryCode(value: string): void {
    this.selectedCountryCode = value;
  }
  sendingAgentsList1 = [
    {
      name: 'hdgj',
      age: 32,
      destination: 'india',
    },
    {
      name: 'hdgjewr',
      age: 32,
      destination: 'indiaewr',
    },
  ];
  srcCurrencyName: any;
  flowflag: any;
  isloader: any;
  senderDailingCode: any;
  destDailingCode: any;
  destCurrencyName: any;
  recAmount: any;
  transactionForm: FormGroup;
  serviceFeeTypeName: any;
  minval: any;
  maxval: any;
  serviceFeeTypeVal: any;
  sfee: any;
  receivingCurrency: any;
  sendingCurrency: any;
  serviceFeeTypeId: any;
  reselection: any;
  selectpaytype: any;
  geoRate: number;
  paymentDestination: any;
  destCountryIso: any;
  srcCountryIso: any;
  benifPayTypeName: any;
  totalPay: any;
  sendingCountry: any;
  benifCountry: any;
  srcCountryId: any;
  selectedCity: any;
  apiName: any;
  apiResponse: any;
  fliqpayRates: any;
  selectedCityIds: string[];
  selectedCityName = 'Vilnius';
  selectedCityId: number;
  selectedUserIds: number[];
  cselection: any;
  cselectionName: any;
  destinationCountryId: any;
  markUpTypeName: any;

  // Added by martins
  showContent: boolean = true;

  collectionTypeName: any;
  cminval: any;
  cmaxval: any;
  customerIdd: any;
  collectionType: any;
  showlogin: any;
  gswiftMarkupType: any;
  fliqpayMarkupType: any;
  ColtnFee: any;
  totalFee: any;
  cities3 = [
    {
      id: 1,
      name: 'Vilnius',
      avatar:
        '//www.gravatar.com/avatar/b0d8c6e5ea589e6fc3d3e08afb1873bb?d=retro&r=g&s=30 2x',
    },
    {
      id: 2,
      name: 'Kaunas',
      avatar:
        '//www.gravatar.com/avatar/ddac2aa63ce82315b513be9dc93336e5?d=retro&r=g&s=15',
    },
    {
      id: 3,
      name: 'Pavilnys',
      avatar:
        '//www.gravatar.com/avatar/6acb7abf486516ab7fb0a6efa372042b?d=retro&r=g&s=15',
    },
  ];

  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _transactionService: TransactionService,
    private bnIdle: BnNgIdleService,
    private utilityService: UtilityService,
    private _exchangeratesservice: ExchangeRatesService,
    private processIdGeneraterService: ProcessIdGeneraterService
  ) {
    //     this._router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   /** START : Code to Track Page View  */
    //   alert('f')
    //    gtag('event', 'page_view', {
    //       page_path: event.urlAfterRedirects
    //    })
    //   /** END */
    // })
  }

  ngOnInit(): void {
      const popup = document.querySelector('.popup');
      const x = document.querySelector('.popup-content .btn');
  
      window.addEventListener('load', () => {
        if (popup && x) {
          (popup as HTMLElement).classList.add('showPopup');
          (popup.childNodes[1] as HTMLElement).classList.add('showPopup');
        }
      });
  
      if (x) {
        x.addEventListener('click', () => {
          if (popup) {
            (popup as HTMLElement).classList.remove('showPopup');
            (popup.childNodes[1] as HTMLElement).classList.remove('showPopup');
          }
        });
      }
    
  
this.paymentMethodType="PayId";
if(localStorage.getItem("userId")!=null){
  this._router.navigate(['/index'])
}
this.customerIdd=localStorage.getItem('userId')

if(this.customerIdd != null){

  this.showlogin=true

}else{
  this.showlogin=false

}
this.flowflag='empty';
this.selection=this.sendingAgentsList
this.isloader=false;
this.transactionForm=new FormGroup({
  sendingAgentUserId:new FormControl(''),
  sendingBranchId:new FormControl(''),
  sendingCustomerUserId:new FormControl(''),
  sendingCurrency:new FormControl(''),
  sendingAmount:new FormControl('10'),
  receivingAgentId:new FormControl(''),
  receivingCurrency:new FormControl(''),
  receivingAmount:new FormControl(''),
  beneficiaryPaymentType:new FormControl(''),
  transactionFee:new FormControl(''),
  promoCode:new FormControl(''),
  transactionFeeCurrency:new FormControl(''),
  collectionType:new FormControl(''),
  sellRate:new FormControl(''),
  totalAmount:new FormControl(''),
  sendingReason:new FormControl(''),
  sourceOfFund:new FormControl(''),
  sourceCountryId:new FormControl(''),
  destinationCountryId:new FormControl(''),
  serviceFeeId:new FormControl(''),
  feeCurrency:new FormControl(''),
  serviceFeeMinVal:new FormControl(''),
  serviceFeeMaxVal:new FormControl(''),
  serviceFeeTypeVal:new FormControl(''),
  agentCollectionId:new FormControl(''),
  agentCollectionMinVal:new FormControl(''),
  agentCollectionMaxVal:new FormControl(''),
  markupTypeId:new FormControl(''),
  markupVal:new FormControl(''),
    paymentMethodType:new FormControl(''),
  agentBenifPayTypeId:new FormControl('')
      });

var obj1='SEND';
this.isloader=false;
///////GETTING GSWIFT MARKUP/////////
this._exchangeratesservice.getApiMarkupByApiName('GSWIFT',
this.processIdGeneraterService.getPid()).subscribe(responsee=>{
if(responsee){
this.gswiftMarkup=responsee.response.markup;
this.gswiftMarkupType=responsee.response.markupType;

}
})
///////GETTING FLIQPAY MARKUP/////////
this._exchangeratesservice.getApiMarkupByApiName('FLIQPAY',
this.processIdGeneraterService.getPid()).subscribe(responsee=>{
if(responsee){
this.fliqpayMarkup=responsee.response.markup;
this.fliqpayMarkupType=responsee.response.markupType;
}
})


      // GEO SWIFT EXCHANGE RATE BASED ON RECEIVING COUNTRY
      this._transactionService.getGeoSwiftExcRate(this.processIdGeneraterService.getPid()
      ).subscribe(res=>
      {
      if(res){
        this.isloader=true;

      this.geoRate=res.response;

      }
    });



//SENDING AGENT LIST BASED ON FUNCTIONALITY
this._transactionService.getSendAgentShowList(
this.processIdGeneraterService.getPid()).subscribe(res => {
     if (res){
           this.sendingAgentsList =res.response;
           console.log('sendingAgentsList',this.sendingAgentsList)
           this.sagent=this.sendingAgentsList[0].userId
           this.selection=this.sendingAgentsList[0].userId+"-"+this.sendingAgentsList[0].currency1
           this.sendingCountry=this.sendingAgentsList[0].countryName
           this.sCurrency=this.sendingAgentsList[0].currency1
           this.senderDailingCode=this.sendingAgentsList[0].dailingCode
//GET AGENT COLLECTION TYPES BSED ON SENDING AGENT ID
this._transactionService.getColtnTypesByAgentIdAndSendAmount(
this.sagent,
this.sendingAgentsList[0].currency1,
'FRONTEND',
this.transactionForm.value.sendingAmount,
    this.processIdGeneraterService.getPid()).subscribe(
            res => {
                           if (res){
                                 this.collectionTypeList =res.response;
                                 console.log('collectionTypeList',this.collectionTypeList)
                                 this.cselection=this.collectionTypeList[0].agentCollectionId
                                 this.cselectionName=this.collectionTypeList[0].collectionTypeName

//COLLECTION Fee
this._transactionService.getCollectionFee(
this.processIdGeneraterService.getPid(),
this.cselection,
this.transactionForm.value.sendingAmount,'Personal').subscribe(res => {
       this.ColtnFee =res.response.markupVal;
       this.collectionTypeName=res.response.collectionTypeName;
       this.markUpTypeName=res.response.markUpTypeName;
      this.cminval=res.response.minVal;
      this.cmaxval=res.response.maxVal;
      this.markUpTypeId=res.response.markUpTypeId;
                 if(this.markUpTypeName=='FLATFEE'){
                   this.ColtnFee=res.response.markupVal;
                 }else{
                   this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                 }
this.ColtnFee=(this.ColtnFee).toFixed(2)
       console.log('ColtnFee======',this.ColtnFee)


    //GET DEFAULT RECEIVING AGENTS LIST
    //GET DEFAULT RECEIVING AGENTS LIST
           this._transactionService.getRecAgents(
             this.processIdGeneraterService.getPid(),
             this.sagent).subscribe(
             res => {
               this.recAgentsList = res.response;
               console.log('recAgentsList', this.recAgentsList)
               this.ragent=this.recAgentsList[0].destAgentId
               this.reselection = this.recAgentsList[0].destAgentId+"-"+this.recAgentsList[0].destCurrencyName
               this.destinationCountryId = this.recAgentsList[0].destCountryId;
               this.sourceCountryName=this.recAgentsList[0].srcCountryName
               this.dCurrency=this.recAgentsList[0].destCurrencyName
               this.benifCountry=this.recAgentsList[0].destCountryName
               this.destDailingCode=this.recAgentsList[0].dailingCode
               this.senderDailingCode=this.recAgentsList[0].srcDailingCode
   //GET DEFAULT  AGENT PAYTYPES BY RECEIVING AGENT ID
   this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
    this.ragent,
    this.recAgentsList[0].destCurrencyName,
    this.processIdGeneraterService.getPid()).subscribe(
    res => {
                  if (res){
                        this.paymentTypeList =res.response;
                        console.log('paymentTypeList',this.paymentTypeList)
                        this.selectpaytype = this.paymentTypeList[0].agentBenifPayTypeId
                        this.paymentDestination=this.paymentTypeList[0].benifPayTypeName



                        ///////////////MIN SENDING  AMOUNT
                        this._transactionService.getServiceFeeBySrcAndDestAgentAndPtype(
                          this.processIdGeneraterService.getPid(),
                          this.sagent,
                          this.ragent,
                          this.selectpaytype

                      ).subscribe(respi => {
                                                       if (respi){
                                                        

///////////////////////GET API NAME BY SOURCE AND DEST COUNTRY AND PAYMENT TYPE///////////////////////
this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
this.sendingCountry,
this.benifCountry,
this.paymentDestination,this.dCurrency).subscribe(
res => {
if(res){
this.apiResponse=res.response
this.benifApiName=this.apiResponse.apiName
if(this.benifApiName=='GSWIFT'){
/////////CLOSED API NAME/
this._transactionService.getGeoSwiftExcRate(this.processIdGeneraterService.getPid()
).subscribe(res=>
{
if(res){

this.geoRate=res.response;
this.exchRate = this.geoRate;
let rate=this.exchRate*(this.gswiftMarkup/100);
this.exchRate=this.exchRate-Number(rate);
this.exchRate=(this.exchRate).toFixed(2)

this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);

}},
error => {
console.log(error)
//this.srcAgentPercentage=res.excRate;
}
);
}////////CLOSING GEO SWIFT RATE






//DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
this.processIdGeneraterService.getPid(),
this.sagent,
this.ragent,
this.selectpaytype,
this.sendingAgentsList[0].currency1,
this.recAgentsList[0].destCurrencyName,
'sell','Personal').subscribe(res => {
                           if (res){
                             this.exchangerateId=res.response.exchRateId;
                              this.apiName=res.response.apiName;
                              if(res.response.apiName==='GSWIFT'){
                                  this.exchRate = this.geoRate;
                                if(this.gswiftMarkupType==='FLATFEE'){
                                    this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                }
                                else if(this.gswiftMarkupType==='PERCENTAGE'){

                             let rate=this.exchRate*(this.gswiftMarkup/100);
                             this.exchRate=this.exchRate-Number(rate);
                           }

                           }

                           else if(res.response.apiName==='FLIQPAY'){
                             const obj={
                             'sourceCurrency':res.response.sourceCurrencyCode,
                             'destinationCurrency':res.response.destCurrencyCode,
                             'amount':this.transactionForm.value.sendingAmount,
                             'paymentDestination':this.paymentDestination,
                             'action':'send',

                             }
                             this._transactionService.getDefaultFliqPayRates(
                               this.processIdGeneraterService.getPid(),
                             obj).subscribe(ress => {
                                 if(ress){
                                   this.fliqpayRates=ress.response
                                   this.exchRate = this.fliqpayRates.rate;
                                 if(this.fliqpayMarkupType==='PERCENTAGE'){
       let rate=this.exchRate*(this.fliqpayMarkup/100);
       this.exchRate=this.exchRate-Number(rate);

     }
     else {
         this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
     }
     this.destinationCountryId = res.response.destCountryId;
       this.srcCurrencyName=res.response.srcCurrencyName;
       this.destCurrencyName=res.response.destCurrencyName;
       this.srcCountryIso=res.response.srcCountryIso;
       this.destCountryIso=res.response.destCountryIso;
       this.benifPayTypeName=res.response.benifPayTypeName;
        this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
       this.srcCountryId=res.response.srcCountryId;
        this.destCountryName=res.response.destCountryName;
        this.destCountryName=res.response.destCountryName;
       console.log('exchRate', this.recAmount)
       this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(this.processIdGeneraterService.getPid(),
       this.sagent,
       this.ragent,
       this.selectpaytype,
       this.transactionForm.value.sendingAmount,
       this.sendingAgentsList[0].currency1,
       this.recAgentsList[0].destCurrencyName,
       'Personal'
       ).subscribe(res => {
            if (res){
              this.serviceFee = res.response;
              this.serviceFeeTypeId=res.response.serviceFeeTypeId;
               this.serviceFeeTypeName=res.response.serviceFeeTypeName;
              this.minval=res.response.minVal;

              this.maxval=res.response.maxVal;
              this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

              if(this.apiName=='FLIQPAY'){
          this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
              }
              else{
                     if(this.serviceFeeTypeName=='FLATFEE'){
                       this.sfee=this.serviceFeeTypeVal;
                     }else{
                       this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                     }}

              console.log('serviceFee', this.sfee)
              this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
              this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                  this.isloader=true;
              this.utilityService.getRevenuePercentageRates(
                this.processIdGeneraterService.getPid(),
                this.selection.split("-")[0],
                this.reselection.split("-")[0]
              ).subscribe(
                res1 => {
                  this.sendAgentPercentage=res1.response.srcAgentPercentage;
                  this.adminPercentage=res1.response.adminPercentage;
                  this.recAgentPercentage=res1.response.destAgentPercentage;

                }
              );

            }
          },err =>{
            console.log(err);
          }
        );//CLOSE SERVICE FEE


                                 }  },
                                     error => {
console.log(error);

                           });
                              }

                              else{
                                this.exchRate=(res.response.sellRate).toFixed(2);

                              }
                              if(this.apiName!='FLIQPAY'){
                              this.destinationCountryId = res.response.destCountryId;
                                this.srcCurrencyName=res.response.srcCurrencyName;
                                this.destCurrencyName=res.response.destCurrencyName;
                                this.srcCountryIso=res.response.srcCountryIso;
                                this.destCountryIso=res.response.destCountryIso;
                                this.benifPayTypeName=res.response.benifPayTypeName;
                                 this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                this.srcCountryId=res.response.srcCountryId;
                                 this.destCountryName=res.response.destCountryName;
                                 this.destCountryName=res.response.destCountryName;
                                console.log('exchRate', this.recAmount)
                          }

                           }
                         },err =>{
                           console.log(err);
                         }
                       );//CLOSE EXCHANGE RATE

if(this.apiName!='FLIQPAY'){
//GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(this.processIdGeneraterService.getPid(),
this.sagent,
this.ragent,
this.selectpaytype,
this.transactionForm.value.sendingAmount,
this.sendingAgentsList[0].currency1,
this.recAgentsList[0].destCurrencyName,
'Personal'
).subscribe(res => {
if (res){

this.serviceFee = res.response;
this.serviceFeeTypeId=res.response.serviceFeeTypeId;
this.serviceFeeTypeName=res.response.serviceFeeTypeName;
this.minval=res.response.minVal;
this.maxval=res.response.maxVal;

this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

if(this.apiName=='FLIQPAY'){
this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
}
else{
    if(this.serviceFeeTypeName=='FLATFEE'){
      this.sfee=this.serviceFeeTypeVal;
    }else{
      this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
    }}

console.log('serviceFee', this.sfee)
this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);

 this.isloader=true;
this.utilityService.getRevenuePercentageRates(
this.processIdGeneraterService.getPid(),
this.selection.split("-")[0],
this.reselection.split("-")[0]
).subscribe(
res1 => {
 this.sendAgentPercentage=res1.response.srcAgentPercentage;
 this.adminPercentage=res1.response.adminPercentage;
 this.recAgentPercentage=res1.response.destAgentPercentage;

}
);

}
},err =>{
console.log(err);
}
);//CLOSE SERVICE FEE
}

}

});//getApiName CLOSED

}//IF getAgentBenifPaymentTypes RESPONSE CLOSED
                },err =>{
                  console.log(err);
                });//getAgentBenifPaymentTypes CLOSED

              }
              });
              //////////CLOSE MIN SENDING AMOUNT

            });//RECEIVING AGENT LIST CLOSED
          });//getCollectionFee closed

        }//if closed
        },err =>{
        console.log(err);
});//getAgentCollectionTypes closed

     }//IF SENDING AGENT LIST RESPONSE CLOSED
   },err =>{
     console.log(err);
   });//GET SENDING AGENTS CLOSED


}//NG ON INIT CLOSED
openlogin(){
this._router.navigate(['/login'])
}
opensignup(){
this._router.navigate(['/signup'])
}

//ON CHANGE SENDING AGENT ID
     onChangeSendingAgentId(value:any) {
       this.promoButtonFlag=true;
       this.isloader=false;
       let val=value.split("-");
this.sagent=val[0];
this.sCurrency=val[1];
//this.transactionForm.value.sendingAgentId=agentId;
      if (value) {
        //GET AGENT COLLECTION TYPES BSED ON SENDING AGENT ID
        this._transactionService.getColtnTypesByAgentIdAndSendAmount(
          this.sagent,
          this.sCurrency,
          'FRONTEND',
          this.transactionForm.value.sendingAmount,
                       this.processIdGeneraterService.getPid()).subscribe(
          res => {
                                     if (res){
                                           this.collectionTypeList =res.response;
                                           console.log('collectionTypeList',this.collectionTypeList)
                                           this.cselection=this.collectionTypeList[0].agentCollectionId
                                            this.cselectionName=this.collectionTypeList[0].collectionTypeName

      //COLLECTION Fee
      this._transactionService.getCollectionFee(
      this.processIdGeneraterService.getPid(),
      this.cselection,
      this.transactionForm.value.sendingAmount,'Personal').subscribe(res => {
                 this.ColtnFee =res.response.markupVal;
                 this.collectionTypeName=res.response.collectionTypeName;
                 this.markUpTypeName=res.response.markUpTypeName;
                this.cminval=res.response.minVal;
                this.cmaxval=res.response.maxVal;
                this.markUpTypeId=res.response.markUpTypeId;
                           if(this.markUpTypeName=='FLATFEE'){
                             this.ColtnFee=res.response.markupVal;
                           }else{
                             this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                           }
                           this.ColtnFee=(this.ColtnFee).toFixed(2)

                 console.log('ColtnFee======',this.ColtnFee)

//GET RECEIVE AGENTS
this._transactionService.getRecAgents(
this.processIdGeneraterService.getPid(),this.sagent).subscribe(
res => {
  this.recAgentsList = res.response;
  console.log('recAgentsList', this.recAgentsList)
  this.ragent=this.recAgentsList[0].destAgentId
  this.reselection = this.recAgentsList[0].destAgentId+"-"+this.recAgentsList[0].destCurrencyName
  this.destinationCountryId = this.recAgentsList[0].destCountryId;
  this.sourceCountryName=this.recAgentsList[0].srcCountryName
  this.dCurrency=this.recAgentsList[0].destCurrencyName
                this.benifCountry=this.recAgentsList[0].destCountryName
                this.sendingCountry=this.recAgentsList[0].srcCountryName
                this.destDailingCode=this.recAgentsList[0].dailingCode
                this.senderDailingCode=this.recAgentsList[0].srcDailingCode
                //GET AGENT BENIF PAYMENT Types
                this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
                  this.ragent,
                  this.dCurrency,
                   this.processIdGeneraterService.getPid()).subscribe(
                  res => {
                    this.paymentTypeList = res.response;
                    console.log('paymentTypeList', this.paymentTypeList)
                    this.selectpaytype=this.paymentTypeList[0].agentBenifPayTypeId
                      this.paymentDestination=this.paymentTypeList[0].benifPayTypeName


                                                  ///////////////MIN SENDING  AMOUNT
                                                  this._transactionService.getServiceFeeBySrcAndDestAgentAndPtype(
                                                    this.processIdGeneraterService.getPid(),
                                                    this.sagent,
                                                    this.ragent,
                                                    this.selectpaytype

                                                ).subscribe(respi => {
                                                                                 if (respi){
                                                                                   const o={
                                                                                     'sendingAmount':respi.response[0].minVal
                                                                                   }
                                                                                   this.transactionForm.patchValue(o)


                    ///////////////////////GET API NAME BY SOURCE AND DEST COUNTRY AND PAYMENT TYPE///////////////////////
                    this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
                    this.sendingCountry,
                    this.benifCountry,
                    this.paymentDestination,this.dCurrency).subscribe(
                     result => {
                       if(result){

                    this.benifApiName=result.response.apiName;
                    this.apiResponse=result.response

                    //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                    this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                      this.processIdGeneraterService.getPid(),
                      this.sagent,
                      this.ragent,
                      this.selectpaytype,
                    this.sCurrency,
                  this.dCurrency,'sell','Personal').subscribe(
                      res => {
                                                   if (res){
                                                        this.exchangerateId=res.response.exchRateId;
                                                      this.apiName=res.response.apiName;
                                                      if(res.response.apiName==='GSWIFT'){
                                                          this.exchRate = this.geoRate;
                                                        if(this.gswiftMarkupType==='FLATFEE'){
                                                            this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                            this.exchRate=(this.exchRate).toFixed(2)
                                                        }
                                                        else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                     let rate=this.exchRate*(this.gswiftMarkup/100);
                                                     this.exchRate=this.exchRate-Number(rate);
                                                       this.exchRate=(this.exchRate).toFixed(2)
                                                   }

                                                   }

                                                   else if(res.response.apiName==='FLIQPAY'){
                                                     const obj={
                                                     'sourceCurrency':res.response.sourceCurrencyCode,
                                                     'destinationCurrency':res.response.destCurrencyCode,
                                                     'amount':this.transactionForm.value.sendingAmount,
                                                     'paymentDestination':this.paymentDestination,
                                                     'action':'send',

                                                     }
                                                     this._transactionService.getDefaultFliqPayRates(
                                                       this.processIdGeneraterService.getPid(),
                                                     obj).subscribe(ress => {
                                                         if(ress){
                                                           this.fliqpayRates=ress.response
                                                           this.exchRate = this.fliqpayRates.rate;
                                                         if(this.fliqpayMarkupType==='PERCENTAGE'){
                               let rate=this.exchRate*(this.fliqpayMarkup/100);
                               this.exchRate=this.exchRate-Number(rate);

                             }
                             else {
                                 this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                             }
                             this.destinationCountryId = res.response.destCountryId;
                               this.srcCurrencyName=res.response.srcCurrencyName;
                               this.destCurrencyName=res.response.destCurrencyName;
                               this.srcCountryIso=res.response.srcCountryIso;
                               this.destCountryIso=res.response.destCountryIso;
                               this.benifPayTypeName=res.response.benifPayTypeName;
                                this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                               this.srcCountryId=res.response.srcCountryId;
                                this.destCountryName=res.response.destCountryName;
                                this.destCountryName=res.response.destCountryName;

                               console.log('exchRate', this.recAmount)
                               this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                                 this.processIdGeneraterService.getPid(),
                                 this.sagent,
                                 this.ragent,
                               this.selectpaytype,
                                 this.transactionForm.value.sendingAmount,
                                 this.sCurrency,
                                 this.dCurrency,'Personal').subscribe(
                                 res => {
                                    if (res){


                                      this.serviceFee = res.response;
                                      this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                       this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                      this.minval=res.response.minVal;
                                      this.maxval=res.response.maxVal;
                                      this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                      if(this.apiName=='FLIQPAY'){
                                  this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                      }
                                      else{
                                             if(this.serviceFeeTypeName=='FLATFEE'){
                                               this.sfee=this.serviceFeeTypeVal;
                                             }else{
                                               this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                             }}

                                      console.log('serviceFee', this.sfee)
                                      this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                      this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                                          this.isloader=true;
                                      this.utilityService.getRevenuePercentageRates(
                                        this.processIdGeneraterService.getPid(),
                                        this.selection.split("-")[0],
                                        this.reselection.split("-")[0]
                                      ).subscribe(
                                        res1 => {
                                          this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                          this.adminPercentage=res1.response.adminPercentage;
                                          this.recAgentPercentage=res1.response.destAgentPercentage;

                                        }
                                      );

                                    }
                                  },err =>{
                                    console.log(err);
                                  }
                                );//CLOSE SERVICE FEE


                                                         }  },
                                                             error => {
                    console.log(error);

                                                   });
                                                      }

                                                      else{
                                                        this.exchRate=(res.response.sellRate).toFixed(2);

                                                      }
                                                      if(this.apiName!='FLIQPAY'){
                                                      this.destinationCountryId = res.response.destCountryId;
                                                        this.srcCurrencyName=res.response.srcCurrencyName;
                                                        this.destCurrencyName=res.response.destCurrencyName;
                                                        this.srcCountryIso=res.response.srcCountryIso;
                                                        this.destCountryIso=res.response.destCountryIso;
                                                        this.benifPayTypeName=res.response.benifPayTypeName;
                                                         this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                        this.srcCountryId=res.response.srcCountryId;
                                                         this.destCountryName=res.response.destCountryName;
                                                         this.destCountryName=res.response.destCountryName;

                                                        console.log('exchRate', this.recAmount)
                                                  }

                                                   }
                                                 },err =>{
                                                   console.log(err);
                                                 }
                                               );//CLOSE EXCHANGE RATE

                    if(this.apiName!='FLIQPAY'){
                    //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                    this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                      this.processIdGeneraterService.getPid(),
                      this.sagent,
                      this.ragent,
                    this.selectpaytype,
                      this.transactionForm.value.sendingAmount,
                      this.sCurrency,
                      this.dCurrency,'Personal').subscribe(
                      res => {
                    if (res){

                     this.serviceFee = res.response;
                     this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                      this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                     this.minval=res.response.minVal;
                     this.maxval=res.response.maxVal;
                     this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                     if(this.apiName=='FLIQPAY'){
                    this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                     }
                     else{
                            if(this.serviceFeeTypeName=='FLATFEE'){
                              this.sfee=this.serviceFeeTypeVal;
                            }else{
                              this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                            }}

                     console.log('serviceFee', this.sfee)
                     this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                     this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                         this.isloader=true;
                     this.utilityService.getRevenuePercentageRates(
                       this.processIdGeneraterService.getPid(),
                       this.selection.split("-")[0],
                       this.reselection.split("-")[0]
                     ).subscribe(
                       res1 => {
                         this.sendAgentPercentage=res1.response.srcAgentPercentage;
                         this.adminPercentage=res1.response.adminPercentage;
                         this.recAgentPercentage=res1.response.destAgentPercentage;

                       }
                     );

                    }
                    },err =>{
                    console.log(err);
                    }
                    );//CLOSE SERVICE FEE
                    }
                              }});//////////////////API NAME END

//}////////////////////////////IF API NAME EMPTY CLOSED//////////////////////////
}
});

                  }
                );

              });//get rec agents list closed
            });//getCollectionFee closed

          }//if closed
          },err =>{
          console.log(err);
  });//getAgentCollectionTypes closed
          }//IF CLOSE OF ONCHANGE SENDING AGENT

        }//CLOSE ONCHANGE SENDING AGENT



///////////////////////////////////////////////////////////////////////////////////////////////////////

        //ON CHANGE RECEIVING AGENT ID
            onChangeRecAgentId(agentId:any) {
              this.promoButtonFlag=true;
                this.isloader=false;
                let val=agentId.split("-");
              //  let agentId=val[0];
                this.ragent=val[0];
                this.dCurrency=val[1];
                      if (this.ragent) {
                      //GET AGENT BENIF PAYMENT TYPES
                      this._transactionService.getAgentBenifPaymentTypeListByAgentIdAndCurrency(
                        this.ragent,
                        this.dCurrency,
                         this.processIdGeneraterService.getPid()).subscribe(
                        res => {
                          this.paymentTypeList = res.response;
                          console.log('paymentTypeList', this.paymentTypeList)
                           this.selectpaytype=this.paymentTypeList[0].agentBenifPayTypeId
                           this.paymentDestination=this.paymentTypeList[0].benifPayTypeName


        this._transactionService.getCountryNameByRecAgentId(
          this.ragent, this.processIdGeneraterService.getPid()).subscribe(
          res => {
            if(res){
              this.benifCountry=res.response.destCountryName;
              this.destDailingCode=res.response.dailingCode

        ////////////////api name
        this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
        this.sendingCountry,
        this.benifCountry,
        this.paymentDestination,this.dCurrency).subscribe(
         res => {
        this.apiResponse=res.response
        this.benifApiName=this.apiResponse.apiName


        //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
        this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
          this.processIdGeneraterService.getPid(),
          this.sagent,
          this.ragent,this.selectpaytype,
        this.sCurrency,
      this.dCurrency,'sell','Personal').subscribe(
          res => {
                                       if (res){
                                            this.exchangerateId=res.response.exchRateId;
                                          this.apiName=res.response.apiName;
                                          if(res.response.apiName==='GSWIFT'){
                                              this.exchRate = this.geoRate;
                                            if(this.gswiftMarkupType==='FLATFEE'){
                                                this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                  this.exchRate=(this.exchRate).toFixed(2)
                                            }
                                            else if(this.gswiftMarkupType==='PERCENTAGE'){

                                         let rate=this.exchRate*(this.gswiftMarkup/100);
                                         this.exchRate=this.exchRate-Number(rate);
                                           this.exchRate=(this.exchRate).toFixed(2)
                                       }

                                       }

                                       else if(res.response.apiName==='FLIQPAY'){
                                         const obj={
                                         'sourceCurrency':res.response.sourceCurrencyCode,
                                         'destinationCurrency':res.response.destCurrencyCode,
                                         'amount':this.transactionForm.value.sendingAmount,
                                         'paymentDestination':this.paymentDestination,
                                         'action':'send',

                                         }
                                         this._transactionService.getDefaultFliqPayRates(
                                           this.processIdGeneraterService.getPid(),
                                         obj).subscribe(ress => {
                                             if(ress){
                                               this.fliqpayRates=ress.response
                                               this.exchRate = this.fliqpayRates.rate;
                                             if(this.fliqpayMarkupType==='PERCENTAGE'){
                   let rate=this.exchRate*(this.fliqpayMarkup/100);
                   this.exchRate=this.exchRate-Number(rate);

                 }
                 else {
                     this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                 }
                 this.destinationCountryId = res.response.destCountryId;
                   this.srcCurrencyName=res.response.srcCurrencyName;
                   this.destCurrencyName=res.response.destCurrencyName;
                   this.srcCountryIso=res.response.srcCountryIso;
                   this.destCountryIso=res.response.destCountryIso;
                   this.benifPayTypeName=res.response.benifPayTypeName;
                    this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                   this.srcCountryId=res.response.srcCountryId;
                    this.destCountryName=res.response.destCountryName;
                    this.destCountryName=res.response.destCountryName;

                   console.log('exchRate', this.recAmount)
                   this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                     this.processIdGeneraterService.getPid(),
                     this.sagent,
                     this.ragent,
                     this.selectpaytype,
                     this.transactionForm.value.sendingAmount,
                   this.sCurrency,
                 this.dCurrency,'Personal').subscribe(
                     res => {
                        if (res){


                          this.serviceFee = res.response;
                          this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                           this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                          this.minval=res.response.minVal;
                          this.maxval=res.response.maxVal;
                          this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                          if(this.apiName=='FLIQPAY'){
                      this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                          }
                          else{
                                 if(this.serviceFeeTypeName=='FLATFEE'){
                                   this.sfee=this.serviceFeeTypeVal;
                                 }else{
                                   this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                 }}

                          console.log('serviceFee', this.sfee)
                          this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                          this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                              this.isloader=true;
                          this.utilityService.getRevenuePercentageRates(
                            this.processIdGeneraterService.getPid(),
                            this.selection.split("-")[0],
                            this.reselection.split("-")[0]
                          ).subscribe(
                            res1 => {
                              this.sendAgentPercentage=res1.response.srcAgentPercentage;
                              this.adminPercentage=res1.response.adminPercentage;
                              this.recAgentPercentage=res1.response.destAgentPercentage;

                            }
                          );

                        }
                      },err =>{
                        console.log(err);
                      }
                    );//CLOSE SERVICE FEE


                                             }  },
                                                 error => {
        console.log(error);

                                       });
                                          }

                                          else{
                                            this.exchRate=(res.response.sellRate).toFixed(2);
                                          }
                                          if(this.apiName!='FLIQPAY'){
                                          this.destinationCountryId = res.response.destCountryId;
                                            this.srcCurrencyName=res.response.srcCurrencyName;
                                            this.destCurrencyName=res.response.destCurrencyName;
                                            this.srcCountryIso=res.response.srcCountryIso;
                                            this.destCountryIso=res.response.destCountryIso;
                                            this.benifPayTypeName=res.response.benifPayTypeName;
                                             this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                            this.srcCountryId=res.response.srcCountryId;
                                             this.destCountryName=res.response.destCountryName;
                                             this.destCountryName=res.response.destCountryName;

                                            console.log('exchRate', this.recAmount)
                                      }

                                       }
                                     },err =>{
                                       console.log(err);
                                     }
                                   );//CLOSE EXCHANGE RATE

        if(this.apiName!='FLIQPAY'){
        //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
        this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
          this.processIdGeneraterService.getPid(),
          this.sagent,
          this.ragent,
          this.selectpaytype,
          this.transactionForm.value.sendingAmount,
        this.sCurrency,
      this.dCurrency,'Personal').subscribe(
          res => {
        if (res){

         this.serviceFee = res.response;
         this.serviceFeeTypeId=res.response.serviceFeeTypeId;
          this.serviceFeeTypeName=res.response.serviceFeeTypeName;
         this.minval=res.response.minVal;
         this.maxval=res.response.maxVal;
         this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

         if(this.apiName=='FLIQPAY'){
        this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
         }
         else{
                if(this.serviceFeeTypeName=='FLATFEE'){
                  this.sfee=this.serviceFeeTypeVal;
                }else{
                  this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                }}

         console.log('serviceFee', this.sfee)
         this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
         this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
             this.isloader=true;
         this.utilityService.getRevenuePercentageRates(
           this.processIdGeneraterService.getPid(),
           this.selection.split("-")[0],
           this.reselection.split("-")[0]
         ).subscribe(
           res1 => {
             this.sendAgentPercentage=res1.response.srcAgentPercentage;
             this.adminPercentage=res1.response.adminPercentage;
             this.recAgentPercentage=res1.response.destAgentPercentage;

           }
         );

        }
        },err =>{
        console.log(err);
        }
        );//CLOSE SERVICE FEE
        }
                      });
                    }
                  })

          });////////////////payment types closed
        }
        }///////////////////////////////////////////

        //ON CHANGE PAYMENTTYPE
        onChangeAgentBenifPayTypeId(agentBenifPayTypeId:any) {
          this.promoButtonFlag=true;
this.isloader=false;
          if (agentBenifPayTypeId) {

this._transactionService.getBenifPayTypeName(this.processIdGeneraterService.getPid(),
agentBenifPayTypeId).subscribe(res=>{
if(res){
this.paymentDestination=res.response.benifPayTypeName
        //GET EXCHANGE RATES
                this._transactionService.getApiName(this.processIdGeneraterService.getPid(),
                this.sendingCountry,
                this.benifCountry,
                this.paymentDestination,this.dCurrency).subscribe(
                 res => {
                this.apiResponse=res.response
                this.benifApiName=this.apiResponse.apiName

                //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                  this.processIdGeneraterService.getPid(),
                  this.sagent,
                  this.ragent,
                  agentBenifPayTypeId,
                this.sCurrency,this.dCurrency,'sell','Personal').subscribe(
                  res => {
                                               if (res){
                                                    this.exchangerateId=res.response.exchRateId;
                                                  this.apiName=res.response.apiName;
                                                  if(res.response.apiName==='GSWIFT'){
                                                      this.exchRate = this.geoRate;
                                                    if(this.gswiftMarkupType==='FLATFEE'){
                                                        this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                          this.exchRate=(this.exchRate).toFixed(2)
                                                    }
                                                    else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                 let rate=this.exchRate*(this.gswiftMarkup/100);
                                                 this.exchRate=this.exchRate-Number(rate);
                                                   this.exchRate=(this.exchRate).toFixed(2)
                                               }

                                               }

                                               else if(res.response.apiName==='FLIQPAY'){
                                                 const obj={
                                                 'sourceCurrency':res.response.sourceCurrencyCode,
                                                 'destinationCurrency':res.response.destCurrencyCode,
                                                 'amount':this.transactionForm.value.sendingAmount,
                                                 'paymentDestination':this.paymentDestination,
                                                 'action':'send',

                                                 }
                                                 this._transactionService.getDefaultFliqPayRates(
                                                   this.processIdGeneraterService.getPid(),
                                                 obj).subscribe(ress => {
                                                     if(ress){
                                                       this.fliqpayRates=ress.response
                                                       this.exchRate = this.fliqpayRates.rate;
                                                     if(this.fliqpayMarkupType==='PERCENTAGE'){
                           let rate=this.exchRate*(this.fliqpayMarkup/100);
                           this.exchRate=this.exchRate-Number(rate);

                         }
                         else {
                             this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                         }
                         this.destinationCountryId = res.response.destCountryId;
                           this.srcCurrencyName=res.response.srcCurrencyName;
                           this.destCurrencyName=res.response.destCurrencyName;
                           this.srcCountryIso=res.response.srcCountryIso;
                           this.destCountryIso=res.response.destCountryIso;
                           this.benifPayTypeName=res.response.benifPayTypeName;
                            this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                           this.srcCountryId=res.response.srcCountryId;
                            this.destCountryName=res.response.destCountryName;
                            this.destCountryName=res.response.destCountryName;

                           console.log('exchRate', this.recAmount)
                           this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                             this.processIdGeneraterService.getPid(),
                             this.sagent,
                             this.ragent,
                             agentBenifPayTypeId,
                             this.transactionForm.value.sendingAmount,
                           this.sCurrency,this.dCurrency,'Personal').subscribe(
                             res => {
                                if (res){


                                  this.serviceFee = res.response;
                                  this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                   this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                  this.minval=res.response.minVal;
                                  this.maxval=res.response.maxVal;
                                  this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                  if(this.apiName=='FLIQPAY'){
                              this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                  }
                                  else{
                                         if(this.serviceFeeTypeName=='FLATFEE'){
                                           this.sfee=this.serviceFeeTypeVal;
                                         }else{
                                           this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                         }}

                                  console.log('serviceFee', this.sfee)
                                  this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                  this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                                      this.isloader=true;
                                  this.utilityService.getRevenuePercentageRates(
                                    this.processIdGeneraterService.getPid(),
                                    this.sagent,
                                    this.ragent
                                  ).subscribe(
                                    res1 => {
                                      this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                      this.adminPercentage=res1.response.adminPercentage;
                                      this.recAgentPercentage=res1.response.destAgentPercentage;

                                    }
                                  );

                                }
                              },err =>{
                                console.log(err);
                              }
                            );//CLOSE SERVICE FEE


                                                     }  },
                                                         error => {
                console.log(error);

                                               });
                                                  }

                                                  else{
                                                    this.exchRate=(res.response.sellRate).toFixed(2);
                                                  }
                                                  if(this.apiName!='FLIQPAY'){
                                                  this.destinationCountryId = res.response.destCountryId;
                                                    this.srcCurrencyName=res.response.srcCurrencyName;
                                                    this.destCurrencyName=res.response.destCurrencyName;
                                                    this.srcCountryIso=res.response.srcCountryIso;
                                                    this.destCountryIso=res.response.destCountryIso;
                                                    this.benifPayTypeName=res.response.benifPayTypeName;
                                                     this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                                                    this.srcCountryId=res.response.srcCountryId;
                                                     this.destCountryName=res.response.destCountryName;
                                                     this.destCountryName=res.response.destCountryName;

                                                    console.log('exchRate', this.recAmount)
                                              }

                                               }
                                             },err =>{
                                               console.log(err);
                                             }
                                           );//CLOSE EXCHANGE RATE

                if(this.apiName!='FLIQPAY'){
                //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                  this.processIdGeneraterService.getPid(),
                  this.sagent,
                  this.ragent,
                  agentBenifPayTypeId,
                  this.transactionForm.value.sendingAmount,
                this.sCurrency,this.dCurrency,'Personal').subscribe(
                  res => {
                if (res){

                 this.serviceFee = res.response;
                 this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                  this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                 this.minval=res.response.minVal;
                 this.maxval=res.response.maxVal;
                 this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                 if(this.apiName=='FLIQPAY'){
                this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                 }
                 else{
                        if(this.serviceFeeTypeName=='FLATFEE'){
                          this.sfee=this.serviceFeeTypeVal;
                        }else{
                          this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                        }}

                 console.log('serviceFee', this.sfee)
                 this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                 this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                     this.isloader=true;
                 this.utilityService.getRevenuePercentageRates(
                   this.processIdGeneraterService.getPid(),
                   this.sagent,
                   this.ragent
                 ).subscribe(
                   res1 => {
                     this.sendAgentPercentage=res1.response.srcAgentPercentage;
                     this.adminPercentage=res1.response.adminPercentage;
                     this.recAgentPercentage=res1.response.destAgentPercentage;

                   }
                 );

                }
                },err =>{
                console.log(err);
                }
                );//CLOSE SERVICE FEE
                }
 });

}
})

        }
        }////////////////////////ON CHANGE

        onChangePromo(){
          this.utilityService.getPromotionByCriteriaOnFront(this.processIdGeneraterService.getPid(),
          this.sendingCountry,this.destCountryName,this.benifPayTypeName,
        this.transactionForm.value.sendingAmount,this.recAmount,this.transactionForm.value.promoCode,'enabled').subscribe(res=>{
          if(res.response){
            this.promotionList=res.response;
            this.promoButtonFlag=false;
            console.log("promotion",res.response);
            if(this.promotionList.promotionType==="FlatFee"){

              this.exchRate=Number(this.exchRate)+Number(this.promotionList.promtionValue)
              this.recAmount=Number(this.transactionForm.value.sendingAmount)*Number(this.exchRate);
                     this.transactionForm.get('receivingAmount').setValue(this.recAmount);
            }
            if(this.promotionList.promotionType==='percentage'){
              this.exchRate=Number(this.exchRate)+Number((this.exchRate/100)*this.promotionList.promtionValue)

              this.recAmount=Number(this.transactionForm.value.sendingAmount)*Number(this.exchRate);
                     this.transactionForm.get('receivingAmount').setValue(this.recAmount);
              }
        this.promoFlag=true;
        this.promoFlag1=false;
        setTimeout(function () {
          this.promoFlag=false;
        }.bind(this),2000);
          }
          else{
            this.promoFlag=false;
            this.promoFlag1=true;

        //  this.transactionForm.value.promoCode.reset();
        this.transactionForm.get('promoCode').reset();
            setTimeout(function () {
              this.promoFlag1=false;
            }.bind(this),2000);
          }
        })

        }
        public contact(){
          this._router.navigate(['/contactus'])
        }
        public faqs(){
          this._router.navigate(['faqs'])
        }
        public aboutus(){
          this._router.navigate(['/aboutus'])
        }

onChangePayment(val:any){
//COLLECTION Fee
this._transactionService.getCollectionFee(
this.processIdGeneraterService.getPid(),
val,
this.transactionForm.value.sendingAmount,'Personal').subscribe(res => {
       this.ColtnFee =(res.response.markupVal).toFixed(2);
       this.collectionTypeName=res.response.collectionTypeName;
       this.markUpTypeName=res.response.markUpTypeName;
      this.cminval=res.response.minVal;
      this.cmaxval=res.response.maxVal;
      this.markUpTypeId=res.response.markUpTypeId;
                 if(this.markUpTypeName=='FLATFEE'){
                   this.ColtnFee=(res.response.markupVal).toFixed(2);
                 }else{
                   this.ColtnFee=((this.transactionForm.value.sendingAmount*res.response.markupVal)/100).toFixed(2);
                 }

                 this.ColtnFee=(this.ColtnFee).toFixed(2)

       console.log('onChangePayment ColtnFee======',this.ColtnFee)
       this.totalFee=(Number(this.sfee)+Number(this.ColtnFee)).toFixed(2);
       console.log('onChangePayment totalFee', this.totalFee)
       this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
       console.log('onChangePayment totalPay', this.totalPay)

});//getCollectionFee closed
}


onKeyup(AMount){
  AMount=  parseFloat(AMount).toFixed(2);

this.promoButtonFlag=true;
this.isloader=false;
if(this.maxval<AMount){

this.sendingAmountFlag=true;
}
else{
this.sendingAmountFlag=false;
}
if(this.minval>AMount){

this.minAmountFlag=true;
}
else{
this.minAmountFlag=false;
}
this._transactionService.getCollectionFee(
this.processIdGeneraterService.getPid(),
this.transactionForm.value.paymentMethodType,
AMount,'Personal').subscribe(res => {
         this.ColtnFee =res.response.markupVal;
         this.collectionTypeName=res.response.collectionTypeName;
         this.markUpTypeName=res.response.markUpTypeName;
        this.cminval=res.response.minVal;
        this.cmaxval=res.response.maxVal;
        this.markUpTypeId=res.response.markUpTypeId;
                   if(this.markUpTypeName=='FLATFEE'){
                     this.ColtnFee=res.response.markupVal;
                   }else{
                     this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                   }

                   this.ColtnFee=(this.ColtnFee).toFixed(2)

         console.log('onkeyup ColtnFee======',this.ColtnFee)


         //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
         this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
           this.processIdGeneraterService.getPid(),
           this.sagent,
           this.ragent,
           this.transactionForm.value.agentBenifPayTypeId,
         this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'sell','Personal').subscribe(
           res => {
                                        if (res){
                                             this.exchangerateId=res.response.exchRateId;
                                           this.apiName=res.response.apiName;
                                           if(res.response.apiName==='GSWIFT'){
                                               this.exchRate = this.geoRate;
                                             if(this.gswiftMarkupType==='FLATFEE'){
                                                 this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                   this.exchRate=(this.exchRate).toFixed(2)
                                             }
                                             else if(this.gswiftMarkupType==='PERCENTAGE'){

                                          let rate=this.exchRate*(this.gswiftMarkup/100);
                                          this.exchRate=this.exchRate-Number(rate);
                                            this.exchRate=(this.exchRate).toFixed(2)
                                        }

                                        }

                                        else if(res.response.apiName==='FLIQPAY'){
                                          const obj={
                                          'sourceCurrency':res.response.sourceCurrencyCode,
                                          'destinationCurrency':res.response.destCurrencyCode,
                                          'amount':this.transactionForm.value.sendingAmount,
                                          'paymentDestination':this.paymentDestination,
                                          'action':'send',

                                          }
                                          this._transactionService.getDefaultFliqPayRates(
                                            this.processIdGeneraterService.getPid(),
                                          obj).subscribe(ress => {
                                              if(ress){
                                                this.fliqpayRates=ress.response
                                                this.exchRate = this.fliqpayRates.rate;
                                              if(this.fliqpayMarkupType==='PERCENTAGE'){
                    let rate=this.exchRate*(this.fliqpayMarkup/100);
                    this.exchRate=this.exchRate-Number(rate);

                  }
                  else {
                      this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                  }
                  this.destinationCountryId = res.response.destCountryId;
                    this.srcCurrencyName=res.response.srcCurrencyName;
                    this.destCurrencyName=res.response.destCurrencyName;
                    this.srcCountryIso=res.response.srcCountryIso;
                    this.destCountryIso=res.response.destCountryIso;
                    this.benifPayTypeName=res.response.benifPayTypeName;
                     this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                     this.transactionForm.get('receivingAmount').setValue(this.recAmount);

                    this.srcCountryId=res.response.srcCountryId;
                     this.destCountryName=res.response.destCountryName;
                     this.destCountryName=res.response.destCountryName;

                    console.log('exchRate', this.recAmount)
                    this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                      this.processIdGeneraterService.getPid(),
                      this.sagent,
                      this.ragent,
                      this.transactionForm.value.agentBenifPayTypeId,
                      this.transactionForm.value.sendingAmount,
                    this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'Personal').subscribe(
                      res => {
                         if (res){


                           this.serviceFee = res.response;
                           this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                            this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                           this.minval=res.response.minVal;
                           this.maxval=res.response.maxVal;
                           this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                           if(this.apiName=='FLIQPAY'){
                       this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                           }
                           else{
                                  if(this.serviceFeeTypeName=='FLATFEE'){
                                    this.sfee=this.serviceFeeTypeVal;
                                  }else{
                                    this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                  }}

                           console.log('serviceFee', this.sfee)
                           this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                           this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                               this.isloader=true;
                           this.utilityService.getRevenuePercentageRates(
                             this.processIdGeneraterService.getPid(),
                             this.sagent,
                             this.ragent
                           ).subscribe(
                             res1 => {
                               this.sendAgentPercentage=res1.response.srcAgentPercentage;
                               this.adminPercentage=res1.response.adminPercentage;
                               this.recAgentPercentage=res1.response.destAgentPercentage;

                             }
                           );

                         }
                       },err =>{
                         console.log(err);
                       }
                     );//CLOSE SERVICE FEE


                                              }  },
                                                  error => {
         console.log(error);

                                        });
                                           }

                                           else{
                                             this.exchRate=(res.response.sellRate).toFixed(2);


                                           }
                                           if(this.apiName!='FLIQPAY'){
                                           this.destinationCountryId = res.response.destCountryId;
                                             this.srcCurrencyName=res.response.srcCurrencyName;
                                             this.destCurrencyName=res.response.destCurrencyName;
                                             this.srcCountryIso=res.response.srcCountryIso;
                                             this.destCountryIso=res.response.destCountryIso;
                                             this.benifPayTypeName=res.response.benifPayTypeName;
                                              this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate);
                                              this.transactionForm.get('receivingAmount').setValue(this.recAmount);
                                             this.srcCountryId=res.response.srcCountryId;
                                              this.destCountryName=res.response.destCountryName;
                                              this.destCountryName=res.response.destCountryName;

                                             console.log('exchRate', this.recAmount)
                                       }

                                        }
                                      },err =>{
                                        console.log(err);
                                      }
                                    );//CLOSE EXCHANGE RATE

         if(this.apiName!='FLIQPAY'){
         //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
         this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
           this.processIdGeneraterService.getPid(),
           this.sagent,
           this.ragent,
           this.transactionForm.value.agentBenifPayTypeId,
           this.transactionForm.value.sendingAmount,
         this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'Personal').subscribe(
           res => {
         if (res){

          this.serviceFee = res.response;
          this.serviceFeeTypeId=res.response.serviceFeeTypeId;
           this.serviceFeeTypeName=res.response.serviceFeeTypeName;
          this.minval=res.response.minVal;
          this.maxval=res.response.maxVal;
          this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

          if(this.apiName=='FLIQPAY'){
         this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
          }
          else{
                 if(this.serviceFeeTypeName=='FLATFEE'){
                   this.sfee=this.serviceFeeTypeVal;
                 }else{
                   this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                 }}

          console.log('serviceFee', this.sfee)
          this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
          this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
              this.isloader=true;

          this.utilityService.getRevenuePercentageRates(
            this.processIdGeneraterService.getPid(),
            this.sagent,
            this.ragent
          ).subscribe(
            res1 => {
              this.sendAgentPercentage=res1.response.srcAgentPercentage;
              this.adminPercentage=res1.response.adminPercentage;
              this.recAgentPercentage=res1.response.destAgentPercentage;

            }
          );

         }
         },err =>{
         console.log(err);
         }
         );//CLOSE SERVICE FEE
         }    });//get coltn fee closed
        }

        onKeyupRR(AMount){
          AMount=  parseFloat(AMount).toFixed(2);

    this.promoButtonFlag=true;
      this.isloader=false;
      if(this.maxval<AMount){
        this.sendingAmountFlag=true;
      }

      else{
        this.sendingAmountFlag=false;
      }
      if(this.minval>AMount){
        this.minAmountFlag=true;
            }
      else{
        this.minAmountFlag=false;
      }
      this._transactionService.getCollectionFee(
      this.processIdGeneraterService.getPid(),
      this.transactionForm.value.paymentMethodType,
    AMount,'Personal').subscribe(res => {
                 this.ColtnFee =res.response.markupVal;
                 this.collectionTypeName=res.response.collectionTypeName;
                 this.markUpTypeName=res.response.markUpTypeName;
                this.cminval=res.response.minVal;
                this.cmaxval=res.response.maxVal;
                this.markUpTypeId=res.response.markUpTypeId;
                           if(this.markUpTypeName=='FLATFEE'){
                             this.ColtnFee=res.response.markupVal;
                           }else{
                             this.ColtnFee=(this.transactionForm.value.sendingAmount*res.response.markupVal)/100;
                           }
                           this.ColtnFee=(this.ColtnFee).toFixed(2)

                 console.log('onkeyup ColtnFee======',this.ColtnFee)


                 //DEFAULT EXCHANGE RATES BY SENDING,RECEIVING AGENTS AND PTYPES
                 this._transactionService.getExchangeRatesByDestAndSrcAgentAndSrcCurrencyAndDestCurrencyAndPType(
                   this.processIdGeneraterService.getPid(),
                   this.sagent,
                   this.ragent,
                   this.transactionForm.value.agentBenifPayTypeId,
                 this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'sell','Personal').subscribe(
                   res => {
                                                if (res){
                                                     this.exchangerateId=res.response.exchRateId;
                                                   this.apiName=res.response.apiName;
                                                   if(res.response.apiName==='GSWIFT'){
                                                       this.exchRate = this.geoRate;
                                                     if(this.gswiftMarkupType==='FLATFEE'){
                                                         this.exchRate=this.exchRate-Number(this.gswiftMarkup);
                                                           this.exchRate=(this.exchRate).toFixed(2)
                                                     }
                                                     else if(this.gswiftMarkupType==='PERCENTAGE'){

                                                  let rate=this.exchRate*(this.gswiftMarkup/100);
                                                  this.exchRate=this.exchRate-Number(rate);
                                                    this.exchRate=(this.exchRate).toFixed(2)
                                                }

                                                }

                                                else if(res.response.apiName==='FLIQPAY'){
                                                  const obj={
                                                  'sourceCurrency':res.response.sourceCurrencyCode,
                                                  'destinationCurrency':res.response.destCurrencyCode,
                                                  'amount':this.transactionForm.value.sendingAmount,
                                                  'paymentDestination':this.paymentDestination,
                                                  'action':'send',

                                                  }
                                                  this._transactionService.getDefaultFliqPayRates(
                                                    this.processIdGeneraterService.getPid(),
                                                  obj).subscribe(ress => {
                                                      if(ress){
                                                        this.fliqpayRates=ress.response
                                                        this.exchRate = this.fliqpayRates.rate;
                                                      if(this.fliqpayMarkupType==='PERCENTAGE'){
                            let rate=this.exchRate*(this.fliqpayMarkup/100);
                            this.exchRate=this.exchRate-Number(rate);

                          }
                          else {
                              this.exchRate=this.exchRate-Number(this.fliqpayMarkup);
                          }
                          this.destinationCountryId = res.response.destCountryId;
                            this.srcCurrencyName=res.response.srcCurrencyName;
                            this.destCurrencyName=res.response.destCurrencyName;
                            this.srcCountryIso=res.response.srcCountryIso;
                            this.destCountryIso=res.response.destCountryIso;
                            this.benifPayTypeName=res.response.benifPayTypeName;
                             this.recAmount=(this.transactionForm.value.sendingAmount*this.exchRate).toFixed(2);
                             this.transactionForm.get('receivingAmount').setValue(this.recAmount);

                            this.srcCountryId=res.response.srcCountryId;
                             this.destCountryName=res.response.destCountryName;
                             this.destCountryName=res.response.destCountryName;

                            console.log('exchRate', this.recAmount)
                            this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                              this.processIdGeneraterService.getPid(),
                              this.sagent,
                              this.ragent,
                              this.transactionForm.value.agentBenifPayTypeId,
                              this.transactionForm.value.sendingAmount,
                            this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'Personal').subscribe(
                              res => {
                                 if (res){


                                   this.serviceFee = res.response;
                                   this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                                    this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                                   this.minval=res.response.minVal;
                                   this.maxval=res.response.maxVal;
                                   this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                                   if(this.apiName=='FLIQPAY'){
                               this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                                   }
                                   else{
                                          if(this.serviceFeeTypeName=='FLATFEE'){
                                            this.sfee=this.serviceFeeTypeVal;
                                          }else{
                                            this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                                          }}

                                   console.log('serviceFee', this.sfee)
                                   this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                                   this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                                       this.isloader=true;
                                   this.utilityService.getRevenuePercentageRates(
                                     this.processIdGeneraterService.getPid(),
                                     this.sagent,
                                     this.ragent
                                   ).subscribe(
                                     res1 => {
                                       this.sendAgentPercentage=res1.response.srcAgentPercentage;
                                       this.adminPercentage=res1.response.adminPercentage;
                                       this.recAgentPercentage=res1.response.destAgentPercentage;

                                     }
                                   );

                                 }
                               },err =>{
                                 console.log(err);
                               }
                             );//CLOSE SERVICE FEE


                                                      }  },
                                                          error => {
                 console.log(error);

                                                });
                                                   }

                                                   else{
                                                     this.exchRate=(res.response.sellRate).toFixed(2);


                                                   }
                                                   if(this.apiName!='FLIQPAY'){
                                                   this.destinationCountryId = res.response.destCountryId;
                                                     this.srcCurrencyName=res.response.srcCurrencyName;
                                                     this.destCurrencyName=res.response.destCurrencyName;
                                                     this.srcCountryIso=res.response.srcCountryIso;
                                                     this.destCountryIso=res.response.destCountryIso;
                                                     this.benifPayTypeName=res.response.benifPayTypeName;


                                                     this.srcCountryId=res.response.srcCountryId;
                                                      this.destCountryName=res.response.destCountryName;
                                                      this.destCountryName=res.response.destCountryName;

                                                     console.log('exchRate', this.recAmount)
                                               }

                                                }
                                              },err =>{
                                                console.log(err);
                                              }
                                            );//CLOSE EXCHANGE RATE

                 if(this.apiName!='FLIQPAY'){
                 //GET SERVICE FEE BY SENDING,RECEIVING ,PAYTYPE,SENDING AMount
                 this._transactionService.getServiceFeeBySrcAndDestAgentAndPtypeAndCurrency(
                   this.processIdGeneraterService.getPid(),
                   this.sagent,
                   this.ragent,
                   this.transactionForm.value.agentBenifPayTypeId,
                   this.transactionForm.value.sendingAmount,
                 this.transactionForm.value.sendingAgentUserId.split("-")[1],this.dCurrency,'Personal').subscribe(
                   res => {
                 if (res){
this.isloader=false;
                  this.serviceFee = res.response;
                  this.serviceFeeTypeId=res.response.serviceFeeTypeId;
                   this.serviceFeeTypeName=res.response.serviceFeeTypeName;
                  this.minval=res.response.minVal;
                  this.maxval=res.response.maxVal;
                  this.serviceFeeTypeVal=res.response.serviceFeeTypeVal;

                  if(this.apiName=='FLIQPAY'){
                 this.sfee=this.fliqpayRates.fee/this.fliqpayRates.rate;
                  }
                  else{
                         if(this.serviceFeeTypeName=='FLATFEE'){
                           this.sfee=this.serviceFeeTypeVal;
                         }else{
                           this.sfee=(this.transactionForm.value.sendingAmount*this.serviceFeeTypeVal)/100;
                         }}

                  console.log('serviceFee', this.sfee)
                  this.totalFee=Number(this.sfee)+Number(this.ColtnFee);
                  this.totalPay=(Number(this.sfee)+Number(this.transactionForm.value.sendingAmount)+Number(this.ColtnFee)).toFixed(2);
                      // this.isloader=true;

                  this.utilityService.getRevenuePercentageRates(
                    this.processIdGeneraterService.getPid(),
                    this.sagent,
                    this.ragent
                  ).subscribe(
                    res1 => {
                      this.sendAgentPercentage=res1.response.srcAgentPercentage;
                      this.adminPercentage=res1.response.adminPercentage;
                      this.recAgentPercentage=res1.response.destAgentPercentage;

                    }
                  );

                 }
                 },err =>{
                 console.log(err);
                 this.isloader=false;
                 }
                 );//CLOSE SERVICE FEE
                 }    });//get coltn fee closed
                }

                onKeyupR(AMount){
      AMount=  parseFloat(AMount).toFixed(2);
                  AMount=AMount/this.exchRate;
                  AMount=  parseFloat(AMount).toFixed(2);

                const o={
                  'sendingAmount':AMount
                }
                this.transactionForm.patchValue(o);

              this.onKeyupRR(AMount);
                }

        private _filter(value: string): string[] {
          const filterValue = value.toLowerCase();

          return this.sendingAgentsList.filter(option => option.toLowerCase().includes(filterValue));
        }

        onSubmit(){

            if(this.apiName==='FLIQPAY'){
              localStorage.setItem("quoteRef",this.fliqpayRates.reference);
              localStorage.setItem("fliqpayRates",JSON.stringify(this.fliqpayRates));
              this.serviceFeeTypeVal=this.fliqpayRates.fee;
            }


            console.log('rec  amount in onsubmit', this.recAmount)
            const newObj = {
              'receivingAmount':this.recAmount,
              'sendingAgentUserId':this.sagent,
              'receivingAgentId':this.ragent,
              'sendingAmount' :this.transactionForm.value.sendingAmount,
              'sendingCurrency':this.sCurrency,
              'destinationCountryId':this.destinationCountryId,
              'agentBenifPayTypeId':this.transactionForm.value.agentBenifPayTypeId,
              'serviceFeeTypeName':this.serviceFeeTypeName,
              'serviceFeeId':this.serviceFeeTypeId,
              'feeCurrency':this.srcCurrencyName,
              'serviceFeeMinVal':this.minval,
              'transactionFee':this.sfee,
              'serviceFeeMaxVal':this.maxval,
              'serviceFeeTypeVal':this.serviceFeeTypeVal,
              'sellRate':this.exchRate,
              'destCountryIso':this.destCountryIso,
              'srcCountryIso':this.srcCountryIso,
              'benifPayTypeName':this.benifPayTypeName,
              'totalPay':this.totalPay,
              'destCurrencyName':this.transactionForm.value.receivingAgentId.split("-")[1],
              'srcCountryId':this.srcCountryId,
              'agentCollectionId':this.cselection,
              'ColtnFee':this.ColtnFee,
              'cminval':this.cminval,
              'cmaxval':this.cmaxval,
              'collectionType':this.collectionType,
              'sendAgentPercentage':this.sendAgentPercentage,
              'adminPercentage':this.adminPercentage,
              'recAgentPercentage':this.recAgentPercentage,
              'markUpTypeId':this.markUpTypeId,
              'destCountryName':this.destCountryName,
              'sourceCountryName':this.sendingCountry,
              'apiName':this.benifApiName,
              'sourceDailingCode':this.senderDailingCode,
              'destDailingCode':this.destDailingCode,
              'paymentMethodType':this.paymentMethodType,
              'exchangerateId':this.exchangerateId
            }

            localStorage.setItem("apiName",this.benifApiName);
          if(this.destCountryName=='NIGERIA'){
            localStorage.setItem("benifCountry",'NGN');
          }else if(this.destCountryName=='GHANA'){
            localStorage.setItem("benifCountry",'GHS')
          }else if(this.destCountryName=='UNITED STATES'){
            localStorage.setItem("benifCountry",'USD')
          }else if(this.destCountryName=='UNITED KINGDOM'){
            localStorage.setItem("benifCountry",'GBP')
          }else if(this.destCountryName=='KENYA'){
            localStorage.setItem("benifCountry",'KES')
          }else if(this.destCountryName=='SOUTH AFRICA'){
            localStorage.setItem("benifCountry",'ZAR')
          }else{
              localStorage.setItem("benifCountry",'NA')
            }
this.flowflag='calculator';
localStorage.setItem('flowflag',this.flowflag)
          console.log("newObj=",newObj);

          localStorage.setItem('newObj', JSON.stringify(newObj));
          let retrievedObject = localStorage.getItem('newObj');
          console.log('retrievedObject: ',JSON.parse(retrievedObject));
          //localStorage.setItem('benifCountry',"GHS")
          console.log('retrievedObject: ', JSON.parse(retrievedObject));
          let customerId=localStorage.getItem('customerId');
          if(customerId==null){
            this._router.navigate(['/login'])
          }
          else{
          this._router.navigate(['/index'])
          }


        }


onclick(){
this._router.navigate(['/signup'])
}

openaboutus(){
this._router.navigate(['aboutus'])
}
opencontact(){
this._router.navigate(['contactus'])
}
openfaqs(){
this._router.navigate(['faqs'])
}
openhelp(){
this._router.navigate(['help'])
}

// Added by Martins
navbarfixed: boolean = false;

onScroll() {
  if (window.scrollY > 100) {
    this.navbarfixed = true;
  } else {
    this.navbarfixed = false;
  }

  // this.isSticky = window.pageYOffset > 0;
}
toggleShowCalculations() {
  this.showCalculations = !this.showCalculations;
}
}
