
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboardmodal',
  templateUrl: './dashboardmodal.component.html',
  styleUrls: ['./dashboardmodal.component.css'],
})
export class DashboardModalComponent {
  @Input() transaction: any;
  @Output() closeModal = new EventEmitter<void>();

  constructor(private _router: Router) {}

  onClick1(val: any) {
    localStorage.setItem('txnId', val);
    this._router.navigate(['/customerreciptt']);
  }
}

