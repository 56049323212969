import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutusnew.component.html',
  styleUrls: ['./aboutus.component.css'],
})
export class AboutusComponent implements OnInit {

  constructor(
    private _router: Router,
    private _route: ActivatedRoute
  ) {}
  // constructor(private _router: Router) {}

  ngOnInit(): void {
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      location.reload() 
    } else {
      localStorage.removeItem('foo') 
    }
  }

  openlogin() {
    this._router.navigate(['/login']);
  }
  opensignup() {
    this._router.navigate(['/signup']);
  }
  openaboutus() {
    this._router.navigate(['/aboutus']);
  }
  opencontact() {
    this._router.navigate(['/contactus']);
  }
  openFaqs() {
    this._router.navigate(['/faqs']);
  }
  openhelp() {
    this._router.navigate(['/help']);
  }
  onhome() {
    this._router.navigate(['/']);
  }
  // onHome(){
  //   this._router.navigate(['/'])
  // }

  navbarfixed: boolean = false;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (window.scrollY > 100) {
      this.navbarfixed = true;
    } else {
      this.navbarfixed = false;
    }
  }
}
