<head>
  <title>Dashboard | Klorofil Pro - Bootstrap 4 Admin Dashboard Template</title>
  <meta charset="utf-8" />
  <meta http-equiv="X-UA-Compatible" content="IE=edge, chrome=1" />
  <meta
    name="viewport"
    content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
  />

  <style>
    .navbar {
      background: #000;
    }
    .navbar a {
      color: white;
      font-size: 13px;
    }
    .navbar .dropdown-menu a {
      color: #4d90fe;
      background: white;
    }
    .navbar .dropdown-menu a:active,
    a:hover {
      color: black;
      background: none;
    }
    .navbar ul li {
      margin-right: 4px;
    }
  </style>
</head>
<link
  rel="stylesheet"
  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
/>

<body>
  <nav class="navbar navbar-expand-lg fixed-top">
    <a class="navbar-brand" href="javascript:void(0)"
      ><img src="assets/images/logo.jpeg" width="160px"
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon">
        <i class="fas fa-bars" style="color: white"></i
      ></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent"  >
      <ul class="navbar-nav mr-auto" >
        <li
          class="nav-item dropdown"
          *ngIf="(branchUrl && Branch_Creation) || Branch_list"
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Branch
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color: black; width: 200px !important"
          >
            <a
              *ngIf="Branch_Creation"
              class="dropdown-item"
              
             href="#"
              routerLink="createbranch"
              >Branch create</a
            >
            <a
              *ngIf="Branch_list"
              class="dropdown-item"
              
              href="#"
              routerLink="branch"
              >Branch</a
            >
          </div>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Revenue_Mapping) ||
            Agent_Mappings ||
            agentcreation ||
            agentlist
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Agent
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color: black; width: 200px !important"
          >
            <a
              *ngIf="agentcreation"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="createagent"
              >Create agent</a
            >
            <a
              *ngIf="agentlist"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="agent"
              >List</a
            >
            <a
              *ngIf="Agent_Mappings"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="agentmapping"
              >Mapping
            </a>

            <a
              *ngIf="Revenue_Mapping"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="revenuepercentage"
            >
              Revenue Percentage</a
            >

            <a
              *ngIf="Agent_Deposit_Lists"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="agentdepositlist"
              >Agentdepositlist</a
            >
          </div>
        </li>
        <!-- <li class="nav-item" *ngIf="branchUrl">
            <a *ngIf="Branch_Creation" class="nav-link"
            href="javascript:void(0)" routerLink="branch"  >Branch</a>
          </li> -->
        <!-- <li class="nav-item" *ngIf="branchUrl">
            <a class="nav-link" href="javascript:void(0)" routerLink="mgcustomer"  >Customer List</a>
          </li> -->
        <li class="nav-item" *ngIf="branchUrl && Payment_Types">
          <a
            *ngIf="Payment_Type"
            class="nav-link"
            href="javascript:void(0)"
            routerLink="paymenttypes"
            >Paymenttypes</a
          >
        </li>
        <li class="nav-item" *ngIf="branchUrl && Promotions">
          <a class="nav-link" href="javascript:void(0)" routerLink="/promotions"
            >Promotions</a
          >
        </li>
        <li class="nav-item" *ngIf="remitzcomplaince">
          <a class="nav-link" href="javascript:void(0)" routerLink="approvelist"
            >Approvelist</a
          >
        </li>
        <li class="nav-item" *ngIf="remitzcomplaince">
          <a
            class="nav-link"
            href="javascript:void(0)"
            routerLink="complaincelist"
            >Compliancelist</a
          >
        </li>
        <li class="nav-item" *ngIf="remitzcomplaince">
          <a class="nav-link" href="javascript:void(0)" routerLink="cstlist"
            >Rejected Transactions</a
          >
        </li>
        <li class="nav-item" *ngIf="remitzcomplaince">
          <a
            class="nav-link"
            href="javascript:void(0)"
            routerLink="blocked"
            >Blocked Customer</a
          >

        </li>
        <li class="nav-item" *ngIf="remitzcomplaince">
        <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="completedcustomer"
              >Completed customer List</a
            >
          </li>

<li  class="nav-item" *ngIf="remitzcomplaince">
   <a
             
  class="dropdown-item"
  
  href="javascript:void(0)"
  routerLink="CustomerManualIdVerification"
  >Personal Kyc Approval</a
>
</li>
<li  class="nav-item" *ngIf="remitzcomplaince">
  <a 
class="dropdown-item"
  
href="javascript:void(0)" 
routerLink="businesscustomeridverification" 
>Business Kyc Approval</a>
</li>
<li  class="nav-item" *ngIf="remitzmarketing">
  <a
  class="dropdown-item"
  
  href="javascript:void(0)"
  routerLink="partialcustomerlist"
  >Partial customer List</a
>
</li>
<li  class="nav-item" *ngIf="remitzmarketing">
  <a
  
  class="dropdown-item"
  
  href="javascript:void(0)"
  routerLink="transactionlist"
  >List</a
>
</li>

<li  class="nav-item" *ngIf="remitzmarketing">
  <a
  class="dropdown-item"
  
  href="javascript:void(0)"
  routerLink="completedcustomer"
  >Completed customer List</a
  >
</li>


        <!-- <li class="nav-item dropdown" *ngIf="remitzcomplaince">
            <a  class="nav-link " href="#" id="navbarDropdown1"
            role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Complaince
            </a>
            <div  class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color:black;width: 200px !important;">


              <a *ngIf="Complaince_Approval" class="dropdown-item"
                href="javascript:void(0)"
              routerLink="complaincelist"  >Complaincelist</a>
*ngIf="Sanction_List"
                  <a  class="dropdown-item"
                   href="javascript:void(0)" routerLink="sanctionlist">Sanction list</a>
                  <a class="dropdown-item"
                   href="javascript:void(0)"
                  routerLink="approvelist">Approvelist</a>


            </div>
          </li> -->
        <!-- <li class="nav-item dropdown" *ngIf="remitzcomplaince">
            <a class="nav-link " href="#" id="navbarDropdown1"
            role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            User
            </a>
            <div  class="dropdown-menu" aria-labelledby="navbarDropdown1"
             style="color:black;width: 200px !important;">

*ngIf="Kyc_Approval"
                  <a  class="dropdown-item"
                    href="javascript:void(0)" routerLink="CustomerManualIdVerification" >KYC Approval</a>
                  <a  class="dropdown-item"
                    href="javascript:void(0)" routerLink="customerlist" >Customer List</a>

*ngIf="Customer_List"
            </div>
          </li> -->

        <!-- <li class="nav-item" *ngIf="branchUrl">
            <a class="nav-link" href="javascript:void(0)" routerLink="apimapping"  >Api mapping</a>
          </li>
          <li class="nav-item" *ngIf="branchUrl">
            <a class="nav-link" href="javascript:void(0)" routerLink="velocity_check"  >Velocity-check</a>
          </li> -->

        <!-- <li class="nav-item" *ngIf="branchUrl">
            <a class="nav-link" href="javascript:void(0)" routerLink="apimarkup"  >Apimarkup</a>
          </li> -->

        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Referal_Code) ||
            Api_Mapping ||
            Collection_Fee ||
            Service_Fee ||
            Buy_Rates ||
            Sell_Rates
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Fee
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black"
          >
            <a
              *ngIf="Buy_Rates"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="buy-rates"
              >Buy-rates</a
            >
            <a
              *ngIf="Sell_Rates"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="exchangerates"
              >Sell-rates</a
            >
            <a
              *ngIf="Service_Fee"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="servicefee"
              >Service Fee</a
            >
            <a
              *ngIf="Collection_Fee"
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="agent-collection-markup"
              >Collection Fee</a
            >
            <a
              *ngIf="Api_Mapping"
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="apimarkup"
              >Apimarkup</a
            >

            <a
              *ngIf="Referal_Code"
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="/referalcoderates"
              >Referalcoderates</a
            >

            <a
              *ngIf="Ambassadors"
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="/ambassadors"
              >Ambassadors</a
            >
          </div>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Profit_Loss) || AccessLogg || AuditTrail || Austrac
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Reports
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              *ngIf="AccessLogg"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="access_logs"
              >Access logs</a
            >
            <a
              *ngIf="AuditTrail"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="audit_trail"
              >Audit trail</a
            >
            <a
              *ngIf="Austrac"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="austrac"
              >Austrac</a
            >
            <a
              *ngIf="Profit_Loss"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="profitloss"
            >
              Profit Loss</a
            >
          </div>
        </li>

        <!-- <li class="nav-item" *ngIf="branchUrl">
  <a class="nav-link" href="javascript:void(0)" routerLink="buy-rates"  >Rates</a>
</li> -->
        <li
          class="nav-item dropdown"
          *ngIf="(branchUrl && Samsara) || Geo_Swift || Fliq_pay || Nsano"
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            API
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="alianza"
              >Alianza</a
            >
            <a
              *ngIf="Fliq_pay"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="fliqpay"
              >Fliqpay</a
            >
            <a
              *ngIf="Nsano"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="nsano"
              >Nsano</a
            >

            <a
              *ngIf="Geo_Swift"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="geoswift"
              >Geoswift</a
            >
            <a
              *ngIf="Samsara"
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="samsara"
              >Samsara</a
            >
            <a *ngIf="kaara"
             href="javascript:void(0)" 
             class="dropdown-item"
             
              routerLink="kaara" >
              kaara</a>

            <a *ngIf="kaarahistory"
             href="javascript:void(0)"
              class="dropdown-item"
               
              routerLink="kaarahistory" >
              Kaara Histoty</a>
          </div>
        </li>

        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Transactionlistr) ||
            Arc_Transaction_List ||
            Mon_Transaction_List
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Transaction
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="width: fit-content !important"
          >
            <a
              *ngIf="Transactionlistr"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="transactionlist"
              >List</a
            >

            <a
              *ngIf="air_trancation"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="air-transaction"
              >Top-up Transaction</a
            >

            <a
              *ngIf="bill_tranaction"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="bill-transactions"
              >Bill Transaction</a
            >

            <a
              *ngIf="Arc_Transaction_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="arctransaction"
              >Arctransaction</a
            >

            <a
              *ngIf="Mon_Transaction_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="monthlytransactions"
              >Download transactions</a
            >

            <a
              *ngIf="Mon_Transaction_Time"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="monthlystatustime"
              >Download transactions status time</a
            >

            <a
              *ngIf="Exchange_rate_history"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="exchangeratehistory"
              >Exchange rate history</a
            >

            <a
            *ngIf="airtopupexchangerate"
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="air-top-up-exchangerate"
            >Air Top-Up Exchangerate</a
          >
          </div>
        </li>

        <!-- <li class="nav-item dropdown" *ngIf="trans">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Transaction 
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              class="dropdown-item"
              
              style="color: black"
              href="javascript:void(0)"
              routerLink="transactionlist"
              >List</a
            >
            <a 
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="air-transaction"
              >Top-up Transaction</a
            >

            <a
             
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="bill-transactions"
              >Bill Transaction</a
            >
          </div>
        </li> -->

        <li
        class="nav-item dropdown" *ngIf="trans"
      >
        <a
          class="nav-link"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
        Transaction 
        </a>
        <div
          class="dropdown-menu"
          aria-labelledby="navbarDropdown"
          style="color: black; width: 200px !important ;"
        >
        <a
        class="dropdown-item"
        
        style="color: black"
        href="javascript:void(0)"
        routerLink="transactionlist"
        >List</a
      >
      <a 
        class="dropdown-item"
        
        href="javascript:void(0)"
        routerLink="air-transaction"
        >Top-up Transaction</a
      >

      <a
       
        class="dropdown-item"
        
        href="javascript:void(0)"
        routerLink="bill-transactions"
        >Bill Transaction</a
      >
         
        </div>
      </li>


        <li
          class="nav-item dropdown"
          *ngIf="(branchUrl && Market_transactions) || Market_comission"
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Market
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <!-- <a *ngIf="Market_transactions" class="dropdown-item"
               href="javascript:void(0)" routerLink="createmarketcurrency"  >create market currency</a>

    <a *ngIf="Market_comission" class="dropdown-item"
     href="javascript:void(0)" routerLink="marketmappinglist"  >Market comission</a> -->
            <a
              *ngIf="Market_transactions"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="marketorderlist"
              >Market orders</a
            >

            <a
              *ngIf="Market_transactions"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="markettransactions"
              >Market transactions</a
            >
            <a
              *ngIf="Market_transactions"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="marketcustomers"
              >Market Customers</a
            >
          </div>
        </li>

        <li
          class="nav-item dropdown"
          *ngIf="(branchUrl && Aml_List) || Velocity_Checks"
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Compliance
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color: black; width: 200px !important"
          >
            <!-- <a class="dropdown-item"
               href="javascript:void(0)" routerLink="sanctionlist">Sanction list</a> -->
            <a
              *ngIf="Aml_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="maxamount"
              >AML Limits</a
            >
            <a
              *ngIf="Velocity_Checks"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="velocitycheck"
              >Velocity-check</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="customerriskscore"
              >Customer risk score</a
            >
          </div>
        </li>
        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Expired) ||
            Blocked ||
            Migrated ||
            Beneficiary_List ||
            Kyc_Approval ||
            Customer_List
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            User
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color: black; width: 200px !important"
          >
            <a
              *ngIf="Kyc_Approval"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="CustomerManualIdVerification"
              >Personal Kyc Approval</a
            >
            <a *ngIf="Kyc_Approval" 
            class="dropdown-item"
              
            href="javascript:void(0)" 
            routerLink="businesscustomeridverification" 
            >Business Kyc Approval</a>

            <a
              *ngIf="Customer_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              >Customer List(disabled)</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="partialcustomerlist"
              >Partial customer List</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="completedcustomer"
              >Completed customer List</a
            >
            <a
              *ngIf="Beneficiary_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              >Beneficiary(disabled)</a
            >

            <a
              *ngIf="Migrated"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="mgcustomer"
              >Migrated Customer List</a
            >

            <a
              *ngIf="Blocked"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="blocked"
              >Blocked Customer List</a
            >
            <a
              *ngIf="Expired"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="expclistt"
              >Expired Customer List</a
            >
          </div>
        </li>

        <li
          class="nav-item dropdown"
          *ngIf="
            (branchUrl && Banks) ||
            Api_Mapping ||
            Internet_Bank_Transfer ||
            Reason_For_Transfer ||
            RelationShip_List ||
            Email_Template ||
            Source_Of_Fund
          "
        >
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown1"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Settings
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown1"
            style="color: black; width: 200px !important"
          >
            <a
              *ngIf="Email_Template"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="email"
              >Email Template</a
            >
            <a
              *ngIf="Source_Of_Fund"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="sourceoffound"
              >Source Of Fund</a
            >
            <a
              *ngIf="RelationShip_List"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="relation-ship"
              >Relationship</a
            >
            <a
              *ngIf="Reason_For_Transfer"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="reasonfortransfer"
              >Reason For Transfer</a
            >
            <a
              *ngIf="Internet_Bank_Transfer"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="internetbankdetails"
              >Internet Bank Transfer</a
            >
            <a
              *ngIf="Api_Mapping"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="apimapping"
              >Api mapping</a
            >

            <a
              *ngIf="Banks"
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="banks"
              >Banks</a
            >
            <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="deposits"
            >Deposit</a
          >
          <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="depositlist"
            >Deposit Profit </a
          >
          <a
          class="dropdown-item"
          
          href="javascript:void(0)"
          routerLink="fbmdashboard"
          >Send Notification </a
        > 
        <!-- <a
        class="dropdown-item"
        
        href="javascript:void(0)"
        routerLink="mtbs"
        >Mtbs</a
      >
       <a
      class="dropdown-item"
      
      href="javascript:void(0)"
      routerLink="mtbstransactionhistory"
      >Mtbs Account Statement </a
    >
    <a
    class="dropdown-item"
    
    href="javascript:void(0)"
    routerLink="mtbscompliance"
    >Mtbs Compliance </a
  > -->
          </div>
        </li>
        <li class="nav-item" *ngIf="branchUrl">
          <a class="nav-link" href="javascript:void(0)" routerLink="createuser"
            >Create Login</a
          >
        </li>

        <li class="nav-item" *ngIf="newrec">
          <a
            *ngIf="Destination_Agent_ExchangeRate"
            class="nav-link"
            href="javascript:void(0)"
            routerLink="destagentexchratest"
            >Destination AgentExhangeRates</a
          >
        </li>

        <li class="nav-item" *ngIf="newrec">
  <a class="nav-link" href="javascript:void(0)" 
  routerLink="transactionlisst"
  id="navbarDropdown"
  role="button"
  data-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false">
  Transaction
</a> 

<div class="dropdown-menu" aria-labelledby="navbarDropdown" style="color: black; width: 200px !important; left: 393px !important; ">
  <a class="dropdown-item" style="color: black" href="javascript:void(0)" routerLink="transactionlist">List</a>
  <a class="dropdown-item" href="javascript:void(0)" routerLink="air-transaction">Top-up Transaction</a>
  <a class="dropdown-item" href="javascript:void(0)" routerLink="bill-transactions">Bill Transaction</a>
</div>

</li>
<li class="nav-item"  *ngIf="accountant">
  <a class="nav-link"
    href="#"
    id="navbarDropdown"
    role="button"
    data-toggle="dropdown"
    aria-haspopup="true"
    aria-expanded="false">
    Transaction 
  </a> 
  
  <div class="dropdown-menu" aria-labelledby="navbarDropdown" style="color: black; width: 200px !important; left: 0px !important; ">
    <a class="dropdown-item" style="color: black" href="javascript:void(0)" routerLink="transactionlist">List</a>
    <a class="dropdown-item" href="javascript:void(0)" routerLink="air-transaction">Top-up Transaction</a>
    <a class="dropdown-item" href="javascript:void(0)" routerLink="bill-transactions">Bill Transaction</a>
  </div>
</li>

        <li class="nav-item" *ngIf="newrec">
          <a class="nav-link" href="javascript:void(0)" routerLink="cashpickup"
            >Cashpickup</a
          >
        </li>
        <li class="nav-item" *ngIf="newrec">
          <a
            *ngIf="switchUser"
            class="nav-link"
            class="btn btn-primary"
            href="javascript:void(0)"
            (click)="SwitcToAdmin()"
            >SwitchToAdmin</a
          >
        </li>

        <li class="nav-item" *ngIf="newsend">
          <a
            class="nav-link"
            href="javascript:void(0)"
            routerLink="srcagentexchangerate"
            >Source AgentExhangeRates</a
          >
        </li>

        <li class="nav-item" *ngIf="newsend">
          <a
            *ngIf="Agent_Depositsend"
            class="nav-link"
            href="javascript:void(0)"
            routerLink="trnnnlist"
            >Transactions</a
          >
        </li>
        <li class="nav-item" *ngIf="newsend">
          <a
            class="nav-link"
            href="javascript:void(0)"
            routerLink="agentdeposit"
            >Agent Deposit
          </a>
        </li>
        <li class="nav-item" *ngIf="newsend">
          <a class="nav-link" href="javascript:void(0)" routerLink="ledger"
            >Ledger</a
          >
        </li>

        <li class="nav-item" *ngIf="newsend">
          <a
            *ngIf="Customersend"
            class="nav-link"
            href="javascript:void(0)"
            routerLink="customer"
            >Customer</a
          >
        </li>

        <li class="nav-item" *ngIf="newsend">
          <a
            *ngIf="switchUser"
            class="nav-link"
            class="btn btn-primary"
            href="javascript:void(0)"
            (click)="SwitcToAdmin()"
            >SwitcToAdmin</a
          >
        </li>

        <li class="nav-item dropdown" *ngIf="newrolepayout">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            API 
          </a>

          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="alianza"
              >Alianza</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="fliqpay"
              >Fliqpay</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="nsano"
              >Nsano</a
            >

            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="geoswift"
              >Geoswift</a
            >
            <a
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="samsara"
              >Samsara</a
            >
            
          </div>
        </li>

        <li class="nav-item" *ngIf="newrolepayout">
          <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="transactionlist"
            >Transaction List</a
          >
        </li>

        <li class="nav-item dropdown" *ngIf="accountant">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            API 
          </a>

          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="alianza"
              >Alianza</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="fliqpay"
              >Fliqpay</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="nsano"
              >Nsano</a
            >

            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="geoswift"
              >Geoswift</a
            >
            <a
              href="javascript:void(0)"
              class="dropdown-item"
              
              routerLink="samsara"
              >Samsara</a
            >
            <a 
             href="javascript:void(0)" 
             class="dropdown-item"
             
              routerLink="kaara" >
              kaara</a>

            <a 
             href="javascript:void(0)"
              class="dropdown-item"
               
              routerLink="kaarahistory" >
              Kaara Histoty</a>
          </div>
        </li>
        <li class="nav-item dropdown" *ngIf="accountant">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Compliance
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <!-- <a class="dropdown-item"
               href="javascript:void(0)" routerLink="sanctionlist">Sanction list</a> -->
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="maxamount"
              >AML Limits</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="velocitycheck"
              >Velocity-check</a
            >
          </div>
        </li>
        <!-- <li class="nav-item" *ngIf="accountant">
          <a
            class="nav-link"
            href="javascript:void(0)"
            routerLink="transactionlist"
            >Transaction List</a
          >
        </li> -->
        <li class="nav-item dropdown" *ngIf="accountant">
          <a
            class="nav-link"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Reports
          </a>
          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; width: 200px !important"
          >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="access_logs"
              >Access logs</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="audit_trail"
              >Audit trail</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="austrac"
              >Austrac</a
            >
            <a
              class="dropdown-item"
              
              href="javascript:void(0)"
              routerLink="profitloss"
            >
              Profit Loss</a
            >
          </div>
        </li>
        <li class="nav-item" *ngIf="ticketIssue">
          <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="partialcustomerlist"
            >Partial customer List</a
          >
        </li>

        <li class="nav-item" *ngIf="ticketIssue">
          <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="completedcustomer"
            >Completed customer List</a
          >
        </li>

        <li class="nav-item" *ngIf="customerService">
          <a
            class="dropdown-item"
            
            href="javascript:void(0)"
            routerLink="transactionlist"
            >Transaction List</a
          >
        
        </li>

        <!-- <li class="nav-item" *ngIf="newsend">
  <a *ngIf="Agent_Depositsend" class="nav-link" href="javascript:void(0)" routerLink="trnnnlist">Transactions</a>
</li> -->
        <!-- <li class="nav-item" *ngIf="newsend">
  <a   class="nav-link"  href="javascript:void(0)" routerLink="agentdeposit">Agent Deposit </a>
</li>
<li class="nav-item" *ngIf="newsend">
  <a  class="nav-link" href="javascript:void(0)" routerLink="ledger" >Ledger</a>
</li>


<li class="nav-item" *ngIf="newsend">
  <a *ngIf="Customersend" class="nav-link" href="javascript:void(0)" routerLink="customer" >Customer</a>
</li>


<li class="nav-item" *ngIf="newsend">
  <a *ngIf="switchUser" class="nav-link" class="btn btn-primary" href="javascript:void(0)" (click)="SwitcToAdmin()">SwitcToAdmin</a>
</li> -->
      </ul>

      <ul class="navbar-nav ml-auto">
        <!-- <li class="nav-item" *ngIf="newsend" >
    <a class="nav-link" href="javascript:void(0)" class="btn btn-primary" >Switchto Recieving Agent</a>
  </li> -->
        <!-- <li class="nav-item" *ngIf="newsend" >
    <a class="nav-link" href="javascript:void(0)" ></a>
  </li> -->
        <!-- <li class="nav-item" *ngIf="newrec" >
    <a class="nav-link" href="javascript:void(0)" class="btn btn-primary" >Switchto Sending Agent</a>
  </li> -->
        <!-- <li class="nav-item" *ngIf="newrec" >
    <a class="nav-link" href="javascript:void(0)" ></a>
  </li> -->
        <li class="nav-item">
          
          <a class="nav-link"  href="http://support.tayrexchange.com.au/admin/"
            >Customer support</a
          >
        </li>

        <li class="nav-item dropdown">
          <a
            class="nav-link btn btn-primary btn-block"
            style="
              font-weight: 700;
              font-size: 15px;
              background: white;
              display: flex;
              align-items:center;
              color: #4d90fe;
            "
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            My Account &nbsp;&nbsp;
            <span class="fa fa-user-circle" style="font-size: 30px"></span>
          </a>

          <div
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
            style="color: black; margin-top: 10px"
          >
            <a
              class="dropdown-item tr"
              href="javascript:void(0)"
              (click)="loggout()"
              ><span class="fa fa-sign-out" style="font-size: 20px"></span
              >&nbsp; Logout</a
            >
            <a 
            class="dropdown-item tr" 
            href="javascript:void(0)" 
            (click)="ChangePassword()">
            <span class="fa fa-sign-out" style="font-size:20px;">
            </span>&nbsp;Change Password</a>
          </div>
        </li>
        <li class="nav-item" *ngIf="agentUrl">
          <a class="nav-link" href="javascript:void(0)"> </a>
        </li>
      </ul>
    </div>
  </nav>
  <!-- WRAPPER -->
  <div style="margin-top: 120px"></div>

  <!-- <form class="form-auth-small" [formGroup]="AccessLogForm" >
  <input type="hidden"
   formControlName="ipAddress"
    name="ipAddress" id="ipAddress">
</form> -->

  <!-- END LEFT SIDEBAR -->
</body>
