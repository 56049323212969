<div class="transactions-popup">
  <div class="container-fruid">
    <div class="row">
      <div class="col-lg-6">
        <div class="modal fade" id="transactionsMod" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header justify-content-between">
                <h5>Transaction Details</h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  (click)="closeModal.emit()"
                >
                  <i class="fa-solid fa-xmark"></i>
                </button>
              </div>
              <div class="main-content">
                <div class="row">
                  <div class="col-sm-5 text-center">
                    <div class="icon-area">
                      <img
                        src="assetsnew/dashboard/images/icon/transaction-details-icon.png"
                        alt="icon"
                      />
                    </div>
                    <div class="text-area">
                      <h6>
                        {{ transaction.cusName }} {{ transaction.custLastName }}
                      </h6>
                      <p>{{ transaction.createdOn }}</p>
                      <h3>
                        {{ transaction.receivingAmount }}
                        {{ transaction.receivingCurrency }}
                      </h3>
                     <div class="mb-10" *ngIf="transaction.status == 'Possible duplicate transaction.\nPlease check transaction history'">
               
                       Paid
               
                    </div>
                    <div class="mb-10" *ngIf="transaction.status == 'Duplicate request ID'">
                  
                       Paid
                     
                    </div>
                    <div class="mb-10" *ngIf="transaction.status !== 'Possible duplicate transaction.\nPlease check transaction history' && transaction.status !== 'Duplicate request ID'">
                    
                      {{ transaction.status }}
                    
                   </div>
                      <!-- <p class="com">{{ transaction.status }}</p> -->
                      <div class="mb-10" *ngIf="transaction.complainceRequired == 'yes'"><h3 style="color: red;">awaiting compliance review</h3></div>
                    </div>
                  </div>
                  <div class="col-sm-7">
                    <div class="right-area">
                      <h6>Transaction Details</h6>
                      <ul class="payment-details">
                        <li>
                          <span>Payment Amount</span>
                          <span
                            >{{ transaction.sendingAmount
                            }}{{ transaction.sendingCurrency }}</span
                          >
                        </li>
                        <li>
                          <span>Fee</span>
                          <span
                            >{{ transaction.transactionFee }}
                            {{ transaction.sendingCurrency }}</span
                          >
                        </li>
                        <li>
                          <span>Total Amount</span>
                          <span
                            >{{ transaction.totalAmount }}
                            {{ transaction.sendingCurrency }}</span
                          >
                        </li>
                      </ul>
                      <ul class="payment-info">
                        <li>
                          <p>Payment From</p>
                          <span class="mdr"
                            >{{ transaction.cusName }}
                            {{ transaction.custLastName }}</span
                          >
                        </li>
                        <!-- <li>
                          <p>Payment Status</p>
                          <span class="mdr">{{
                            transaction.paymentStatus
                          }}</span>
                        </li> -->
                        <li (click)="onClick1(transaction.transactionId)">
                          <p>Transaction ID:</p>
                          <span class="mdr">{{ transaction.transactionId }}</span>
                        </li>

                      
                      </ul>
                    </div>

                    
                  </div>
                  <hr>
                   <div class="col-sm-12">
                    <div class="hlo">
                      <div class="right-area">
                        <h6>Benificiary Details</h6>
                        <ul class="payment-details">
                          <li>
                            <span>Benificiary  Name</span>
                            <span
                              >{{ transaction.benificiaryName
                              }}</span
                            >
                          </li>
                          <li>
                            <span>Benificiary Number</span>
                            <span
                              >{{ transaction.beneficiaryPhone }}
                             </span
                            >
                          </li>
                          <li>
                            <span>Beneficiary Address</span>
                            <span
                              >{{ transaction.beneficiaryAddress}}
                              </span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                   </div>

                   <hr>
                   <div class="col-sm-12">
                    <div class="hlo">
                      <div class="right-area">
                        <h6>Bank Details</h6>
                        <ul class="payment-details">
                          <li>
                            <span>Bank  Name</span>
                            <span
                              >{{ transaction.bankName
                              }}</span
                            >
                          </li>
                          <li>
                            <span>Bank code</span>
                            <span
                              >{{ transaction.bankCode}}
                             </span
                            >
                          </li>
                          <li>
                            <span>Account Holder</span>
                            <span
                              >{{ transaction.accountHolderName}}
                              </span
                            >
                          </li>
                          <li>
                            <span>Account Number</span>
                            <span
                              >{{ transaction.accountNumber}}
                              </span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                   </div>
                  
                </div>
                c
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="modal fade" id="transactionsMod" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              
              <div class="main-content">
                <div class="row">
                  
                  <div class="col-sm-7">
                    <div class="right-area">
                      <h6>Benificiary Details</h6>
                      <ul class="payment-details">
                        <li>
                          <span>Benificiary  Name</span>
                          <span
                            >{{ transaction.benificiaryName
                            }}</span
                          >
                        </li>
                        <li>
                          <span>Benificiary Number</span>
                          <span
                            >{{ transaction.beneficiaryPhone }}
                           </span
                          >
                        </li>
                        <li>
                          <span>Beneficiary Address</span>
                          <span
                            >{{ transaction.beneficiaryAddress}}
                            </span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
