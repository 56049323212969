import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators'

import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RelationShipFoundService {
  apiUrl=environment.baseUrl;
  public getRelationshipApi:string = `${this.apiUrl}/utility/getRelationShipList`;
  public postRelationAhipApi:string = `${this.apiUrl}/utility/setRelationShipDetails`;
  public updateRelationApi:string = `${this.apiUrl}/utility/updateRelationShipDetails`;


  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
    // .set('Authorization', localStorage.getItem("datas"))

  };
  constructor(private _http:HttpClient) { }
  public extractData(res:Response):Observable<any> | {}{
    const body =res;
    console.log('res',res)
    return body || {} ;
  }

  public getrelationshipList(pId:string):Observable<any>{
    return this._http.get(`${this.getRelationshipApi}`+'?pId='+pId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public postrelationList(pId:string,record:any):Observable<any>{
    return this._http.post(`${this.postRelationAhipApi}`+'?pId='+pId,record,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public updateRelationList(pId:string,relationShipId:any,record:any):Observable<any>{
    return this._http.put(`${this.updateRelationApi}`+'?pId='+pId+'&relationShipId='+relationShipId,record).pipe(
      map(this.extractData)
    )
  }
}
