<div class="container">
  <form [formGroup]="destExchRateForm">

  <div style="margin-top:2%">

  </div>


  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">



          <div class="row">
            <div class="col-md-12 form-group">
            <div    >
              <table class="table">
                <thead>
                  <tr>

                    <th>Source Agent</th>
                    <th>Dest Agent</th>
                    <th>Payment Type</th>
                    <th>Source Value</th>
                    <th>Dest Value</th>
                    <th>Update</th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let list of List">
                  <td>{{list.srcUserName}}</td>
                  <td>{{list.destUserName}}</td>
                  <td>{{list.benifPayTypeName}}</td>
                  <td>{{list.srcCurrencyValue}} {{list.srcCurrencyName}}</td>
                  <td>  <div contenteditable='true' class="div"  (input)="onRowClick($event, list.destCurrencyValue)">{{list.destCurrencyValue}}</div>
                {{list.destCurrencyName}}  </td>
                  <td><button type="button" class="btn btn-primary" (click)="onUpdate(this.list)" name="button">Update</button></td>
                </tr>





            </tbody></table>



            </div>


            </div>

          </div>

        </div>

      </div>


    </div>
  </div>
</form>

</div>
