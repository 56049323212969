import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders ,HttpBackend} from '@angular/common/http';
import { map,tap } from 'rxjs/operators';
import {Observable} from 'rxjs';
import{ environment} from '../../environments/environment';
@Injectable({
      providedIn: 'root'
})
export class BranchService {
  apiUrl =environment.baseUrl

public baseUrl :string =`${this.apiUrl}/branch`;
public baseUrl12 :string =`${this.apiUrl}/branch/updateBranchDetails`;
public baseapi1 :string =`${this.apiUrl}/keyclock/getClientCountrys`;
public baseapi2 :string =`${this.apiUrl}/utility/getSates`;
public statelist :string =`${this.apiUrl}/utility/getSatesList`;
  public apiUrl7: string = `${this.apiUrl}/branch/getBranchInfo`;
public updateCustomer: string = `${this.apiUrl}/customer/updateCustomerDetails`;
public countryDetailsBasedOnIdUrl:string= `${this.apiUrl}/utility/getCountryDetailsBasedOnCountryId`;
public getDetailsByAgentId: string = `${this.apiUrl}/agent/getAgentBenifPaymentTypeListByAgentId`;
public getDetailByAgentId: string = `${this.apiUrl}/user/findCountryNameBydestAgentId`;
public userDetailsByUserId:string= `${this.apiUrl}/user/getUserDetailsByUserId`;
public updateBusinessCustomer: string = `${this.apiUrl}/customer/updateBusinessCustomerDetails`;
httpOptions = {
      headers : new HttpHeaders ({'content-type' : 'application/json'})
      //.set('Authorization', localStorage.getItem("datas"))
};
constructor(private _http:HttpClient,private _httpp:HttpClient,
handler: HttpBackend) {
  this._httpp = new HttpClient(handler);}
  private  extractData( res: Response ) : Array <any> | {} {
  const body = res;
  console.log('res ',res)
  return body || {} ;
 }
 //this method is used for create new branch
  public getUserInfoByUserId(agentId:any,pId : string): Observable <any>{

    return this._http.get(`${this.userDetailsByUserId}`+'?pId='+pId+'&userId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
 }


 //this method is used for create new branch
  public getAgentInfoByAgentId(agentId:any,pId : string): Observable <any>{
    return this._http.get(`${this.getDetailByAgentId}`+'?pId=' + pId+'&agentId='+agentId, this.httpOptions).pipe(
      map(this.extractData)
    )
 }

//this method is used for create new branch
 public createBranch(branchRecord:any,pId : string): Observable <any>{
      return this._http.post(`${this.baseUrl}/createBranch`+'?pId='+pId,branchRecord,this.httpOptions)
      .pipe(
      map(this.extractData)
      );
}
//this method is used for getting getClientCountrys
public getClientCountrys(token: any,pId : string): Observable <any>{
  console.log("pId..in client countrys...!"+localStorage.getItem("datas"));
  console.log("this.baseapi1.."+this.baseapi1);
  return this._http.get(`${this.baseapi1}`+'?pId='+pId,this.httpOptions).pipe(
  map(this.extractData)
  );
}
//this method is used for getting states based on countries
public getCountryStates(countryIso: any,pId : string): Observable <any>{
  console.log("pId..in  country states...!"+pId+"......."+countryIso);
  return this._http.get(`${this.baseapi2}`+'?pId='+pId+'&countryIso='+countryIso,this.httpOptions).pipe(
  map(this.extractData)
);
}

public getUser(branchId:any):Observable<any>{
  return this._http.get(`${this.apiUrl7}/${branchId}`,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public getCountryDetailsBasedOnCountryId(pId:any,countryId:any):Observable<any>{
  return this._httpp.get(`${this.countryDetailsBasedOnIdUrl}`+'?pId='+pId+'&countryId='+countryId,this.httpOptions).pipe(
    map(this.extractData)
  )
}

public editUser(userId:number,branchRecord:any,pId : string){

  return this._http.put(`${this.baseUrl12}`+'?pId='+pId+'&branchId='+userId,branchRecord,this.httpOptions).pipe(
      map(this.extractData)
  )
}


//this method is used for getting states based on countries
public getStates(pId : string): Observable <any>{

  return this._http.get(`${this.statelist}`+'?pId='+pId,this.httpOptions).pipe(
  map(this.extractData)
);
}
public updateProfile(profUpdateRecord:any,pId : string,userId : string): Observable <any>{
     return this._http.post(`${this.updateCustomer}`+'?pId='+pId+'&userId='+userId,profUpdateRecord,this.httpOptions)
     .pipe(
     map(this.extractData)
     );
}

getToken(){
  return localStorage.getItem('datas');
}

public updateBusinessProfile(profUpdateBussinessBusinessRecord:any,pId : string,userId : string): Observable <any>{
  return this._http.post(`${this.updateBusinessCustomer}`+'?pId='+pId+'&userId='+userId,profUpdateBussinessBusinessRecord,this.httpOptions)
  .pipe(
  map(this.extractData)
  );
}

}
