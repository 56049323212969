<!DOCTYPE html>
<html lang="en" dir="ltr">
  <head>
    <meta charset="utf-8">
    <title></title>
    <style>
    .navbar{
      background:#000;
    }
    .navbar a{
      color:white;

    }
    </style>

  </head>
  <body>
<!-- -----------------------------------------  -->

<!-- Login Reg In start -->
<section class="log-reg login">
  <div class="overlay">
    <div class="container">
      <div class="top-head-area">
        <div class="row d-flex align-items-center">
          <div class="col-sm-5 col">
            <a
              class="back-home text-light"
              href="javascript:void(0)"
              (click)="openHome()"
            >
              <img src="assetsnew/img/left-icon.png"  alt="back to home" />
              Back To Home
            </a>
          </div>
          <div class="col-sm-5 col">
            <a href="javascript:void(0)" (click)="openHome()">
              <img src="assetsnew/img/logo-png.png" alt="logo" />
            </a>
          </div>
        </div>
      </div>
      <div class="row justify-content-center ">
        <div class="col-md-5">
          <div class="img-area">
            <img src="assetsnew/img/login-bg.png" alt="image" />
          </div>
        </div>
        <div
          class="col-lg-6 col-md-7 z-1 text-center d-flex justify-content-center pb-120"
        >
          <div class="form-boxx">
          <div class="form-box">
            <h4>Log in to Tayrex</h4>
            <form class="form-auth-small" [formGroup]="userForm" >
              <div class="form-group">
                <!-- <label  class="control-label sr-only">Username</label> -->
                <div class="single-input d-flex align-items-center">
                <input type="userName" class="log-form-custom" formControlName="userName" placeholder="Username">
              </div>
              </div>
              <div class="form-group snd-log-lable input-groupp">
                <div class="single-input d-flex align-items-center">
                <input type="password"
                  [type]="fieldTextType ? 'text':'password'"
                formControlName="password"
                 name="Password" id="Password"
                 class="log-form-custom"

                   value="" placeholder="Password" >
                   <div>
                     <span  class="form-controll">
               <i class="fa" [ngClass]="{
                 'fa-eye-slash':!fieldTextType,
                 'fa-eye':fieldTextType
               }" (click)="toggleFieldTextType()" aria-hidden="true"></i>
                     </span>
                   </div>
               </div>
               </div>
               <div  *ngIf="invalidResponseFlag" class="col-md-12 text-center" >
                 <span style="color:red;text-align:center">{{invalidResponse}}</span>
               </div>


<div class="btn-area">
              <button type="submit" class="cmn-btn" (click)="onSubmit()">login</button>
            </div>
              <!-- <div class="bottom">
                <span class="helper-text d-block">Don't have an account? <a href="page-register.html">Register</a></span>
                <span class="helper-text"><i class="fa fa-lock"></i> <a href="page-forgot-password.html">Forgot password?</a></span>
              </div> -->
            </form>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- ============================================= -->


  <script async src="https://www.googletagmanager.com/gtag/js?id=G-WMDPCVE6LL"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'G-WMDPCVE6LL');
  </script>

  </body>
</html>
