import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpBackend} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Router, ActivatedRoute } from '@angular/router';

import{ environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessLogService {
  apiUrl=environment.baseUrl
  public saveAccessLogUrl: string = `${this.apiUrl}/access/saveAccessLog`;
  public getAccessLogListByDatesUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDate`;
  public getAccessLogListByDatesAndUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDateAndUserType`;
  public getAccessLogListByDatesAndUserTypeAndUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByLogOnDateAndLogOffDateAndUserTypeAnduserName`;
  public getAccessLogListByUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByUserType`;
  public getAccessLogListByUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByUserName`;
  public getAccessLogListByBetweenDatesAndUserNameUrl: string = `${this.apiUrl}/access/getAccessLogListByBetweenDatesAndUserName`;
  public getAccessLogListByUserNameAndUserTypeUrl: string = `${this.apiUrl}/access/getAccessLogListByUserNameAndUserType`;
  public getAccessLogListUrl: string = `${this.apiUrl}/access/getAccessLogList`;
  public getAccessLogByAccessIdUrl: string = `${this.apiUrl}/access/getAccessLogByAccessId`;
  public getAccessLogByAccessIdAndUserIdUrl: string = `${this.apiUrl}/access/getAccessLogByAccessIdAndUserId`;
  public updateAccessLogByAccessIdUrl: string = `${this.apiUrl}/access/updateAccessLogByAccessId`;

  httpOptions = {
    headers: new HttpHeaders({ 'content-type': 'application/json' })
    //.set('Authorization', localStorage.getItem("datas"))

  };

  constructor(private _http: HttpClient,private _httpp:HttpClient,
     handler: HttpBackend,private router: Router, private _route: ActivatedRoute) {

        this._httpp = new HttpClient(handler);}

  private extractData(res: Response): Array<any> | {} {
    const body = res;
    console.log('res ', res)
    return body || {};

  }



  public saveAccessLog(record: any,pId:string): Observable<any> {
    console.log('saveAccessLogUrl....',record);
    return this._http.post(this.saveAccessLogUrl + '?pId=' + pId, record).pipe(
      map(this.extractData)
    );
  }

  public updateAccessLogByAccessId(accessId:any,pId:string):Observable<any>{
    return this._httpp.get(`${this.updateAccessLogByAccessIdUrl}`+`?pId=`+pId+'&accessId='+accessId,this.httpOptions).pipe(
      map(this.extractData)
    )
  }

  public getAccessLogList(pId: string): Observable<any> {
    console.log("getAccessLogListUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogListUrl}` + '?pId=' + pId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
  public getAccessLogByAccessId(accessId: any,pId: string): Observable<any> {
    console.log("getAccessLogByAccessIdUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogByAccessIdUrl}` + '?pId=' + pId+'&accessId='+accessId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAccessLogByAccessIdAndUserId(accessId: any,userId:String,pId: string): Observable<any> {
    console.log("getAccessLogByAccessIdAndUserIdUrl  list.." + pId);
    return this._httpp.get(`${this.getAccessLogByAccessIdAndUserIdUrl}` + '?pId=' + pId+'&accessId='+accessId+'&userId='+userId, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAccessLogListByUserNameAndUserType(userType: any,userName:String,pId: string): Observable<any> {
    console.log("getAccessLogListByUserNameAndUserTypeUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogListByUserNameAndUserTypeUrl}` + '?pId=' + pId+'&userType='+userType+'&userName='+userName, this.httpOptions).pipe(
      map(this.extractData)
    );
  }


  public getAccessLogListByBetweenDatesAndUserName(logOnDate: any,logOffDate:any,userName:String,pId: string): Observable<any> {
    console.log("getAccessLogListByBetweenDatesAndUserNameUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogListByBetweenDatesAndUserNameUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userName='+userName, this.httpOptions).pipe(
      map(this.extractData)
    );
  }

  public getAccessLogListByUserName(userName:String,pId: string): Observable<any> {
    console.log("getAccessLogListByUserNameUrl  list.." + pId);
    return this._http.get(`${this.getAccessLogListByUserNameUrl}` + '?pId=' + pId+'&userName='+userName, this.httpOptions).pipe(
      map(this.extractData)
    );
  }
//getAccessLogListByUserTypeUrl
public getAccessLogListByUserType(userType:String,pId: string): Observable<any> {
  console.log("getAccessLogListByUserTypeUrl  list.." + pId);
  return this._http.get(`${this.getAccessLogListByUserTypeUrl}` + '?pId=' + pId+'&userType='+userType, this.httpOptions).pipe(
    map(this.extractData)
  );
}
//getAccessLogListByDatesAndUserTypeAndUserNameUrl
public getAccessLogListByDatesAndUserTypeAndUserName(logOnDate:any,logOffDate:any,userType:String,userName:any,pId: string): Observable<any> {
  console.log("getAccessLogListByDatesAndUserTypeAndUserNameUrl  list.." + pId);
  return this._http.get(`${this.getAccessLogListByDatesAndUserTypeAndUserNameUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userType='+userType+'&userName='+userName, this.httpOptions).pipe(
    map(this.extractData)
  );
}
//getAccessLogListByDatesAndUserTypeUrl
public getAccessLogListByDatesAndUserType(logOnDate:any,logOffDate:any,userType:String,pId: string): Observable<any> {
  console.log("getAccessLogListByDatesAndUserTypeUrl  list.." + pId);
  return this._http.get(`${this.getAccessLogListByDatesAndUserTypeUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate+'&userType='+userType, this.httpOptions).pipe(
    map(this.extractData)
  );
}
//getAccessLogListByDatesUrl
public getAccessLogListByDates(logOnDate:any,logOffDate:any,pId: string): Observable<any> {
  console.log("getAccessLogListByDatesUrl  list.." + pId);
  return this._http.get(`${this.getAccessLogListByDatesUrl}` + '?pId=' + pId+'&logOnDate='+logOnDate+'&logOffDate='+logOffDate, this.httpOptions).pipe(
    map(this.extractData)
  );
}
}
