<!-- Footer Area Start -->
<footer class="footer-section">
    <div class="containerr">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="newsletter py-0 mb-450">
                    <div class="section-area mb-30 dark-sec text-center">
                        <h3 class="title">Subscribe to Our Rates Alert</h3>
                    </div>
                    <form action="#" class="pb-3">
                        <div class="form-group d-flex align-items-center">
                            <input type="text" placeholder="Your Email Address">
                            <button><img src="assetsnew/images/icon/arrow-right-2.png" alt="icon"></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="footer-area pt-120 px-120">
            <div class="footer-top">
                <div class="row align-items-center">
                    <div class="col-sm-6 d-flex justify-content-center justify-content-sm-start">
                        <div class="menu-item">
                            <ul class="footer-link d-flex align-items-center">
                                <li><a href="javascript:void(0)" (click)="openaboutus()">About Us</a></li>
                                <li><a href="javascript:void(0)" (click)="opencontact()">Contact</a></li>
                                <li><a href="javascript:void(0)" (click)="openfaqs()">Faqs</a></li>
                                <li><a href="javascript:void(0)" (click)="openhelp()">How it works</a></li>
                                <li><a href="javascript:void(0)" (click)="deleteaccount()">Delete Account</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div
                            class="social-link d-flex justify-content-sm-end justify-content-center align-items-center">
                            <a href="https://www.facebook.com/Tayrexchange?mibextid=ZbWKwL"><img
                                    src="assetsnew/images/icon/facebook.png" alt="icon"></a>
                            <a href="javascript:void(0)"><img src="assetsnew/images/icon/linkedin.png" alt="icon"></a>
                            <a href="https://instagram.com/tayrex_exchange?igshid=ZGUzMzM3NWJiOQ=="><img
                                    src="assetsnew/images/icon/instagram.png" alt="icon"></a>
                            <a href="https://twitter.com/tayrexexchange?t=ChWMkWGfDY3bxfYqE462xQ&s=09"><img
                                    src="assetsnew/images/icon/new-twitter.png"  style="border-radius:50%" width="28.63px" height="28.63px" alt="icon"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="row justify-content-between align-items-center">
                    <div
                        class="col-md-6 col-sm-8 d-flex justify-content-center justify-content-sm-start order-sm-0 order-1">
                        <div class="copyright text-center text-sm-start">
                            <p> Copyright © 2023 <a href="javascript:void(0)" (click)="openHome()">Tayrex Exchange.</a> All Rights Reserved.</p>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-4">
                        <div class="menu-item">
                            <ul
                                class="footer-link d-flex justify-content-sm-end justify-content-center align-items-center">
                                <li><a href="javascript:void(0)" (click)="openuseraggrement()">Terms</a></li>
                                <li><a href="javascript:void(0)" (click)="openprivacypolicy()">Privacy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer Area End -->