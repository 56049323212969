import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import{ environment} from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  apiUrl=environment.baseUrl;

  public recCountries : string = `${this.apiUrl}/keyclock/getClientReceivingCountrys`;
  httpOptions = {
        headers : new HttpHeaders ({'content-type' : 'application/json'}).set('Authorization', localStorage.getItem("datas"))
  };

  constructor(private _http:HttpClient) { }
  private  extractData( res: Response ) : Array <any> | {} {
    const body = res;
    console.log('res ',res)
    return body || {} ;
   }
   public getClientReceivingCountrys(token: any, pId: string): Observable<any> {
     console.log("working getusers client reciebing countries..." + pId);
     return this._http.get(`${this.recCountries}` + '?pId=' + pId, this.httpOptions).pipe(
       map(this.extractData)
     );
   }




}
