<div class="carousel">
  <div
    class="slides"
    [style.transform]="'translateX(-' + currentIndex * 33.3333 + '%)'"
  >
    <div
      class="slide"
      *ngFor="let slide of slides; let i = index"
      [class.center]="i === currentIndex"
      [class.side]="
        i !== currentIndex && (i === currentIndex - 1 || i === currentIndex + 1)
      "
    >
      <div class="single-slide row">
        <div class="single-content review col-md-12">
          <div class="start-area">
            <a href="javascript:void(0)"><i class="fas fa-star"></i></a>
            <a href="javascript:void(0)"><i class="fas fa-star"></i></a>
            <a href="javascript:void(0)"><i class="fas fa-star"></i></a>
            <a href="javascript:void(0)"><i class="fas fa-star"></i></a>
            <a href="javascript:void(0)"><i class="fas fa-star"></i></a>
          </div>
          <h5 class="title-area review">{{ slide.caption }}</h5>
          <p class="xlr desc">"{{ slide.desc }}"</p>
          <div class="profile-area d-flex align-items-center">
            <div class="icon-area">
              <img src="{{ slide.imageUrl }}" alt="Slide {{ i + 1 }}" />
            </div>
            <div class="text-area mx-2">
              <h5 class="author">{{ slide.author }}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="arrows mt-5">
    <span class="arrow left" (click)="prevSlide()">&#8592;</span>
    <span class="arrow right" (click)="nextSlide()">&#8594;</span>
  </div>
</div>
